import { AxiosPromise } from 'axios';

import { SchedulesResponse, SchedulesRequest } from '../../types';

import { AXIOS, RequestConfig } from '../endpoints';

export function getSchedules(
  request: SchedulesRequest,
  config?: RequestConfig
): AxiosPromise<SchedulesResponse> {
  return AXIOS.get('/schedules', { ...config, params: request });
}
