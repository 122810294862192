import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@react-pdf/renderer';

import { resultsPdfStyles } from '../../../../../components';
import { InsertDriveFileSvg } from '../../../../../components/pdf/PdfIcons';

import { SubmissionDetail } from '../../../../../types';
import { dataStoreNameFromPath } from '../../../../../util';

import { PdfSection, PdfSectionHeader, PdfTableRow, PdfTableCell } from '../components';

interface PdfSubmissionDataProps {
  submission: SubmissionDetail;
}

const PdfSubmissionData: FC<PdfSubmissionDataProps> = ({ submission }) => {
  const dataStores = Array.from(
    new Set([
      ...Object.values(submission.inputsByKey)
        .filter((input) => !!input.file)
        .map((input) => input.selectedDataStore),
      ...Object.keys(submission.modifiedInputs),
      ...Object.keys(submission.outputs),
    ])
  ).sort();

  const auxiliaryInputs = Object.values(submission.inputsByKey).filter(
    (input) => !input.selectedDataStore
  );
  const renderSubmissionDataRow = () => {
    if (dataStores.length) {
      return (
        <>
          {dataStores.map((dataStore) => {
            if (!dataStore) {
              return null;
            }
            const input =
              submission.inputsByKey &&
              Object.values(submission.inputsByKey).find(
                ({ selectedDataStore }) => selectedDataStore === dataStore
              );
            return (
              <PdfTableRow key={dataStore}>
                <PdfTableCell colSpan="half">
                  <Text>{dataStoreNameFromPath(dataStore)}</Text>
                </PdfTableCell>
                <PdfTableCell colSpan="half">
                  <Text>
                    {input?.file ? (
                      input.file.filename
                    ) : (
                      <FormattedMessage
                        id="submission.resultsPdf.dataSet.noUploadedFile"
                        defaultMessage="No uploaded file"
                      />
                    )}
                  </Text>
                </PdfTableCell>
              </PdfTableRow>
            );
          })}
          {auxiliaryInputs.map((input) => {
            if (!input.file) {
              return null;
            }
            return (
              <PdfTableRow key={input.inputName}>
                <PdfTableCell colSpan="half">
                  <Text>{input.inputName}</Text>
                </PdfTableCell>
                <PdfTableCell colSpan="half">
                  <Text>
                    {input?.file ? (
                      input.file.filename
                    ) : (
                      <FormattedMessage
                        id="submission.resultsPdf.dataSet.noUploadedFile"
                        defaultMessage="No uploaded file"
                      />
                    )}
                  </Text>
                </PdfTableCell>
              </PdfTableRow>
            );
          })}
        </>
      );
    }

    return (
      <PdfTableRow>
        <PdfTableCell>
          <Text>
            <FormattedMessage
              id="submission.resultsPdf.dataSet.noUploadedFiles"
              defaultMessage="No uploaded files"
            />
          </Text>
        </PdfTableCell>
      </PdfTableRow>
    );
  };

  return (
    <PdfSection gutterBottom>
      <PdfSectionHeader>
        <InsertDriveFileSvg
          style={[resultsPdfStyles.mediumIcon, resultsPdfStyles.iconMarginRight]}
        />
        <Text style={[resultsPdfStyles.sectionHeaderText]}>
          <FormattedMessage
            id="submission.resultsPdf.dataSet.title"
            defaultMessage="Submission Data"
          />
        </Text>
      </PdfSectionHeader>
      <PdfTableRow header>
        <PdfTableCell colSpan="half" header>
          <Text>
            <FormattedMessage
              id="submission.resultsPdf.dataSet.dataSetHeader"
              defaultMessage="Data Set"
            />
          </Text>
        </PdfTableCell>
        <PdfTableCell colSpan="half" header>
          <Text>
            <FormattedMessage
              id="submission.resultsPdf.dataSet.fileHeader"
              defaultMessage="Uploaded File"
            />
          </Text>
        </PdfTableCell>
      </PdfTableRow>
      {renderSubmissionDataRow()}
    </PdfSection>
  );
};

export default PdfSubmissionData;
