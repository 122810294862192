import { FC, useState } from 'react';

import { ProjectImportJobDetail } from '../../../types';

import UploadZip from './UploadZip';
import PreviewUpload from './PreviewUpload';

const Import: FC = () => {
  const [jobDetail, setJobDetail] = useState<ProjectImportJobDetail>();

  if (jobDetail) {
    return <PreviewUpload jobDetail={jobDetail} clearJob={() => setJobDetail(undefined)} />;
  }

  return <UploadZip onUploaded={setJobDetail} />;
};

export default Import;
