import { AxiosPromise } from 'axios';

import { CopySavedMappingRequest, SavedMappingDetail } from '../../types';

import { AXIOS } from '../endpoints';

export function copySavedMapping(
  assignmentKey: string,
  request: CopySavedMappingRequest
): AxiosPromise<SavedMappingDetail> {
  return AXIOS.post(`assignments/${assignmentKey}/copy_saved_mapping`, request);
}
