import { AXIOS } from '../endpoints';
import { AxiosPromise } from 'axios';

import { MailSenderSettings } from '../../types/mail';

export function getSenderSettings(): AxiosPromise<MailSenderSettings> {
  return AXIOS.get(`mail/sender`);
}

export function updateSenderSettings(
  senderSettings: MailSenderSettings
): AxiosPromise<MailSenderSettings> {
  return AXIOS.post(`mail/sender`, senderSettings);
}
