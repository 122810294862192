import { useState, FC, useEffect, PropsWithChildren, useRef } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';

import { LayoutAppBar } from './app-bar';
import { Sidebar } from './sidebar';

export const DRAWER_WIDTH = 240;
export const OPEN_DELAY = 20;

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const permanentDrawer = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const shiftContent = sidebarOpen && permanentDrawer;

  useEffect(() => {
    setSidebarOpen(permanentDrawer);
  }, [permanentDrawer]);

  const handleDrawerToggle = () => {
    setSidebarOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (shiftContent) {
      setTimeout(function () {
        contentRef.current?.classList.remove('sidebarHidden');
      }, OPEN_DELAY);
    } else {
      contentRef.current?.classList.add('sidebarHidden');
    }
  }, [shiftContent]);

  return (
    <>
      <LayoutAppBar handleDrawerToggle={handleDrawerToggle} shiftAppBar={shiftContent} />
      <Sidebar
        handleDrawerToggle={handleDrawerToggle}
        sidebarOpen={sidebarOpen}
        permanentDrawer={permanentDrawer}
      />
      <Box
        ref={contentRef}
        sx={{
          'marginLeft': `${DRAWER_WIDTH}px`,
          'transition': (theme) =>
            theme.transitions.create(['margin-left'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          '&.sidebarHidden': {
            transition: (theme) =>
              theme.transitions.create(['margin-left'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            marginLeft: 0,
          },
        }}
        flexGrow={1}
        component="main"
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
