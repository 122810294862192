import { FC, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Grid, FormControlLabel, Switch, Container } from '@mui/material';

import { PaddedPaper } from '../../../../../components';
import { intl } from '../../../../../Internationalization';
import { extractErrorMessage } from '../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../api/specification/specification';
import { useTitle } from '../../../../../hooks';

import { SpecificationContext } from '../SpecificationContext';

import Documents from './Documents';
import Documentation from './Documentation';

const SpecificationInformation: FC = () => {
  const { specification, specificationKey, specificationUpdated } =
    useContext(SpecificationContext);
  const { enqueueSnackbar } = useSnackbar();
  useTitle(
    intl.formatMessage(
      { id: 'title.specification', defaultMessage: 'Specification ({specificationName})' },
      { specificationName: specification.name }
    )
  );

  const [showInformation, setShowInformation] = useState<boolean>(specification.showInformation);
  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    const updateSpecification = async () => {
      try {
        setProcessing(true);
        const updatedSpecification = { ...specification, showInformation };
        const { data } = await SpecificationApi.updateSpecification(
          specificationKey,
          updatedSpecification
        );
        specificationUpdated(data);
        enqueueSnackbar(
          data.showInformation
            ? intl.formatMessage({
                id: 'specification.information.saveSuccess.showInformation',
                defaultMessage: 'Specification information now displaying',
              })
            : intl.formatMessage({
                id: 'specification.information.saveSuccess.hideInformation',
                defaultMessage: 'Specification information now hidden',
              }),
          { variant: 'success' }
        );
      } catch (error: any) {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'specification.information.saveError',
              defaultMessage: 'Failed to update specification',
            })
          ),
          { variant: 'error' }
        );
      } finally {
        setProcessing(false);
      }
    };

    if (showInformation !== specification.showInformation) {
      updateSpecification();
    }
  }, [enqueueSnackbar, showInformation, specification, specificationKey, specificationUpdated]);

  return (
    <Container maxWidth="lg" id="project-specification-information" disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaddedPaper>
            <FormControlLabel
              labelPlacement="start"
              label={intl.formatMessage({
                id: 'specification.information.displayInformation.label',
                defaultMessage: 'Display information to suppliers?',
              })}
              control={
                <Switch
                  name="showInformation"
                  checked={showInformation}
                  onChange={() => setShowInformation((showInfo) => !showInfo)}
                  disabled={processing}
                />
              }
            />
          </PaddedPaper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Documentation />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Documents />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SpecificationInformation;
