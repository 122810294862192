import defaultProjectImage from '../../assets/images/default-project.jpg';
import { ProjectMetadata, VirusCheckedMediaDetail, virusScanStatePassed } from '../../types';
import { generateApiUri } from '../endpoints';

export const downloadProjectImageUri = (
  projectKey: string,
  image: VirusCheckedMediaDetail
): string => {
  return generateApiUri(`/projects/${projectKey}/image/${image.storedFilename}`);
};

export const projectImageOrDefault = ({ key, image }: ProjectMetadata): string =>
  image && virusScanStatePassed(image.virusScanState)
    ? downloadProjectImageUri(key, image)
    : defaultProjectImage;
