import { useContext } from 'react';
import { Grid, Container } from '@mui/material';

import * as ProjectApi from '../../../api/project/project';
import { ReceiverAdminOnly, UploadAndVirusScanImage } from '../../../components';
import { FileUploadConfig } from '../../../api/endpoints';
import * as ProjectMediaApi from '../../../api/project/projectMedia';
import { VirusCheckedMediaDetail } from '../../../types';
import { intl } from '../../../Internationalization';
import { useTitle } from '../../../hooks';

import { ProjectContext } from './ProjectContext';
import ProjectSettingsForm from './ProjectSettingsForm';
import ProjectManagement from './ProjectManagement';

function ProjectSettings() {
  const { projectKey, project, projectUpdated, handleRefreshProject } = useContext(ProjectContext);
  useTitle(
    intl.formatMessage(
      { id: 'title.project', defaultMessage: 'Project ({projectName})' },
      { projectName: project.name }
    )
  );

  const uploadImage = (image: File, fileUploadConfig?: FileUploadConfig) =>
    ProjectApi.uploadProjectImage(projectKey, image, fileUploadConfig);
  const deleteImage = () => ProjectApi.deleteProjectImage(projectKey);

  const imageUploaded = (image: VirusCheckedMediaDetail) => projectUpdated({ ...project, image });
  const imageDeleted = () => projectUpdated({ ...project, image: undefined });

  return (
    <Container maxWidth="lg" id="project-settings" disableGutters>
      <Grid container spacing={3} id="project-configuration-content">
        <Grid item xs={12} sm={6}>
          <ProjectSettingsForm />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UploadAndVirusScanImage
            id="project-settings-image"
            title={intl.formatMessage({
              id: 'project.settings.imageUpload.title',
              defaultMessage: 'Project Image',
            })}
            image={project.image}
            imageUrl={ProjectMediaApi.projectImageOrDefault(project)}
            uploadHandler={uploadImage}
            deleteHandler={deleteImage}
            onUploaded={imageUploaded}
            onDeleted={imageDeleted}
            imageStyle={project.imageStyle}
            pollApi={handleRefreshProject}
          />
        </Grid>
        <ReceiverAdminOnly>
          <Grid item xs={12}>
            <ProjectManagement />
          </Grid>
        </ReceiverAdminOnly>
      </Grid>
    </Container>
  );
}

export default ProjectSettings;
