import { FC } from 'react';
import { ValidateFieldsError } from 'async-validator';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material';

import { InputTooltip } from '.';

interface ValidatedFieldProps {
  fieldErrors?: ValidateFieldsError;
  name: string;
  tooltip?: string;
}

export interface ValidatedCheckboxProps extends ValidatedFieldProps {
  checkboxProps: Omit<CheckboxProps, 'name'>;
  formControlLabelProps: Omit<FormControlLabelProps, 'control'>;
}

const ValidatedCheckbox: FC<ValidatedCheckboxProps> = ({
  name,
  fieldErrors,
  checkboxProps,
  formControlLabelProps,
  tooltip,
}) => {
  const errors = (fieldErrors && fieldErrors[name]) || undefined;

  const renderErrors = () => {
    return (
      errors &&
      errors.map((e, i) => (
        <FormHelperText key={i} error data-error-for={name}>
          {e.message}
        </FormHelperText>
      ))
    );
  };

  const field = (
    <FormControlLabel
      {...formControlLabelProps}
      control={<Checkbox name={name} {...checkboxProps} />}
    />
  );

  const renderContent = () => {
    if (tooltip) {
      return (
        <InputTooltip title={tooltip} data-tooltip-for={name}>
          {field}
        </InputTooltip>
      );
    }

    return field;
  };

  return (
    <>
      {renderContent()}
      {renderErrors()}
    </>
  );
};

export default ValidatedCheckbox;
