import { AXIOS, entityDoesNotExist } from '../endpoints';

export function receiverMappingByGroup(
  identityProviderKey: string,
  group: string
): Promise<boolean> {
  return entityDoesNotExist(
    AXIOS.head('/receiverMappingByGroup', { params: { identityProviderKey, group } })
  );
}
