import { AxiosPromise } from 'axios';

import {
  SpecificationDetail,
  SpecificationSettings,
  DataStoreConfig,
  TaskConfig,
  SpecificationValidationResult,
  SessionSchema,
  evaluateSpecificationValidationResult,
  DataStoreConfigDetail,
  VirusCheckedMediaDetail,
  SpecificationInputDetail,
  InputConfig,
} from '../../types';
import { AXIOS, FileUploadConfig } from '../endpoints';

export function getSpecification(specificationKey: string): AxiosPromise<SpecificationDetail> {
  return AXIOS.get(`/specifications/${specificationKey}`);
}

export function updateSpecification(
  specificationKey: string,
  specification: SpecificationSettings
): AxiosPromise<SpecificationDetail> {
  return AXIOS.post(`/specifications/${specificationKey}`, specification);
}

export function validateSpecification(
  specificationKey: string
): AxiosPromise<SpecificationValidationResult> {
  return AXIOS.get(`/specifications/${specificationKey}/validate`);
}

export const isSpecificationValid = async (specificationKey: string) => {
  return evaluateSpecificationValidationResult(
    (await AXIOS.get(`/specifications/${specificationKey}/validate`)).data
  );
};

export function getDataStoreConfigs(
  specificationKey: string
): AxiosPromise<DataStoreConfigDetail[]> {
  return AXIOS.get(`/specifications/${specificationKey}/dataStores`);
}

export function updateDataStoreConfigs(
  specificationKey: string,
  request: DataStoreConfig[]
): AxiosPromise<DataStoreConfigDetail[]> {
  return AXIOS.post(`/specifications/${specificationKey}/dataStores`, request);
}

export function getInputs(specificationKey: string): AxiosPromise<SpecificationInputDetail[]> {
  return AXIOS.get(`/specifications/${specificationKey}/inputs`);
}

export function updateInputs(
  specificationKey: string,
  request: InputConfig[]
): AxiosPromise<SpecificationInputDetail[]> {
  return AXIOS.post(`/specifications/${specificationKey}/inputs`, request);
}

export function getSessionSchema(specificationKey: string): AxiosPromise<SessionSchema> {
  return AXIOS.get(`/specifications/${specificationKey}/session`);
}

export function getTaskConfigs(specificationKey: string): AxiosPromise<TaskConfig[]> {
  return AXIOS.get(`/specifications/${specificationKey}/tasks`);
}

export function updateTaskConfigs(
  specificationKey: string,
  request: TaskConfig[]
): AxiosPromise<TaskConfig[]> {
  return AXIOS.post(`/specifications/${specificationKey}/tasks`, request);
}

export function uploadDocument(
  specificationKey: string,
  files: File[],
  config: FileUploadConfig
): AxiosPromise<VirusCheckedMediaDetail[]> {
  var formData = new FormData();

  files.forEach((file) => {
    formData.append('file', file);
  });

  return AXIOS.post(`/specifications/${specificationKey}/documents`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...(config || {}),
  });
}

export function removeDocument(
  specificationKey: string,
  storedFilename: string
): AxiosPromise<VirusCheckedMediaDetail[]> {
  return AXIOS.delete(`/specifications/${specificationKey}/documents`, {
    params: { storedFilename },
  });
}

export function extractSpecificationSettings({
  name,
  description,
  active,
  sessionPath,
  allowSourceUnmapped,
  allowTargetUnmapped,
  showTargetUnmappedWarning,
  showInformation,
  documentation,
  timeoutPeriod,
  autoClosePeriod,
  mediaRetentionPeriod,
  mediaRetentionPolicy,
  conformanceMode,
  enableMap,
}: SpecificationDetail): SpecificationSettings {
  return {
    name,
    description,
    active,
    sessionPath,
    allowSourceUnmapped,
    allowTargetUnmapped,
    showTargetUnmappedWarning,
    showInformation,
    documentation,
    timeoutPeriod,
    autoClosePeriod,
    mediaRetentionPeriod,
    mediaRetentionPolicy,
    conformanceMode,
    enableMap,
  };
}
