import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Container, Paper, Table, TableCell, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {
  browseTableBody,
  DefaultButton,
  FilterToggle,
  ProjectAutocomplete,
  StyledTableHead,
} from '../../../components';
import { exportProjectUri } from '../../../api/export';
import { intl } from '../../../Internationalization';
import { ProjectDetail } from '../../../types';

import ProjectExportRowDetails from './ProjectExportRowDetails';

const ProjectTableBody = browseTableBody<ProjectDetail>();

const ProjectExport: FC = () => {
  const [addProjects, setAddProjects] = useState<ProjectDetail[]>([]);
  const [projects, setProjects] = useState<ProjectDetail[]>([]);

  const [active, setActive] = useState<boolean | undefined>(true);

  const [expandedProject, setExpandedProject] = useState<ProjectDetail>();

  const handleAddProject = (newProjects: ProjectDetail[]) => {
    setProjects((prev) => [...prev, ...newProjects]);
    setAddProjects([]);
  };

  const handleRemoveProject = (projectKey: string) => {
    if (expandedProject?.key === projectKey) {
      setExpandedProject(undefined);
    }
    setProjects((prev) => {
      const updatedProjects = [...prev];
      const index = updatedProjects.findIndex((project) => project.key === projectKey);

      updatedProjects.splice(index, 1);

      return updatedProjects;
    });
  };

  const handleActiveToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked || undefined);
    setAddProjects([]);
  };

  const projectRow = (project: ProjectDetail) => (
    <ProjectExportRowDetails
      key={project.key}
      project={project}
      isExpanded={!!expandedProject && expandedProject.key === project.key}
      handleExpandProject={setExpandedProject}
      handleRemoveProject={handleRemoveProject}
    />
  );

  return (
    <Container maxWidth="lg" id="system-project-import-export" disableGutters>
      <Paper>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: '100%' }} colSpan={2}>
                  <Box display="flex" columnGap={2} width="100%" flexGrow={1}>
                    <ProjectAutocomplete
                      id="project-select"
                      tooltip={intl.formatMessage({
                        id: 'projectImport.export.projectSelect.tooltip',
                        defaultMessage: 'Select project',
                      })}
                      name="projectSelect"
                      label={intl.formatMessage({
                        id: 'projectImport.export.projectSelect.label',
                        defaultMessage: 'Project',
                      })}
                      value={addProjects}
                      multiple
                      onChange={setAddProjects}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      filterOptions={(options) =>
                        options.filter(
                          (option) => !projects.map((project) => project.key).includes(option.key)
                        )
                      }
                      active={active}
                    />
                    <FilterToggle
                      label={intl.formatMessage({
                        id: 'projectImport.filterActiveToggle.label',
                        defaultMessage: 'Active Projects Only',
                      })}
                      name="active"
                      checked={!!active}
                      onChange={handleActiveToggle}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <DefaultButton
                    name="addProjectButton"
                    startIcon={<AddIcon />}
                    color="grey"
                    onClick={() => addProjects && handleAddProject(addProjects)}
                    disabled={!addProjects}
                  >
                    <FormattedMessage id="projectImport.export.addButton" defaultMessage="Add" />
                  </DefaultButton>
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  <DefaultButton
                    name="export"
                    startIcon={<FileDownloadIcon />}
                    download
                    href={exportProjectUri(projects.map((project) => project.key))}
                    disabled={!projects.length}
                  >
                    <FormattedMessage
                      id="projectImport.export.exportButton"
                      defaultMessage="Export"
                    />
                  </DefaultButton>
                </TableCell>
              </TableRow>
            </StyledTableHead>
          </Table>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="projectImport.export.preview.projectsColumn"
                    defaultMessage="Projects"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="projectImport.export.preview.specificationsColumn"
                    defaultMessage="Specifications"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="projectImport.export.preview.assignmentsColumn"
                    defaultMessage="Assignments"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="projectImport.export.preview.suppliersColumn"
                    defaultMessage="Supplier"
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            </StyledTableHead>
            <ProjectTableBody
              data={projects}
              mapToRow={projectRow}
              noDataMessage={intl.formatMessage({
                id: 'projectImport.export.noProjects',
                defaultMessage: 'No selected project.',
              })}
              numCols={5}
            />
          </Table>
        </Box>
      </Paper>
    </Container>
  );
};
export default ProjectExport;
