import { FC } from 'react';
import { CardContent, Typography, Grid, Box, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AssignmentDetail } from '../../types';
import { intl } from '../../Internationalization';

import { NavigationCard, SupplierAvatar } from '../';

import ProjectCardImage from './ProjectCardImage';

interface AssignmentCardProps {
  assignment: AssignmentDetail;
  assignmentUrl: string;
}

const AssignmentCard: FC<AssignmentCardProps> = ({
  assignment: {
    reference,
    key,
    viewOnly,
    specification,
    specification: { project },
    supplier,
  },
  assignmentUrl,
  ...rest
}) => (
  <Grid item xs={12} sm={6} lg={4} xl={3}>
    <NavigationCard
      to={assignmentUrl}
      key={key}
      sx={viewOnly ? { opacity: 0.7, filter: 'grayscale(50%)' } : {}}
      {...rest}
    >
      <SupplierAvatar
        sx={{ position: 'absolute', right: 10, top: 10 }}
        supplier={supplier}
        size="large"
      />
      <ProjectCardImage project={project} sx={{ width: '100%', height: 200 }} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {specification.name} - {reference}
        </Typography>
        <Typography gutterBottom variant="subtitle1" color="textSecondary" component="h3">
          {project.name}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="textSecondary" component="p">
            {specification.description}
          </Typography>
          {viewOnly && (
            <Tooltip
              title={intl.formatMessage({
                id: 'assignmentCard.viewOnly.tooltip',
                defaultMessage: 'View only assignment',
              })}
            >
              <VisibilityIcon color="primary" />
            </Tooltip>
          )}
        </Box>
      </CardContent>
    </NavigationCard>
  </Grid>
);

export default AssignmentCard;
