import { FC, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import PersonIcon from '@mui/icons-material/Person';
import { Box, Typography } from '@mui/material';

import { intl } from '../../Internationalization';
import {
  AccountHeader,
  AppDecorator,
  AppTabs,
  HeaderBreadcrumb,
  RouterTab,
  RouterTabs,
} from '../../components';
import { AuthenticatedContext } from '../../contexts/authentication';
import { ErrorBlock } from '../../contexts/error-block';
import { useRouterTab, useTitle } from '../../hooks';
import ScheduledSubmissions from '../components/ScheduledSubmissions';
import ApiKeyRouting from '../components/api-keys/ApiKeyRouting';
import ChangePassword from './ChangePassword';
import MyAvatar from './MyAvatar';
import MyProfile from './MyProfile';

const MyAccountRouting: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.myAccount',
      defaultMessage: 'My Account',
    })
  );
  const { me } = useContext(AuthenticatedContext);
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="my-account-tabs" value={routerTab}>
      <RouterTab
        id="profile-tab"
        value="profile"
        label={intl.formatMessage({
          id: 'myAccount.routing.tabs.profile',
          defaultMessage: 'Profile',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="password-tab"
        value="password"
        label={intl.formatMessage({
          id: 'myAccount.routing.tabs.password',
          defaultMessage: 'Password',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="avatar-tab"
        value="avatar"
        label={intl.formatMessage({
          id: 'myAccount.routing.tabs.avatar',
          defaultMessage: 'Avatar',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="scheduled-submissions-tab"
        value="scheduled_submissions"
        label={intl.formatMessage({
          id: 'myAccount.routing.tabs.scheduledSubmissions',
          defaultMessage: 'Scheduled Submissions',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="api-keys-tab"
        value="api_keys"
        label={intl.formatMessage({
          id: 'myAccount.routing.tabs.apiKeys',
          defaultMessage: 'API Keys',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  const renderBreadcrumb = () => {
    const { email } = me;

    return (
      <HeaderBreadcrumb>
        <Box display="flex" alignItems="center">
          <PersonIcon
            titleAccess={intl.formatMessage({
              id: 'screens.myAccountRouting.user.titleAccess',
              defaultMessage: 'User',
            })}
          />
          <Typography color="inherit">{email}</Typography>
        </Box>
      </HeaderBreadcrumb>
    );
  };

  return (
    <AppDecorator
      title={intl.formatMessage({ id: 'myAccount.routing.title', defaultMessage: 'My Account' })}
      renderTabs={renderTabs}
      renderBreadcrumb={renderBreadcrumb}
    >
      <AccountHeader user={me} />
      <AppTabs />
      <ErrorBlock>
        <Box p={3}>
          <Routes>
            <Route path="profile" element={<MyProfile />} />
            <Route path="password" element={<ChangePassword />} />
            <Route path="avatar" element={<MyAvatar />} />
            <Route
              path="scheduled_submissions"
              element={<ScheduledSubmissions userKey={me.key} />}
            />
            <Route path="api_keys/*" element={<ApiKeyRouting user={me} />} />
            <Route path="*" element={<Navigate to="profile" replace />} />
          </Routes>
        </Box>
      </ErrorBlock>
    </AppDecorator>
  );
};

export default MyAccountRouting;
