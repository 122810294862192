import { FC, useCallback, useContext } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Link, Typography } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MapIcon from '@mui/icons-material/Map';

import { AppDecorator } from '../../../../../../components';

import { HeaderBreadcrumb } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';
import { dateTimeFormat } from '../../../../../../util';
import { useQuery, useTitle } from '../../../../../../hooks';

import { toMySubmissionLink } from '../../MySubmissions';

import { OpenSubmissionContext } from '../OpenSubmissionContext';
import MapViewState from './MapViewState';
import MapViewerComponents from './MapViewerComponents';
import MapViewerSessionState from './MapViewerSessionState';
import MapViewerReportsState from './MapViewerReportsState';
import MapViewerSelectionState from './MapViewerSelectionState';

export const getGothicClassName = (name: string): string => {
  if (name.startsWith('sys:')) {
    return name;
  }

  return 'rs:' + name;
};

const MapViewer: FC = () => {
  useTitle(intl.formatMessage({ id: 'title.submissionMap', defaultMessage: 'Submission Map' }));
  const queryParams = useQuery();
  const taskId = queryParams.get('taskId') ?? '';

  const { assignment, submission } = useContext(OpenSubmissionContext);

  const renderBreadcrumb = useCallback(
    () => (
      <HeaderBreadcrumb>
        <Link component={RouterLink} to={`/my_assignments/${assignment.key}/submissions`}>
          <AssignmentIcon
            titleAccess={intl.formatMessage({
              id: 'openSubmission.map.mapContainer.breadcrumb.assignment.titleAccess',
              defaultMessage: 'Assignment',
            })}
          />
          {assignment.reference}
        </Link>
        <Link component={RouterLink} to={toMySubmissionLink(submission)}>
          <AssessmentIcon
            titleAccess={intl.formatMessage({
              id: 'openSubmission.map.mapContainer.breadcrumb.submission.titleAccess',
              defaultMessage: 'Submission created at',
            })}
          />
          {dateTimeFormat(submission.createdAt)}
        </Link>
        <Typography>
          <MapIcon />
          <FormattedMessage
            id="openSubmission.map.mapContainer.breadcrumb.viewMap"
            defaultMessage="View Map"
          />
        </Typography>
      </HeaderBreadcrumb>
    ),
    [assignment.key, assignment.reference, submission]
  );

  return (
    <AppDecorator renderBreadcrumb={renderBreadcrumb} renderTabs={undefined}>
      <MapViewState>
        <MapViewerSessionState assignmentKey={assignment.key} taskId={taskId}>
          <MapViewerReportsState>
            <MapViewerSelectionState>
              <MapViewerComponents />
            </MapViewerSelectionState>
          </MapViewerReportsState>
        </MapViewerSessionState>
      </MapViewState>
    </AppDecorator>
  );
};

export default MapViewer;
