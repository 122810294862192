import { AxiosPromise } from 'axios';

import { NotificationDetail, NotificationPatch } from '../../types';
import { AXIOS } from '../endpoints';

export function patchNotification(
  request: NotificationPatch,
  notificationKey: string
): AxiosPromise<NotificationDetail> {
  return AXIOS.patch(`/my_notifications/${notificationKey}`, request);
}

export function deleteNotification(notificationKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/my_notifications/${notificationKey}`);
}
