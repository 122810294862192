import { FC, useContext } from 'react';

import * as MeApi from '../../api/me';
import * as UserMediaApi from '../../api/user/userMedia';
import { AvatarSelect } from '../../components';
import { AuthenticatedContext } from '../../contexts/authentication';
import { intl } from '../../Internationalization';
import { AvatarDetail, MediaDetail } from '../../types';
import { Container } from '@mui/material';

const MyAvatar: FC = () => {
  const { me, updateMe, refresh } = useContext(AuthenticatedContext);
  const onUpdate = (avatar: AvatarDetail) => {
    updateMe({ ...me, avatar });
  };

  const onDelete = () => {
    updateMe({ ...me, avatar: { ...me.avatar, image: undefined } });
  };

  return (
    <Container maxWidth="md" disableGutters>
      <div id="account-avatar">
        <AvatarSelect
          title={intl.formatMessage({
            id: 'account.myAvatar.title',
            defaultMessage: 'Profile Avatar',
          })}
          avatar={me.avatar}
          name={me.name}
          onUpdate={onUpdate}
          onDelete={onDelete}
          deleteImage={MeApi.deleteImage}
          uploadImage={MeApi.uploadImageAndVirusCheck}
          updateAvatar={MeApi.updateAvatar}
          imageUrl={(image: MediaDetail) => UserMediaApi.downloadImageUri(me.key, image)}
          pollApi={refresh}
        />
      </div>
    </Container>
  );
};

export default MyAvatar;
