import { AxiosPromise } from 'axios';
import {
  ApplyMappingRequest,
  DataStoreClassMapping,
  DataStoreMapping,
  MappingRatios,
  MappingRecommendationRequest,
} from '../../types';
import { AXIOS } from '../endpoints';

export function updateSchemaMappings(
  submissionReference: string,
  schemaMap: DataStoreMapping[]
): AxiosPromise<DataStoreMapping[]> {
  return AXIOS.post(`/submissions/${submissionReference}/schema/mappings`, schemaMap);
}

export function evaluateMappings(submissionReference: string): AxiosPromise<MappingRatios> {
  return AXIOS.get(`/submissions/${submissionReference}/schema/mappings/evaluate`);
}

export function applyMapping(
  submissionReference: string,
  request: ApplyMappingRequest
): AxiosPromise<DataStoreMapping[]> {
  return AXIOS.post(`/submissions/${submissionReference}/schema/mappings/apply`, undefined, {
    params: request,
  });
}
export function recommendAttributeMappings(
  submissionReference: string,
  request: MappingRecommendationRequest
): AxiosPromise<DataStoreClassMapping> {
  return AXIOS.post(`/submissions/${submissionReference}/schema/mappings/recommendation`, request);
}
