import { AxiosPromise } from 'axios';
import {
  AssignmentSavedMappingsRequest,
  SavedMappingDetail,
  SavedMappingResponse,
  SavedMappingSettings,
} from '../../types';

import { AXIOS, RequestConfig } from '../endpoints';

export function getSavedMappings(
  assignmentKey: string,
  request: AssignmentSavedMappingsRequest,
  config?: RequestConfig
): AxiosPromise<SavedMappingResponse> {
  return AXIOS.get(`/assignments/${assignmentKey}/saved_mappings`, {
    ...config,
    params: request,
  });
}

export function createSavedMapping(
  assignmentKey: string,
  mappings: SavedMappingSettings
): AxiosPromise<SavedMappingDetail> {
  return AXIOS.post(`/assignments/${assignmentKey}/saved_mappings`, mappings);
}
