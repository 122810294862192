import { FC } from 'react';
import { intl } from '../../../Internationalization';
import { TabbedDialog } from '../../../components/dialog';
import NewSupplierAssignmentForm from './NewSupplierAssignmentForm';
import CopySupplierAssignmentsForm from './CopySupplierAssignmentsForm';

interface AddSupplierAssignmentDialogProps {
  onClose: () => void;
  onRefreshAssignments: () => void;
}

const AddSupplierAssignmentDialog: FC<AddSupplierAssignmentDialogProps> = ({
  onClose,
  onRefreshAssignments,
}) => {
  const tabs = [
    {
      id: 'SINGLE',
      title: intl.formatMessage({
        id: 'suppliers.assignment.add.tabs.singleAssignment',
        defaultMessage: 'Single Assignment',
      }),
      content: <NewSupplierAssignmentForm onClose={onClose} />,
    },
    {
      id: 'COPY',
      title: intl.formatMessage({
        id: 'suppliers.assignment.add.tabs.copyAssignments',
        defaultMessage: 'Copy Assignments',
      }),
      content: (
        <CopySupplierAssignmentsForm
          onClose={onClose}
          onRefreshAssignments={onRefreshAssignments}
        />
      ),
    },
  ];

  return (
    <TabbedDialog
      title={intl.formatMessage({
        id: 'suppliers.assignment.create.title',
        defaultMessage: 'Add Assignment',
      })}
      tabs={tabs}
      id="add-assignment-dialog"
      onClose={onClose}
      open={true}
      fullWidth
    />
  );
};

export default AddSupplierAssignmentDialog;
