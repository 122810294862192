import { AxiosPromise } from 'axios';

import { ProjectImportJobDetail, ExportMetadata } from '../../types';
import { AXIOS } from '../endpoints';

export function previewJob(jobId: number): AxiosPromise<ExportMetadata> {
  return AXIOS.get(`/project_import/${jobId}/preview`);
}

export function startJob(jobId: number): AxiosPromise {
  return AXIOS.post(`/project_import/${jobId}/start`);
}

export function cancelJob(jobId: number): AxiosPromise<ProjectImportJobDetail> {
  return AXIOS.post(`/project_import/${jobId}/cancel`);
}
