import { AxiosPromise } from 'axios';

import { AssignmentsResponse, MyAssignmentsRequest } from '../../types';
import { AXIOS, RequestConfig } from '../endpoints';

export function getMyAssignments(
  request: MyAssignmentsRequest,
  config?: RequestConfig
): AxiosPromise<AssignmentsResponse> {
  return AXIOS.get(`/my_assignments`, {
    ...config,
    params: request,
  });
}
