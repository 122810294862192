import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText, SvgIconProps } from '@mui/material';

import { routeMatches } from '../../../util';

interface SidebarMenuItemProps {
  id?: string;
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  linkUrl: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({ icon: Icon, label, linkUrl, id }) => {
  const { pathname } = useLocation();
  const selected = routeMatches(linkUrl, pathname);

  return (
    <ListItem
      id={id}
      to={linkUrl}
      selected={selected}
      button
      component={Link}
      aria-current={selected}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  );
};

export default SidebarMenuItem;
