import { AxiosPromise } from 'axios';

import { AXIOS, RequestConfig } from '../endpoints';
import { UsersRequest, UsersResponse } from '../../types';

export function getUsers(
  request: UsersRequest,
  config?: RequestConfig
): AxiosPromise<UsersResponse> {
  return AXIOS.get('/users', {
    ...config,
    params: request,
  });
}
