import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Checkbox, Container, FormControlLabel, MenuItem, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import {
  SUBMISSION_AUTOMATION_MODE_METADATA,
  SUBMISSION_AUTOMATION_MODES,
  SubmissionAutomationMode,
} from '../../../types';
import * as ProjectApi from '../../../api/project/project';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  PaddedPaper,
  ValidatedTextField,
  FormButtons,
  DefaultButton,
  InputTooltip,
} from '../../../components';
import { useTitle } from '../../../hooks';

import { ProjectContext } from './ProjectContext';

const ProjectSpecificationDefaultsForm: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { project, projectUpdated } = useContext(ProjectContext);

  useTitle(
    intl.formatMessage(
      { id: 'title.project', defaultMessage: 'Project ({projectName})' },
      { projectName: project.name }
    )
  );

  const projectSpecificationDefaults = ProjectApi.extractProjectSpecificationDefaults(project);

  const [defaultAutomationMode, setDefaultAutomationMode] = useState<SubmissionAutomationMode>(
    projectSpecificationDefaults.defaultAutomationMode
  );

  const [defaultNotifications, setDefaultNotifications] = useState<boolean>(
    project.defaultNotifications
  );

  const [processing, setProcessing] = useState<boolean>(false);

  const updateProject = async () => {
    setProcessing(true);
    try {
      const response = await ProjectApi.updateProjectSpecificationDefaults(project.key, {
        ...project,
        defaultAutomationMode,
        defaultNotifications,
      });
      projectUpdated(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'project.specificationDefaults.saveSuccess',
          defaultMessage: 'Project updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'project.specificationDefaults.saveError',
            defaultMessage: 'Failed to update project',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="md" id="project-specification-defaults" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" component="h3">
          <FormattedMessage
            id="project.specificationDefaults.title"
            defaultMessage="Project Defaults"
          />
        </Typography>
        <ValidatedTextField
          disabled={processing}
          name="defaultAutomationMode"
          label={intl.formatMessage({
            id: 'project.specificationDefaults.defaultAutomationMode.label',
            defaultMessage: "Submission's Default Automation Mode",
          })}
          value={defaultAutomationMode}
          onChange={(event) =>
            setDefaultAutomationMode(event.target.value as SubmissionAutomationMode)
          }
          margin="normal"
          variant="outlined"
          select
        >
          {SUBMISSION_AUTOMATION_MODES.map((mode) => (
            <MenuItem key={mode} value={mode}>
              {SUBMISSION_AUTOMATION_MODE_METADATA[mode].label}
            </MenuItem>
          ))}
        </ValidatedTextField>
        <InputTooltip
          data-tooltip-for="sendNotifications"
          title={intl.formatMessage({
            id: 'project.specificationDefaults.defaultNotifications.tooltip',
            defaultMessage:
              'When enabled, any new submissions created for this project will default to sending notifications to the supplier user when the submission requires attention, unless overriden by the supplier.',
          })}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={defaultNotifications}
                onChange={(event) => setDefaultNotifications(event.target.checked)}
                name="sendNotifications"
                color="primary"
                disabled={processing}
              />
            }
            label={intl.formatMessage({
              id: 'project.specificationDefaults.defaultNotifications.label',
              defaultMessage: 'Notify supplier user when action is required',
            })}
          />
        </InputTooltip>
        <FormButtons>
          <DefaultButton
            name="updateProjectSpecificationDefaults"
            onClick={updateProject}
            disabled={processing}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage id="project.specificationDefaults.saveButton" defaultMessage="Save" />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default ProjectSpecificationDefaultsForm;
