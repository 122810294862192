import { FC, useEffect, useRef, MutableRefObject } from 'react';
import { Navigate, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import { AxiosError } from 'axios';
import { isNumber } from 'lodash';

import { Layout, AppDecorationWrapper } from './components';

import {
  Dashboard,
  MyAccountRouting,
  MyAssignmentsRouting,
  UsersRouting,
  ProjectsRouting,
  SubmissionsRouting,
  SuppliersRouting,
  SystemRouting,
} from './screens';

import { AXIOS } from './api/endpoints';
import * as AuthApi from './api/auth';
import { AppBarStats } from './contexts/app-bar-stats';
import Global from './contexts/global-state/Global';
import { RequireReceiver, RequireReceiverAdmin } from './routing';
import { NavigationPrompt } from './contexts/navigation-prompt';

const removeInterceptor = (axiosHandlerId: MutableRefObject<number | undefined>) => {
  isNumber(axiosHandlerId.current) && AXIOS.interceptors.response.eject(axiosHandlerId.current);
};

const AuthenticatedRouting: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const axiosHandlerId = useRef<number>();

  useEffect(() => {
    removeInterceptor(axiosHandlerId);
    const handleApiResponseError = (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        AuthApi.storeLoginRedirect(location);
        navigate('/unauthorized');
      }
      return Promise.reject(error);
    };
    axiosHandlerId.current = AXIOS.interceptors.response.use(
      (response) => response,
      handleApiResponseError
    );
  }, [location, navigate]);

  useEffect(() => () => removeInterceptor(axiosHandlerId), []);

  return (
    <AppDecorationWrapper>
      <NavigationPrompt>
        <Global>
          <AppBarStats>
            <Layout>
              <Routes>
                <Route path="my_assignments/*" element={<MyAssignmentsRouting />} />
                <Route path="submissions/*" element={<SubmissionsRouting />} />
                <Route path="account/*" element={<MyAccountRouting />} />
                <Route
                  path="projects/*"
                  element={
                    <RequireReceiver>
                      <ProjectsRouting />
                    </RequireReceiver>
                  }
                />
                <Route
                  path="suppliers/*"
                  element={
                    <RequireReceiver>
                      <SuppliersRouting />
                    </RequireReceiver>
                  }
                />
                <Route
                  path="dashboard/*"
                  element={
                    <RequireReceiver>
                      <Dashboard />
                    </RequireReceiver>
                  }
                />
                <Route
                  path="users/*"
                  element={
                    <RequireReceiver>
                      <UsersRouting />
                    </RequireReceiver>
                  }
                />
                <Route
                  path="system/*"
                  element={
                    <RequireReceiverAdmin>
                      <SystemRouting />
                    </RequireReceiverAdmin>
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Layout>
          </AppBarStats>
        </Global>
      </NavigationPrompt>
    </AppDecorationWrapper>
  );
};

export default AuthenticatedRouting;
