import { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography, Grid, FormControlLabel, Checkbox } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import * as LdapUserSettingsApi from '../../../../api/ldap/ldapUserSettings';
import { extractErrorMessage } from '../../../../api/endpoints';
import {
  PaddedPaper,
  ValidatedTextField,
  FormButtons,
  DefaultButton,
  InputTooltip,
} from '../../../../components';
import { LdapUserSettings, LdapUser } from '../../../../types';
import { editableDurationToIso, isoDurationToEditable } from '../../../../util';
import { intl } from '../../../../Internationalization';
import { LDAP_USER_SETTINGS_VALIDATOR, validate } from '../../../../validation';

import { LdapContext } from '../LdapContext';
import UserPreview from './UserPreview';

const UserSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const ldapContext = useContext(LdapContext);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});
  const [processing, setProcessing] = useState<boolean>(false);
  const [additionalDn, setAdditionalDn] = useState<string>(ldapContext.userSettings.additionalDn);
  const [filter, setFilter] = useState<string>(ldapContext.userSettings.filter);
  const [synchronizationPeriod, setSynchronizationPeriod] = useState<string>(
    isoDurationToEditable(ldapContext.userSettings.synchronizationPeriod)
  );
  const [jobRetentionPeriod, setJobRetentionPeriod] = useState<string>(
    isoDurationToEditable(ldapContext.userSettings.jobRetentionPeriod)
  );
  const [synchronizationEnabled, setSynchronizationEnabled] = useState<boolean>(
    ldapContext.userSettings.synchronizationEnabled
  );
  const [uid, setUid] = useState<string>(ldapContext.userSettings.uid);
  const [email, setEmail] = useState<string>(ldapContext.userSettings.email);
  const [firstName, setFirstName] = useState<string>(ldapContext.userSettings.firstName);
  const [lastName, setLastName] = useState<string>(ldapContext.userSettings.lastName);

  const [previewUsers, setPreviewUsers] = useState<LdapUser[]>();
  const [previewErrorMessage, setPreviewErrorMessage] = useState<string>();

  const validateAndSave = () => {
    setFieldErrors({});
    setPreviewUsers(undefined);
    setPreviewErrorMessage('');
    const settings: LdapUserSettings = {
      additionalDn,
      filter,
      synchronizationPeriod: editableDurationToIso(synchronizationPeriod),
      jobRetentionPeriod: editableDurationToIso(jobRetentionPeriod),
      synchronizationEnabled,
      uid,
      email,
      firstName,
      lastName,
    };
    setProcessing(true);
    validate(LDAP_USER_SETTINGS_VALIDATOR, settings)
      .then(() => saveUserSettings(settings))
      .catch((newFieldErrors: ValidateFieldsError) => {
        setProcessing(false);
        setFieldErrors(newFieldErrors);
      });
  };

  const saveUserSettings = (userSettings: LdapUserSettings) => {
    LdapUserSettingsApi.updateUserSettings(userSettings)
      .then((response) => {
        ldapContext.userSettingsUpdated(response.data);
        enqueueSnackbar(
          intl.formatMessage({
            id: 'ldap.userSettings.saveSettingsSuccess',
            defaultMessage: 'LDAP user settings updated',
          }),
          { variant: 'success' }
        );
        setProcessing(false);
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'ldap.userSettings.saveSettingsError',
              defaultMessage: 'Failed to update LDAP user settings',
            })
          ),
          { variant: 'error' }
        );
        setProcessing(false);
      });
  };

  return (
    <Box id="system-ldap-user-settings">
      <PaddedPaper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              <FormattedMessage
                id="ldap.userSettings.title"
                defaultMessage="User Synchronisation Settings"
              />
            </Typography>
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="additionalDn"
              label={intl.formatMessage({
                id: 'ldap.userSettings.additionalDn.label',
                defaultMessage: 'Relative DN',
              })}
              tooltip={intl.formatMessage({
                id: 'ldap.userSettings.additionalDn.tooltip',
                defaultMessage: 'The relative DN used for looking up users',
              })}
              value={additionalDn}
              onChange={(event) => setAdditionalDn(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              multiline
              name="filter"
              label={intl.formatMessage({
                id: 'ldap.userSettings.filter.label',
                defaultMessage: 'Filter',
              })}
              tooltip={intl.formatMessage({
                id: 'ldap.userSettings.filter.tooltip',
                defaultMessage: 'An additional filter to apply when looking up users',
              })}
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="synchronizationPeriod"
              label={intl.formatMessage({
                id: 'ldap.userSettings.synchronizationPeriod.label',
                defaultMessage: 'Synchronisation (e.g. 1h 30m)',
              })}
              tooltip={intl.formatMessage({
                id: 'ldap.userSettings.synchronizationPeriod.tooltip',
                defaultMessage:
                  'The amount of time between the automatic execution of the user synchronisation job',
              })}
              value={synchronizationPeriod}
              onChange={(event) => setSynchronizationPeriod(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="jobRetentionPeriod"
              label={intl.formatMessage({
                id: 'ldap.userSettings.jobRetentionPeriod.label',
                defaultMessage: 'Job Log Retention (e.g. 1h 30m)',
              })}
              tooltip={intl.formatMessage({
                id: 'ldap.userSettings.jobRetentionPeriod.tooltip',
                defaultMessage:
                  'The amount to time to keep the synchronisation jobs and their logs before they are automatically deleted',
              })}
              value={jobRetentionPeriod}
              onChange={(event) => setJobRetentionPeriod(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <InputTooltip
              data-tooltip-for="synchronizationEnabled"
              title={intl.formatMessage({
                id: 'ldap.userSettings.synchronizationEnabled.tooltip',
                defaultMessage:
                  'Enables the automatic synchronisation of users with the LDAP server',
              })}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="synchronizationEnabled"
                    disabled={processing}
                    checked={synchronizationEnabled}
                    onChange={(event, checked) => setSynchronizationEnabled(checked)}
                    value="primary"
                  />
                }
                label={intl.formatMessage({
                  id: 'ldap.userSettings.synchronizationEnabled.label',
                  defaultMessage: 'Synchronisation enabled',
                })}
              />
            </InputTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              <FormattedMessage
                id="ldap.userSettings.userSchema.title"
                defaultMessage="User Schema"
              />
            </Typography>
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="uid"
              label={intl.formatMessage({
                id: 'ldap.userSettings.uid.label',
                defaultMessage: 'UID',
              })}
              tooltip={intl.formatMessage({
                id: 'ldap.userSettings.uid.tooltip',
                defaultMessage: 'The unique ID attribute within the user object',
              })}
              value={uid}
              onChange={(event) => setUid(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="email"
              label={intl.formatMessage({
                id: 'ldap.userSettings.email.label',
                defaultMessage: 'Email',
              })}
              tooltip={intl.formatMessage({
                id: 'ldap.userSettings.email.tooltip',
                defaultMessage: 'The email attribute within the user object',
              })}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="firstName"
              label={intl.formatMessage({
                id: 'ldap.userSettings.firstName.label',
                defaultMessage: 'Given Name',
              })}
              tooltip={intl.formatMessage({
                id: 'ldap.userSettings.firstName.tooltip',
                defaultMessage: 'The given name attribute within the user object',
              })}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="lastName"
              label={intl.formatMessage({
                id: 'ldap.userSettings.lastName.label',
                defaultMessage: 'Last Name',
              })}
              tooltip={intl.formatMessage({
                id: 'ldap.userSettings.lastName.tooltip',
                defaultMessage: 'The last name attribute within the user object',
              })}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              margin="normal"
              variant="outlined"
            />
            <FormButtons>
              <DefaultButton
                name="updateUserSettings"
                onClick={validateAndSave}
                disabled={processing}
                startIcon={<SaveIcon />}
              >
                <FormattedMessage
                  id="ldap.userSettings.saveButton"
                  defaultMessage="Save Settings"
                />
              </DefaultButton>
            </FormButtons>
          </Grid>
        </Grid>
      </PaddedPaper>
      <UserPreview
        users={previewUsers}
        setUsers={setPreviewUsers}
        errorMessage={previewErrorMessage}
        setErrorMessage={setPreviewErrorMessage}
      />
    </Box>
  );
};

export default UserSettings;
