import Schema from 'async-validator';
import { baseUriValidator } from './shared';
import { intl } from '../Internationalization';
import { AuthenticationType } from '../types';

export const oneIntegrateServerSettingsValidator = (authenticationType: AuthenticationType) =>
  new Schema({
    baseUri: [
      {
        type: 'string',
        required: true,
        message: intl.formatMessage({
          id: 'oneIntegrate.validator.baseUri.required',
          defaultMessage: 'Please provide the server base URI',
        }),
      },
      baseUriValidator,
    ],
    ...(authenticationType === AuthenticationType.API_KEY
      ? {
          apiKey: [
            {
              type: 'string',
              required: true,
              message: intl.formatMessage({
                id: 'oneIntegrate.validator.apiKey.required',
                defaultMessage: 'Please provide an API key',
              }),
            },
          ],
        }
      : {
          password: {
            type: 'string',
            required: true,
            message: intl.formatMessage({
              id: 'oneIntegrate.validator.password.required',
              defaultMessage: 'Please provide the server password',
            }),
          },
          username: {
            type: 'string',
            required: true,
            message: intl.formatMessage({
              id: 'oneIntegrate.validator.username.required',
              defaultMessage: 'Please provide the server username',
            }),
          },
        }),
  });
