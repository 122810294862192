import { AxiosPromise } from 'axios';

import { CreateValidationJobRequest, ValidationJobDetail } from '../types';
import { AXIOS } from './endpoints';

export function createValidationJob(
  request: CreateValidationJobRequest
): AxiosPromise<ValidationJobDetail> {
  return AXIOS.post(`/validation`, request);
}

export function fetchValidationJob(validationJobKey: string): AxiosPromise<ValidationJobDetail> {
  return AXIOS.get(`/validation/${validationJobKey}`);
}
