import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { AssignmentSettings, ProjectDetail, SpecificationDetail } from '../../../types';
import { SupplierContext } from './SupplierContext';
import { validate, assignmentSettingsValidator } from '../../../validation';
import * as AssignmentsApi from '../../../api/assignment/assignments';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import { toAssignmentUrl } from '../../projects/project/specification/Assignments';
import { DialogContent, Checkbox, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
  ProjectAutocomplete,
  SpecificationAutocomplete,
  ValidatedTextField,
  InputTooltip,
  BlockFormControlLabel,
  PaddedDialogActions,
  DefaultButton,
} from '../../../components';
import AddIcon from '@mui/icons-material/Add';

interface NewSupplierAssignmentFormProps {
  onClose: () => void;
}

const NewSupplierAssignmentForm: FC<NewSupplierAssignmentFormProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const context = useContext(SupplierContext);
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [reference, setReference] = useState<string>(context.supplier.name);
  const [project, setProject] = useState<ProjectDetail | null>(null);
  const [specification, setSpecification] = useState<SpecificationDetail | null>(null);
  const [viewOnly, setViewOnly] = useState<boolean>(false);

  const selectProject = (newProject: ProjectDetail | null) => {
    setProject(newProject);
    setSpecification(null);
    setFieldErrors(undefined);
  };

  const collectCreateAssignmentSettings = (): Partial<AssignmentSettings> => ({
    reference,
    active: true,
    restricted: false,
    viewOnly,
    specificationKey: specification?.key,
    supplierKey: context.supplierKey,
  });

  const validateAndSubmitCreateAssignment = async () => {
    setProcessing(true);
    setFieldErrors(undefined);
    const settings = collectCreateAssignmentSettings();
    try {
      createAssignment(
        await validate(
          assignmentSettingsValidator(() => (specification && specification.key) || ''),
          settings
        )
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createAssignment = async (settings: AssignmentSettings) => {
    try {
      const { data: assignment } = await AssignmentsApi.createAssignment(settings);
      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'suppliers.assignment.create.createSuccess',
            defaultMessage: 'Assignment {reference} has been created',
          },
          { reference: assignment.reference }
        ),
        { variant: 'success' }
      );
      navigate(toAssignmentUrl(assignment));
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'suppliers.assignment.create.createError',
            defaultMessage: 'Failed to create assignment',
          })
        ),
        { variant: 'error' }
      );
      setProcessing(false);
    }
  };

  return (
    <>
      <DialogContent>
        <ProjectAutocomplete
          id="project-select"
          tooltip={intl.formatMessage({
            id: 'suppliers.assignment.create.project.tooltip',
            defaultMessage: 'Select a project.',
          })}
          name="projectKey"
          label={intl.formatMessage({
            id: 'suppliers.assignment.create.project.label',
            defaultMessage: 'Project',
          })}
          fieldErrors={fieldErrors}
          disabled={processing}
          value={project}
          multiple={false}
          onChange={selectProject}
          margin="normal"
          variant="outlined"
          active={true}
          fullWidth
        />
        <SpecificationAutocomplete
          id="specification-select"
          tooltip={intl.formatMessage({
            id: 'suppliers.assignment.create.specification.tooltip',
            defaultMessage: 'Select a specification.',
          })}
          name="specificationKey"
          label={intl.formatMessage({
            id: 'suppliers.assignment.create.specification.label',
            defaultMessage: 'Specification',
          })}
          fieldErrors={fieldErrors}
          disabled={processing}
          value={specification}
          onChange={setSpecification}
          margin="normal"
          variant="outlined"
          projectKey={project?.key}
          fullWidth
        />
        <ValidatedTextField
          tooltip={intl.formatMessage({
            id: 'suppliers.assignment.create.reference.tooltip',
            defaultMessage: 'A reference for the assignment, unique within the specification.',
          })}
          fieldErrors={fieldErrors}
          disabled={processing}
          name="reference"
          label={intl.formatMessage({
            id: 'suppliers.assignment.create.reference.label',
            defaultMessage: 'Reference',
          })}
          value={reference}
          onChange={(event) => setReference(event.target.value)}
          margin="normal"
          variant="outlined"
          fullWidth
        />
        <InputTooltip
          data-tooltip-for="viewOnly"
          title={intl.formatMessage({
            id: 'suppliers.assignment.create.viewOnly.tooltip',
            defaultMessage: 'If selected only the assignment information will be visible.',
          })}
        >
          <BlockFormControlLabel
            control={
              <Checkbox
                color="primary"
                name="viewOnly"
                checked={viewOnly}
                onChange={(e, checked) => setViewOnly(checked)}
                disabled={processing}
              />
            }
            label={intl.formatMessage({
              id: 'suppliers.assignment.create.viewOnly.label',
              defaultMessage: 'View Only?',
            })}
          />
        </InputTooltip>
      </DialogContent>
      <Divider />
      <PaddedDialogActions>
        <DefaultButton
          name="cancelAddNewAssignment"
          color="secondary"
          onClick={onClose}
          disabled={processing}
        >
          <FormattedMessage id="suppliers.assignment.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          name="addNewAssignment"
          onClick={validateAndSubmitCreateAssignment}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage
            id="suppliers.assignment.create.createButton"
            defaultMessage="Add Assignment"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </>
  );
};

export default NewSupplierAssignmentForm;
