import { FC, PropsWithChildren } from 'react';
import { Box, SxProps } from '@mui/material';

interface IconBoxProps extends PropsWithChildren {
  sx?: SxProps;
}

const IconBox: FC<IconBoxProps> = ({ sx = [], children }) => (
  <Box
    sx={[
      {
        'display': 'flex',
        'height': '100%',
        'alignItems': 'center',
        '& > :first-child': {
          mr: 1,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {children}
  </Box>
);

export default IconBox;
