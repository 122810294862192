import { FC, useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { toAssignmentUrl } from '../../projects/project/specification/Assignments';
import { updateActiveOnlyValue } from '../../../util';
import { useBrowseRequest, useTitle } from '../../../hooks';
import * as AssignmentsApi from '../../../api/assignment/assignments';
import {
  AddFab,
  BrowseTable,
  DefaultButton,
  FilterBar,
  FilterPagination,
  FilterSearch,
  FilterToggle,
  MinWidthTableCell,
  StyledTableHead,
  browseTableBody,
} from '../../../components';
import { CrossIcon, TickIcon } from '../../../components/icons';
import { AssignmentDetail } from '../../../types';
import { intl } from '../../../Internationalization';
import { SupplierContext } from './SupplierContext';
import AddSupplierAssignmentDialog from './AddSupplierAssignmentDialog';

const AssignmentsTableBody = browseTableBody<AssignmentDetail>();

const PAGE_SIZE = 10;

const SupplierAssignments: FC = () => {
  const { supplierKey, supplier } = useContext(SupplierContext);
  useTitle(
    intl.formatMessage(
      { id: 'title.supplier', defaultMessage: 'Supplier ({supplierName})' },
      { supplierName: supplier.name }
    )
  );

  const { request, response, processing, updateRequest, setPage, refresh } = useBrowseRequest({
    initialRequest: {
      page: 0,
      size: PAGE_SIZE,
      active: true,
      filterRelated: true,
      supplierKey: supplierKey,
    },
    onRequest: AssignmentsApi.getAssignments,
  });

  const [addAssignment, setAddAssignment] = useState<boolean>(false);

  const handleFilterUpdate = useCallback(
    (filter: string) => updateRequest({ filter }),
    [updateRequest]
  );
  const handleActiveOnlyUpdate = updateActiveOnlyValue(updateRequest);
  const renderRestricted = (restricted: boolean) => {
    if (restricted) {
      return (
        <TickIcon
          titleAccess={intl.formatMessage({
            id: 'supplier.assignments.table.restricted',
            defaultMessage: 'Restricted',
          })}
        />
      );
    }
    return (
      <CrossIcon
        titleAccess={intl.formatMessage({
          id: 'supplier.assignments.table.unrestricted',
          defaultMessage: 'Unrestricted',
        })}
      />
    );
  };

  const assignmentRow = (assignment: AssignmentDetail) => {
    return (
      <TableRow key={assignment.key}>
        <TableCell align="left">{assignment.specification.project.name}</TableCell>
        <TableCell align="left">{assignment.specification.name}</TableCell>
        <TableCell align="left">{assignment.reference}</TableCell>
        <TableCell align="center">{renderRestricted(assignment.restricted)}</TableCell>
        <MinWidthTableCell>
          <DefaultButton
            color="grey"
            component={Link}
            to={toAssignmentUrl(assignment)}
            aria-label={intl.formatMessage({
              id: 'supplier.assignments.table.navigateToAssignment.ariaLabel',
              defaultMessage: 'Navigate to assignment',
            })}
          >
            <EditIcon />
          </DefaultButton>
        </MinWidthTableCell>
      </TableRow>
    );
  };

  return (
    <div id="supplier-assignments">
      <FilterBar
        startInput={
          <FilterSearch
            label={intl.formatMessage({
              id: 'supplier.assignments.filterSearch.placeholder',
              defaultMessage: 'Filter assignments…',
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <>
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
            <FilterToggle
              name="active"
              label={intl.formatMessage({
                id: 'supplier.assignments.filterActiveToggle.label',
                defaultMessage: 'Active Only',
              })}
              checked={!!request.active}
              onChange={handleActiveOnlyUpdate}
              disabled={processing}
            />
          </>
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell align="left">
              <FormattedMessage
                id="supplier.assignments.table.projectColumn"
                defaultMessage="Project"
              />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage
                id="supplier.assignments.table.specificationColumn"
                defaultMessage="Specification"
              />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage
                id="supplier.assignments.table.referenceColumn"
                defaultMessage="Reference"
              />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage
                id="supplier.assignments.table.restrictedColumn"
                defaultMessage="Restricted"
              />
            </TableCell>
            <MinWidthTableCell>
              <FormattedMessage
                id="supplier.assignments.table.actionsColumn"
                defaultMessage="Actions"
              />
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <AssignmentsTableBody
          data={response && response.results}
          mapToRow={assignmentRow}
          noDataMessage={intl.formatMessage({
            id: 'supplier.assignments.noAssignments',
            defaultMessage: 'No matching assignments',
          })}
          numCols={4}
        />
      </BrowseTable>
      {addAssignment && (
        <AddSupplierAssignmentDialog
          onClose={() => setAddAssignment(false)}
          onRefreshAssignments={refresh}
        />
      )}
      <AddFab
        name="addAssignment"
        aria-label={intl.formatMessage({
          id: 'supplier.assignments.addFab.addAssignment.ariaLabel',
          defaultMessage: 'Add assignment',
        })}
        onClick={() => setAddAssignment(true)}
      />
    </div>
  );
};

export default SupplierAssignments;
