import { Geometry, Feature } from 'geojson';
import { CircularProgress, SxProps } from '@mui/material';

import { RGBAColor } from '@deck.gl/core/utils/color';

import { SubmissionDetail, TASK_STATE_METADATA, TaskResult, TaskState } from '../types';
import { getTaskStateMetadata } from '../screens/components/submission/taskResultUtils';

export const wgs84 = 'epsg:4326';
export const REPORTS_LAYER_NAME = '1int_reports_layer';

export interface GeometryBounds {
  minx: number;
  miny: number;
  maxx: number;
  maxy: number;
}

export const getGeometryBounds = (geom: Geometry): GeometryBounds => {
  const points: number[][] = [];

  if (geom.type === 'Point') {
    points.push(geom.coordinates);
  } else if (geom.type === 'LineString') {
    points.push(...geom.coordinates);
  } else if (geom.type === 'Polygon') {
    points.push(...geom.coordinates[0]);
  } else if (geom.type === 'MultiPoint') {
    points.push(...geom.coordinates);
  } else if (geom.type === 'MultiLineString') {
    for (const line of geom.coordinates) {
      points.push(...line);
    }
  } else if (geom.type === 'MultiPolygon') {
    for (const polygon of geom.coordinates) {
      points.push(...polygon[0]);
    }
  }

  if (points.length === 0) {
    points.push([0, 0]);
  }

  let minx = points[0][0];
  let miny = points[0][1];
  let maxx = minx;
  let maxy = miny;

  for (const point of points) {
    const x = point[0];
    const y = point[1];
    if (x < minx) {
      minx = x;
    }
    if (y < miny) {
      miny = y;
    }
    if (x > maxx) {
      maxx = x;
    }
    if (y > maxy) {
      maxy = y;
    }
  }

  return {
    minx,
    miny,
    maxx,
    maxy,
  };
};

export const createRowBackground = (
  baseBrightness: number,
  mouseOver: boolean,
  selected: boolean
): string => {
  let brightness = baseBrightness;

  if (mouseOver) {
    brightness += 0.02;
  }
  if (selected) {
    brightness += 0.04;
  }
  // Support for disabled and fromTemplate task formatting removed from here; ref 1Integrate for original
  return 'rgba(0, 0, 0, ' + brightness + ')';
};

export const getTaskStateColour = (taskResult: TaskResult | undefined) => {
  if (taskResult) {
    if (taskResult.status === TaskState.PAUSED && taskResult.errors > 0) {
      return '#D32F2F';
    } else {
      return TASK_STATE_METADATA[taskResult.status].color;
    }
  }
  return undefined;
};

export const getRunStateIcon = (taskResult?: TaskResult, submission?: SubmissionDetail) => {
  const taskStatusIcon: SxProps = {
    height: 20,
    width: 20,
  };

  if (!taskResult || taskResult.status === TaskState.NOT_STARTED) {
    return null;
  }
  const taskConformance = submission?.taskConformance.find(
    ({ taskIdentifier }) => taskIdentifier === taskResult.taskIdentifier
  );
  const { icon: Icon, label } = getTaskStateMetadata(taskResult, taskConformance);

  if (taskResult.status === 'RUNNING') {
    return <CircularProgress size={18} />;
  } else {
    return <Icon sx={taskStatusIcon} titleAccess={label} />;
  }
};

export const colShift = (col: RGBAColor, shift: number): RGBAColor => {
  return [col[0] + shift, col[1] + shift, col[2] + shift, col[3]];
};

export interface SelectedFeature {
  id: string;
  mbr: string;
  layer: string;
  attributes?: { [key: string]: string };
  feature?: Feature;
}
