import { FC, useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TableRow, TableCell } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import * as SpecificationsApi from '../../../api/specification/specifications';
import {
  AddFab,
  BrowseTable,
  browseTableBody,
  FilterPagination,
  FilterBar,
  DefaultButton,
  FilterToggle,
  MinWidthTableCell,
  FilterSearch,
  StyledTableHead,
  FilterContainer,
} from '../../../components';
import { intl } from '../../../Internationalization';
import { updateActiveOnlyValue } from '../../../util';
import { useBrowseRequest, useTitle } from '../../../hooks';
import { SpecificationDetail, SpecificationsRequest } from '../../../types';
import { ProjectContext } from './ProjectContext';
import NewSpecificationForm from './specification/NewSpecificationForm';
import { RequestConfig } from '../../../api/endpoints';
import TriStateFilter from '../../../components/browse-table/TriStateFilter';
import ConfigurationInvalid from './ConfigurationInvalid';
import RevalidateSpecifications from '../RevalidateSpecifications';

export const toSpecificationUrl = (specification: SpecificationDetail) =>
  `/projects/${specification.project.key}/specifications/${specification.key}`;

const SpecificationsTableBody = browseTableBody<SpecificationDetail>();

const PAGE_SIZE = 10;

export const SpecificationsTableHeader: FC = () => (
  <StyledTableHead>
    <TableRow>
      <TableCell>
        <FormattedMessage id="specifications.table.nameColumn" defaultMessage="Name" />
      </TableCell>
      <TableCell>
        <FormattedMessage
          id="specifications.table.descriptionColumn"
          defaultMessage="Description"
        />
      </TableCell>
      <TableCell align="center">
        <FormattedMessage
          id="specifications.table.validColumn"
          defaultMessage="Configuration Status"
        />
      </TableCell>
      <MinWidthTableCell>
        <FormattedMessage id="specifications.table.actionsColumn" defaultMessage="Actions" />
      </MinWidthTableCell>
    </TableRow>
  </StyledTableHead>
);

const Specifications: FC = () => {
  const { projectKey, project } = useContext(ProjectContext);
  useTitle(
    intl.formatMessage(
      { id: 'title.project', defaultMessage: 'Project ({projectName})' },
      { projectName: project.name }
    )
  );
  const { request, response, processing, updateRequest, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, active: true, filter: '' },
    onRequest: useCallback(
      (req: SpecificationsRequest, config?: RequestConfig) =>
        SpecificationsApi.getSpecifications(projectKey, req, config),
      [projectKey]
    ),
  });

  const [addSpecification, setAddSpecification] = useState<boolean>(false);

  const handleFilterUpdate = useCallback(
    (filter: string) => updateRequest({ filter }),
    [updateRequest]
  );
  const handleActiveOnlyUpdate = updateActiveOnlyValue(updateRequest);
  const handleUpdateConfigurationsValid = (_: any, newValue?: boolean) => {
    updateRequest({ configurationValid: newValue });
  };

  const specificationRow = (specification: SpecificationDetail) => (
    <TableRow key={specification.key}>
      <TableCell>{specification.name}</TableCell>
      <TableCell>{specification.description}</TableCell>
      <TableCell align="center">
        <ConfigurationInvalid invalid={!specification.configurationValid} />
      </TableCell>
      <MinWidthTableCell>
        <DefaultButton
          component={Link}
          to={toSpecificationUrl(specification)}
          color="grey"
          aria-label={intl.formatMessage({
            id: 'specifications.table.navigateToSpecification.ariaLabel',
            defaultMessage: 'Navigate to specification',
          })}
        >
          <EditIcon />
        </DefaultButton>
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <div id="project-specifications">
      <FilterBar
        startInput={
          <FilterSearch
            label={intl.formatMessage({
              id: 'specifications.filterSearch.placeholder',
              defaultMessage: 'Filter specifications…',
            })}
            onSearch={handleFilterUpdate}
            name="specificationsFilter"
          />
        }
        barFilters={
          <FilterContainer>
            <TriStateFilter
              name="configurationFilter"
              value={request.configurationValid}
              label={intl.formatMessage({
                id: 'specifications.validConfigurationOnly.label',
                defaultMessage: 'Configuration',
              })}
              unsetLabel={intl.formatMessage({
                id: 'specifications.validConfigurationOnly.unsetLabel',
                defaultMessage: 'Any',
              })}
              trueLabel={intl.formatMessage({
                id: 'specifications.validConfigurationOnly.trueLabel',
                defaultMessage: 'Valid',
              })}
              falseLabel={intl.formatMessage({
                id: 'specifications.validConfigurationOnly.falseLabel',
                defaultMessage: 'Invalid',
              })}
              onChange={handleUpdateConfigurationsValid}
            />
          </FilterContainer>
        }
        actions={
          <>
            <RevalidateSpecifications projectKey={project.key} onRevalidationComplete={refresh} />
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
            <FilterToggle
              label={intl.formatMessage({
                id: 'specifications.filterActiveToggle.label',
                defaultMessage: 'Active Only',
              })}
              name="active"
              checked={!!request.active}
              onChange={handleActiveOnlyUpdate}
              disabled={processing}
            />
          </>
        }
      />
      <BrowseTable>
        <SpecificationsTableHeader />
        <SpecificationsTableBody
          data={response && response.results}
          mapToRow={specificationRow}
          noDataMessage={intl.formatMessage({
            id: 'specifications.noSpecification',
            defaultMessage: 'No matching specifications.',
          })}
          numCols={3}
        />
      </BrowseTable>
      {addSpecification && (
        <NewSpecificationForm
          onCancel={() => setAddSpecification(false)}
          toSpecificationUrl={toSpecificationUrl}
        />
      )}
      <AddFab
        name="addSpecification"
        onClick={() => setAddSpecification(true)}
        aria-label={intl.formatMessage({
          id: 'specifications.addFab.addSpecification.ariaLabel',
          defaultMessage: 'Add specification',
        })}
      />
    </div>
  );
};

export default Specifications;
