import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { FC, useRef } from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AppRouting from './AppRouting';
import { Token } from './screens/pre-auth';
import { DATE_FNS_LOCALE_LOOKUP, intl, LOCALE } from './Internationalization';

import { Application } from './contexts/application';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/token" element={<Token />} />
      <Route path="/*" element={<AppRouting />} />
    </>
  )
);

const App: FC = () => {
  const notistackRef = useRef<SnackbarProvider>(null);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      ref={notistackRef}
      classes={{
        root: 'Snackbar-root',
      }}
      action={(key) => (
        <IconButton
          aria-label={intl.formatMessage({
            id: 'app.closeSnackbar.ariaLabel',
            defaultMessage: 'Dismiss message',
          })}
          onClick={() => notistackRef.current?.closeSnackbar(key)}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      <Application>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={DATE_FNS_LOCALE_LOOKUP[LOCALE]}
        >
          <RouterProvider router={router} />
        </LocalizationProvider>
      </Application>
    </SnackbarProvider>
  );
};

export default App;
