import { FC, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Grid,
} from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SaveIcon from '@mui/icons-material/Save';

import * as SubmissionMappingsApi from '../../../../../../api/submission/submissionMappings';
import * as AssignmentSavedMappingApi from '../../../../../../api/assignment/assignmentSavedMapping';
import * as AssignmentSavedMappingsApi from '../../../../../../api/assignment/assignmentSavedMappings';

import { extractErrorMessage } from '../../../../../../api/endpoints';
import {
  FullWidthButton,
  DefaultButton,
  PaddedDialogActions,
  ButtonRow,
  Loading,
  ValidatedTextField,
  ConfirmDialog,
} from '../../../../../../components';
import {
  EvaluatedSavedMapping,
  MappingRatios,
  SavedMappingSettings,
  SchemaMapperType,
} from '../../../../../../types';
import { savedMappingValidator, validate } from '../../../../../../validation';
import { intl } from '../../../../../../Internationalization';
import { useNavigationPrompt } from '../../../../../../contexts/navigation-prompt';
import { SchemaMappingContext } from '../../../../../components/schema-mapper';

import { OpenSubmissionContext } from '../OpenSubmissionContext';

const convertRatio = (ratio: number) => {
  return (ratio * 100).toFixed(2);
};

interface ManageSchemaMappingProps {
  disabled: boolean;
}

const ManageSchemaMapping: FC<ManageSchemaMappingProps> = ({ disabled }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { clearNavigationBlock } = useNavigationPrompt();
  const { submission, assignment } = useContext(OpenSubmissionContext);
  const { updateSchemaMappings, schemaMappings } = useContext(SchemaMappingContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [mappingRatios, setMappingRatios] = useState<MappingRatios>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [removingMapping, setRemovingMapping] = useState<EvaluatedSavedMapping>();
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const openDialog = () => {
    fetchMapping();
    setDialogOpen(true);
  };

  const fetchMapping = async () => {
    setLoading(true);
    try {
      setMappingRatios((await SubmissionMappingsApi.evaluateMappings(submission.reference)).data);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.schemaMapping.manageSchemaMapping.loadError',
            defaultMessage: 'Failed to fetch schema mappings',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleApplyMapping = async (type: SchemaMapperType, mapping?: EvaluatedSavedMapping) => {
    setProcessing(true);
    try {
      updateSchemaMappings(
        (
          await SubmissionMappingsApi.applyMapping(submission.reference, {
            type,
            key: mapping?.key,
          })
        ).data
      );
      clearNavigationBlock();
      setDialogOpen(false);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'openSubmission.schemaMapping.manageSchemaMapping.applyMappingSuccess',
          defaultMessage: 'Mapping has been applied',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.schemaMapping.manageSchemaMapping.applyMappingError',
            defaultMessage: 'Failed to apply schema mapping',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleDeleteMapping = async (mapping: EvaluatedSavedMapping) => {
    setProcessing(true);
    try {
      await AssignmentSavedMappingApi.deleteSavedMapping(assignment.key, mapping.key);
      setMappingRatios((await SubmissionMappingsApi.evaluateMappings(submission.reference)).data);
      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'openSubmission.schemaMapping.manageSchemaMapping.deleteMappingSuccess',
            defaultMessage: 'Deleted mapping: {name}',
          },
          { name: mapping.name }
        ),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage(
            {
              id: 'openSubmission.schemaMapping.manageSchemaMapping.deleteMappingError',
              defaultMessage: 'Failed to delete mapping: {name}',
            },
            { name: mapping.name }
          )
        ),
        { variant: 'error' }
      );
    } finally {
      setRemovingMapping(undefined);
      setProcessing(false);
    }
  };

  const handleSaveMapping = async () => {
    setProcessing(true);
    setFieldErrors(undefined);

    const savedMapping: SavedMappingSettings = { name, mappings: schemaMappings };

    // validate mapping name
    try {
      await validate(savedMappingValidator(assignment, mappingRatios!), savedMapping);
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
      return;
    }

    // save mapping
    try {
      updateSchemaMappings(
        (await AssignmentSavedMappingsApi.createSavedMapping(assignment.key, savedMapping)).data
          .mappings
      );
      setMappingRatios((await SubmissionMappingsApi.evaluateMappings(submission.reference)).data);
      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'openSubmission.schemaMapping.manageSchemaMapping.saveMappingSuccess',
            defaultMessage: 'Current mapping saved as {name}',
          },
          { name }
        ),
        { variant: 'success' }
      );
      clearNavigationBlock();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage(
            {
              id: 'openSubmission.schemaMapping.manageSchemaMapping.saveMappingError',
              defaultMessage: 'Failed to save new mapping: {name}',
            },
            { name }
          )
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const renderDialogContent = () => {
    if (loading || !mappingRatios) {
      return <Loading />;
    }
    return (
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <InputIcon fontSize="large" />
          <Typography variant="body1">
            <FormattedMessage
              id="openSubmission.schemaMapping.manageSchemaMapping.naturalMapping"
              defaultMessage="Natural Mapping ({percent}% mapped)"
              values={{ percent: convertRatio(mappingRatios.natural) }}
            />
          </Typography>
          <DefaultButton
            disabled={processing || !submission.canUpdateSubmission}
            onClick={() => handleApplyMapping(SchemaMapperType.NATURAL)}
          >
            <FormattedMessage
              id="openSubmission.schemaMapping.manageSchemaMapping.applyButton"
              defaultMessage="Apply"
            />
          </DefaultButton>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <SyncAltIcon fontSize="large" />
          <Typography variant="body1">
            <FormattedMessage
              id="openSubmission.schemaMapping.manageSchemaMapping.predefinedMapping"
              defaultMessage="Predefined Mapping ({percent}% mapped)"
              values={{ percent: convertRatio(mappingRatios.predefined) }}
            />
          </Typography>
          <DefaultButton
            disabled={processing || !submission.canUpdateSubmission}
            onClick={() => handleApplyMapping(SchemaMapperType.PREDEFINED)}
          >
            <FormattedMessage
              id="openSubmission.schemaMapping.manageSchemaMapping.applyButton"
              defaultMessage="Apply"
            />
          </DefaultButton>
        </Box>
        <Box mb={2}>
          <Typography variant="body2">
            <FormattedMessage
              id="openSubmission.schemaMapping.manageSchemaMapping.description"
              defaultMessage="As well as the default mapping behaviour, you may save up to 10 schema mappings for this assignment.
              The best fitting schema mapping will be applied automatically when you start a submission."
            />
          </Typography>
        </Box>
        <Box mb={2}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={8}>
              <ValidatedTextField
                fieldErrors={fieldErrors}
                label={intl.formatMessage({
                  id: 'openSubmission.schemaMapping.manageSchemaMapping.mappingName.label',
                  defaultMessage: 'Mapping Name',
                })}
                name="name"
                variant="outlined"
                margin="dense"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Box pt={1}>
                <DefaultButton
                  fullWidth
                  startIcon={<SaveIcon />}
                  disabled={processing || !submission.canUpdateSubmission}
                  onClick={handleSaveMapping}
                  name="saveMapping"
                >
                  <FormattedMessage
                    id="openSubmission.schemaMapping.manageSchemaMapping.saveButton"
                    defaultMessage="Save Mapping"
                  />
                </DefaultButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {Object.keys(mappingRatios.saved).length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="openSubmission.schemaMapping.manageSchemaMapping.table.savedMappingColumn"
                    defaultMessage="Saved Mapping"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="openSubmission.schemaMapping.manageSchemaMapping.table.mappedColumn"
                    defaultMessage="Mapped"
                  />
                </TableCell>
                <TableCell align="center">
                  <FormattedMessage
                    id="openSubmission.schemaMapping.manageSchemaMapping.table.actionsColumn"
                    defaultMessage="Actions"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappingRatios.saved.map((mapping) => (
                <TableRow key={mapping.key}>
                  <TableCell>{mapping.name}</TableCell>
                  <TableCell>{convertRatio(mapping.mapped)}%</TableCell>
                  <TableCell align="right">
                    <ButtonRow>
                      <DefaultButton
                        disabled={processing || !submission.canUpdateSubmission}
                        onClick={() => setRemovingMapping(mapping)}
                        color="secondary"
                        name="deleteMapping"
                      >
                        <FormattedMessage
                          id="openSubmission.schemaMapping.manageSchemaMapping.deleteButton"
                          defaultMessage="Delete"
                        />
                      </DefaultButton>
                      <DefaultButton
                        disabled={processing || !submission.canUpdateSubmission}
                        onClick={() => handleApplyMapping(SchemaMapperType.SAVED, mapping)}
                        name="applyMapping"
                      >
                        <FormattedMessage
                          id="openSubmission.schemaMapping.manageSchemaMapping.applyButton"
                          defaultMessage="Apply"
                        />
                      </DefaultButton>
                    </ButtonRow>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <ConfirmDialog
          id="confirm-remove-mapping"
          isOpen={!!removingMapping}
          title={intl.formatMessage({
            id: 'openSubmission.schemaMapping.manageSchemaMapping.confirmRemove.title',
            defaultMessage: 'Remove mapping',
          })}
          text={intl.formatMessage(
            {
              id: 'openSubmission.schemaMapping.manageSchemaMapping.confirmRemove.text',
              defaultMessage: 'Are you sure you wish to remove mapping: {name}?',
            },
            { name: removingMapping?.name }
          )}
          confirmBtnText={intl.formatMessage({
            id: 'openSubmission.schemaMapping.manageSchemaMapping.confirmRemove.confirmButton',
            defaultMessage: 'Remove Mapping',
          })}
          confirmAction={() => handleDeleteMapping(removingMapping!)}
          closeAction={() => {
            setRemovingMapping(undefined);
          }}
          disabled={!submission.canUpdateSubmission}
          BackdropProps={{ invisible: true }}
        />
      </>
    );
  };

  return (
    <>
      <FullWidthButton
        startIcon={<SyncAltIcon />}
        color="primary"
        onClick={openDialog}
        label={intl.formatMessage({
          id: 'openSubmission.schemaMapping.manageSchemaMapping.manageMappingsButton',
          defaultMessage: 'Manage Schema Mappings',
        })}
        name="manageSchemaMappings"
        disabled={disabled}
      />
      <Dialog
        open={dialogOpen}
        id="manage-schema-mapping-dialog"
        className={loading ? 'ManageSchemaMapping-loading' : 'ManageSchemaMapping-loaded'}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <FormattedMessage
            id="openSubmission.schemaMapping.manageSchemaMapping.title"
            defaultMessage="Manage Schema Mappings"
          />
        </DialogTitle>
        <DialogContent dividers={true}>{renderDialogContent()}</DialogContent>
        <PaddedDialogActions>
          <DefaultButton
            name="closeManageSchemaMappingsDialog"
            color="secondary"
            onClick={() => setDialogOpen(false)}
          >
            <FormattedMessage
              id="openSubmission.schemaMapping.manageSchemaMapping.closeButton"
              defaultMessage="Close"
            />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default ManageSchemaMapping;
