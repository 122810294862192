import { AxiosPromise } from 'axios';

import { AXIOS } from '../endpoints';
import { OneIntegrateServerSettings } from '../../types';

export function getServerSettings(): AxiosPromise<OneIntegrateServerSettings> {
  return AXIOS.get('/1integrate/server');
}

export function updateServerSettings(
  settings: OneIntegrateServerSettings
): AxiosPromise<OneIntegrateServerSettings> {
  return AXIOS.post('/1integrate/server', settings);
}
