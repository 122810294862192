import { AxiosPromise } from 'axios';

import {
  OAuth2IdentityProviderSettings,
  OAuth2IdentityProviderDetail,
  CreateOAuth2IdentityProviderRequest,
} from '../../types';
import { AXIOS } from '../endpoints';

export function createIdentityProvider(
  request: CreateOAuth2IdentityProviderRequest
): AxiosPromise<OAuth2IdentityProviderDetail> {
  return AXIOS.post('/identity_providers/oauth2', request);
}

export function getIdentityProvider(
  identityProviderKey: string
): AxiosPromise<OAuth2IdentityProviderDetail> {
  return AXIOS.get(`/identity_providers/oauth2/${identityProviderKey}`);
}

export function updateIdentityProvider(
  identityProviderKey: string,
  request: OAuth2IdentityProviderSettings
): AxiosPromise<OAuth2IdentityProviderDetail> {
  return AXIOS.post(`/identity_providers/oauth2/${identityProviderKey}`, request);
}

export function deleteIdentityProvider(identityProviderKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/identity_providers/oauth2/${identityProviderKey}`);
}
