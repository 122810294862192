import { FC, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Typography,
  Box,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Grid,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { extractErrorMessage } from '../../../api/endpoints';
import * as UserImportApi from '../../../api/user/userImport';
import {
  PaddedPaper,
  TableLoadingRow,
  TableErrorRow,
  TableInfoRow,
  TablePreviewRow,
  FullWidthButton,
} from '../../../components';
import { UserImportJobDetail, EvaluatedImportUser } from '../../../types';
import PreviewFieldCell from './PreviewFieldCell';
import { intl } from '../../../Internationalization';
import { FormattedMessage } from 'react-intl';

interface PreviewUploadProps {
  jobDetail: UserImportJobDetail;
  clearJob: () => void;
}

const PreviewUpload: FC<PreviewUploadProps> = ({ jobDetail, clearJob }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>();
  const [processing, setProcessing] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [users, setUsers] = useState<EvaluatedImportUser[]>();

  const disableImport = loading || !!errorMessage || users?.length === 0;

  useEffect(() => {
    fetchUserPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startImport = () => {
    setProcessing(true);
    UserImportApi.startJob(jobDetail.id)
      .then(() => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'userImport.preview.importSuccess',
            defaultMessage: 'User import job started successfully',
          }),
          { variant: 'success' }
        );
        navigate(`/system/user_import/import_history`);
      })
      .catch((error) => {
        setProcessing(false);
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'userImport.preview.importError',
              defaultMessage: 'Failed to import users',
            })
          ),
          { variant: 'error' }
        );
      });
  };

  const cancelImport = () => {
    UserImportApi.cancelJob(jobDetail.id)
      .then(() => {
        clearJob();
        enqueueSnackbar(
          intl.formatMessage({
            id: 'userImport.preview.uploadCancelSuccess',
            defaultMessage: 'Upload cancelled',
          }),
          { variant: 'success' }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'userImport.preview.uploadCancelError',
              defaultMessage: 'Failed to cancel upload',
            })
          ),
          { variant: 'error' }
        );
      });
  };

  const fetchUserPreview = () => {
    setLoading(true);
    UserImportApi.previewJob(jobDetail.id)
      .then((response) => {
        setUsers(response.data);
        setErrorMessage('');
      })
      .catch((error) => {
        setUsers(undefined);
        const extractedErrorMessage = extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'userImport.preview.loadError',
            defaultMessage: 'Failed to fetch preview',
          })
        );
        enqueueSnackbar(extractedErrorMessage, { variant: 'error' });
        setErrorMessage(extractedErrorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderTableRows = () => {
    if (loading) {
      return <TableLoadingRow colSpan={5} />;
    }

    if (errorMessage) {
      return <TableErrorRow colSpan={5} errorMessage={errorMessage} />;
    }

    if (!users) {
      return (
        <TablePreviewRow
          colSpan={5}
          fetchData={fetchUserPreview}
          message={intl.formatMessage({
            id: 'userImport.preview.noUpload',
            defaultMessage: 'You may use this panel to verify your users upload',
          })}
        />
      );
    }

    if (!users.length) {
      return (
        <TableInfoRow
          colSpan={5}
          size="medium"
          message={intl.formatMessage({
            id: 'userImport.preview.noUsers',
            defaultMessage: 'No users found in the CSV file provided',
          })}
        />
      );
    }

    return users.map(({ user, fieldErrors }) => (
      <TableRow key={user.name}>
        <PreviewFieldCell
          fieldErrors={fieldErrors.name}
          fieldValue={user.name}
          displayNoneMessage
        />
        <PreviewFieldCell
          fieldErrors={fieldErrors.email}
          fieldValue={user.email}
          displayNoneMessage
        />
        <PreviewFieldCell fieldErrors={fieldErrors.supplier} fieldValue={user.supplier} />
        <PreviewFieldCell fieldErrors={fieldErrors.receiver} fieldValue={user.receiver} />
        <PreviewFieldCell fieldErrors={fieldErrors.activate} fieldValue={user.activate} />
      </TableRow>
    ));
  };

  return (
    <Container maxWidth="lg" id="system-user-import-preview-upload" disableGutters>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <PaddedPaper>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h5" component="h3">
                <FormattedMessage id="userImport.preview.title" defaultMessage="Preview Users" />
              </Typography>
            </Box>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="userImport.preview.nameColumn" defaultMessage="Name" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="userImport.preview.emailColumn" defaultMessage="Email" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.preview.supplierColumn"
                      defaultMessage="Supplier"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.preview.receiverColumn"
                      defaultMessage="Receiver"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="userImport.preview.activateColumn"
                      defaultMessage="Activate"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTableRows()}</TableBody>
            </Table>
          </PaddedPaper>
        </Grid>
        <Grid item xs={6}>
          <FullWidthButton
            id="cancel-import"
            label={intl.formatMessage({
              id: 'userImport.preview.cancelButton',
              defaultMessage: 'Cancel',
            })}
            color="secondary"
            processing={processing}
            startIcon={<CancelIcon />}
            onClick={cancelImport}
          />
        </Grid>
        <Grid item xs={6}>
          <FullWidthButton
            id="import"
            label={intl.formatMessage({
              id: 'userImport.preview.importButton',
              defaultMessage: 'Import',
            })}
            color="primary"
            processing={processing}
            disabled={disableImport}
            startIcon={<CloudUploadIcon />}
            onClick={startImport}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PreviewUpload;
