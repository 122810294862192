import { AxiosPromise } from 'axios';

import {
  Me,
  ChangePasswordRequest,
  UpdateMyProfileRequest,
  UpdateAvatarRequest,
  VirusCheckedMediaDetail,
  AvatarDetail,
} from '../types';
import { AXIOS, FileUploadConfig } from './endpoints';
import { uploadMediaAndVirusCheck } from './media';

export function getMe(): AxiosPromise<Me> {
  return AXIOS.get('/me');
}

export function updateProfile(request: UpdateMyProfileRequest): AxiosPromise<Me> {
  return AXIOS.post('/me', request);
}

export function updateAvatar(request: UpdateAvatarRequest): AxiosPromise<AvatarDetail> {
  return AXIOS.post('/me/avatar', request);
}

export const uploadImageAndVirusCheck = (
  image: File,
  config?: FileUploadConfig
): AxiosPromise<VirusCheckedMediaDetail> =>
  uploadMediaAndVirusCheck('/me/avatar/image', image, config);

export function deleteImage(): AxiosPromise<void> {
  return AXIOS.delete(`/me/avatar/image`);
}

export function changePassword(changePasswordRequest: ChangePasswordRequest): AxiosPromise<void> {
  return AXIOS.post('/me/change_password', changePasswordRequest);
}
