import { FC, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { Divider, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { onEnterCallback } from '../../util';

const DEBOUNCE_DELAY = 300;

interface FilterSearchProps {
  onSearch: (filter: string) => void;
  label: string;
  disabled?: boolean;
  name?: string;
}

const FilterSearch: FC<FilterSearchProps> = ({ label, disabled, onSearch, name }) => {
  const didMount = useRef(false);
  const [filter, setFilter] = useState('');

  const debouncedSearch = useRef(debounce(onSearch, DEBOUNCE_DELAY));

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    debouncedSearch.current(filter);
  }, [filter]);

  const searchNow = () => {
    onSearch(filter);
    debouncedSearch.current.cancel();
  };
  const submitOnEnter = onEnterCallback(searchNow);

  return (
    <>
      <TextField
        name={name}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        sx={{
          minWidth: 100,
          maxWidth: 250,
          flex: 1,
          mx: 1,
          mb: 1,
          mt: 0.25,
        }}
        label={label}
        onKeyDown={submitOnEnter}
        disabled={disabled}
        variant="standard"
        size="small"
        margin="dense"
        InputProps={{
          'endAdornment': (
            <IconButton
              onClick={searchNow}
              sx={{ p: 1, mt: -1 }}
              aria-label={label}
              disabled={disabled}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          ),
          'aria-label': label,
          'disableUnderline': true,
        }}
      />
      <Divider orientation="vertical" sx={{ width: '1px', height: '40px', m: 1 }} />
    </>
  );
};

export default FilterSearch;
