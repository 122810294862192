import { FC } from 'react';
import { Tooltip } from '@mui/material';

import { SUBMISSION_SOURCE_METADATA, SubmissionSummary } from '../../types';

interface SubmissionSubmittedViaIconProps {
  submission: SubmissionSummary;
}

const SubmissionSubmittedViaIcon: FC<SubmissionSubmittedViaIconProps> = ({ submission }) => {
  const sourceMetadata = SUBMISSION_SOURCE_METADATA[submission.source];
  const description = sourceMetadata.tooltip;
  const Icon = sourceMetadata.icon;

  return (
    <Tooltip title={description}>
      <Icon />
    </Tooltip>
  );
};
export default SubmissionSubmittedViaIcon;
