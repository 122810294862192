import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import { AppDecorator, HeaderBreadcrumb } from '../../../components';
import { ErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';

import SystemHeader from '../SystemHeader';
import IdentityProviders from './IdentityProviders';
import Saml2IdentityProvider from './saml2/Saml2IdentityProvider';
import OAuth2IdentityProvider from './oauth2/OAuth2IdentityProvider';
import { useTitle } from '../../../hooks';

const IdentityProvidersRouting: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.system.identityProviders',
      defaultMessage: 'Identity Providers',
    })
  );
  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Typography>
        <VpnKeyIcon />
        <FormattedMessage
          id="identityProvider.routing.breadcrumb"
          defaultMessage="Identity Providers"
        />
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderBreadcrumb={renderBreadcrumb}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'identityProvider.routing.title',
          defaultMessage: 'Identity Providers',
        })}
        icon={VpnKeyIcon}
      />
      <ErrorBlock>
        <Routes>
          <Route index element={<IdentityProviders />} />
          <Route path="saml2/:identityProviderKey/*" element={<Saml2IdentityProvider />} />
          <Route path="oauth2/:identityProviderKey/*" element={<OAuth2IdentityProvider />} />
          <Route path="*" element={<Navigate to="." replace />} />
        </Routes>
      </ErrorBlock>
    </AppDecorator>
  );
};

export default IdentityProvidersRouting;
