import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { intervalToDuration, formatDuration } from 'date-fns';

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Container,
  Typography,
  ListItemAvatar,
} from '@mui/material';

import ForwardIcon from '@mui/icons-material/Forward';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import * as UserApi from '../../../api/user/user';
import { intl } from '../../../Internationalization';
import { isLdapUser, UserDetail } from '../../../types';
import { extractErrorMessage } from '../../../api/endpoints';
import { ApplicationContext } from '../../../contexts/application';
import { AuthenticatedContext } from '../../../contexts/authentication';
import {
  PaddedPaper,
  ConfirmDialog,
  DefaultButton,
  ActiveAvatar,
  SplitButton,
} from '../../../components';
import { dateTimeFormat } from '../../../util';

import LdapUserMessage from './LdapUserMessage';
import PasswordResetLinkDialog from './PasswordResetLinkDialog';
import ActivationLinkDialog from './ActivationLinkDialog';

interface AccountStatusProps {
  user: UserDetail;
  userUpdated: (profile: UserDetail) => void;
}

const AccountStatus: FC<AccountStatusProps> = ({ user, userUpdated }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useContext(AuthenticatedContext);
  const {
    applicationDetails: { mailSenderEnabled },
  } = useContext(ApplicationContext);

  const [processing, setProcessing] = useState<boolean>(false);
  const [suspendConfirm, setSuspendConfirm] = useState<boolean>(false);

  const [resendActivationEmailConfirm, setResendActivationEmailConfirm] = useState<boolean>(false);
  const [passwordResetLinkDialogOpen, setPasswordResetLinkDialogOpen] = useState<boolean>(false);
  const [activationLinkDialogOpen, setActivationLinkDialogOpen] = useState<boolean>(false);

  const ldapUser = isLdapUser(user);
  const performingOnSelf = me.key === user.key;

  const disableForm = !me.receiverPermissions?.admin || performingOnSelf || processing;

  const handleSuspend = async () => {
    setProcessing(true);
    setSuspendConfirm(false);
    try {
      const response = await UserApi.suspendUser(user.key);
      userUpdated(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'user.status.suspendAccountSuccess',
          defaultMessage: 'Account suspended',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'user.status.suspendAccountError',
            defaultMessage: 'Failed to suspend account',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleUnsuspend = async () => {
    setProcessing(true);
    setSuspendConfirm(false);
    try {
      const response = await UserApi.unsuspendUser(user.key);
      userUpdated(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'user.status.unsuspendAccountSuccess',
          defaultMessage: 'Account unsuspended',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'user.status.unsuspendAccountError',
            defaultMessage: 'Failed to unsuspend account',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleResendActivationEmail = () => {
    setResendActivationEmailConfirm(false);
    resendActivationEmail();
  };

  const resendActivationEmail = async () => {
    setProcessing(true);
    try {
      const response = await UserApi.resendActivationEmail(user.key);
      userUpdated(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'user.status.resendActivationEmailSuccess',
          defaultMessage: 'Resent activation email',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'user.status.resendActivationEmailError',
            defaultMessage: 'Failed to resend activation email',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const renderActivationButtons = () => {
    const activationLinkLabel = intl.formatMessage({
      id: 'user.status.activationLinkButton',
      defaultMessage: 'Activation Link',
    });
    if (mailSenderEnabled) {
      return (
        <SplitButton
          name="activationLink"
          moreName="moreActivationLinkOptions"
          label={activationLinkLabel}
          onClick={() => setActivationLinkDialogOpen(true)}
          items={[
            {
              label: intl.formatMessage({
                id: 'user.status.resendActivationEmailButton',
                defaultMessage: 'Resend Activation Email',
              }),
              onClick: () => setResendActivationEmailConfirm(true),
            },
          ]}
          disabled={disableForm}
        />
      );
    }
    return (
      <DefaultButton
        name="activationLink"
        color="grey"
        onClick={() => setActivationLinkDialogOpen(true)}
        disabled={disableForm}
      >
        {activationLinkLabel}
      </DefaultButton>
    );
  };

  return (
    <Container maxWidth="md" id="user-status" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" component="h3" gutterBottom>
          <FormattedMessage id="user.status.title" defaultMessage="Account Status" />
        </Typography>
        <LdapUserMessage user={user} />
        <List>
          <ListItem>
            <ListItemAvatar>
              <ActiveAvatar activated="active">
                <PersonAddIcon />
              </ActiveAvatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage(
                {
                  id: 'user.status.createdBy',
                  defaultMessage: 'Created by {email}',
                },
                { email: user.created.performedBy.email }
              )}
              secondary={intl.formatMessage(
                {
                  id: 'user.status.createdAt',
                  defaultMessage: 'Created at {createdAt}',
                },
                { createdAt: dateTimeFormat(user.created.performedAt) }
              )}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <ActiveAvatar activated={user.activated ? 'active' : 'default'}>
                <ContactMailIcon />
              </ActiveAvatar>
            </ListItemAvatar>
            {user.activated ? (
              <ListItemText
                primary={intl.formatMessage({
                  id: 'user.status.activated',
                  defaultMessage: 'Activated',
                })}
                secondary={intl.formatMessage(
                  {
                    id: 'user.status.activatedAt',
                    defaultMessage: 'Activated at {activatedAt}',
                  },
                  { activatedAt: dateTimeFormat(user.activated.performedAt) }
                )}
              />
            ) : (
              <>
                <ListItemText
                  primary={intl.formatMessage({
                    id: 'user.status.notActivated',
                    defaultMessage: 'Not Activated',
                  })}
                  secondary={intl.formatMessage({
                    id: 'user.status.notActivated.description',
                    defaultMessage: 'User has not activated their account',
                  })}
                />
                <ListItemSecondaryAction>{renderActivationButtons()}</ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          <ConfirmDialog
            id="confirm-resend-activation-email"
            title={intl.formatMessage({
              id: 'user.status.confirmResendActivation.title',
              defaultMessage: 'Resend Activation Email',
            })}
            text={intl.formatMessage({
              id: 'user.status.confirmResendActivation.text',
              defaultMessage: 'Are you sure you wish resend the activation email?',
            })}
            confirmBtnText={intl.formatMessage({
              id: 'user.status.confirmResendActivation.confirmButton',
              defaultMessage: 'Resend',
            })}
            confirmAction={handleResendActivationEmail}
            closeAction={() => setResendActivationEmailConfirm(false)}
            isOpen={resendActivationEmailConfirm}
          />
          <ActivationLinkDialog
            open={activationLinkDialogOpen}
            onClose={() => setActivationLinkDialogOpen(false)}
          />
          <ListItem>
            <ListItemAvatar>
              <ActiveAvatar activated={user.loggedIn ? 'active' : 'default'}>
                <ForwardIcon />
              </ActiveAvatar>
            </ListItemAvatar>
            {user.loggedIn ? (
              <ListItemText
                primary={intl.formatMessage(
                  {
                    id: 'user.status.loggedIn',
                    defaultMessage: 'Logged in {lastSeen} ago',
                  },
                  {
                    lastSeen: formatDuration(
                      intervalToDuration({
                        start: new Date(user.loggedIn.performedAt),
                        end: new Date(),
                      }),
                      {
                        format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'],
                      }
                    ),
                  }
                )}
                secondary={intl.formatMessage(
                  {
                    id: 'user.status.loggedInAt',
                    defaultMessage: 'Logged in at {loggedInAt}',
                  },
                  { loggedInAt: dateTimeFormat(user.loggedIn.performedAt) }
                )}
              />
            ) : (
              <ListItemText
                primary={intl.formatMessage({
                  id: 'user.status.neverLoggedIn',
                  defaultMessage: 'Never logged in',
                })}
                secondary={intl.formatMessage({
                  id: 'user.status.neverLoggedIn.description',
                  defaultMessage: 'User has never logged in',
                })}
              />
            )}
            <ListItemSecondaryAction>
              <DefaultButton
                id="password-reset-link"
                color="grey"
                onClick={() => setPasswordResetLinkDialogOpen(true)}
                disabled={
                  !me.receiverPermissions?.admin || processing || ldapUser || !user.activated
                }
              >
                <FormattedMessage
                  id="user.status.passwordResetLink.button.label"
                  defaultMessage="Password reset link"
                />
              </DefaultButton>
            </ListItemSecondaryAction>
          </ListItem>
          <PasswordResetLinkDialog
            open={passwordResetLinkDialogOpen}
            onClose={() => setPasswordResetLinkDialogOpen(false)}
          />
          <ListItem>
            <ListItemAvatar>
              <ActiveAvatar activated={user.suspended ? 'inactive' : 'active'}>
                <PriorityHighIcon />
              </ActiveAvatar>
            </ListItemAvatar>
            {user.suspended ? (
              <>
                <ListItemText
                  primary={intl.formatMessage(
                    {
                      id: 'user.status.suspendedBy',
                      defaultMessage: 'Suspended by {suspendedBy}',
                    },
                    { suspendedBy: user.suspended.performedBy.email }
                  )}
                  secondary={intl.formatMessage(
                    {
                      id: 'user.status.suspendedAt',
                      defaultMessage: 'Suspended at {suspendedAt}',
                    },
                    { suspendedAt: dateTimeFormat(user.suspended.performedAt) }
                  )}
                />
                <ListItemSecondaryAction>
                  <DefaultButton
                    id="unsuspend-account"
                    color="grey"
                    onClick={() => setSuspendConfirm(true)}
                    disabled={processing || ldapUser || performingOnSelf}
                  >
                    <FormattedMessage
                      id="user.status.unsuspendAccountButton"
                      defaultMessage="Unsuspend account"
                    />
                  </DefaultButton>
                </ListItemSecondaryAction>
              </>
            ) : (
              <>
                <ListItemText
                  primary={intl.formatMessage({
                    id: 'user.status.accountActive',
                    defaultMessage: 'Account active',
                  })}
                  secondary={intl.formatMessage({
                    id: 'user.status.accountActive.description',
                    defaultMessage: 'Account is currently active',
                  })}
                />
                <ListItemSecondaryAction>
                  <DefaultButton
                    id="suspend-account"
                    color="grey"
                    onClick={() => setSuspendConfirm(true)}
                    disabled={disableForm || ldapUser || performingOnSelf}
                  >
                    <FormattedMessage
                      id="user.status.suspendAccountButton"
                      defaultMessage="Suspend account"
                    />
                  </DefaultButton>
                </ListItemSecondaryAction>
              </>
            )}
            <ConfirmDialog
              id="confirm-suspend-toggle"
              isOpen={suspendConfirm}
              title={
                user.suspended
                  ? intl.formatMessage({
                      id: 'user.status.suspendToggleConfirm.unsuspendTitle',
                      defaultMessage: 'Unsuspend account',
                    })
                  : intl.formatMessage({
                      id: 'user.status.suspendToggleConfirm.suspendTitle',
                      defaultMessage: 'Suspend account',
                    })
              }
              text={
                user.suspended
                  ? intl.formatMessage({
                      id: 'user.status.suspendToggleConfirm.unsuspendText',
                      defaultMessage: 'Are you sure you wish to unsuspend the account?',
                    })
                  : intl.formatMessage({
                      id: 'user.status.suspendToggleConfirm.suspendText',
                      defaultMessage: 'Are you sure you wish to suspend the account?',
                    })
              }
              confirmBtnText={
                user.suspended
                  ? intl.formatMessage({
                      id: 'user.status.suspendToggleConfirm.unsuspendButton',
                      defaultMessage: 'Unsuspend',
                    })
                  : intl.formatMessage({
                      id: 'user.status.suspendToggleConfirm.suspendButton',
                      defaultMessage: 'Suspend',
                    })
              }
              confirmAction={user.suspended ? handleUnsuspend : handleSuspend}
              closeAction={() => setSuspendConfirm(false)}
            />
          </ListItem>
        </List>
      </PaddedPaper>
    </Container>
  );
};

export default AccountStatus;
