import React, { FC } from 'react';

import { UserProfile } from '../../types';
import * as UserMediaApi from '../../api/user/userMedia';

import ImageAvatar, { ImageAvatarProps } from './ImageAvatar';
import { checkVirusScanMediaDetail } from '../../util/files';

interface AccountAvatarProps extends Omit<ImageAvatarProps, 'label' | 'color'> {
  user: UserProfile;
}

const AccountAvatar: FC<AccountAvatarProps> = ({
  user: {
    name,
    key,
    avatar: { color, image },
  },
  ...rest
}) => {
  const checkedMedia = checkVirusScanMediaDetail(image);

  return (
    <ImageAvatar
      label={name}
      alt={name}
      color={color}
      src={checkedMedia && UserMediaApi.downloadImageUri(key, checkedMedia)}
      {...rest}
    />
  );
};

export default AccountAvatar;
