import { FC, useCallback, useState, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { FormattedMessage } from 'react-intl';

import { ValidateFieldsError } from 'async-validator';

import * as SpecificationActionsApi from '../../../../../../api/specification/specificationActions';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import {
  CardGrid,
  DefaultButton,
  FormButtons,
  LabeledAddFab,
  Loading,
  NoContentPlaceholder,
} from '../../../../../../components';
import { specificationActionConfigValidator } from '../../../../../../validation';
import { useErrorBlock } from '../../../../../../contexts/error-block';
import { useNavigationPrompt } from '../../../../../../contexts/navigation-prompt';
import { intl } from '../../../../../../Internationalization';
import {
  CreateSpecificationActionConfig,
  SessionSchema,
  SpecificationActions,
  SpecificationActionConfig,
  ActionKind,
  SpecificationActionConfigs,
  ReorderDirection,
} from '../../../../../../types';
import { validate } from '../../../../../../validation';

import { SpecificationContext } from '../../SpecificationContext';

import NoSessionMessage from '../NoSessionMessage';

import CopyInputActionCard from './CopyInputActionCard';
import AddActionDialog from './AddActionDialog';
import DeleteActionDialog from './DeleteActionDialog';
import DebugActionCard from './DebugActionCard';

const parseActionDetailsToConfig = (actions: SpecificationActions[]) => {
  return actions.map((action) => {
    const sharedActionConfig = {
      key: action.key,
      name: action.name,
      kind: action.kind,
      inputKeys: action.inputs?.map((input) => input.key) || [],
    } as SpecificationActionConfigs;

    switch (action.kind) {
      case ActionKind.COPY_INPUTS:
        return {
          ...sharedActionConfig,
          targetPath: action.targetPath,
          namedLocation: action.namedLocation?.key,
        };
      default:
        return sharedActionConfig;
    }
  });
};

const moveArrayPosition = (array: any[], from: number, to: number) => {
  const updatedArray = [...array];
  updatedArray.splice(to, 0, updatedArray.splice(from, 1)[0]);
  return updatedArray;
};

interface ActionConfigEditorProps {
  sessionSchema: SessionSchema;
}

const ActionConfigEditor: FC<ActionConfigEditorProps> = ({ sessionSchema }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { raiseError } = useErrorBlock();
  const { raiseNavigationBlock, clearNavigationBlock } = useNavigationPrompt();
  const { specificationKey, specification, projectKey, validateSpecification } =
    useContext(SpecificationContext);

  const [actions, setActions] = useState<SpecificationActions[]>([]);
  const [addActionDialogOpen, setAddActionDialogOpen] = useState<boolean>(false);
  const [actionToDelete, setActionToDelete] = useState<string>();
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [fetching, setFetching] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const disabled = processing || fetching;

  const handleCreateAction = async (action: CreateSpecificationActionConfig) => {
    setProcessing(true);
    try {
      const response = await SpecificationActionsApi.createSpecificationAction(
        specificationKey,
        action
      );
      setActions((prevActions) => prevActions.concat(response.data));
      setAddActionDialogOpen(false);
      raiseNavigationBlock();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.actionConfig.createError',
            defaultMessage: 'Failed to create action',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const validateAndSaveActions = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      saveConfig(
        (
          await validate(specificationActionConfigValidator(actions), {
            actions: parseActionDetailsToConfig(actions),
          })
        ).actions
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const saveConfig = async (validatedActions: SpecificationActionConfig[]) => {
    try {
      const response = await SpecificationActionsApi.updateSpecificationActions(
        specificationKey,
        validatedActions
      );
      setActions(response.data);
      setAddActionDialogOpen(false);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'specification.configuration.actionConfig.saveSuccessful',
          defaultMessage: 'Actions updated successfully',
        }),
        { variant: 'success' }
      );
      clearNavigationBlock();
      validateSpecification();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.actionConfig.saveError',
            defaultMessage: 'Failed to save actions',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const fetchConfig = useCallback(async () => {
    try {
      setFetching(true);
      const response = await SpecificationActionsApi.listSpecificationActions(specificationKey);
      setActions(response.data);
      validateSpecification();
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.actionConfig.loadError',
            defaultMessage: 'Failed to fetch actions',
          })
        )
      );
    } finally {
      setFetching(false);
    }
  }, [raiseError, specificationKey, validateSpecification]);

  useEffect(() => {
    fetchConfig();
  }, [sessionSchema, fetchConfig]);

  const handleUpdateAction = (action: SpecificationActions) => {
    raiseNavigationBlock();
    setActions((prevActions) => {
      const updatedActions = [...prevActions];
      const actionIndex = prevActions.findIndex(({ key }) => key === action.key);
      if (actionIndex !== undefined) {
        updatedActions[actionIndex] = { ...updatedActions[actionIndex], ...action };
      }
      return updatedActions;
    });
  };

  const handleDeleteAction = async () => {
    setActions((prevActions) => {
      const updatedActions = [...prevActions];
      return updatedActions.filter((action) => action.key !== actionToDelete);
    });
    raiseNavigationBlock();
  };

  const handleOpenAddActionDialog = () => {
    setAddActionDialogOpen(true);
  };
  const handleCloseAddActionDialog = () => {
    setAddActionDialogOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setActionToDelete(undefined);
  };

  const handleReorderAction = (actionKey: string, direction: ReorderDirection) => {
    const index = actions.findIndex((action) => action.key === actionKey);
    if (
      (index === 0 && direction === 'UP') ||
      (index === actions.length - 1 && direction === 'DOWN')
    ) {
      return;
    }
    setActions((prevActions) =>
      moveArrayPosition(prevActions, index, direction === 'UP' ? index - 1 : index + 1)
    );
    raiseNavigationBlock();
  };

  const renderActionCards = () => {
    if (fetching) {
      return {
        className: 'ActionConfigEditor-loading',
        content: <Loading />,
      };
    }
    if (!specification.sessionPath) {
      return {
        className: 'ActionConfigEditor-noSession',
        content: <NoSessionMessage projectKey={projectKey} specificationKey={specificationKey} />,
      };
    }

    if (!actions.length) {
      return {
        className: 'ActionConfigEditor-noContent',
        content: (
          <NoContentPlaceholder
            size="medium"
            message={intl.formatMessage({
              id: 'specification.configuration.actionsConfig.noActions',
              defaultMessage: 'No actions exist in this specification.',
            })}
          />
        ),
      };
    }

    return {
      className: 'ActionConfigEditor-loaded',
      content: actions?.map((action, index) => {
        const firstItem = index === 0;
        const lastItem = index === actions.length - 1;
        switch (action.kind) {
          case ActionKind.COPY_INPUTS:
            return (
              <CopyInputActionCard
                key={action.key}
                action={action}
                handleUpdateAction={handleUpdateAction}
                handleDeleteAction={() => setActionToDelete(action.key)}
                handleReorderAction={handleReorderAction}
                disableNext={lastItem}
                disablePrev={firstItem}
                index={index.toString()}
                fieldErrors={fieldErrors}
                disabled={disabled}
              />
            );
          case ActionKind.DEBUG:
            return (
              <DebugActionCard
                key={action.key}
                action={action}
                handleUpdateAction={handleUpdateAction}
                handleDeleteAction={() => setActionToDelete(action.key)}
                handleReorderAction={handleReorderAction}
                disableNext={lastItem}
                disablePrev={firstItem}
                index={index.toString()}
                fieldErrors={fieldErrors}
                disabled={disabled}
              />
            );
          default:
            return null;
        }
      }),
    };
  };

  const cards = renderActionCards();

  return (
    <Box id="specification-actions-editor" className={cards.className}>
      <CardGrid>{cards.content}</CardGrid>
      <FormButtons>
        <DefaultButton
          name="saveActionsConfig"
          startIcon={<SaveIcon />}
          onClick={() => validateAndSaveActions()}
          disabled={processing || fetching}
        >
          <FormattedMessage
            id="specification.configuration.actionConfig.saveButton"
            defaultMessage="Save action config"
          />
        </DefaultButton>
      </FormButtons>
      <LabeledAddFab
        name="addAction"
        label={intl.formatMessage({
          id: 'specification.configuration.actionConfig.addActionButton',
          defaultMessage: 'Add Action',
        })}
        onClick={handleOpenAddActionDialog}
        disabled={disabled}
      />
      <AddActionDialog
        dialogOpen={addActionDialogOpen}
        disabled={disabled}
        onCloseDialog={handleCloseAddActionDialog}
        onConfirm={handleCreateAction}
      />
      <DeleteActionDialog
        dialogOpen={!!actionToDelete}
        disabled={disabled}
        onCloseDialog={handleCloseDeleteDialog}
        onConfirm={handleDeleteAction}
      />
    </Box>
  );
};

export default ActionConfigEditor;
