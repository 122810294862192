import { useCallback, useEffect, useRef, useState } from 'react';
import axios, { AxiosPromise } from 'axios';
import { useSnackbar } from 'notistack';
import { isFunction } from 'lodash';

import { PageResponse, SimplePageRequest } from '../types';
import { extractErrorMessage, RequestConfig } from '../api/endpoints';
import { intl } from '../Internationalization';

interface InfiniteScrollOptions<T, R extends SimplePageRequest, P extends PageResponse<T>> {
  initialRequest: R;
  onRequest: (request: R, config?: RequestConfig) => AxiosPromise<P>;
  onGenerateNextRequest(request: R, lastItem: T): R;
}

const useInfiniteScroll = <
  T,
  R extends SimplePageRequest,
  P extends PageResponse<T>,
  E extends HTMLElement
>({
  initialRequest,
  onRequest,
  onGenerateNextRequest,
}: InfiniteScrollOptions<T, R, P>) => {
  const { enqueueSnackbar } = useSnackbar();
  const observer = useRef<IntersectionObserver | null>(null);
  const [request, setRequest] = useState<R>({ ...initialRequest, page: 0 });

  const [items, setItems] = useState<T[]>([]);
  const [moreItems, setMoreItems] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [failure, setFailure] = useState<boolean>(false);

  const replaceItem = (index: number, newItem?: T) => {
    const splicedItems = [...items];
    if (newItem) {
      splicedItems.splice(index, 1, newItem);
    } else {
      splicedItems.splice(index, 1);
    }
    setItems(splicedItems);
  };

  const lastItemRef = useCallback(
    (node: E | null) => {
      observer.current?.disconnect();
      if (node && moreItems) {
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            setRequest((prev) => onGenerateNextRequest(prev, items[items.length - 1]));
          }
        });
        observer.current.observe(node);
      }
    },
    [onGenerateNextRequest, moreItems, items]
  );

  const updateRequest = useCallback((update?: Partial<R> | ((prevState: R) => R)) => {
    setItems([]);
    setMoreItems(true);
    if (isFunction(update)) {
      setRequest((prevState) => update({ ...prevState, page: 0 }));
    } else {
      setRequest((prevState) => ({
        ...prevState,
        ...update,
        page: 0,
      }));
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchPage = async () => {
      setFailure(false);
      setProcessing(true);
      try {
        const { data } = await onRequest(request, { signal: abortController.signal });
        setMoreItems(data.total > data.size);
        setItems((existingItems) => [...existingItems, ...data.results]);
        setProcessing(false);
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          setFailure(true);
          setProcessing(false);
          enqueueSnackbar(
            extractErrorMessage(
              error,
              intl.formatMessage({
                id: 'hooks.useInfiniteScroll.loadError',
                defaultMessage: 'Failed to load data',
              })
            ),
            { variant: 'error' }
          );
        }
      }
    };
    fetchPage();
    return () => abortController.abort();
  }, [enqueueSnackbar, request, onRequest]);

  return {
    lastItemRef,
    updateRequest,
    replaceItem,
    request,
    items,
    moreItems,
    processing,
    failure,
  } as const;
};

export default useInfiniteScroll;
