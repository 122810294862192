import { FC, useCallback } from 'react';

import * as AssignmentsApi from '../../api/assignment/assignments';
import { AssignmentDetail } from '../../types';

import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from './FilteredAutocomplete';

interface AssignmentAutocompleteProps
  extends BasicFilteredAutocompleteProps<AssignmentDetail, false> {
  projectKey?: string;
  specificationKey?: string;
  active?: boolean;
}

const fetchAssignments = async (
  filter: string,
  projectKey?: string,
  specificationKey?: string,
  active?: boolean
) => {
  const response = await AssignmentsApi.getAssignments({
    page: 0,
    size: 100,
    projectKey,
    specificationKey,
    filter,
    active,
  });
  return response.data.results;
};

const renderOption = (assignment: AssignmentDetail) => assignment.reference;
const getOptionSelected = (option: AssignmentDetail, value: AssignmentDetail) =>
  option.key === value.key;
const getOptionLabel = (assignment: AssignmentDetail) => assignment.reference;

const AssignmentAutocomplete: FC<AssignmentAutocompleteProps> = ({
  projectKey,
  specificationKey,
  disabled,
  active,
  ...rest
}) => {
  const fetchOptions = useCallback(
    (filter: string) => fetchAssignments(filter, projectKey, specificationKey, active),
    [projectKey, specificationKey, active]
  );
  return (
    <FilteredAutocomplete
      {...rest}
      fetchOptions={fetchOptions}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      disabled={disabled}
    />
  );
};

export default AssignmentAutocomplete;
