import { SvgIconProps } from '@mui/material/SvgIcon';

import { intl } from '../Internationalization';
import {
  RejectedIcon,
  CancelledIcon,
  ErrorIcon,
  SuccessIcon,
  SubmissionDownloadIcon,
  SubmissionPauseIcon,
  SubmissionRunningIcon,
  SubmissionUploadIcon,
  SubmissionSchemaMappingIcon,
  TickIcon,
} from '../components/icons';
import { AssignmentMetadata } from './assignment';
import {
  MediaDetail,
  PageResponse,
  SimplePageRequest,
  OffsetDateTimeRange,
  VirusCheckedMediaDetail,
} from './shared';
import { DataStoreParameterType, SupplierMetadata, TaskResult, UserProfile } from '.';
import { SubmissionActionResults } from './submissionAction';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PublicIcon from '@mui/icons-material/Public';

export type SubmissionFileType = 'inputs' | 'modified_inputs' | 'outputs';

export type SubmissionInputFiles = Record<string, VirusCheckedMediaDetail>;
export type SubmissionModifiedInputFiles = Record<string, MediaDetail>;
export type SubmissionOutputFiles = Record<string, MediaDetail>;

export type InputsDescription = Record<string, SubmissionInputDetail>;
export type SubmissionInputsByKey = Record<string, SubmissionInputSummary>;

export interface SubmissionSummary {
  reference: string;
  assignment: AssignmentMetadata;
  supplier: SupplierMetadata;
  user: UserProfile;
  sendNotifications: boolean;
  automationMode: SubmissionAutomationMode;
  closed: boolean;
  createdAt: string;
  startedAt: string;
  finishedAt: string;
  inputsByKey: SubmissionInputsByKey;
  modifiedInputs: SubmissionModifiedInputFiles;
  outputs: SubmissionOutputFiles;
  state: SubmissionState;
  errorState?: SubmissionState;
  errorMessage?: string;
  appliedSchemaMapping?: MediaDetail;
  outcome?: SubmissionOutcome;
  interactive: boolean;
  rejection?: SubmissionRejection;
  mediaRemoved: boolean;
  inputParameters: SubmissionInputParameters;
  source: SubmissionSource;
}

export type SubmissionInputParameters = Record<string, DataStoreParameterDetail[]>;

export interface DataStoreDetail {
  dataStorePath: string;
  parameters: DataStoreParameterDetail[];
}

export interface SubmissionInputSummary {
  inputKey: string;
  inputName: string;
  selectedDataStore?: string;
  file?: VirusCheckedMediaDetail;
}

export interface SubmissionInputDetail extends SubmissionInputSummary {
  uploadSupported: boolean;
  uploadRequired: boolean;
  orphaned: boolean;
  linkedDataStores: Record<string, DataStoreDetail>;
  sortKey: number;
}

export type SubmissionDataSetInputParameters = Record<string, string | null>;

export interface DataStoreParameterDetail {
  type: DataStoreParameterType;
  value: string;
  valuePresent: boolean;
  name: string;
}

export interface SubmissionPatch {
  automationMode?: SubmissionAutomationMode;
  sendNotifications?: boolean;
  fieldValues?: { [key: string]: string | null };
}

export interface ConformanceMetrics {
  processed: number;
  violations: number;
  errors: number;
}

export interface TaskConformance extends ConformanceMetrics {
  taskIdentifier: string;
  passThreshold: number;
  conformancePercentage: number;
  passed: boolean;
}

export interface RuleConformance extends ConformanceMetrics {
  rule: string;
}

export interface SubmissionDetail extends SubmissionSummary {
  sessionId: string;
  taskResults: TaskResult[];
  taskConformance: TaskConformance[];
  ruleConformance: RuleConformance[];
  nextTask: SubmissionTaskType;
  log?: MediaDetail;
  canUpdateSubmission: boolean;
  actionResults: SubmissionActionResults[];
  fieldValues: SubmissionFieldValues;
}

export enum SubmissionTaskType {
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
  FINISH = 'FINISH',
}

export enum SubmissionState {
  NOT_STARTED = 'NOT_STARTED',
  UPLOAD = 'UPLOAD',
  UPLOADING = 'UPLOADING',
  READY = 'READY',
  RUNNING = 'RUNNING',
  PAUSED = 'PAUSED',
  DOWNLOAD = 'DOWNLOAD',
  DOWNLOADING = 'DOWNLOADING',
  PROCESSED = 'PROCESSED',
  FINISH = 'FINISH',
  FINISHING = 'FINISHING',
  FINISHED = 'FINISHED',
}

export enum SubmissionAutomationMode {
  INTERACTIVE = 'INTERACTIVE',
  UNTIL_COMPLETE = 'UNTIL_COMPLETE',
  UNTIL_FINISHED = 'UNTIL_FINISHED',
  UNTIL_CLOSED = 'UNTIL_CLOSED',
}

export const SUBMISSION_AUTOMATION_MODES = [
  SubmissionAutomationMode.INTERACTIVE,
  SubmissionAutomationMode.UNTIL_COMPLETE,
  SubmissionAutomationMode.UNTIL_FINISHED,
  SubmissionAutomationMode.UNTIL_CLOSED,
];

export const SUBMISSION_AUTOMATION_MODE_METADATA: Record<
  SubmissionAutomationMode,
  { label: string }
> = {
  INTERACTIVE: {
    label: intl.formatMessage({
      id: 'submission.automationMode.interactive.label',
      defaultMessage: 'Interactive',
    }),
  },
  UNTIL_COMPLETE: {
    label: intl.formatMessage({
      id: 'submission.automationMode.processed.label',
      defaultMessage: 'Until processing complete',
    }),
  },
  UNTIL_FINISHED: {
    label: intl.formatMessage({
      id: 'submission.automationMode.finished.label',
      defaultMessage: 'Until finished',
    }),
  },
  UNTIL_CLOSED: {
    label: intl.formatMessage({
      id: 'submission.automationMode.closed.label',
      defaultMessage: 'Until finished & closed',
    }),
  },
};

export interface SubmissionStateMetadata {
  label: string;
  description: string;
  notificationDescription?: string;
  icon: React.ComponentType<SvgIconProps>;
}

export const SUBMISSION_STATE_METADATA: { [type in SubmissionState]: SubmissionStateMetadata } = {
  NOT_STARTED: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.notStarted.label',
      defaultMessage: 'Not started',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.notStarted.description',
      defaultMessage: 'Submission not started',
    }),
    icon: SubmissionUploadIcon,
  },
  UPLOAD: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.upload.label',
      defaultMessage: 'Uploading',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.upload.description',
      defaultMessage: 'Submission uploading',
    }),
    icon: SubmissionUploadIcon,
  },
  UPLOADING: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.uploading.label',
      defaultMessage: 'Uploading',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.uploading.description',
      defaultMessage: 'Submission uploading',
    }),
    icon: SubmissionUploadIcon,
  },
  READY: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.ready.label',
      defaultMessage: 'Ready',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.ready.description',
      defaultMessage: 'Submission ready',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.stateMetadata.ready.notificationDescription',
      defaultMessage: 'Your submission is ready for processing and requires your attention.',
    }),
    icon: SubmissionSchemaMappingIcon,
  },
  RUNNING: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.running.label',
      defaultMessage: 'Running',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.running.description',
      defaultMessage: 'Submission running',
    }),
    icon: SubmissionRunningIcon,
  },
  PAUSED: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.paused.label',
      defaultMessage: 'Paused',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.paused.description',
      defaultMessage: 'Submission paused',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.stateMetadata.paused.notificationDescription',
      defaultMessage: 'Your submission has paused and requires your attention.',
    }),
    icon: SubmissionPauseIcon,
  },
  DOWNLOAD: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.download.label',
      defaultMessage: 'Downloading',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.download.description',
      defaultMessage: 'Submission results downloading',
    }),
    icon: SubmissionDownloadIcon,
  },
  DOWNLOADING: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.downloading.label',
      defaultMessage: 'Downloading',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.downloading.description',
      defaultMessage: 'Submission results downloading',
    }),
    icon: SubmissionDownloadIcon,
  },
  PROCESSED: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.processed.label',
      defaultMessage: 'Processed',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.processed.description',
      defaultMessage: 'Submission processed',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.stateMetadata.processed.notificationDescription',
      defaultMessage: 'Your submission has completed processing and requires your attention.',
    }),
    icon: SubmissionPauseIcon,
  },
  FINISH: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.finish.label',
      defaultMessage: 'Finishing',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.finish.description',
      defaultMessage: 'Submission finishing',
    }),
    icon: SubmissionRunningIcon,
  },
  FINISHING: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.finishing.label',
      defaultMessage: 'Finishing',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.finishing.description',
      defaultMessage: 'Submission finishing',
    }),
    icon: SubmissionRunningIcon,
  },
  FINISHED: {
    label: intl.formatMessage({
      id: 'submission.stateMetadata.finished.label',
      defaultMessage: 'Finished',
    }),
    description: intl.formatMessage({
      id: 'submission.stateMetadata.finished.description',
      defaultMessage: 'Submission finished',
    }),
    icon: TickIcon,
  },
};

export enum SubmissionOutcome {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
}

export enum SubmissionRejection {
  SCHEMA = 'SCHEMA',
  CONFORMANCE = 'CONFORMANCE',
  TIMEOUT = 'TIMEOUT',
}

export interface SubmissionOutcomeMetadata {
  label: string;
  description: string;
  notificationDescription: string;
  icon: React.ComponentType<SvgIconProps>;
}

export const SUBMISSION_OUTCOME_METADATA: {
  [type in SubmissionOutcome]: SubmissionOutcomeMetadata;
} = {
  SUCCESS: {
    label: intl.formatMessage({
      id: 'submission.outcomeMetadata.success.label',
      defaultMessage: 'Success',
    }),
    description: intl.formatMessage({
      id: 'submission.outcomeMetadata.success.description',
      defaultMessage: 'Submission successful',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.outcomeMetadata.success.notificationDescription',
      defaultMessage: 'Your submission has completed successfully.',
    }),
    icon: SuccessIcon,
  },
  ERROR: {
    label: intl.formatMessage({
      id: 'submission.outcomeMetadata.error.label',
      defaultMessage: 'Error',
    }),
    description: intl.formatMessage({
      id: 'submission.outcomeMetadata.error.description',
      defaultMessage: 'Submission had errors',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.outcomeMetadata.error.notificationDescription',
      defaultMessage: 'There has been an error while processing your submission.',
    }),
    icon: ErrorIcon,
  },
  CANCELLED: {
    label: intl.formatMessage({
      id: 'submission.outcomeMetadata.cancelled.label',
      defaultMessage: 'Cancelled',
    }),
    description: intl.formatMessage({
      id: 'submission.outcomeMetadata.cancelled.description',
      defaultMessage: 'Submission cancelled',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.outcomeMetadata.cancelled.notificationDescription',
      defaultMessage: 'You have cancelled your submission.',
    }),
    icon: CancelledIcon,
  },
  REJECTED: {
    label: intl.formatMessage({
      id: 'submission.outcomeMetadata.rejected.label',
      defaultMessage: 'Rejected',
    }),
    description: intl.formatMessage({
      id: 'submission.outcomeMetadata.rejected.description',
      defaultMessage: 'Submission rejected',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.outcomeMetadata.rejected.notificationDescription',
      defaultMessage: 'Submission rejected.',
    }),
    icon: RejectedIcon,
  },
};

export interface SubmissionRejectionMetadata {
  qualifier: string;
  notificationDescription: string;
}

export const SUBMISSION_REJECTION_METADATA: {
  [type in SubmissionRejection]: SubmissionRejectionMetadata;
} = {
  SCHEMA: {
    qualifier: intl.formatMessage({
      id: 'submission.rejectionMetadata.schema.qualifier',
      defaultMessage: '(invalid schema)',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.rejectionMetadata.schema.notificationDescription',
      defaultMessage:
        'Your submission has been rejected because the uploaded data did not meet the schema requirements.',
    }),
  },
  CONFORMANCE: {
    qualifier: intl.formatMessage({
      id: 'submission.rejectionMetadata.conformance.qualifier',
      defaultMessage: '(non-conformance)',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.rejectionMetadata.conformance.notificationDescription',
      defaultMessage:
        'Your submission has been rejected because the data did not meet the conformance validation requirements.',
    }),
  },
  TIMEOUT: {
    qualifier: intl.formatMessage({
      id: 'submission.rejectionMetadata.timeout.qualifier',
      defaultMessage: '(timeout)',
    }),
    notificationDescription: intl.formatMessage({
      id: 'submission.rejectionMetadata.timeout.notificationDescription',
      defaultMessage: 'Your submission has been rejected because it has timed out.',
    }),
  },
};

export const FINISHED_SUBMISSION_STATES = [
  SubmissionState.FINISH,
  SubmissionState.FINISHED,
  SubmissionState.FINISHING,
];

export interface SubmissionsRequest extends SimplePageRequest {
  userKey?: string;
  supplierKey?: string;
  createdAt?: OffsetDateTimeRange;
  assignmentKey?: string;
  projectKey?: string;
  specificationKey?: string;
  outcomes?: SubmissionOutcome[];
  state?: SubmissionState;
  closed?: boolean;
  sources?: SubmissionSource[];
  before?: string;
}

export interface SubmissionsResponse extends PageResponse<SubmissionSummary> {}

export interface TaskMapConfig {
  layers: string[];
  srid: string;
  sridWkt: string;
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

export type TaskMapConfigs = {
  [key: string]: TaskMapConfig;
};

export interface SubmissionMapConfig {
  taskMapConfigs: TaskMapConfigs;
}

export interface ObjectReportRequest {
  taskIdentifier: string;
  start: number;
  count: number;
}

export interface ObjectReport {
  className: string;
  gothicId: string;
  nonconformances?: NonConformanceReport[];
  errors?: ErrorReport[];
  reports?: CustomObjectReport[];
  attributes: { [key: string]: any };
}

export interface NonConformanceReport {
  description: string;
  path: string;
  ruleHotspot: RuleHotspot;
}

export interface RuleHotspot {
  spatialHotspots?: SpatialHotspot[];
  nonSpatialHotspots?: NonSpatialHotspot[];
}

export interface SpatialHotspot {
  geometryWkt: string;
}

export interface NonSpatialHotspot {
  className: string;
  label: string;
  attrName: string;
}

export interface ErrorReport {
  nonfatal: boolean;
  message: string;
  cause: string;
  stackTrace: string;
  nativeStackTrace: string;
}
export interface CustomObjectReport {
  path: string;
  label: string;
  values: ReportedValue[];
}

export interface ReportedValue {
  description: string;
  dataType: string;
  value: string;
}

export interface SchemaReport extends ObjectReport {
  sourceDataStore?: string;
  targetDataStore?: string;
  missingClasses: string[];
  excessClasses: string[];
  attributeMismatches: AttributeMismatch[];
}

export interface AttributeMismatch {
  className: string;
  missingAttributes: string[];
  excessAttributes: string[];
  typeMismatches: TypeMismatch[];
}

export interface TypeMismatch {
  attributeName: string;
  actual: string;
  expected: string;
}

export enum SubmissionSource {
  BROWSER = 'BROWSER',
  API = 'API',
  SCHEDULED = 'SCHEDULED',
  ARCGIS = 'ARCGIS',
}

export interface SubmissionSourceMetadata {
  label: string;
  tooltip: string;
  icon: React.ComponentType<SvgIconProps>;
}

export const SUBMISSION_SOURCE_METADATA: {
  [type in SubmissionSource]: SubmissionSourceMetadata;
} = {
  BROWSER: {
    label: intl.formatMessage({
      id: 'submission.source.userInterface.label',
      defaultMessage: 'User Interface',
    }),
    tooltip: intl.formatMessage({
      id: 'submission.source.userInterface.tooltip',
      defaultMessage: 'Submitted via user interface',
    }),
    icon: PersonIcon,
  },
  API: {
    label: intl.formatMessage({
      id: 'submission.source.api.label',
      defaultMessage: 'API',
    }),
    tooltip: intl.formatMessage({
      id: 'submission.source.api.tooltip',
      defaultMessage: 'Submitted via API',
    }),
    icon: ManageAccountsIcon,
  },
  SCHEDULED: {
    label: intl.formatMessage({
      id: 'submission.source.scheduled.label',
      defaultMessage: 'Scheduled',
    }),
    tooltip: intl.formatMessage({
      id: 'submission.source.scheduled.tooltip',
      defaultMessage: 'Submitted via Schedule',
    }),
    icon: AccessTimeIcon,
  },
  ARCGIS: {
    label: intl.formatMessage({
      id: 'submission.source.arcgis.label',
      defaultMessage: '1Data Gateway for ArcGIS Pro',
    }),
    tooltip: intl.formatMessage({
      id: 'submission.source.arcgis.tooltip',
      defaultMessage: 'Submitted via 1Data Gateway for ArcGIS Pro',
    }),
    icon: PublicIcon,
  },
};

export interface SubmissionFieldValues {
  [key: string]: SubmissionFieldValueDetail;
}

export interface SubmissionFieldValueDetail {
  value: string;
  detached: boolean;
}
