export const CenterBoth: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>{props.children}</div>
    </div>
  );
};
