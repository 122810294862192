import { FC } from 'react';

import { ExpressionTemplateDialog } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';
import { DataStoreConfigDetail } from '../../../../../../types';
import { UxDefaultDataStoreIcon } from '../../../../../../components/icons';
import { SPECIFICATION_TEXT_FIELD_GROUPS } from '../../../../../../util';

const dataStoreConfigFieldGroup = {
  icon: UxDefaultDataStoreIcon,
  label: intl.formatMessage({
    id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.fieldGroup.dataStore',
    defaultMessage: 'Data Store',
  }),
  textFields: [
    {
      expression: 'dataStoreName',
      label: intl.formatMessage({
        id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.dataStoreName',
        defaultMessage: 'Data Store',
      }),
      icon: UxDefaultDataStoreIcon,
    },
    {
      expression: 'outputFileExtension',
      label: intl.formatMessage({
        id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.fileExtension',
        defaultMessage: 'File Extension',
      }),
      icon: UxDefaultDataStoreIcon,
    },
  ],
};

const dataStoreConfigFieldGroups = [...SPECIFICATION_TEXT_FIELD_GROUPS, dataStoreConfigFieldGroup];

interface OutputFilenameTemplateProps {
  dataStoreConfig: DataStoreConfigDetail;
  updateDataStoreConfig: (dataStoreConfig: DataStoreConfigDetail) => void;
  disabled: boolean;
}

const OutputFilenameTemplate: FC<OutputFilenameTemplateProps> = ({
  dataStoreConfig,
  updateDataStoreConfig,
  disabled,
}) => {
  const handleConfirm = (updatedFilename: string) => {
    const updatedConfig = { ...dataStoreConfig };
    updatedConfig.outputFilenameTemplate = updatedFilename;
    updateDataStoreConfig(updatedConfig);
  };

  return (
    <ExpressionTemplateDialog
      id="edit-output-filename-dialog"
      fieldName="outputFilenameTemplate"
      label={intl.formatMessage({
        id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate',
        defaultMessage: 'Output Filename Template',
      })}
      onConfirm={handleConfirm}
      textFieldGroups={dataStoreConfigFieldGroups}
      expressionTemplate={dataStoreConfig.outputFilenameTemplate}
      disabled={disabled}
    />
  );
};

export default OutputFilenameTemplate;
