import React, { FC, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, MenuItem, MenuList, Popover, Typography } from '@mui/material';

import WorkIcon from '@mui/icons-material/Work';
import BallotIcon from '@mui/icons-material/Ballot';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { extractErrorMessage } from '../../../../api/endpoints';
import * as MyNotificationApi from '../../../../api/notification/myNotification';

import { toMySubmissionLink } from '../../../../screens/my-assignments/my-assignment/submissions/MySubmissions';
import {
  NotificationDetail,
  SUBMISSION_OUTCOME_METADATA,
  SUBMISSION_REJECTION_METADATA,
  SUBMISSION_STATE_METADATA,
} from '../../../../types';
import { intl } from '../../../../Internationalization';
import { dateTimeFormat } from '../../../../util';

import { IconTypography } from '../../../';

interface NotificationProps {
  notification: NotificationDetail;
  onUpdateNotification: (updatedNotification?: NotificationDetail) => void;
  onNavigate: () => void;
}

const Notification: FC<NotificationProps> = ({
  notification,
  onUpdateNotification,
  onNavigate,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { submission, submissionState, submissionOutcome, submissionRejection } = notification;
  const { assignment } = submission;

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [actionsOpen, setActionsOpen] = useState<boolean>(false);

  const Icon = submissionOutcome
    ? SUBMISSION_OUTCOME_METADATA[submissionOutcome].icon
    : SUBMISSION_STATE_METADATA[submissionState].icon;

  const handleNavigate = () => {
    navigate(toMySubmissionLink(submission));
    handleUpdateReadState(true);
    onNavigate();
  };

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.currentTarget)) {
      return;
    }
    setActionsOpen(false);
  };

  const handleToggleReadState = async () => {
    handleUpdateReadState(!notification.read);
  };

  const handleUpdateReadState = async (newReadState: boolean) => {
    try {
      setActionsOpen(false);
      const response = await MyNotificationApi.patchNotification(
        { read: newReadState },
        notification.key
      );
      onUpdateNotification(response.data);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'components.appBar.notification.toggleReadState.error',
            defaultMessage: 'Failed to update notification read state',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const handleDelete = async () => {
    try {
      setActionsOpen(false);
      await MyNotificationApi.deleteNotification(notification.key);
      onUpdateNotification();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'components.appBar.notification.delete.error',
            defaultMessage: 'Failed to delete notification',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const renderTitle = () => {
    if (submissionOutcome) {
      return SUBMISSION_OUTCOME_METADATA[submissionOutcome].description;
    }
    return SUBMISSION_STATE_METADATA[submissionState].description;
  };

  const renderDescription = () => {
    if (submissionRejection) {
      return SUBMISSION_REJECTION_METADATA[submissionRejection].notificationDescription;
    }
    if (submissionOutcome) {
      return SUBMISSION_OUTCOME_METADATA[submissionOutcome].notificationDescription;
    }
    return SUBMISSION_STATE_METADATA[submissionState].notificationDescription;
  };

  return (
    <Box
      display="flex"
      className="Notification-root"
      sx={{ '&:hover': { bgcolor: (theme) => theme.palette.grey[100] } }}
      p={1}
    >
      <Box display="flex" flexGrow={1} sx={{ cursor: 'pointer' }} onClick={handleNavigate}>
        <Icon sx={{ width: (theme) => theme.spacing(6), height: (theme) => theme.spacing(6) }} />
        <Box ml={2} flexGrow={1}>
          <Typography variant="h6" gutterBottom>
            {renderTitle()}
          </Typography>
          <IconTypography variant="body1">
            <WorkIcon
              fontSize="small"
              titleAccess={intl.formatMessage({
                id: 'components.appBar.notification.submissionProject.titleAccess',
                defaultMessage: 'Project',
              })}
            />
            {assignment.specification.project.name}
          </IconTypography>
          <IconTypography variant="body1">
            <BallotIcon
              fontSize="small"
              titleAccess={intl.formatMessage({
                id: 'components.appBar.notification.submissionSpecification.titleAccess',
                defaultMessage: 'Specification',
              })}
            />
            {assignment.specification.name}
          </IconTypography>
          <IconTypography variant="body1">
            <AssignmentIcon
              fontSize="small"
              titleAccess={intl.formatMessage({
                id: 'components.appBar.notification.submissionAssignment.titleAccess',
                defaultMessage: 'Assignment',
              })}
            />
            {assignment.reference}
          </IconTypography>
          <Box my={1}>
            <Typography variant="body1">{renderDescription()}</Typography>
          </Box>
          <Typography variant="caption">{dateTimeFormat(submission.createdAt)}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <IconButton
          ref={anchorRef}
          aria-haspopup="true"
          onClick={() => setActionsOpen((open) => !open)}
          color="inherit"
          size="large"
          aria-label={intl.formatMessage({
            id: 'components.appBar.notification.notificationOptions.button.ariaLabel',
            defaultMessage: 'Notification options',
          })}
        >
          <MoreHorizIcon fontSize="small" />
        </IconButton>
        <Box textAlign="center">
          {!notification.read && (
            <NotificationsIcon
              titleAccess={intl.formatMessage({
                id: 'components.appBar.notification.notificationUnread.titleAccess',
                defaultMessage: 'Notification unread',
              })}
            />
          )}
        </Box>
        <Popover
          open={actionsOpen}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableScrollLock
        >
          <MenuList>
            <MenuItem onClick={handleToggleReadState}>
              {notification.read
                ? intl.formatMessage({
                    id: 'components.appBar.notification.options.markUnread',
                    defaultMessage: 'Mark as unread',
                  })
                : intl.formatMessage({
                    id: 'components.appBar.notification.options.markRead',
                    defaultMessage: 'Mark as read',
                  })}
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              {intl.formatMessage({
                id: 'components.appBar.notification.options.remove',
                defaultMessage: 'Remove notification',
              })}
            </MenuItem>
          </MenuList>
        </Popover>
      </Box>
    </Box>
  );
};

export default Notification;
