import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Theme,
  PaletteColor,
  alpha,
} from '@mui/material/styles';

import { grey } from '@mui/material/colors';

import { Palette } from '../../types';
import { CustomThemeContext } from './context';
import { GlobalStyles } from '@mui/material';

const defaultPalette = {
  primary: {
    main: '#555',
  },
  secondary: {
    main: '#e1e758',
  },
};

const initialTheme = createTheme({
  palette: {
    ...defaultPalette,
    background: {
      default: '#fafafa',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.77)',
    },
    grey: {
      main: grey[300],
      dark: grey[400],
    },
  },
});

const sharedInputFocusStyles = (theme: Theme) => ({
  'outline': `2px solid ${theme.palette.primary.main}`,
  'outlineOffset': '6px',
  'boxShadow': `0 0 0 4px ${theme.palette.secondary.main}`,
  'z-index': 99,
  'overflow': 'visible',
});

const rawTheme = (theme: Theme) =>
  createTheme(theme, {
    palette: {
      default: theme.palette.augmentColor({
        color: { main: theme.palette.grey[600] },
      }),
      highlight: {
        success: '#ddffdd',
        failure: '#ffdddd',
        selected: '#eeeeee',
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained', color: 'grey' },
            style: {
              color: theme.palette.getContrastText(theme.palette.grey[300]),
            },
          },
          {
            props: { variant: 'outlined', color: 'grey' },
            style: {
              'color': theme.palette.text.primary,
              'borderColor':
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              '&.Mui-disabled': {
                border: `1px solid ${theme.palette.action.disabledBackground}`,
              },
              '&:hover': {
                borderColor:
                  theme.palette.mode === 'light'
                    ? 'rgba(0, 0, 0, 0.23)'
                    : 'rgba(255, 255, 255, 0.23)',
                backgroundColor: alpha(
                  theme.palette.text.primary,
                  theme.palette.action.hoverOpacity
                ),
              },
            },
          },
          {
            props: { color: 'grey', variant: 'text' },
            style: {
              'color': theme.palette.text.primary,
              '&:hover': {
                backgroundColor: alpha(
                  theme.palette.text.primary,
                  theme.palette.action.hoverOpacity
                ),
              },
            },
          },
        ],
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            'overflow': 'visible',
            '&& .MuiSwitch-input': {
              width: '100%',
            },
            '& .Mui-focusVisible': {
              ...sharedInputFocusStyles(theme),
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              ...sharedInputFocusStyles(theme),
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.Mui-focusVisible': {
              ...sharedInputFocusStyles(theme),
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '&& .Mui-focusVisible': {
              outline: `2px solid ${theme.palette.primary.main}`,
              outlineOffset: '-2px',
              boxShadow: `inset 0 0 0px 6px ${theme.palette.secondary.main}`,
            },
          },
        },
      },
      MuiTextField: {
        variants: [
          {
            props: {
              variant: 'standard',
            },
            style: {
              '& .Mui-focused': {
                'input, .MuiSelect-select': {
                  boxShadow: `inset 0 -4px ${theme.palette.secondary.main}, inset 0 4px ${theme.palette.secondary.main}, 4px 0 ${theme.palette.secondary.main}, -4px 0 ${theme.palette.secondary.main}`,
                },
              },
            },
          },
          {
            props: {
              variant: 'filled',
            },
            style: {
              '& .Mui-focused': {
                'input, .MuiSelect-select': {
                  outline: `4px solid ${theme.palette.secondary.main}`,
                  outlineOffset: '-2px',
                },
              },
            },
          },
          {
            props: {
              variant: 'outlined',
            },
            style: {
              '& .Mui-focused': {
                'input, .MuiSelect-select': {
                  outline: `4px solid ${theme.palette.secondary.main}`,
                  outlineOffset: '-8px',
                },
                'input[role=combobox]': {
                  outlineOffset: '-4px',
                },
              },
            },
          },
        ],
      },
    },
  });

const CustomTheme: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(rawTheme(initialTheme));
  const [palette, setPalette] = useState<Palette>(defaultPalette);

  useEffect(() => {
    setTheme((prevTheme) =>
      rawTheme(
        createTheme(prevTheme, {
          palette: {
            primary: prevTheme.palette.augmentColor({ color: palette.primary }),
            secondary: prevTheme.palette.augmentColor({ color: palette.secondary }),
          },
        })
      )
    );
  }, [palette]);

  const updatePalette = useCallback((newPalette: Palette) => setPalette(newPalette), []);

  return (
    <CustomThemeContext.Provider value={{ palette, updatePalette }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              ':focus-visible:not(input, .MuiSelect-select)': {
                ...sharedInputFocusStyles(theme),
              },
            }}
          />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomTheme;

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Palette {
    default: PaletteColor;
    highlight: {
      success: string;
      failure: string;
      selected: string;
    };
  }
  interface Color {
    main: string;
    dark: string;
  }
}
