import { CardMedia, CardMediaProps } from '@mui/material';
import { ElementType, FC } from 'react';

import * as ProjectMediaApi from '../../api/project/projectMedia';
import { ImageStyle, ProjectMetadata } from '../../types';

interface ProjectCardImageProps extends Omit<CardMediaProps<ElementType>, 'image' | 'component'> {
  project: ProjectMetadata;
}

const ProjectCardImage: FC<ProjectCardImageProps> = ({ sx = [], project, ...props }) => (
  <CardMedia
    component="img"
    image={ProjectMediaApi.projectImageOrDefault(project)}
    sx={[
      project.imageStyle === ImageStyle.FIT
        ? {
            objectFit: 'contain',
            padding: '2px',
          }
        : {},
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);

export default ProjectCardImage;
