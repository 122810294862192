import Schema, { InternalRuleItem, Values } from 'async-validator';

import { ldapUrlValidator, durationValidator } from './shared';
import { intl } from '../Internationalization';

export const LDAP_SERVER_SETTINGS_VALIDATOR = new Schema({
  serverUrl: [
    {
      type: 'string',
      required: true,
      message: intl.formatMessage({
        id: 'ldap.validator.serverUrl.required',
        defaultMessage: 'Please provide the server URL',
      }),
    },
    ldapUrlValidator(),
  ],
  baseDn: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.baseDn.required',
      defaultMessage: 'Please provide the base DN',
    }),
  },
  username: {
    validator(
      rule: InternalRuleItem,
      value: any,
      callback: (error?: string) => void,
      source: Values
    ) {
      if (!source.anonymous && !value) {
        callback(
          intl.formatMessage({
            id: 'ldap.validator.username.conditionallyRequired',
            defaultMessage: 'Username must be provided when request is not anonymous',
          })
        );
      }
      callback();
    },
  },
});

export const LDAP_USER_SETTINGS_VALIDATOR = new Schema({
  uid: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.uid.required',
      defaultMessage: 'Must provide a UID field',
    }),
  },
  filter: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.filter.required',
      defaultMessage: 'Must provide a filter',
    }),
  },
  email: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.email.required',
      defaultMessage: 'Must provide an email address field',
    }),
  },
  firstName: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.firstName.required',
      defaultMessage: 'Must provide given name field',
    }),
  },
  synchronizationPeriod: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'ldap.validator.synchronizationPeriod.required',
        defaultMessage: 'Please provide a synchronisation period',
      }),
    },
    durationValidator({ hours: 1 }),
  ],
  jobRetentionPeriod: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'ldap.validator.jobRetentionPeriod.required',
        defaultMessage: 'Please provide a job retention period',
      }),
    },
    durationValidator({ hours: 1 }),
  ],
});
