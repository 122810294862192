import { AxiosPromise } from 'axios';

import { IdentityProviderLinksRequest, IdentityProviderLinksResponse } from '../../types';
import { AXIOS, RequestConfig } from '../endpoints';

export function getIdentityProviderLinks(
  request: IdentityProviderLinksRequest,
  config?: RequestConfig
): AxiosPromise<IdentityProviderLinksResponse> {
  return AXIOS.get('/identity_provider_links', {
    ...config,
    params: request,
  });
}
