import { AxiosPromise } from 'axios';
import { AXIOS, RequestConfig } from '../endpoints';

import { SubmissionsResponse, SubmissionsRequest } from '../../types';

export function getSubmissions(
  request: SubmissionsRequest,
  config?: RequestConfig
): AxiosPromise<SubmissionsResponse> {
  return AXIOS.get(`/submissions`, {
    ...config,
    params: request,
  });
}
