import { FC, useContext } from 'react';
import { TableBody } from '@mui/material';

import {
  TaskConfig,
  SessionSchema,
  TaskConfigKind,
  TASK_CONFIG_KINDS,
} from '../../../../../../types';
import { TableInfoRow } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';

import { SpecificationContext } from '../../SpecificationContext';

import ConformanceTaskRow from './ConformanceTaskRow';
import PauseTaskRow from './PauseTaskRow';
import BaseTaskRow from './BaseTaskRow';

interface TaskConfigTableBodyProps {
  taskConfigs: TaskConfig[];
  sessionSchema: SessionSchema;
  updateTaskConfig: (taskConfig: TaskConfig) => void;
}

const TaskConfigTableBody: FC<TaskConfigTableBodyProps> = ({
  taskConfigs,
  sessionSchema,
  updateTaskConfig,
}) => {
  const { specification } = useContext(SpecificationContext);

  return (
    <TableBody>
      {sessionSchema.tasks.length ? (
        sessionSchema.tasks.map((task) => {
          const taskConfigKind = TASK_CONFIG_KINDS[task.kind];
          const taskConfig = taskConfigs.find(
            (config) => config.taskIdentifier === task.taskIdentifier
          );

          if (!taskConfigKind || !taskConfig) {
            return <></>;
          }

          if (taskConfig.kind === TaskConfigKind.PauseTask) {
            return (
              <PauseTaskRow
                key={task.taskIdentifier}
                task={task}
                taskConfig={taskConfig}
                sessionSchema={sessionSchema}
                updateTaskConfig={updateTaskConfig}
              />
            );
          }

          if (
            taskConfig.kind === TaskConfigKind.ValidateSchemaTask ||
            taskConfig.kind === TaskConfigKind.CheckRulesTask ||
            taskConfig.kind === TaskConfigKind.ApplyActionMapTask
          ) {
            return (
              <ConformanceTaskRow
                key={task.taskIdentifier}
                task={task}
                taskConfig={taskConfig}
                sessionSchema={sessionSchema}
                conformanceMode={specification.conformanceMode}
                updateTaskConfig={updateTaskConfig}
              />
            );
          }

          return (
            <BaseTaskRow
              taskConfig={taskConfig}
              task={task}
              updateTaskConfig={updateTaskConfig}
              sessionSchema={sessionSchema}
            />
          );
        })
      ) : (
        <TableInfoRow
          colSpan={4}
          size="medium"
          message={intl.formatMessage({
            id: 'specification.configuration.taskConfig.noTasks',
            defaultMessage: 'No tasks in the session template.',
          })}
        />
      )}
    </TableBody>
  );
};

export default TaskConfigTableBody;
