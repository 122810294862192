import { FC } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { VIRUS_SCANNER_STATE_METADATA, virusScanStatePassed } from '../../types';

import UploadDropzone, { UploadDropzoneProps, renderProgressText } from './UploadDropzone';
import VirusScanStateIcon from './VirusScanStatus';

const Upload: FC<UploadDropzoneProps> = ({ virusScanState, progress, uploading, ...rest }) => (
  <UploadDropzone
    progress={progress}
    uploading={uploading}
    virusScanState={virusScanState}
    {...rest}
  >
    <Box flexDirection="column" display="flex" alignItems="center">
      {virusScanState && !virusScanStatePassed(virusScanState) ? (
        <Box display="flex" flexDirection="column" alignItems="center" mb={1}>
          <FormattedMessage
            id="upload.virusScanMessage"
            defaultMessage="Virus scan: {status}"
            values={{ status: VIRUS_SCANNER_STATE_METADATA[virusScanState].label }}
          />
          <VirusScanStateIcon state={virusScanState} />
        </Box>
      ) : (
        <CloudUploadIcon fontSize="large" />
      )}
      <Typography variant="body2">{renderProgressText(uploading, progress)}</Typography>
    </Box>
  </UploadDropzone>
);

export default Upload;
