import { FC, useContext, useEffect, useRef } from 'react';
import { isUndefined } from 'lodash';

import { Divider, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { ApplicationContext } from '../../../contexts/application';

import { intl } from '../../../Internationalization';
import { usePrevious } from '../../../hooks';
import { DRAWER_WIDTH, OPEN_DELAY } from '../Layout';
import SidebarMenu from './SidebarMenu';

interface SidebarProps {
  sidebarOpen: boolean;
  handleDrawerToggle: () => void;
  permanentDrawer: boolean;
}

const Sidebar: FC<SidebarProps> = ({ sidebarOpen, handleDrawerToggle, permanentDrawer }) => {
  const { applicationDetails } = useContext(ApplicationContext);

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const prevSidebarOpen = usePrevious(sidebarOpen);

  useEffect(() => {
    if (!isUndefined(prevSidebarOpen) && sidebarOpen !== prevSidebarOpen) {
      if (sidebarOpen) {
        drawerRef.current?.classList.remove('hidden');
        setTimeout(function () {
          drawerRef.current?.classList.remove('visuallyHidden');
        }, OPEN_DELAY);
      } else {
        drawerRef.current?.classList.add('visuallyHidden');
        drawerRef.current?.addEventListener(
          'transitionend',
          function () {
            drawerRef.current?.classList.add('hidden');
          },
          {
            capture: false,
            once: true,
            passive: false,
          }
        );
      }
    }
  }, [prevSidebarOpen, sidebarOpen]);

  return (
    <Drawer
      ref={drawerRef}
      anchor="left"
      ModalProps={{
        closeAfterTransition: true,
        keepMounted: false,
      }}
      sx={[
        {
          '& .MuiPaper-root ': {
            width: DRAWER_WIDTH,
            transition: (theme) =>
              theme.transitions.create(['margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          },
          '&.hidden': {
            '& .MuiPaper-root ': {
              display: 'none',
            },
          },
          '&.visuallyHidden': {
            '& .MuiPaper-root ': {
              overflowX: 'hidden',
              marginLeft: `-${DRAWER_WIDTH}px`,
              transition: (theme) =>
                theme.transitions.create(['margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
            },
          },
        },
      ]}
      onClose={handleDrawerToggle}
      open={sidebarOpen}
      variant={permanentDrawer ? 'permanent' : 'temporary'}
      id="sidebar"
    >
      <Toolbar
        sx={{
          '&&': {
            display: 'flex',
            justifyContent: 'space-between',
            pr: 0,
          },
        }}
      >
        <Typography variant="h6" component="span" display="flex" flexDirection="column">
          {applicationDetails.site.name}
        </Typography>
        <IconButton
          id="close-sidebar"
          onClick={handleDrawerToggle}
          sx={{ mx: 1 }}
          size="large"
          aria-label={intl.formatMessage({
            id: 'components.sidebar.closeSidebar.ariaLabel',
            defaultMessage: 'Close sidebar',
          })}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Divider absolute />
      </Toolbar>
      <SidebarMenu />
    </Drawer>
  );
};

export default Sidebar;
