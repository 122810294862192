import {
  SessionSchema,
  DataStoreSchema,
  Schema,
  DATA_STORE_TYPE_METADATA,
  DataStoreSchemaKey,
  DataStoreInstanceMetadata,
} from '../types';
import { UxErrorIcon, UxDefaultDataStoreIcon } from '../components/icons';
import { intl } from '../Internationalization';
import { SubmissionActionState } from '../types/submissionAction';

export const dataStoreNameFromPath = (dataStorePath: string) => {
  return dataStorePath.substring(dataStorePath.lastIndexOf('/') + 1);
};

export const dataStoreSchemaFromPath = (
  sessionSchema: SessionSchema,
  dataStorePath: string
): DataStoreSchema | undefined => {
  return sessionSchema.dataStores.find(({ path }) => path === dataStorePath);
};

export const dataStoreTypeMetaDataFromPath = (
  sessionSchema: SessionSchema,
  dataStorePath?: string,
  dataStoreSchemaKey?: DataStoreSchemaKey
) => {
  if (dataStorePath && dataStoreSchemaKey) {
    const dataStoreSchema = dataStoreSchemaFromPath(sessionSchema, dataStorePath);
    const schema = dataStoreSchema?.[dataStoreSchemaKey];
    const defaultCRS = dataStoreSchema?.defaultParameterValues?.['Coordinate Reference System'];
    if (schema) {
      return metadataForSchema(schema, defaultCRS);
    }
  }
  return {
    label: intl.formatMessage({
      id: 'submission.dataStoreType.noSchema',
      defaultMessage: 'No Schema',
    }),
    icon: UxErrorIcon,
    defaultCRS: undefined,
  };
};

export const metadataForSchema = (schema: Schema, crs?: string): DataStoreInstanceMetadata => {
  const metadata = DATA_STORE_TYPE_METADATA[schema.type.name];

  return metadata
    ? { ...metadata, defaultCRS: crs }
    : {
        label: schema.type.name,
        icon: UxDefaultDataStoreIcon,
        defaultCRS: crs,
      };
};

export const submissionActionNotFinished = (state: SubmissionActionState) =>
  state === SubmissionActionState.NOT_STARTED || state === SubmissionActionState.RUNNING;
