import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { SpecificationsImportDetail } from '../PreviewUpload';
import SpecificationRow from './SpecificationRow';

interface ProjectRowProps {
  project: { key: string; name: string };
  specification?: SpecificationsImportDetail;
  rowSpan: number;
}

const ProjectRow: FC<ProjectRowProps> = ({ project, specification, rowSpan }) => (
  <TableRow>
    <TableCell rowSpan={rowSpan}>
      {project.key} - {project.name}
    </TableCell>
    {specification ? (
      <SpecificationRow
        specification={specification}
        assignment={specification.assignments[0]}
        nestedRow
        rowSpan={specification.assignments.length || 1}
      />
    ) : (
      <>
        <TableCell />
        <TableCell />
        <TableCell />
      </>
    )}
  </TableRow>
);

export default ProjectRow;
