import { AuditEventDetail } from './shared';

export enum ValidationJobState {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface CreateValidationJobRequest {
  projectKey?: string;
}

export interface ValidationJobDetail {
  key: string;
  state: ValidationJobState;
  createEvent: AuditEventDetail;
  projectKey: string;
  startedAt: string;
  finishedAt: string;
}
