import EventIcon from '@mui/icons-material/Event';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@mui/material';

import {
  SubmissionDetail,
  ProjectMetadata,
  SpecificationMetadata,
  AssignmentMetadata,
  SUBMISSION_OUTCOME_METADATA,
  SubmissionState,
  SUBMISSION_REJECTION_METADATA,
} from '../../../types';
import {
  PaddedPaper,
  CopyToClipboard,
  AccountAvatar,
  SupplierAvatar,
  Loading,
} from '../../../components';
import { intl } from '../../../Internationalization';
import { dateTimeFormat } from '../../../util';

import { SubmissionContext } from './SubmissionContext';
import TaskResults from './TaskResults';
import SubmissionData from './SubmissionData';
import ActionResults from './ActionResults';

interface SubmissionResultDetailsProps {
  submissionUpdated: React.Dispatch<React.SetStateAction<SubmissionDetail | undefined>>;
  submission: SubmissionDetail;
  assignment: AssignmentMetadata;
  project: ProjectMetadata;
  specification: SpecificationMetadata;
}

const SubmissionResultDetails = ({
  assignment,
  project,
  specification,
  submission,
  submissionUpdated,
}: SubmissionResultDetailsProps) => {
  const { supplier, user, outcome, finishedAt } = submission;

  if (submission.state !== SubmissionState.FINISHED) {
    return (
      <Loading
        loadingText={intl.formatMessage({
          id: 'submission.submissionResultDetails.calculatingSubmissionResults',
          defaultMessage: 'Calculating submission results',
        })}
      />
    );
  }

  return (
    <SubmissionContext.Provider
      value={{
        submissionUpdated,
        submission,
        assignment,
        project,
        specification,
      }}
    >
      <Grid item xs={12} lg={6}>
        <PaddedPaper fillVertical>
          <Typography
            variant="h5"
            component="h3"
            className="SubmissionResultDetails-outcomeDescription"
          >
            {SUBMISSION_OUTCOME_METADATA[outcome!].description +
              (submission.rejection != null
                ? ` ${SUBMISSION_REJECTION_METADATA[submission.rejection].qualifier}`
                : '')}
          </Typography>
          <List>
            <ListItem>
              <ListItemAvatar>
                <AccountAvatar user={user} />
              </ListItemAvatar>
              <ListItemText primary={user.name} secondary={user.email} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <SupplierAvatar supplier={supplier} />
              </ListItemAvatar>
              <ListItemText primary={supplier.name} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EventIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={dateTimeFormat(finishedAt)} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>#</Avatar>
              </ListItemAvatar>
              <CopyToClipboard>
                <ListItemText primary={submission.reference} />
              </CopyToClipboard>
            </ListItem>
          </List>
        </PaddedPaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SubmissionData />
      </Grid>
      <Grid item xs={12}>
        <TaskResults submission={submission} />
      </Grid>
      <Grid item xs={12}>
        <ActionResults submission={submission} />
      </Grid>
    </SubmissionContext.Provider>
  );
};

export default SubmissionResultDetails;
