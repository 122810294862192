import { FC, useState } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { add } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import {
  FormHelperText,
  Typography,
  Checkbox,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grid,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import SaveIcon from '@mui/icons-material/Save';
import { AccessTime } from '@mui/icons-material';

import * as ScheduleApi from '../../../../api/schedule/schedule';
import {
  DefaultButton,
  ValidatedTextField,
  FormButtons,
  PaddedPaper,
  BlockFormControlLabel,
  MessageBox,
  ValidatedNumericField,
} from '../../../../components';
import {
  CronSchedulePreviewRequest,
  ScheduleDetail,
  ScheduleEndCriteria,
  UpdateScheduleRequest,
} from '../../../../types';
import {
  REPEAT,
  REPEAT_METADATA,
  Repeat,
  SCHEDULE_END_CRITERIA_METADATA,
  ScheduleSettings,
  parseCronToRepeatValue,
  parseScheduleToUpdateRequest,
  updateValue,
} from '../../../../util';
import { SCHEDULE_SETTINGS_VALIDATOR, validate } from '../../../../validation';
import { intl } from '../../../../Internationalization';

import { useNavigationPrompt } from '../../../../contexts/navigation-prompt';
import CronPreview from './CronPreview';

interface ScheduleSettingsFormProps {
  schedule: ScheduleDetail;
  handleSaveSchedule: (schedule: UpdateScheduleRequest) => void;
  handlePreviewSchedule: (schedule: CronSchedulePreviewRequest) => void;
  processing: boolean;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduleSettingsForm: FC<ScheduleSettingsFormProps> = ({
  schedule,
  handleSaveSchedule,
  handlePreviewSchedule,
  processing,
  setProcessing,
}) => {
  const { raiseNavigationBlock } = useNavigationPrompt();
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [scheduleSettings, setScheduleSettings] = useState<UpdateScheduleRequest>(
    ScheduleApi.extractScheduleSettings(schedule)
  );
  const [startDateTime, setStartDateTime] = useState<Date | null>(new Date(schedule.startDateTime));
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    schedule.endDateTime ? new Date(schedule.endDateTime) : null
  );
  const [numberOfOccurrences, setNumberOfOccurrences] = useState<number | undefined>(
    schedule.numberOfOccurrences
  );

  const [repeat, setRepeat] = useState<Repeat>(parseCronToRepeatValue(schedule));
  const [scheduleEndCriteria, setScheduleEndCriteria] = useState<ScheduleEndCriteria>(
    schedule.endCriteria
  );

  const validateAndSubmit = async () => {
    setProcessing(true);
    setFieldErrors(undefined);
    try {
      const validatedResponse = await validate(SCHEDULE_SETTINGS_VALIDATOR, {
        ...scheduleSettings,
        startDateTime,
        endDateTime,
        repeat,
        scheduleEndCriteria,
        numberOfOccurrences,
      });
      handleSaveSchedule(parseScheduleToUpdateRequest(validatedResponse as ScheduleSettings));
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const validateAnPreview = async () => {
    setFieldErrors(undefined);
    setProcessing(true);
    try {
      const validatedResponse = await validate(SCHEDULE_SETTINGS_VALIDATOR, {
        ...scheduleSettings,
        startDateTime,
        endDateTime,
        repeat,
        scheduleEndCriteria,
        numberOfOccurrences,
      });
      handlePreviewSchedule(parseScheduleToUpdateRequest(validatedResponse as ScheduleSettings));
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    raiseNavigationBlock();
    updateValue(setScheduleSettings)(event);
  };

  const handleChangeRepeat = (updatedRepeat: Repeat) => {
    raiseNavigationBlock();
    if (updatedRepeat === Repeat.NONE) {
      setNumberOfOccurrences(1);
      setScheduleEndCriteria(ScheduleEndCriteria.NUMBER_OF_OCCURRENCES);
      setEndDateTime(null);
    }
    setRepeat(updatedRepeat);
  };

  const handleChangeEndCriteria = (value: ScheduleEndCriteria) => {
    raiseNavigationBlock();
    setScheduleEndCriteria(value);
    switch (value) {
      case ScheduleEndCriteria.NONE:
        setNumberOfOccurrences(undefined);
        setEndDateTime(null);
        break;
      case ScheduleEndCriteria.END_DATE_TIME:
        setNumberOfOccurrences(undefined);
        break;
      case ScheduleEndCriteria.NUMBER_OF_OCCURRENCES:
        setEndDateTime(null);
    }
  };

  return (
    <>
      <Grid item xs={8}>
        <PaddedPaper sx={{ width: '100%' }}>
          <Typography variant="h5" component="h3" gutterBottom>
            <FormattedMessage
              id="myAssignment.schedules.settings.title"
              defaultMessage="Schedule Settings"
            />
          </Typography>
          {!schedule.active && (
            <MessageBox
              gutterBottom
              level="info"
              message={intl.formatMessage({
                id: 'myAssignment.schedules.settings.inactiveMessage',
                defaultMessage:
                  'This schedule is currently inactive, select the checkbox and save to activate it.',
              })}
            />
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <BlockFormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="active"
                    checked={scheduleSettings.active}
                    onChange={handleUpdate}
                    disabled={processing}
                  />
                }
                label={intl.formatMessage({
                  id: 'myAssignment.schedules.settings.active.label',
                  defaultMessage: 'Active?',
                })}
              />
              <DesktopDateTimePicker
                label={intl.formatMessage({
                  id: 'myAssignment.schedules.settings.startDateTime.label',
                  defaultMessage: 'Start Date',
                })}
                value={startDateTime}
                onChange={(date) => {
                  raiseNavigationBlock();
                  setStartDateTime(date || null);
                }}
                minDate={add(new Date(), { days: 1 })}
                disablePast
                disabled={processing}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    margin: 'normal',
                    name: 'startDateTime',
                    error: !!fieldErrors?.startDateTime,
                    fullWidth: true,
                  },
                }}
                closeOnSelect={false}
              />
              {fieldErrors && fieldErrors.startDateTime && (
                <FormHelperText>{fieldErrors?.startDateTime[0]?.message}</FormHelperText>
              )}
              <FormattedMessage
                id="myAssignment.schedules.timeNote"
                defaultMessage="Please note: All times are automatically converted to UTC. The scheduled time might change due to daylight savings time."
              />
              <ValidatedTextField
                fieldErrors={fieldErrors}
                disabled={processing}
                name="repeat"
                label={intl.formatMessage({
                  id: 'myAssignment.schedules.settings.repeat.label',
                  defaultMessage: 'Repeat',
                })}
                value={repeat || ''}
                onChange={(event) => handleChangeRepeat(event.target.value as Repeat)}
                margin="normal"
                variant="outlined"
                select
              >
                {REPEAT.map((value) => (
                  <MenuItem key={value} value={value}>
                    {REPEAT_METADATA[value].label}
                  </MenuItem>
                ))}
              </ValidatedTextField>
              <ValidatedTextField
                fieldErrors={fieldErrors}
                name="note"
                label={intl.formatMessage({
                  id: 'myAssignment.schedules.settings.notes.label',
                  defaultMessage: 'Notes',
                })}
                value={scheduleSettings.note}
                margin="normal"
                variant="outlined"
                multiline
                onChange={handleUpdate}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                margin="normal"
                component="fieldset"
                fullWidth
                disabled={repeat === Repeat.NONE}
                size="small"
              >
                <FormLabel component="legend">
                  <FormattedMessage
                    id="myAssignment.schedules.settings.scheduleEndCriteria.label"
                    defaultMessage="Schedule End Criteria"
                  />
                </FormLabel>
                <RadioGroup
                  name="scheduleEndCriteria"
                  value={scheduleEndCriteria}
                  onChange={(_, value) => handleChangeEndCriteria(value as ScheduleEndCriteria)}
                >
                  <FormControlLabel
                    key={ScheduleEndCriteria.NUMBER_OF_OCCURRENCES}
                    value={ScheduleEndCriteria.NUMBER_OF_OCCURRENCES}
                    componentsProps={{ typography: { sx: { width: '100%' } } }}
                    control={<Radio />}
                    label={
                      <ValidatedNumericField
                        name="numberOfOccurrences"
                        inputProps={{
                          min: 0,
                        }}
                        label={
                          SCHEDULE_END_CRITERIA_METADATA[ScheduleEndCriteria.NUMBER_OF_OCCURRENCES]
                            .label
                        }
                        value={
                          (!numberOfOccurrences && numberOfOccurrences !== 0) ||
                          isNaN(numberOfOccurrences)
                            ? ''
                            : numberOfOccurrences
                        }
                        onChange={(_, value) => {
                          raiseNavigationBlock();
                          setNumberOfOccurrences(value);
                        }}
                        fieldErrors={fieldErrors}
                        disabled={
                          processing ||
                          scheduleEndCriteria !== ScheduleEndCriteria.NUMBER_OF_OCCURRENCES ||
                          repeat === Repeat.NONE
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputLabelProps={{ shrink: typeof numberOfOccurrences === 'number' }}
                      />
                    }
                  />
                  <FormControlLabel
                    key={ScheduleEndCriteria.END_DATE_TIME}
                    value={ScheduleEndCriteria.END_DATE_TIME}
                    control={<Radio />}
                    componentsProps={{ typography: { sx: { width: '100%' } } }}
                    label={
                      <Box>
                        <DesktopDateTimePicker
                          label={
                            SCHEDULE_END_CRITERIA_METADATA[ScheduleEndCriteria.END_DATE_TIME].label
                          }
                          value={endDateTime || null}
                          onChange={(date) => {
                            raiseNavigationBlock();
                            setEndDateTime(date || null);
                          }}
                          minDate={add(new Date(), { days: 1 })}
                          disablePast
                          disabled={
                            processing ||
                            scheduleEndCriteria !== ScheduleEndCriteria.END_DATE_TIME ||
                            repeat === Repeat.NONE
                          }
                          minDateTime={startDateTime}
                          slotProps={{
                            textField: {
                              variant: 'outlined',
                              margin: 'normal',
                              name: 'endDateTime',
                              error: !!fieldErrors?.endDateTime,
                              fullWidth: true,
                            },
                          }}
                          closeOnSelect={false}
                        />
                        {fieldErrors && fieldErrors.endDateTime && (
                          <FormHelperText>{fieldErrors?.endDateTime[0]?.message}</FormHelperText>
                        )}
                      </Box>
                    }
                  />
                  <FormControlLabel
                    key={ScheduleEndCriteria.NONE}
                    value={ScheduleEndCriteria.NONE}
                    control={<Radio />}
                    label={SCHEDULE_END_CRITERIA_METADATA[ScheduleEndCriteria.NONE].label}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <FormButtons>
            <DefaultButton
              name="saveScheduleSettings"
              onClick={validateAndSubmit}
              disabled={processing}
              startIcon={<SaveIcon />}
            >
              <FormattedMessage
                id="myAssignment.schedules.settings.saveButton"
                defaultMessage="Save settings"
              />
            </DefaultButton>
            <DefaultButton
              name="previewSchedule"
              onClick={validateAnPreview}
              disabled={processing}
              startIcon={<AccessTime />}
              color="secondary"
            >
              <FormattedMessage
                id="myAssignment.schedules.settings.refreshPreview"
                defaultMessage="Refresh preview"
              />
            </DefaultButton>
          </FormButtons>
        </PaddedPaper>
      </Grid>
      <Grid item xs={4}>
        <CronPreview nextOccurrences={schedule.nextOccurrences} />
      </Grid>
    </>
  );
};

export default ScheduleSettingsForm;
