import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ValidateFieldsError } from 'async-validator';
import { Box, Typography } from '@mui/material';

import { ValidatedTextField } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';
import {
  CopyInputAction,
  ReorderDirection,
  SpecificationInputDetail,
} from '../../../../../../types';
import NamedLocationsAutocomplete from '../../../../../../components/autocomplete/NamedLocationAutocomplete';

import LinkedInputs from './LinkedInputs';
import ActionCard from './ActionCard';
import TargetPath from './TargetPath';

interface CopyInputActionCardProps {
  action: CopyInputAction;
  disableNext: boolean;
  disablePrev: boolean;
  handleUpdateAction: (action: CopyInputAction) => void;
  handleDeleteAction: () => void;
  handleReorderAction: (actionKey: string, direction: ReorderDirection) => void;
  index: string;
  fieldErrors?: ValidateFieldsError;
  disabled: boolean;
}

const CopyInputActionCard: FC<CopyInputActionCardProps> = ({
  action,
  disableNext,
  disablePrev,
  handleUpdateAction,
  handleDeleteAction,
  handleReorderAction,
  index,
  fieldErrors,
  disabled,
}) => {
  const handleUpdateLinkedInputs = (inputs: SpecificationInputDetail[]) => {
    handleUpdateAction({ ...action, inputs });
  };
  const onValueUpdated = (updatedValue: Partial<CopyInputAction>) => {
    handleUpdateAction({ ...action, ...updatedValue });
  };
  return (
    <ActionCard
      index={index}
      action={action}
      disableNext={disableNext}
      disablePrev={disablePrev}
      handleDeleteAction={handleDeleteAction}
      handleReorderAction={handleReorderAction}
      disabled={disabled}
    >
      <Box display="flex" justifyContent="space-between" mb={2} columnGap={3}>
        <Box sx={{ width: { sm: '100%', md: '50%' } }}>
          <Typography variant="body1" fontWeight="bold">
            <FormattedMessage
              id="specification.configuration.actionConfig.actionSettings.title"
              defaultMessage="Action Settings"
            />
          </Typography>
          <ValidatedTextField
            name={`actions.${index}.name`}
            label={intl.formatMessage({
              id: 'specification.configuration.actionConfig.name.label',
              defaultMessage: 'Name',
            })}
            value={action.name}
            onChange={(event) => onValueUpdated({ name: event.target.value })}
            margin="dense"
            variant="outlined"
            size="small"
            fieldErrors={fieldErrors}
            disabled={disabled}
          />
          <NamedLocationsAutocomplete
            name={`actions.${index}.namedLocation`}
            label={intl.formatMessage({
              id: 'specification.configuration.actionConfig.namedLocation.label',
              defaultMessage: 'Named Location',
            })}
            value={action.namedLocation || null}
            onChange={(namedLocation) =>
              onValueUpdated({
                namedLocation: namedLocation ? namedLocation : undefined,
              })
            }
            margin="dense"
            variant="outlined"
            size="small"
            fieldErrors={fieldErrors}
          />
          <TargetPath
            fieldName={`actions.${index}.targetPath`}
            handleTargetPathUpdated={(updatedPath) =>
              onValueUpdated({
                targetPath: updatedPath,
              })
            }
            targetPath={action.targetPath || ''}
            fieldErrors={fieldErrors}
            disabled={disabled}
          />
        </Box>
        <Box display="flex" sx={{ width: { sm: '100%', md: '50%' } }} flexDirection="column">
          <LinkedInputs
            action={action}
            handleUpdateInputs={handleUpdateLinkedInputs}
            disabled={disabled}
          />
        </Box>
      </Box>
    </ActionCard>
  );
};
export default CopyInputActionCard;
