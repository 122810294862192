import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';

import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../../components';
import { ErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';
import { useRequiredParams, useRouterTab, useTitle } from '../../../hooks';

import SystemHeader from '../SystemHeader';
import DeletionJobs from './DeletionJobs';
import DeletionJob from './DeletionJob';
import { AccountEvents } from '../../components/account-events';
import NamedLocations from './named-locations/NamedLocations';
import ScheduledSubmissions from '../../components/ScheduledSubmissions';

const CurrentDeletionJob = () => {
  const { deletionJobId } = useRequiredParams<{ deletionJobId: string }>();
  return <DeletionJob deletionJobId={parseInt(deletionJobId)} />;
};

const SystemActivityRouting: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.system.systemActivity',
      defaultMessage: 'System Activity',
    })
  );
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="system-activity-tabs" value={routerTab}>
      <RouterTab
        id="deletion-jobs-tab"
        value="deletion_jobs"
        label={intl.formatMessage({
          id: 'systemActivity.routing.tabs.deletionJobs',
          defaultMessage: 'Deletion Jobs',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="account-events-tab"
        value="account_events"
        label={intl.formatMessage({
          id: 'systemActivity.routing.tabs.accountEvents',
          defaultMessage: 'Account Events',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="named-locations-tab"
        value="named_locations"
        label={intl.formatMessage({
          id: 'systemActivity.routing.tabs.namedLocations',
          defaultMessage: 'Named Locations',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="scheduled-submissions-tab"
        value="scheduled_submissions"
        label={intl.formatMessage({
          id: 'systemActivity.routing.tabs.scheduledSubmissions',
          defaultMessage: 'Scheduled Submissions',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'systemActivity.routing.title',
          defaultMessage: 'System Activity',
        })}
        icon={StorageIcon}
      />
      <AppTabs />
      <Box p={3}>
        <ErrorBlock>
          <Routes>
            <Route path="deletion_jobs" element={<DeletionJobs />} />
            <Route path="deletion_jobs/:deletionJobId/*" element={<CurrentDeletionJob />} />
            <Route path="account_events" element={<AccountEvents />} />
            <Route path="named_locations" element={<NamedLocations />} />
            <Route path="scheduled_submissions" element={<ScheduledSubmissions />} />
            <Route path="*" element={<Navigate to="deletion_jobs" replace />} />
          </Routes>
        </ErrorBlock>
      </Box>
    </AppDecorator>
  );
};

export default SystemActivityRouting;
