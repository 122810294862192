import { AxiosApiError } from '../api/endpoints';

export interface VerifyActivationTokenRequest {
  activationToken: string;
}

export interface ActivationRequest {
  activationToken: string;
  password: string;
}

export interface RequestPasswordResetRequest {
  email: string;
}

export interface VerifyPasswordResetTokenRequest {
  passwordResetToken: string;
}

export interface PasswordResetRequest {
  passwordResetToken: string;
  password: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  tokenType: string;
}

export enum FailureReason {
  AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE',
  ACCOUNT_SUSPENDED = 'ACCOUNT_SUSPENDED',
}

export interface LoginFailureResponse extends AxiosApiError, LoginResponse {
  failureReason: FailureReason;
  message: string;
}
