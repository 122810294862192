import React, { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, CircularProgress } from '@mui/material';

import { FeatureCollection } from 'geojson';

import { TileLayer } from 'deck.gl';
import DeckGL from '@deck.gl/react';
import { InteractiveState, PickInfo } from '@deck.gl/core/lib/deck';
import Layer from '@deck.gl/core/lib/layer';
import { GeoJsonLayer } from '@deck.gl/layers';
import { RGBAColor } from '@deck.gl/core/utils/color';

import * as SubmissionVisualiser from '../../../../../../api/submission/submissionVisualiser';
import { colShift, REPORTS_LAYER_NAME } from '../../../../../../util';

import { MapViewerLayerStyleContext, MapViewerSessionContext } from './MapViewerSessionState';
import { MapViewerSelectionContext } from './MapViewerSelectionState';
import { OpenSubmissionContext } from '../OpenSubmissionContext';
import { MapViewStateContext } from './MapViewState';
import { getGothicClassName } from './MapViewer';
import { CenterBoth } from './CenterBoth';

export interface MapLayerProviderContextProps {
  layers: Layer<any>[];
}
export const MapLayerProviderContext = React.createContext<
  MapLayerProviderContextProps | undefined
>(undefined);

interface MapDeckProps {
  sessionNumObjects: number;
  handleFeatureClick: (info: PickInfo<any>, e: HammerInput, layer: string) => void;
  handleMapClick: (info: PickInfo<any>, e: MouseEvent) => void;
  getAdditionalLayers: () => Layer<any>[];
}

const MapDeck: FC<MapDeckProps> = ({
  sessionNumObjects,
  handleFeatureClick,
  handleMapClick,
  getAdditionalLayers,
}) => {
  const { submission } = useContext(OpenSubmissionContext);
  const { visualiserInfo, srid } = useContext(MapViewerSessionContext);
  const { getViewState, setViewStateNoRender } = useContext(MapViewStateContext);

  const { layerStyles, hiddenLayers, wireframeLayers } = useContext(MapViewerLayerStyleContext);
  const { selectedObject } = useContext(MapViewerSelectionContext);
  const mapLayerProvider = useContext(MapLayerProviderContext);

  const [loadingCount, setLoadingCount] = useState<number>(0);

  if (!visualiserInfo) {
    return (
      <CenterBoth>
        <i style={{ opacity: 0.7, userSelect: 'none' }}>
          <FormattedMessage
            id="openSubmission.map.mapDeck.mapUnavailable"
            defaultMessage="Map is not available for this session"
          />
        </i>
      </CenterBoth>
    );
  }

  const loadLayerData = async (
    layer: string,
    minX: number,
    minY: number,
    maxX: number,
    maxY: number
  ): Promise<FeatureCollection> => {
    setLoadingCount((prev) => {
      return prev + 1;
    });

    let featureCoverageCutoff = 0.0001;
    let mbrCoverageCutoff = 0.000001;

    if (sessionNumObjects < 10000) {
      featureCoverageCutoff = 0;
      mbrCoverageCutoff = 0;
    } else if (sessionNumObjects < 100000) {
      featureCoverageCutoff = 0.0001;
      mbrCoverageCutoff = 0.0000001;
    }

    try {
      return (
        await SubmissionVisualiser.generateLayerGeoJson(submission.reference, {
          versionName: visualiserInfo.versionName,
          minX: minX,
          minY: minY,
          maxX: maxX,
          maxY: maxY,
          srid: srid,
          layer: layer,
          featureCoverageCutoff,
          mbrCoverageCutoff,
        })
      ).data;
    } finally {
      setLoadingCount((prev) => {
        return prev - 1;
      });
    }
  };

  const getLayers = (): Layer<any>[] => {
    const output = [];

    if (visualiserInfo) {
      const reversed = [...layerStyles].reverse();
      for (const style of reversed) {
        if (hiddenLayers.includes(style.layerName) || style.layerName === REPORTS_LAYER_NAME) {
          continue;
        }
        const wireframe = wireframeLayers.includes(style.layerName);

        const colour: RGBAColor = [style.colour.red, style.colour.green, style.colour.blue, 255];

        output.push(
          new TileLayer({
            id: style.layerName,
            getTileData: async (tile) => {
              const {
                bbox: { west, south, east, north },
                signal,
              } = tile;
              const geojson = (await loadLayerData(
                getGothicClassName(style.layerName),
                west,
                south,
                east,
                north
              )) as any;
              if (signal.aborted) {
                return undefined;
              }

              return geojson;
            },
            updateTriggers: {
              getTileData: [visualiserInfo, srid],
              renderSubLayers: [visualiserInfo, style, wireframe],
            },
            maxZoom: 19,
            minZoom: 0,
            zRange: visualiserInfo.hasZ ? [0, 1000] : undefined,
            parameters: {
              depthTest: Boolean(visualiserInfo.hasZ),
            },
            tileSize: 256,
            pickable: true,
            onClick: (info, e) => {
              handleFeatureClick(info, e, style.layerName);
            },
            renderSubLayers: (props) => {
              return new GeoJsonLayer({
                id: props.id,
                data: props.data,
                pointRadiusMinPixels: 3,
                pointRadiusMaxPixels: 15,
                lineWidthMinPixels: 0.5,
                lineWidthMaxPixels: 5,
                lineBillboard: Boolean(visualiserInfo.hasZ),
                pickable: true,
                filled: !wireframe,
                parameters: {
                  depthTest: Boolean(visualiserInfo.hasZ),
                },
                _full3d: Boolean(visualiserInfo.hasZ),
                getFillColor: colour,
                getLineColor: colShift(colour, -60),
              });
            },
          })
        );
      }
    }

    if (visualiserInfo && selectedObject?.feature) {
      if (!hiddenLayers.includes(selectedObject.layer)) {
        output.push(
          new GeoJsonLayer({
            id: 'selection',
            data: [selectedObject?.feature],
            pointRadiusMinPixels: 3,
            pointRadiusMaxPixels: 15,
            lineWidthMinPixels: 0.5,
            lineWidthMaxPixels: 5,
            lineBillboard: Boolean(visualiserInfo.hasZ),
            parameters: {
              depthTest: Boolean(visualiserInfo.hasZ),
            },
            _full3d: Boolean(visualiserInfo.hasZ),
            getFillColor: [71, 245, 95, 200],
            getLineColor: [71, 245, 95, 200],
          })
        );
      }
    }

    output.push(...getAdditionalLayers());

    if (mapLayerProvider) {
      output.push(...mapLayerProvider.layers);
    }

    return output;
  };

  const calculateCursor = (state: InteractiveState) => {
    if (state.isHovering) {
      return 'pointer';
    }
    if (state.isDragging) {
      return 'grabbing';
    }
    return 'grab';
  };

  return (
    <>
      <Box id="mapDeck" flexGrow={1} height="100%" position="relative">
        <Box
          height="100%"
          width="100%"
          position="absolute"
          overflow="hidden"
          display="flex"
          flexDirection="column"
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {visualiserInfo?.cacheAvailable ? (
            <DeckGL
              width="100%"
              height="100%"
              useDevicePixels={false}
              initialViewState={getViewState()}
              onViewStateChange={(args) => {
                setViewStateNoRender(args.viewState);
              }}
              controller={{ doubleClickZoom: false }}
              layers={getLayers()}
              getCursor={calculateCursor}
              onClick={handleMapClick}
            />
          ) : (
            <CenterBoth>
              <i style={{ opacity: 0.7, userSelect: 'none' }}>
                <FormattedMessage
                  id="openSubmission.map.mapDeck.mapUnavailable"
                  defaultMessage="Map is not available for this session"
                />
              </i>
            </CenterBoth>
          )}
        </Box>
        {loadingCount ? (
          <Box
            height="100%"
            width="100%"
            position="absolute"
            display="flex"
            alignItems="center"
            style={{ pointerEvents: 'none' }}
          >
            <CenterBoth>
              <CircularProgress />
            </CenterBoth>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default React.memo(MapDeck);
