import Schema from 'async-validator';
import { Rules, InternalRuleItem, RuleItem, Values } from 'async-validator';

import { intl } from '../Internationalization';
import { PasswordRequirements } from '../types';
import { PASSWORD_REQUIREMENTS_METADATA } from './site';

export interface VerifiedPassword {
  password: string;
  verifyPassword: string;
}

export const PASSWORD_VALIDATOR: RuleItem = {
  type: 'string',
  required: true,
  message: intl.formatMessage({
    id: 'password.validator.password.required',
    defaultMessage: 'Must provide a new password',
  }),
};

export const verifiedPasswordDescriptor = (passwordRequirements: PasswordRequirements): Rules => ({
  password: newPasswordDescriptor(passwordRequirements),
  verifyPassword: [
    {
      validator(
        rule: InternalRuleItem,
        value: any,
        callback: (error?: string) => void,
        source: Values
      ) {
        if (source.password && source.password !== source.verifyPassword) {
          callback(
            intl.formatMessage({
              id: 'password.validator.verifyPassword.mustMatch',
              defaultMessage: 'Passwords must match',
            })
          );
        }
        callback();
      },
    },
  ],
});

export const passwordResetValidator = (passwordRequirements: PasswordRequirements) =>
  new Schema(verifiedPasswordDescriptor(passwordRequirements));

export const newPasswordDescriptor = (
  passwordRequirements: PasswordRequirements,
  returnKey?: boolean
): RuleItem[] => {
  return [
    PASSWORD_VALIDATOR,
    ...Object.entries(passwordRequirements).map(([key, value]) => {
      const { validationRegex, validationType, message } =
        PASSWORD_REQUIREMENTS_METADATA[key as keyof PasswordRequirements];
      if (validationType && value) {
        return {
          [validationType]: value,
          message: returnKey ? key : message(value),
          required: true,
        };
      }

      if (validationRegex && value) {
        return {
          pattern: validationRegex(value),
          message: returnKey ? key : message(value),
          required: true,
        };
      }

      return {};
    }),
  ];
};

export const passwordRequirementsValidator = (
  passwordRequirements: PasswordRequirements,
  returnKey?: boolean
) =>
  new Schema({
    password: newPasswordDescriptor(passwordRequirements, returnKey),
  });
