import { AxiosPromise } from 'axios';

import { VirusScannerSettings, VirusScannerTestResponse } from '../types';
import { AXIOS } from './endpoints';

export function getSettings(): AxiosPromise<VirusScannerSettings> {
  return AXIOS.get('virus_scanner');
}

export function updateSettings(
  virusScannerSettings: VirusScannerSettings
): AxiosPromise<VirusScannerSettings> {
  return AXIOS.post('/virus_scanner', virusScannerSettings);
}

export function testSettings(
  virusScannerSettings: VirusScannerSettings
): AxiosPromise<VirusScannerTestResponse> {
  return AXIOS.post('/virus_scanner/test', virusScannerSettings);
}
