import { FC } from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';

import Schedules from './Schedules';
import Schedule from './Schedule';

const SchedulesRouting: FC = () => (
  <Routes>
    <Route index element={<Schedules />} />
    <Route path=":scheduleKey/*" element={<Schedule />} />
    <Route path="*" element={<Navigate to="." replace />} />
  </Routes>
);

export default SchedulesRouting;
