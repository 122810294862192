import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';
import { DefaultButton, PaddedPaper } from '../../../../components';
import { dateTimeFormat, durationFormat } from '../../../../util';

const MAX_PREVIEW_DATES = 5;

export const formatDate = (displayDuration: boolean, schedule: string) => {
  return displayDuration ? durationFormat(schedule) : dateTimeFormat(schedule);
};

interface CronPreviewProps {
  nextOccurrences: string[];
}

const CronPreview: FC<CronPreviewProps> = ({ nextOccurrences }) => {
  const [displayDuration, setDisplayDuration] = useState<boolean>(true);
  const renderPreviewContent = () => (
    <>
      {nextOccurrences
        .filter((_, index) => index < MAX_PREVIEW_DATES)
        .map((occurrence, index) => (
          <Typography
            key={`preview-date-${index}`}
            variant="body2"
            gutterBottom
            align={displayDuration ? 'right' : 'left'}
          >
            {formatDate(displayDuration, occurrence)}
          </Typography>
        ))}
      {nextOccurrences.length > MAX_PREVIEW_DATES && (
        <Typography align={displayDuration ? 'right' : 'left'}>...</Typography>
      )}
    </>
  );

  return (
    <PaddedPaper sx={{ width: '100%' }} fillVertical>
      <Typography variant="h5" component="h3" gutterBottom>
        <FormattedMessage
          id="myAssignment.schedules.settings.preview"
          defaultMessage="Schedule Preview"
        />
      </Typography>
      {renderPreviewContent()}
      <DefaultButton onClick={() => setDisplayDuration((prev) => !prev)} sx={{ mt: 1 }}>
        {displayDuration ? (
          <FormattedMessage
            id="myAssignment.schedules.settings.dateButton"
            defaultMessage="Display date format"
          />
        ) : (
          <FormattedMessage
            id="myAssignment.schedules.settings.durationButton"
            defaultMessage="Display duration format"
          />
        )}
      </DefaultButton>
    </PaddedPaper>
  );
};

export default CronPreview;
