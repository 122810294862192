import { AxiosPromise } from 'axios';
import { AXIOS } from '../endpoints';

import {
  VisualiserInfo,
  TransformCRSResponse,
  TransformCRSRequest,
  WktToGeoJsonRequest,
  FindObjectsRequest,
  GenerateGeoJsonRequest,
  GetObjectAttributesRequest,
  ObjectDetails,
} from '../../types';

export function getVisualiserInfo(
  submissionReference: string,
  taskIdentifier: string
): AxiosPromise<VisualiserInfo> {
  return AXIOS.get(`/submissions/${submissionReference}/visualiser/info`, {
    params: { taskIdentifier },
  });
}

export function transformCRS(
  submissionReference: string,
  request: TransformCRSRequest
): AxiosPromise<TransformCRSResponse> {
  return AXIOS.post(`/submissions/${submissionReference}/visualiser/transformCRS`, request);
}

export function generateLayerGeoJson(
  submissionReference: string,
  request: GenerateGeoJsonRequest
): AxiosPromise {
  return AXIOS.post(`/submissions/${submissionReference}/visualiser/generateLayerGeoJson`, request);
}

export function getObjectAttributes(
  submissionReference: string,
  request: GetObjectAttributesRequest
): AxiosPromise<{ [key: string]: string }> {
  return AXIOS.post(`/submissions/${submissionReference}/visualiser/getObjectAttributes`, request);
}

export function findObjects(
  submissionReference: string,
  request: FindObjectsRequest
): AxiosPromise<ObjectDetails[]> {
  return AXIOS.post(`/submissions/${submissionReference}/visualiser/findObjects`, request);
}

export function convertWktToGeoJson(
  submissionReference: string,
  request: WktToGeoJsonRequest
): AxiosPromise<string[]> {
  return AXIOS.post(`/submissions/${submissionReference}/visualiser/convertWktToGeoJson`, request);
}
