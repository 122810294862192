import { useState, useEffect, FC } from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';
import { Typography, Link, Container, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';

import * as ApiKeysApi from '../../../api/apiKeys';
import { extractErrorMessage } from '../../../api/endpoints';
import { HeaderBreadcrumb, AppDecorator, PaperFormSkeleton } from '../../../components';
import { useErrorBlock } from '../../../contexts/error-block';
import { ApiKeyDetail, UserProfile } from '../../../types';
import { intl } from '../../../Internationalization';

import ApiKeySettingsForm from './ApiKeySettingsForm';

interface ApiKeyProps {
  user: UserProfile;
}

const ApiKey: FC<ApiKeyProps> = ({ user }) => {
  const { apiKeyName } = useParams<{ apiKeyName: string }>();
  const { raiseError } = useErrorBlock();

  const [apiKey, setApiKey] = useState<ApiKeyDetail>();

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await ApiKeysApi.getApiKey(user.key, apiKeyName!);
        setApiKey(response.data);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'apiKeys.apiKey.loadError',
              defaultMessage: 'Failed to update new API key',
            })
          )
        );
      }
    };

    fetchApiKey();
  }, [apiKeyName, raiseError, user.key]);

  const renderBreadcrumb = () => {
    return (
      <HeaderBreadcrumb>
        <Link component={RouterLink} to="./api_keys">
          <Box display="flex" alignItems="center">
            <PersonIcon
              titleAccess={intl.formatMessage({
                id: 'apiKeys.routing.breadcrumb.user.titleAccess',
                defaultMessage: 'User',
              })}
            />
            {user.email}
          </Box>
        </Link>
        <Box display="flex" alignItems="center">
          <StorageIcon
            titleAccess={intl.formatMessage({
              id: 'apiKeys.routing.breadcrumb.apiKey.titleAccess',
              defaultMessage: 'API key',
            })}
          />
          <Typography color="inherit">{apiKeyName}</Typography>
        </Box>
      </HeaderBreadcrumb>
    );
  };

  return (
    <AppDecorator renderTabs={undefined} renderBreadcrumb={renderBreadcrumb}>
      <Container maxWidth="md" disableGutters>
        {apiKey ? (
          <ApiKeySettingsForm user={user} apiKey={apiKey} />
        ) : (
          <PaperFormSkeleton contentRows={3} />
        )}
      </Container>
    </AppDecorator>
  );
};

export default ApiKey;
