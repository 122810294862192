import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Box, DialogContent, Radio, Table, TableBody, TableCell, TableRow } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import * as SavedMappingsApi from '../../../../../api/saved-mapping/savedMappings';
import * as AssignmentSavedMappingApi from '../../../../../api/assignment/assignmentSavedMapping';
import { extractErrorMessage } from '../../../../../api/endpoints';
import {
  DefaultButton,
  FilterPagination,
  FilterTextField,
  MinWidthTableCell,
  StyledTableHead,
  TableInfoRow,
  TableLoadingRow,
} from '../../../../../components';
import { useBrowseRequest } from '../../../../../hooks';
import { intl } from '../../../../../Internationalization';
import { SavedMappingDetail, SavedMappingSummary } from '../../../../../types';

import SavedMappingBreadcrumb from './SavedMappingBreadcrumb';
import CopySavedMappingActions from './CopySavedMappingActions';

const PAGE_SIZE = 10;

interface SelectMappingProps {
  onSavedMappingSelected: (savedMapping: SavedMappingDetail) => void;
  onCancel: () => void;
  disableNext: boolean;
}

const SelectMapping: FC<SelectMappingProps> = ({
  onSavedMappingSelected,
  onCancel,
  disableNext,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { request, response, processing, setPage, updateRequest } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE },
    onRequest: SavedMappingsApi.getSavedMappings,
  });

  const [selectedSavedMapping, setSelectedSavedMapping] = useState<SavedMappingSummary>();

  const handleNext = async () => {
    if (!selectedSavedMapping) {
      return;
    }
    try {
      const savedMapping = (
        await AssignmentSavedMappingApi.getSavedMapping(
          selectedSavedMapping.assignment.key,
          selectedSavedMapping.key
        )
      ).data;
      onSavedMappingSelected(savedMapping);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.savedMapping.copy.steps.loadSavedMappingError',
            defaultMessage: 'Failed to fetch saved mapping details',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const handleSearch = (value: string) => {
    updateRequest({ filter: value });
    setSelectedSavedMapping(undefined);
  };

  const renderTableRows = () => {
    if (!response) {
      return <TableLoadingRow colSpan={3} />;
    }

    if (!response.results.length) {
      return (
        <TableInfoRow
          colSpan={3}
          size="medium"
          message={intl.formatMessage({
            id: 'myAssignment.savedMapping.copy.selectMapping.noSavedMappings',
            defaultMessage: 'No saved mappings to display',
          })}
        />
      );
    }
    const handleSavedMappingSelect = (savedMapping: SavedMappingSummary) => {
      setSelectedSavedMapping((prevSavedMapping) =>
        prevSavedMapping?.key === savedMapping.key ? undefined : savedMapping
      );
    };

    return response.results.map((savedMapping) => {
      const { key, name } = savedMapping;
      const selected = selectedSavedMapping?.key === key;

      return (
        <TableRow
          key={key}
          selected={selected}
          onClick={() => handleSavedMappingSelect(savedMapping)}
          sx={{ cursor: 'pointer' }}
        >
          <MinWidthTableCell>
            <Radio checked={selected} value={key} name="savedMapping" />
          </MinWidthTableCell>
          <TableCell>{name}</TableCell>
          <TableCell>
            <SavedMappingBreadcrumb savedMapping={savedMapping} />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <FilterTextField
            name="filter"
            label={intl.formatMessage({
              id: 'myAssignment.savedMapping.copy.selectMapping.filter.label',
              defaultMessage: 'Filter…',
            })}
            value={request.filter || ''}
            onSearch={handleSearch}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <Box whiteSpace="nowrap" ml={2}>
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
          </Box>
        </Box>
        <Box maxHeight={300} minHeight={150} overflow="auto">
          <Table size="small">
            <StyledTableHead>
              <TableRow>
                <MinWidthTableCell />
                <TableCell>
                  <FormattedMessage
                    id="myAssignment.savedMapping.copy.selectMapping.mappingColumn"
                    defaultMessage="Mapping"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="myAssignment.savedMapping.copy.selectMapping.assignmentColumn"
                    defaultMessage="Assignment"
                  />
                </TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        </Box>
      </DialogContent>
      <CopySavedMappingActions
        onCancel={onCancel}
        nextButton={
          <DefaultButton
            name="next"
            onClick={handleNext}
            disabled={!selectedSavedMapping || disableNext || processing}
            endIcon={<NavigateNextIcon />}
          >
            <FormattedMessage
              id="myAssignment.savedMapping.copy.selectMapping.nextButton"
              defaultMessage="Next"
            />
          </DefaultButton>
        }
      />
    </>
  );
};

export default SelectMapping;
