import { FC, PropsWithChildren } from 'react';

import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { intl } from '../../../../../../Internationalization';
import {
  ACTION_KIND_METADATA,
  ReorderDirection,
  SpecificationActions,
} from '../../../../../../types';

interface ActionCardProps extends PropsWithChildren {
  index: string;
  action: SpecificationActions;
  disableNext: boolean;
  disablePrev: boolean;
  handleReorderAction: (actionKey: string, direction: ReorderDirection) => void;
  handleDeleteAction: () => void;
  disabled: boolean;
}

const ActionCard: FC<ActionCardProps> = ({
  index,
  action,
  disablePrev,
  disableNext,
  handleReorderAction,
  handleDeleteAction,
  disabled,
  children,
}) => (
  <Grid item xs={12}>
    <Card sx={{ p: 2 }} elevation={3}>
      <Box display="flex" justifyContent="space-between" columnGap={3} alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" mr={1}>
            <IconButton
              size="small"
              onClick={() => handleReorderAction(action.key, 'UP')}
              disabled={disablePrev || disabled}
            >
              <ArrowUpwardIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handleReorderAction(action.key, 'DOWN')}
              disabled={disableNext || disabled}
            >
              <ArrowDownwardIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography gutterBottom>{ACTION_KIND_METADATA[action.kind].label}</Typography>
        </Box>
        <IconButton
          name={`actions.${index}.delete`}
          disabled={disabled}
          aria-label={intl.formatMessage({
            id: 'specification.configuration.actionConfig.deleteAction.ariaLabel',
            defaultMessage: 'Delete Action',
          })}
          onClick={handleDeleteAction}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {children}
    </Card>
  </Grid>
);

export default ActionCard;
