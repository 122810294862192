import { AXIOS, entityDoesNotExist } from '../endpoints';

export function supplierMappingBySupplierKeyAndGroup(
  identityProviderKey: string,
  supplierKey: string,
  group: string
): Promise<boolean> {
  return entityDoesNotExist(
    AXIOS.head('/supplierMappingBySupplierKeyAndGroup', {
      params: { identityProviderKey, supplierKey, group },
    })
  );
}
