import Schema, { InternalRuleItem, RuleItem } from 'async-validator';
import { add, endOfDay, formatDuration, isAfter, isBefore, isEqual, isValid } from 'date-fns';

import {
  duplicateValidator,
  routerSafeValidator,
  NOT_BLANK_REGEX,
  notTrimmableValidator,
} from './shared';
import { intl } from '../Internationalization';

import { entityDoesNotExist } from '../api/endpoints';
import { getApiKey } from '../api/apiKeys';
import { getSiteSettings } from '../api/site/siteSettings';
import { parse } from 'iso8601-duration';

export function lookupByName(userKey: string, name: string): Promise<boolean> {
  return entityDoesNotExist(getApiKey(userKey, name));
}

const descriptionValidator: RuleItem = {
  required: true,
  message: intl.formatMessage({
    id: 'apiKey.validator.description.required',
    defaultMessage: 'Please provide a description',
  }),
};

const expiresAtValidator: RuleItem[] = [
  {
    required: true,
    message: intl.formatMessage({
      id: 'apiKey.validator.expiresAt.required',
      defaultMessage: 'Please select an expiration date',
    }),
  },
  {
    validator: (rule: InternalRuleItem, value: Date, callback: (error?: string) => void) => {
      if (value) {
        const dayEnd = endOfDay(new Date());

        if (!isValid(value)) {
          callback(
            intl.formatMessage({
              id: 'apiKey.validator.expiresAt.isDate',
              defaultMessage: 'Must be a valid date',
            })
          );
        } else if (isBefore(value, dayEnd) || isEqual(value, dayEnd)) {
          callback(
            intl.formatMessage({
              id: 'apiKey.validator.expiresAt.futureDate',
              defaultMessage: 'Date must be in the future',
            })
          );
        } else {
          getSiteSettings()
            .then((siteSettings) => {
              const maxApiKeyPeriod = siteSettings?.data?.maxApiKeyPeriod;
              const maxExpirationDate = maxApiKeyPeriod
                ? add(new Date(), parse(maxApiKeyPeriod))
                : undefined;
              if (maxApiKeyPeriod && maxExpirationDate && isAfter(value, maxExpirationDate)) {
                callback(
                  intl.formatMessage(
                    {
                      id: 'apiKey.validator.expiresAt.longerThanMaxPeriod',
                      defaultMessage:
                        'Expiration date must not be more than {maxExpirationDate} in the future.',
                    },
                    {
                      maxExpirationDate: formatDuration(parse(maxApiKeyPeriod), {
                        format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
                      }),
                    }
                  )
                );
              } else {
                callback();
              }
            })
            .catch(() => callback());
        }
      }
    },
  },
];

export const API_KEY_SETTINGS_VALIDATOR = new Schema({
  description: descriptionValidator,
  expiresAt: expiresAtValidator,
});

export function newApiKeySettingsValidator(getUserKey: () => string) {
  return new Schema({
    name: [
      {
        required: true,
        message: intl.formatMessage({
          id: 'apiKey.validator.name.required',
          defaultMessage: 'Please provide a unique name',
        }),
      },
      notTrimmableValidator,
      routerSafeValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: (name) => lookupByName(getUserKey(), name),
        alreadyExistsMessage: intl.formatMessage({
          id: 'apiKey.validator.name.unique',
          defaultMessage: 'This name is already in use by another API key',
        }),
        errorMessage: intl.formatMessage({
          id: 'apiKey.validator.name.checkUniqueError',
          defaultMessage: 'There was a problem verifying the key name',
        }),
      }),
    ],
    description: descriptionValidator,
    expiresAt: expiresAtValidator,
  });
}
