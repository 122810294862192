import { useContext, useEffect } from 'react';
import { ApplicationContext } from '../contexts/application';

const useTitle = (newTitle: string) => {
  const {
    applicationDetails: {
      site: { name },
    },
  } = useContext(ApplicationContext);
  const documentDefined = typeof document !== 'undefined';
  const title = `${newTitle} - ${name}`;

  useEffect(() => {
    if (document.title !== title) {
      document.title = title;
    }
    return () => {
      document.title = name;
    };
  }, [documentDefined, name, title]);
};

export default useTitle;
