import Schema from 'async-validator';

import { intl } from '../Internationalization';
import { durationValidator } from './shared';

export const SETTINGS_VALIDATOR = new Schema({
  scannerSettings: {
    required: true,
    type: 'object',
    fields: {
      scannerPath: {
        type: 'string',
        required: true,
        message: intl.formatMessage({
          id: 'virusScanner.validator.scannerPath.required',
          defaultMessage: 'Please provide the scanner path',
        }),
      },
      scanTimeout: [
        {
          required: true,
          message: intl.formatMessage({
            id: 'virusScanner.validator.scanTimeout.required',
            defaultMessage: 'Please provide a timeout duration',
          }),
        },
        durationValidator({ seconds: 1 }),
      ],
    },
  },
});
