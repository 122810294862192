import { FC } from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { SupplierAvatar } from '../../../../components';
import { AvatarDetail, SupplierDetail } from '../../../../types';

export interface AssignmentRowDetailProps {
  name?: string;
  reference?: string;
  key: string;
  supplier: { key: string; name: string; avatar?: AvatarDetail };
}

interface AssignmentRowProps {
  assignment: AssignmentRowDetailProps;
  actionColumn?: boolean;
  nestedRow?: boolean;
}

const AssignmentRow: FC<AssignmentRowProps> = ({ assignment, actionColumn, nestedRow }) => {
  const details = (
    <>
      <TableCell>
        {assignment.key} - {assignment.name ? assignment.name : assignment.reference}
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          {!!assignment.supplier.avatar ? (
            <SupplierAvatar supplier={assignment.supplier as SupplierDetail} sx={{ mr: 1 }} />
          ) : null}
          {assignment.supplier.key} - {assignment.supplier.name}
        </Box>
      </TableCell>
    </>
  );
  if (nestedRow) {
    return details;
  }
  return (
    <TableRow>
      {details}
      {actionColumn && <TableCell />}
    </TableRow>
  );
};

export default AssignmentRow;
