import { useContext } from 'react';
import {
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

import WebIcon from '@mui/icons-material/Web';
import PublicIcon from '@mui/icons-material/Public';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { PaddedPaper, ExternalLink } from '../../../components';
import { ApplicationContext } from '../../../contexts/application';
import { intl } from '../../../Internationalization';
import { useTitle } from '../../../hooks';

import SystemHeader from '../SystemHeader';

export const createDocumentationUrl = (version: string) => {
  var versionRegex = /^([\d]+)\.([\d]+)/;
  var [match, major, minor] = version.match(versionRegex) || [];
  if (match) {
    return `https://1spatial.com/documentation/1datagateway/v${major}_${minor}/`;
  }
  return;
};

const Help = () => {
  useTitle(intl.formatMessage({ id: 'title.system.help', defaultMessage: 'Help and Support' }));
  const { applicationDetails } = useContext(ApplicationContext);
  const documentationUrl = createDocumentationUrl(applicationDetails.version);

  return (
    <>
      <SystemHeader
        title={intl.formatMessage({ id: 'help.title', defaultMessage: 'Help and Support' })}
        icon={HelpOutlineIcon}
      />
      <Box p={3} id="system-help">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <PaddedPaper fillVertical>
              <Typography variant="h5" component="p" gutterBottom>
                <FormattedMessage id="help.emailSupport.title" defaultMessage="Email Support" />
              </Typography>
              <Typography variant="body1" paragraph>
                <FormattedMessage
                  id="help.emailSupport.description"
                  defaultMessage="All email enquiries can be sent to our global support address:"
                />
              </Typography>
              <Typography variant="body1" paragraph>
                <ExternalLink className="Help-emailSupportLink" href="mailto:support@1spatial.com">
                  <FormattedMessage id="help.emailSupport.link" defaultMessage="Email Support" />
                </ExternalLink>
              </Typography>

              <Typography variant="h5" component="p" gutterBottom>
                <FormattedMessage
                  id="help.telephoneSupport.title"
                  defaultMessage="Telephone Support"
                />
              </Typography>
              <Typography variant="subtitle1" paragraph>
                <FormattedMessage
                  id="help.telephoneSupport.description"
                  defaultMessage="Telephone support is provided by our local support team:"
                />
              </Typography>
              <Typography variant="body1" paragraph>
                <ExternalLink
                  className="Help-telephoneSupportLink"
                  href="https://1spatial.com/services/support/contact-support/"
                  target="_blank"
                >
                  <FormattedMessage
                    id="help.telephoneSupport.link"
                    defaultMessage="Telephone Support"
                  />
                </ExternalLink>
              </Typography>

              <Typography variant="h5" component="p" gutterBottom>
                <FormattedMessage id="help.supportPortal.title" defaultMessage="Support Portal" />
              </Typography>
              <Typography variant="subtitle1" paragraph>
                <FormattedMessage
                  id="help.supportPortal.description"
                  defaultMessage="Login to our support portal via the link below:"
                />
              </Typography>
              <Typography variant="body1" paragraph>
                <ExternalLink
                  className="Help-supportPortalLink"
                  href="https://1spatial.force.com/success/s/login/?language=en_GB"
                  target="_blank"
                >
                  <FormattedMessage id="help.supportPortal.link" defaultMessage="Support Portal" />
                </ExternalLink>
              </Typography>
            </PaddedPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PaddedPaper fillVertical>
              <Typography variant="h5" component="p" gutterBottom>
                <FormattedMessage
                  id="help.documentation.title"
                  defaultMessage="Data Gateway Documentation"
                />
              </Typography>
              <Typography variant="subtitle1" paragraph>
                <FormattedMessage
                  id="help.documentation.description"
                  defaultMessage="Documentation for 1Data Gateway can be accessed from the link below:"
                />
              </Typography>
              {documentationUrl && (
                <Typography variant="body1" paragraph>
                  <ExternalLink
                    className="Help-documentationLink"
                    href={documentationUrl}
                    target="_blank"
                  >
                    <FormattedMessage
                      id="help.documentation.link"
                      defaultMessage="Data Gateway Documentation"
                    />
                  </ExternalLink>
                </Typography>
              )}
              <Typography variant="body1" paragraph>
                <ExternalLink
                  className="Help-apiLink"
                  href="/swagger-ui/index.html"
                  target="_blank"
                >
                  <FormattedMessage
                    id="help.restApiReference.link"
                    defaultMessage="Data Gateway REST API Reference"
                  />
                </ExternalLink>
              </Typography>
              <Typography variant="h5" component="p">
                <FormattedMessage id="help.details.title" defaultMessage="Data Gateway Details" />
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PublicIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="Help-siteName"
                    primary={intl.formatMessage({
                      id: 'help.siteName',
                      defaultMessage: 'Site Name',
                    })}
                    secondary={applicationDetails.site.name}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <WebIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={intl.formatMessage({
                      id: 'help.siteAddress',
                      defaultMessage: 'Site address',
                    })}
                    secondary={
                      <ExternalLink
                        className="Help-siteAddressLink"
                        href={applicationDetails.site.rootUrl}
                        target="_blank"
                      >
                        {applicationDetails.site.rootUrl}
                      </ExternalLink>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>#</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="Help-version"
                    primary={intl.formatMessage({
                      id: 'help.version',
                      defaultMessage: 'Version',
                    })}
                    secondary={applicationDetails.version}
                  />
                </ListItem>
              </List>
            </PaddedPaper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Help;
