import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { SubmissionSource, SUBMISSION_SOURCE_METADATA } from '../../types';

export type SubmissionSourceSelections = Record<SubmissionSource, boolean>;

export const toSubmissionSourceArray = (
  submissionSourceSelections: SubmissionSourceSelections
): SubmissionSource[] => {
  return (Object.keys(submissionSourceSelections) as SubmissionSource[]).filter(
    (source) => submissionSourceSelections[source]
  );
};

interface SubmissionSourceSelectorProps {
  selections: SubmissionSourceSelections;
  onSelectionsUpdated: (sourceSelections: SubmissionSourceSelections) => void;
}

function SubmissionSourceSelector({
  selections,
  onSelectionsUpdated,
}: SubmissionSourceSelectorProps) {
  return (
    <Box display="flex" my={1.5} mx={1}>
      {(Object.keys(SUBMISSION_SOURCE_METADATA) as SubmissionSource[]).map((sourceKey) => {
        const checked = selections[sourceKey];
        return (
          <FormControlLabel
            key={sourceKey}
            control={
              <Checkbox
                checked={checked}
                onChange={() => {
                  onSelectionsUpdated({
                    ...selections,
                    [sourceKey]: !checked,
                  });
                }}
                name={sourceKey}
                sx={{ p: (theme) => theme.spacing(0, 0.5, 0, 1) }}
              />
            }
            label={SUBMISSION_SOURCE_METADATA[sourceKey].label}
          />
        );
      })}
    </Box>
  );
}

export default SubmissionSourceSelector;
