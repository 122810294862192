import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import {
  IconButton,
  Tooltip,
  TableRow,
  TableCell,
  Box,
  MenuItem,
  Typography,
  Select,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

import * as SubmissionApi from '../../../../../../api/submission/submission';
import { extractErrorMessage, FileUploadConfig } from '../../../../../../api/endpoints';
import { UploadCompact, ConfirmDialog, MinWidthTableCell } from '../../../../../../components';
import { UploadedIcon } from '../../../../../../components/icons';
import {
  SessionSchema,
  VIRUS_SCANNER_STATE_METADATA,
  SubmissionInputParameters,
  SubmissionInputDetail,
  InputsDescription,
} from '../../../../../../types';

import { dataStoreNameFromPath, dataStoreTypeMetaDataFromPath } from '../../../../../../util';
import { intl } from '../../../../../../Internationalization';
import { useMediaUpload } from '../../../../../../hooks';
import VirusScanStateIcon from '../../../../../../components/media/VirusScanStatus';

import InputParametersTableCell from './InputParametersTableCell';

interface InputRowProps {
  input: SubmissionInputDetail;
  submissionReference: string;

  sessionSchema: SessionSchema;

  virusScannerEnabled: boolean;
  mediaColumn: boolean;
  displayParameters: boolean;

  onInputsUpdated(submissionInputs: InputsDescription): void;
  onRemoveMedia(dataStorePath: string, inputKey: string): void;

  handleUploadChange: (uploading: boolean) => void;
  handleUpdateInput: (inputKey: string, input: SubmissionInputDetail) => void;
}

const InputRow: FC<InputRowProps> = ({
  input,
  sessionSchema,
  mediaColumn,
  submissionReference,
  virusScannerEnabled,
  displayParameters,
  onInputsUpdated,
  handleUploadChange,
  handleUpdateInput,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDataStore, setSelectedDataStore] = useState<string>(
    (input.selectedDataStore && input.linkedDataStores[input.selectedDataStore].dataStorePath) ||
      Object.values(input.linkedDataStores)[0]?.dataStorePath
  );
  const [processing, setProcessing] = useState<boolean>(false);

  const [upload, uploading, uploadProgress] = useMediaUpload({
    onUpload: (files: File[], fileUploadConfig: FileUploadConfig) => {
      handleUploadChange(true);
      return SubmissionApi.updateInput(
        submissionReference,
        input.inputKey,
        selectedDataStore,
        files[0],
        fileUploadConfig
      );
    },
    onUploadComplete: (submissionInputs: InputsDescription) => {
      onInputsUpdated(submissionInputs);
    },
    onResetCallback: () => {
      handleUploadChange(false);
    },
  });

  const [deleteFileConfirm, setDeleteFileConfirm] = useState<boolean>(false);

  const deleteSubmissionInputMedia = async () => {
    setDeleteFileConfirm(false);
    try {
      await SubmissionApi.removeFileForInput(submissionReference, input.inputKey);
      const updatedInput = { ...input };
      delete updatedInput.file;
      handleUpdateInput(input.inputKey, updatedInput);

      enqueueSnackbar(
        intl.formatMessage({
          id: 'openSubmission.inputUploadRow.deleteSuccess',
          defaultMessage: 'File deleted',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUploadRow.deleteError',
            defaultMessage: 'Failed to remove file from input',
          })
        ),
        { variant: 'warning' }
      );
    }
  };

  const handleDataStoreSelect = async (event: SelectChangeEvent<string>) => {
    try {
      setProcessing(true);
      const response = await SubmissionApi.updateInput(
        submissionReference,
        input.inputKey,
        event.target.value
      );
      setSelectedDataStore(event.target.value);
      onInputsUpdated(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'openSubmission.inputUploadRow.inputUpdateSuccess',
          defaultMessage: 'Input updated successfully ',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUploadRow.inputUpdateError',
            defaultMessage: 'Failed to update input',
          })
        ),
        { variant: 'warning' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleOnParametersUpdated = (newParameters: SubmissionInputParameters) => {
    const updatedInput = { ...input };
    Object.entries(newParameters).forEach(([dataStorePath, parameters]) => {
      if (updatedInput.linkedDataStores[dataStorePath]) {
        updatedInput.linkedDataStores[dataStorePath].parameters = parameters;
      }
    });

    handleUpdateInput(input.inputKey, updatedInput);
  };

  const handleUpload = (acceptedFiles: File[]) => {
    upload(acceptedFiles);
  };

  const uploadTitle = () => {
    if (input.file) {
      if (input.orphaned) {
        return intl.formatMessage(
          {
            id: 'openSubmission.inputUploadRow.dataSetNotFound',
            defaultMessage: '{filename} - Data set not found',
          },
          { filename: input.file.filename }
        );
      }
      return input.file.filename;
    }
    return intl.formatMessage({
      id: 'openSubmission.inputUploadRow.uploadAFile',
      defaultMessage: 'Please upload a file',
    });
  };

  const renderMediaRequirementCell = () => {
    if (input.uploadRequired) {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: 'openSubmission.inputUploadRow.uploadRequired.tooltip',
            defaultMessage: 'Upload required',
          })}
        >
          <CheckIcon
            fontSize="large"
            titleAccess={intl.formatMessage({
              id: 'openSubmission.inputUploadRow.uploadRequired.titleAccess',
              defaultMessage: 'Upload required',
            })}
          />
        </Tooltip>
      );
    }

    if (input.orphaned && input.file) {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: 'openSubmission.inputUploadRow.uploadNotSupported.tooltip',
            defaultMessage: 'Upload not supported',
          })}
        >
          <DoNotDisturbIcon
            color="error"
            fontSize="large"
            titleAccess={intl.formatMessage({
              id: 'openSubmission.inputUploadRow.uploadNotSupported.titleAccess',
              defaultMessage: 'Upload not supported',
            })}
          />
        </Tooltip>
      );
    }
  };

  const renderDataStoreInfo = (path: string) => {
    const {
      icon: Icon,
      label,
      defaultCRS,
    } = dataStoreTypeMetaDataFromPath(sessionSchema, path, 'importSchema');

    const displayCRS = (crs?: string) => {
      if (!crs) {
        return undefined;
      }
      return (
        <Typography variant="body2" className="InputRow-crs">
          {intl.formatMessage({
            id: 'openSubmission.inputUploadRow.linkedDataStores.crs',
            defaultMessage: 'CRS:',
          })}
          &nbsp;
          {crs}
        </Typography>
      );
    };

    const overridenCRS = () => {
      const crsParam = input.linkedDataStores[selectedDataStore]?.parameters
        .filter((parameter) => parameter.name === 'Coordinate Reference System')
        .find((parameter) => parameter.valuePresent);
      return crsParam?.value;
    };

    const overridenCRSValue = overridenCRS();

    return (
      <Box display="flex" alignItems="center">
        <Icon fontSize="large" />
        <Box pl={1}>
          <Tooltip title={path} placement="right">
            <Typography>{dataStoreNameFromPath(path)}</Typography>
          </Tooltip>
          <Typography variant="body2">{label}</Typography>
          {displayCRS(overridenCRSValue || defaultCRS)}
        </Box>
      </Box>
    );
  };

  const renderFileTypeCell = () => {
    if (Object.values(input.linkedDataStores).length > 1) {
      return (
        <FormControl fullWidth variant="outlined">
          <InputLabel>
            <FormattedMessage
              id="openSubmission.inputUploadRow.linkedDataStores.label"
              defaultMessage="Linked data stores"
            />
          </InputLabel>
          <Select
            name="linkedDataStore"
            label={intl.formatMessage({
              id: 'openSubmission.inputUploadRow.linkedDataStores.label',
              defaultMessage: 'Linked data stores',
            })}
            value={selectedDataStore || ''}
            onChange={handleDataStoreSelect}
            renderValue={renderDataStoreInfo}
            variant="outlined"
            fullWidth
            disabled={processing}
          >
            {Object.values(input.linkedDataStores).map((dataStore) => {
              return (
                <MenuItem value={dataStore.dataStorePath} key={dataStore.dataStorePath}>
                  {renderDataStoreInfo(dataStore.dataStorePath)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }

    if (Object.values(input.linkedDataStores).length === 1) {
      return renderDataStoreInfo(Object.values(input.linkedDataStores)[0].dataStorePath);
    }

    return null;
  };

  const renderDataStoreParametersCell = () => {
    if (selectedDataStore) {
      return (
        <InputParametersTableCell
          key={selectedDataStore}
          dataStore={input.linkedDataStores[selectedDataStore]}
          dataStoreInputParameters={input.linkedDataStores[selectedDataStore]?.parameters}
          submissionReference={submissionReference}
          handleOnParametersUpdated={handleOnParametersUpdated}
          disabled={processing}
        />
      );
    } else {
      return <TableCell />;
    }
  };

  return (
    <TableRow>
      <TableCell>{input.inputName}</TableCell>
      <TableCell>{renderFileTypeCell()}</TableCell>
      {mediaColumn && <TableCell align="center">{renderMediaRequirementCell()}</TableCell>}
      {mediaColumn && virusScannerEnabled && (
        <TableCell className="InputRow-virusScanState">
          {input.file && (
            <Box display="flex" alignItems="center">
              <VirusScanStateIcon state={input.file.virusScanState} />
              <Box pl={1}>{VIRUS_SCANNER_STATE_METADATA[input.file.virusScanState].label}</Box>
            </Box>
          )}
        </TableCell>
      )}
      {mediaColumn && (
        <TableCell>
          <Box minWidth={400} display="flex" width="100%">
            <UploadCompact
              onDrop={handleUpload}
              progress={uploadProgress}
              uploading={uploading}
              disabled={input.orphaned || !input.uploadSupported || processing}
              title={uploadTitle()}
              icon={input.file && UploadedIcon}
            />
            <Box alignItems="center" display="flex" ml={1}>
              <IconButton
                name="removeMedia"
                onClick={() => setDeleteFileConfirm(true)}
                disabled={!input.file || processing}
                size="large"
                aria-label={intl.formatMessage({
                  id: 'openSubmission.inputUploadRow.deleteUpload.ariaLabel',
                  defaultMessage: 'Delete uploaded file',
                })}
              >
                {input.orphaned ? <CloseIcon /> : <DeleteForeverIcon />}
              </IconButton>
            </Box>
            <ConfirmDialog
              id="confirm-delete-uploaded-file"
              isOpen={deleteFileConfirm}
              title={intl.formatMessage({
                id: 'openSubmission.inputUploadRow.confirmDelete.title',
                defaultMessage: 'Delete file',
              })}
              text={intl.formatMessage({
                id: 'openSubmission.inputUploadRow.confirmDelete.text',
                defaultMessage: 'Are you sure you wish to delete the uploaded file?',
              })}
              confirmBtnText={intl.formatMessage({
                id: 'openSubmission.inputUploadRow.confirmDelete.confirmButton',
                defaultMessage: 'Delete File',
              })}
              confirmAction={deleteSubmissionInputMedia}
              closeAction={() => setDeleteFileConfirm(false)}
            />
          </Box>
        </TableCell>
      )}
      {displayParameters && renderDataStoreParametersCell()}
      <MinWidthTableCell />
    </TableRow>
  );
};

export default InputRow;
