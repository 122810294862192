import defaultLogo from '../../assets/images/1datagateway_1300.jpg';
import defaultHeroImage from '../../assets/images/bg1.jpg';
import { VirusCheckedMediaDetail, virusScanStatePassed } from '../../types';

import { generateApiUri } from '../endpoints';

export const downloadSiteResourceUri = (filename: string): string => {
  return generateApiUri(`/site/resources/${filename}`);
};

export const siteLogoOrDefault = (logo?: VirusCheckedMediaDetail): string => {
  return logo && virusScanStatePassed(logo.virusScanState)
    ? downloadSiteResourceUri(logo.storedFilename)
    : defaultLogo;
};

export const siteHeroImageOrDefault = (heroImage?: VirusCheckedMediaDetail): string => {
  return heroImage && virusScanStatePassed(heroImage.virusScanState)
    ? downloadSiteResourceUri(heroImage.storedFilename)
    : defaultHeroImage;
};
