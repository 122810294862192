import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  IconButton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';

import * as SpecificationFieldsApi from '../../../../../../api/specification/specificationFields';
import * as SubmissionApi from '../../../../../../api/submission/submission';
import {
  DefaultButton,
  PaddedDialogActions,
  ValidatedNumericField,
  ValidatedTextField,
  ValidatedCheckbox,
} from '../../../../../../components';
import {
  FIELD_TYPES_METADATA,
  FieldType,
  SpecificationFieldDetail,
  SubmissionFieldValues,
} from '../../../../../../types';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import { intl } from '../../../../../../Internationalization';
import { submissionFieldsValidation, validate } from '../../../../../../validation';

import { OpenSubmissionContext } from '../OpenSubmissionContext';
import { isUndefined } from 'lodash';

const extractFieldRequestValues = (validatedFields: FieldValues) => {
  const request: Record<string, any> = {};
  Object.entries(validatedFields).forEach(([name, field]) => {
    request[name] = field === undefined ? null : field;
  });
  return request;
};

const extractFieldValues = (
  fieldValues: SubmissionFieldValues,
  configs: SpecificationFieldDetail[]
): FieldValues => {
  const values: FieldValues = {};

  configs.forEach(({ name, type }) => {
    const { value, detached } = fieldValues[name] || { value: undefined, detached: false };
    if (detached) {
      values[name] = '';
    } else if (type === FieldType.BOOLEAN) {
      values[name] = !!value;
    } else {
      values[name] = value;
    }
  });
  return values;
};

const extractNumericValue = (fieldValue?: string | number | boolean) => {
  if (!isUndefined(fieldValue)) {
    return fieldValue as number | string;
  }
  return '';
};

type FieldValues = Record<string, string | number | boolean>;

interface FieldValuesDialogProps {
  fields: SubmissionFieldValues;
  specificationKey: string;
  onRevalidate: (valid: boolean) => void;
}

const MetadataDialog: FC<FieldValuesDialogProps> = ({ fields, specificationKey, onRevalidate }) => {
  const { submission } = useContext(OpenSubmissionContext);
  const { enqueueSnackbar } = useSnackbar();

  const [openFieldsDialog, setOpenFieldsDialog] = useState<boolean>(false);
  const [fieldConfig, setFieldConfig] = useState<SpecificationFieldDetail[]>([]);
  const [fieldValues, setFieldValues] = useState<FieldValues>();
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenFieldsDialog(false);
    fetchSpecificationFieldsConfig();
  };

  const fetchSpecificationFieldsConfig = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await SpecificationFieldsApi.fetchSpecificationFields(specificationKey);
      setFieldConfig(data);
      setFieldValues(extractFieldValues(fields, data));
      setLoaded(true);
    } catch (error) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUpload.fieldValues.dialog.fetchFieldDetails.error',
            defaultMessage: 'Failed to load metadata.',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, fields, specificationKey]);

  useEffect(() => {
    fetchSpecificationFieldsConfig();
  }, [fetchSpecificationFieldsConfig]);

  const handleSaveFields = useCallback(
    async (validatedFields: FieldValues) => {
      const extractedValues = extractFieldRequestValues(validatedFields);
      try {
        await SubmissionApi.patchSubmission(submission.reference, {
          fieldValues: extractedValues,
        });
        setOpenFieldsDialog(false);
        enqueueSnackbar(
          intl.formatMessage({
            id: 'openSubmission.inputUpload.fieldValues.dialog.save.success',
            defaultMessage: 'Metadata saved successfully.',
          }),
          { variant: 'success' }
        );
      } catch (error: any) {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.inputUpload.fieldValues.dialog.save.error',
              defaultMessage: 'Failed to save metadata.',
            })
          ),
          { variant: 'error' }
        );
      } finally {
        setProcessing(false);
      }
    },
    [enqueueSnackbar, submission.reference]
  );

  const validateAndSubmit = useCallback(
    async (submit: boolean) => {
      if (!fieldValues) {
        return;
      }
      setProcessing(true);
      setFieldErrors(undefined);
      try {
        const validatedValues = await validate(
          submissionFieldsValidation(fieldConfig),
          fieldValues,
          { firstFields: true }
        );
        if (submit) {
          handleSaveFields(validatedValues);
        }
        onRevalidate(true);
        setProcessing(false);
      } catch (errors: any) {
        setFieldErrors(errors);
        setProcessing(false);
        onRevalidate(false);
      }
    },
    [fieldConfig, fieldValues, onRevalidate, handleSaveFields]
  );

  useEffect(() => {
    if (loaded) {
      validateAndSubmit(false);
      setLoaded(false);
    }
  }, [validateAndSubmit, loaded]);

  const handleUpdateValue = (newValue: any, name: string) => {
    setFieldValues((prev) => {
      const updatedValues = { ...prev };
      updatedValues[name] = newValue;

      return updatedValues;
    });
  };

  const disabled = loading || processing;

  return (
    <>
      <Dialog
        id="metadata-fields-dialog"
        onClose={handleCloseDialog}
        open={openFieldsDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <FormattedMessage
            id="openSubmission.inputUpload.fieldValues.dialog.title"
            defaultMessage="Metadata"
          />
        </DialogTitle>
        <Divider />
        <DialogContent>
          {fieldConfig.map((field) => {
            const label = `${field.name} - (${FIELD_TYPES_METADATA[field.type].label})`;

            switch (field.type) {
              case FieldType.STRING:
                return (
                  <Box key={field.name} mb={0.5}>
                    <ValidatedTextField
                      fieldErrors={fieldErrors}
                      disabled={disabled}
                      label={label}
                      name={field.name}
                      value={fieldValues && fieldValues[field.name] ? fieldValues[field.name] : ''}
                      onChange={(event) => handleUpdateValue(event.target.value, field.name)}
                      required={field.required}
                      margin="dense"
                      variant="outlined"
                    />
                    <FormHelperText data-info-for={field.name}>{field.description}</FormHelperText>
                  </Box>
                );
              case FieldType.BOOLEAN:
                const boolValue =
                  fieldValues && fieldValues[field.name] ? fieldValues[field.name] : false;
                return (
                  <Box key={field.name} mb={0.5}>
                    <ValidatedCheckbox
                      name={field.name}
                      formControlLabelProps={{
                        label: label,
                      }}
                      checkboxProps={{
                        checked: !!boolValue,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                          handleUpdateValue(event.target.checked, field.name),

                        color: 'primary',
                        disabled: disabled,
                      }}
                      fieldErrors={fieldErrors}
                    />
                    <FormHelperText data-info-for={field.name}>{field.description}</FormHelperText>
                  </Box>
                );
              case FieldType.DOUBLE:
                return (
                  <Box key={field.name} mb={0.5}>
                    <ValidatedNumericField
                      name={field.name}
                      required={field.required}
                      label={label}
                      value={extractNumericValue(fieldValues && fieldValues[field.name])}
                      onChange={(_: string, value?: number) => handleUpdateValue(value, field.name)}
                      fieldErrors={fieldErrors}
                      allowDecimals
                      allowMinus
                      disabled={disabled}
                      variant="outlined"
                      margin="dense"
                    />
                    <FormHelperText data-info-for={field.name}>{field.description}</FormHelperText>
                  </Box>
                );
              case FieldType.INTEGER:
              case FieldType.LONG:
                return (
                  <Box key={field.name} mb={0.5}>
                    <ValidatedNumericField
                      name={field.name}
                      required={field.required}
                      label={label}
                      value={extractNumericValue(fieldValues && fieldValues[field.name])}
                      onChange={(_: string, value?: number) => handleUpdateValue(value, field.name)}
                      fieldErrors={fieldErrors}
                      disabled={disabled}
                      variant="outlined"
                      margin="dense"
                      allowMinus
                    />
                    <FormHelperText data-info-for={field.name}>{field.description}</FormHelperText>
                  </Box>
                );
            }
            return null;
          })}
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton
            name="cancel"
            color="secondary"
            onClick={handleCloseDialog}
            disabled={disabled}
          >
            <FormattedMessage
              id="openSubmission.inputUpload.fieldValues.dialog.cancelButton"
              defaultMessage="Cancel"
            />
          </DefaultButton>
          <DefaultButton
            name="create"
            onClick={() => validateAndSubmit(true)}
            disabled={disabled}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage
              id="openSubmission.inputUpload.fieldValues.dialog.saveButton"
              defaultMessage="Save"
            />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
      {fieldValues && !!Object.keys(fieldValues).length && (
        <Badge
          variant="standard"
          color="error"
          badgeContent={new Set([...Object.keys(fieldErrors || {})]).size}
        >
          <IconButton name="openFieldsDialog" onClick={() => setOpenFieldsDialog(true)}>
            <SettingsIcon />
          </IconButton>
        </Badge>
      )}
    </>
  );
};

export default MetadataDialog;
