import { FC, useContext } from 'react';
import { useSnackbar } from 'notistack';

import { extractErrorMessage } from '../../../../api/endpoints';
import * as Saml2IdentityProviderApi from '../../../../api/identity-provider/saml2IdentityProvider';
import { IdentityProviderSettings } from '../../../../types';
import { intl } from '../../../../Internationalization';

import IdentityProviderSettingsForm from '../IdentityProviderSettingsForm';
import { Saml2IdentityProviderContext } from './Saml2IdentityProviderContext';

const Saml2IdentityProviderSettings: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { identityProvider, identityProviderUpdated } = useContext(Saml2IdentityProviderContext);

  const updateIdentityProvider = async (identityProviderSettings: IdentityProviderSettings) => {
    try {
      const { data: updatedIdentityProvider } =
        await Saml2IdentityProviderApi.updateIdentityProvider(identityProvider.key, {
          ...identityProvider,
          ...identityProviderSettings,
        });
      identityProviderUpdated(updatedIdentityProvider);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'saml2.identityProviderSettings.saveSuccess',
          defaultMessage: 'Identity provider updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'saml2.identityProviderSettings.saveError',
            defaultMessage: 'Failed to update identity provider',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  return (
    <IdentityProviderSettingsForm
      onValidSubmit={updateIdentityProvider}
      identityProviderSettings={identityProvider}
    />
  );
};

export default Saml2IdentityProviderSettings;
