import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Box, TableRow, TableCell, Tooltip } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

import { intl } from '../../../../Internationalization';
import * as LdapUserSyncJobMediaApi from '../../../../api/ldap/ldapUserSyncJobMedia';
import * as LdapUserSyncJobApi from '../../../../api/ldap/ldapUserSyncJob';
import { extractErrorMessage } from '../../../../api/endpoints';
import { ButtonRow, DefaultButton, MinWidthTableCell } from '../../../../components';
import { LDAP_SYNC_METADATA, LdapUserSyncJobDetail, LdapUserSyncJobState } from '../../../../types';
import { dateTimeFormat } from '../../../../util';

interface SyncJobTableRowProps {
  job: LdapUserSyncJobDetail;
  onCancel: () => void;
  disabled: boolean;
}

const SyncJobTableRow: FC<SyncJobTableRowProps> = ({ job, onCancel, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState<boolean>(false);

  const cancelSyncJob = (jobId: number) => {
    setProcessing(true);
    LdapUserSyncJobApi.cancelRunningSyncJob(jobId)
      .then(() => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'ldap.syncMonitor.cancelSyncSuccess',
            defaultMessage: 'Sync cancelled',
          }),
          { variant: 'success' }
        );
        onCancel();
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'ldap.syncMonitor.cancelError',
              defaultMessage: 'Failed to cancel the running sync',
            })
          ),
          { variant: 'error' }
        );
        setProcessing(false);
      });
  };

  const { icon: Icon, label } = LDAP_SYNC_METADATA[job.state];

  return (
    <TableRow key={job.id} data-state={job.state}>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Tooltip title={label}>
            <Icon sx={{ mr: 1 }} />
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          {dateTimeFormat(job.startedAt)}
        </Box>
      </TableCell>
      <TableCell align="right">{job.added}</TableCell>
      <TableCell align="right">{job.updated}</TableCell>
      <TableCell align="right">{job.removed}</TableCell>
      <TableCell align="right">{job.skipped}</TableCell>
      <MinWidthTableCell align="right">
        <ButtonRow whiteSpace="nowrap">
          {job.state === LdapUserSyncJobState.RUNNING && (
            <DefaultButton
              startIcon={<CancelIcon />}
              size="small"
              color="secondary"
              onClick={() => cancelSyncJob(job.id)}
              disabled={disabled || processing || !!job.cancelledAt}
            >
              <FormattedMessage id="ldap.syncMonitor.cancelJobButton" defaultMessage="Cancel" />
            </DefaultButton>
          )}
          <DefaultButton
            download
            color="secondary"
            size="small"
            startIcon={<DescriptionOutlinedIcon />}
            href={LdapUserSyncJobMediaApi.downloadUserSyncJobLogFileUri(job.id, job.log)}
          >
            <FormattedMessage id="ldap.syncMonitor.downloadLogButton" defaultMessage="log" />
          </DefaultButton>
        </ButtonRow>
      </MinWidthTableCell>
    </TableRow>
  );
};

export default SyncJobTableRow;
