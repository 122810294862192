import { AxiosPromise } from 'axios';

import {
  ReceiverMappingsRequest,
  ReceiverMappingsResponse,
  ReceiverMappingSettings,
  ReceiverMappingDetail,
} from '../../types';
import { AXIOS, RequestConfig } from '../endpoints';

export function getReceiverMappings(
  request: ReceiverMappingsRequest,
  config?: RequestConfig
): AxiosPromise<ReceiverMappingsResponse> {
  return AXIOS.get('/receiver_mappings', {
    ...config,
    params: request,
  });
}

export function createReceiverMapping(
  request: ReceiverMappingSettings
): AxiosPromise<ReceiverMappingDetail> {
  return AXIOS.post('/receiver_mappings', request);
}
