import { AxiosPromise } from 'axios';
import { LdapUserSyncJobDetail, LdapUserSyncJobsResponse, SimplePageRequest } from '../../types';
import { AXIOS } from '../endpoints';

export function getUserSyncJobs(
  request: SimplePageRequest
): AxiosPromise<LdapUserSyncJobsResponse> {
  return AXIOS.get('/ldap_sync/user', { params: request });
}

export function startSyncJobs(): AxiosPromise<LdapUserSyncJobsResponse> {
  return AXIOS.post('/ldap_sync/user/start_sync');
}

export function cancelRunningSyncJob(jobId: number): AxiosPromise<LdapUserSyncJobDetail> {
  return AXIOS.post(`/ldap_sync/user/${jobId}/cancel`);
}
