import { FC } from 'react';
import { MessageBox } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';
import { SubmissionInputsByKey, VirusScanState } from '../../../../../../types';

interface VirusScanMessageProps {
  inputs: SubmissionInputsByKey;
}

const inputsHaveVirusScanState = (inputs: SubmissionInputsByKey, virusScanState: VirusScanState) =>
  Object.values(inputs).find(
    (currentInput) => currentInput.file && currentInput.file.virusScanState === virusScanState
  );

const VirusScanMessage: FC<VirusScanMessageProps> = ({ inputs }) => {
  if (inputsHaveVirusScanState(inputs, VirusScanState.FOUND)) {
    return (
      <MessageBox
        level="warning"
        message={intl.formatMessage({
          id: 'openSubmission.inputUpload.virusScanning.detected',
          defaultMessage: 'A virus was detected in the uploaded files.',
        })}
      />
    );
  }

  if (inputsHaveVirusScanState(inputs, VirusScanState.ERROR)) {
    return (
      <MessageBox
        level="error"
        message={intl.formatMessage({
          id: 'openSubmission.inputUpload.virusScanning.error',
          defaultMessage: 'An error occurred while virus checking the uploaded files.',
        })}
      />
    );
  }

  return (
    <MessageBox
      level="info"
      message={intl.formatMessage({
        id: 'openSubmission.inputUpload.virusScanning.inProgress',
        defaultMessage: 'The uploaded files are being checked for viruses.',
      })}
    />
  );
};

export default VirusScanMessage;
