import { FC } from 'react';

import * as SupplierMediaApi from '../../api/supplier/supplierMedia';
import { SupplierMetadata } from '../../types';
import { checkVirusScanMediaDetail } from '../../util/files';
import ImageAvatar, { ImageAvatarProps } from './ImageAvatar';

interface SupplierAvatarProps extends Omit<ImageAvatarProps, 'label' | 'color'> {
  supplier: SupplierMetadata;
}

const SupplierAvatar: FC<SupplierAvatarProps> = ({ supplier: { key, name, avatar }, ...rest }) => {
  const checkedMedia = checkVirusScanMediaDetail(avatar.image);
  return (
    <ImageAvatar
      label={name}
      alt={name}
      color={avatar.color}
      logo
      src={checkedMedia && SupplierMediaApi.downloadImageUri(key, checkedMedia)}
      {...rest}
    />
  );
};

export default SupplierAvatar;
