import { Box, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ButtonRow,
  DefaultButton,
  FilterPagination,
  MinWidthTableCell,
  PaddedPaper,
  StyledTableHead,
  browseTableBody,
} from '../../../../components';
import { intl } from '../../../../Internationalization';
import { ScheduleEventDetail, ScheduleEventsRequest } from '../../../../types/scheduleEvent';
import { RequestConfig } from '../../../../api/endpoints';
import { useBrowseRequest, useInterval } from '../../../../hooks';
import * as ScheduleApi from '../../../../api/schedule/schedule';
import { SCHEDULE_EVENT_FAILURE_REASON_METADATA, dateTimeFormat } from '../../../../util';
import { CrossIcon, TickIcon } from '../../../../components/icons';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Link } from 'react-router-dom';

const ScheduleEventsTableBody = browseTableBody<ScheduleEventDetail>();
export const toSubmissionUrl = (submissionReference: string, assignmentKey: string) =>
  `/my_assignments/${assignmentKey}/submissions/${submissionReference}`;
const PAGE_SIZE = 5;
const POLL_INTERVAL = 60000;

export interface ScheduleEventsProps {
  scheduleKey: string;
  active: boolean;
}

export const ScheduleEvents: FC<ScheduleEventsProps> = ({ scheduleKey, active }) => {
  const { request, response, processing, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE },
    onRequest: useCallback(
      (req: ScheduleEventsRequest, config?: RequestConfig) =>
        ScheduleApi.getScheduleEvents(scheduleKey, req, config),
      [scheduleKey]
    ),
  });

  const refreshIfRequired = () => {
    if (active) {
      refresh();
    }
  };

  useInterval(refreshIfRequired, POLL_INTERVAL);

  const renderScheduleEventRow = (scheduleEvent: ScheduleEventDetail) => (
    <TableRow key={scheduleEvent.startDateTime}>
      <TableCell>{dateTimeFormat(scheduleEvent.startDateTime)}</TableCell>
      <TableCell>{scheduleEvent.success ? <TickIcon /> : <CrossIcon />}</TableCell>
      <TableCell>
        <Typography>
          {scheduleEvent.failureReason ? (
            SCHEDULE_EVENT_FAILURE_REASON_METADATA[scheduleEvent.failureReason].label
          ) : (
            <FormattedMessage
              id="myAssignments.schedule.events.success"
              defaultMessage="Submission started successfully."
            />
          )}
        </Typography>
      </TableCell>
      <MinWidthTableCell>
        {scheduleEvent.submissionReference && scheduleEvent.assignmentKey && (
          <ButtonRow whiteSpace="nowrap">
            <DefaultButton
              name="navigateToSubmission"
              color="grey"
              component={Link}
              to={toSubmissionUrl(scheduleEvent.submissionReference, scheduleEvent.assignmentKey)}
            >
              <PlayArrowIcon
                titleAccess={intl.formatMessage({
                  id: 'myAssignment.schedules.events.openSubmission',
                  defaultMessage: 'Open Submission',
                })}
              />
            </DefaultButton>
          </ButtonRow>
        )}
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <Grid item xs={12}>
      <PaddedPaper>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5" component="h3" gutterBottom>
            <FormattedMessage
              id="myAssignments.schedule.events.title"
              defaultMessage="Scheduled Events"
            />
          </Typography>
          <Box>
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
          </Box>
        </Box>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id="myAssignment.schedules.events.table.startColumn"
                    defaultMessage="Started At"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="myAssignment.schedules.events.table.statusColumn"
                    defaultMessage="Status"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="myAssignment.schedules.events.table.notesColumn"
                    defaultMessage="Notes"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id="myAssignment.schedules.events.table.submission"
                    defaultMessage="Submission"
                  />
                </TableCell>
              </TableRow>
            </StyledTableHead>
            <ScheduleEventsTableBody
              data={response?.results}
              mapToRow={renderScheduleEventRow}
              noDataMessage={intl.formatMessage({
                id: 'myAssignment.schedules.table.events.noEvents',
                defaultMessage: 'No scheduled events created.',
              })}
              numCols={4}
              processing={processing}
            />
          </Table>
        </Box>
      </PaddedPaper>
    </Grid>
  );
};
