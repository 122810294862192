import React, { useContext, FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Link,
  Tooltip,
  Box,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import * as SubmissionMediaApi from '../../../api/submission/submissionMedia';
import { MessageBox, DefaultButton, ButtonRow } from '../../../components';
import { dataStoreNameFromPath } from '../../../util';
import { intl } from '../../../Internationalization';
import { MediaDetail, VirusCheckedMediaDetail, virusScanStatePassed } from '../../../types';

import { SubmissionContext } from './SubmissionContext';

const extractInputFileMetadata = (
  submissionReference: string,
  mediaRemoved: boolean,
  inputFile: VirusCheckedMediaDetail
) => {
  const url = SubmissionMediaApi.downloadSubmissionFileUri(
    submissionReference,
    inputFile,
    'inputs'
  );
  const disabled = !url || !virusScanStatePassed(inputFile.virusScanState) || mediaRemoved;
  return {
    url,
    disabled,
  };
};

interface DownloadFilesButtonProps {
  submissionReference: string;
  input?: VirusCheckedMediaDetail;
  modifiedInput?: MediaDetail;
  output?: MediaDetail;
  mediaRemoved: boolean;
}

const DownloadFilesButton: FC<DownloadFilesButtonProps> = ({
  submissionReference,
  input,
  modifiedInput,
  output,
  mediaRemoved,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const inputMetadata = input && extractInputFileMetadata(submissionReference, mediaRemoved, input);
  const modifiedInputUrl =
    modifiedInput &&
    SubmissionMediaApi.downloadSubmissionFileUri(
      submissionReference,
      modifiedInput,
      'modified_inputs'
    );
  const outputUrl =
    output && SubmissionMediaApi.downloadSubmissionFileUri(submissionReference, output, 'outputs');

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const modifiedInputDisabled = !modifiedInputUrl || mediaRemoved;
  const outputDisabled = !outputUrl || mediaRemoved;

  return (
    <ButtonRow>
      <DefaultButton
        name="downloadFiles"
        color="secondary"
        download
        startIcon={<CloudDownloadIcon />}
        endIcon={<ArrowDropDown />}
        onClick={handleOpenMenu}
        disabled={
          (!inputMetadata || inputMetadata.disabled) && modifiedInputDisabled && outputDisabled
        }
      >
        <FormattedMessage
          id="submission.submissionFiles.downloadFilesButton"
          defaultMessage="Download Files"
        />
      </DefaultButton>
      <Menu
        id="submission-files"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem
          href={inputMetadata?.url}
          component={Link}
          disabled={!inputMetadata || inputMetadata.disabled}
        >
          <FormattedMessage
            id="submission.submissionFiles.inputFileLabel"
            defaultMessage="Input file"
          />
        </MenuItem>
        <MenuItem href={modifiedInputUrl} component={Link} disabled={modifiedInputDisabled}>
          <FormattedMessage
            id="submission.submissionFiles.modifiedInputFileLabel"
            defaultMessage="Modified input file"
          />
        </MenuItem>
        <MenuItem href={outputUrl} component={Link} disabled={outputDisabled}>
          <FormattedMessage
            id="submission.submissionFiles.outputFileLabel"
            defaultMessage="Output file"
          />
        </MenuItem>
      </Menu>
    </ButtonRow>
  );
};

const SubmissionFiles: FC = () => {
  const { submission } = useContext(SubmissionContext);
  const dataStores = Array.from(
    new Set([
      ...Object.values(submission.inputsByKey)
        .filter((input) => !!input.file)
        .map((input) => input.selectedDataStore),
      ...Object.keys(submission.modifiedInputs),
      ...Object.keys(submission.outputs),
    ])
  ).sort();

  const auxiliaryInputs = Object.values(submission.inputsByKey).filter(
    (input) => !input.selectedDataStore
  );

  const renderContent = () => {
    if (!dataStores.length) {
      return (
        <MessageBox
          className="SubmissionFiles-noFiles"
          message={intl.formatMessage({
            id: 'submission.submissionFiles.noFiles',
            defaultMessage: 'There are no files relating to this submission.',
          })}
          level="info"
        />
      );
    }

    return (
      <>
        <List component="div" className="SubmissionFiles-dataStores" disablePadding>
          {dataStores.map((dataStore) => {
            if (!dataStore) {
              return null;
            }
            const input =
              submission.inputsByKey &&
              Object.values(submission.inputsByKey).find(
                ({ selectedDataStore }) => selectedDataStore === dataStore
              );
            const modifiedInput = submission.modifiedInputs && submission.modifiedInputs[dataStore];
            const output = submission.outputs && submission.outputs[dataStore];

            return (
              <ListItem key={dataStore}>
                <Tooltip title={dataStore} placement="top-start">
                  <ListItemText
                    primary={dataStoreNameFromPath(dataStore)}
                    secondary={input?.file?.filename}
                  />
                </Tooltip>
                <ListItemSecondaryAction>
                  <DownloadFilesButton
                    submissionReference={submission.reference}
                    input={input?.file}
                    modifiedInput={modifiedInput}
                    output={output}
                    mediaRemoved={submission.mediaRemoved}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
          <Divider />
          {auxiliaryInputs.map((input) => {
            if (!input.file) {
              return null;
            }
            const inputMetadata = extractInputFileMetadata(
              submission.reference,
              submission.mediaRemoved,
              input.file
            );
            return (
              <ListItem key={input.inputName}>
                <ListItemText primary={input.inputName} secondary={input.file.filename} />
                <ListItemSecondaryAction>
                  <DefaultButton
                    name="downloadInputFile"
                    color="secondary"
                    download
                    startIcon={<CloudDownloadIcon />}
                    component={Link}
                    href={inputMetadata.url}
                    disabled={inputMetadata.disabled}
                  >
                    <FormattedMessage
                      id="submission.submissionFiles.downloadFileButton"
                      defaultMessage="Download File"
                    />
                  </DefaultButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        {submission.mediaRemoved && (
          <Box my={1} className="SubmissionFiles-mediaRemoved">
            <MessageBox
              message={intl.formatMessage({
                id: 'submission.submissionFiles.mediaRemoved',
                defaultMessage: 'Media for this submission has been removed.',
              })}
              level="info"
            />
          </Box>
        )}
      </>
    );
  };

  return <div className="SubmissionFiles-root">{renderContent()}</div>;
};

export default SubmissionFiles;
