import { Dialog, DialogProps, DialogTitle, Divider, Tab, Tabs } from '@mui/material';
import { FC, ReactNode, useState } from 'react';

interface TabProps {
  id: string;
  title: string;
  content: ReactNode;
}

interface TabbedDialogProps extends DialogProps {
  tabs: TabProps[];
  title: string;
}

const TabbedDialog: FC<TabbedDialogProps> = ({ tabs, title, ...props }) => {
  const [tab, setTab] = useState<string | undefined>(tabs[0]?.id);

  return (
    <Dialog {...props}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)} variant="fullWidth">
        {tabs.map((t) => {
          return <Tab id={t.id} key={t.id} label={t.title} value={t.id} />;
        })}
      </Tabs>
      {tabs.filter((t) => t.id === tab)[0]?.content}
    </Dialog>
  );
};

export default TabbedDialog;
