import { isBefore, isPast, isValid } from 'date-fns';
import Schema, { InternalRuleItem } from 'async-validator';
import { ScheduleSettings } from '../util';
import { notesLengthValidator, validateWhen } from './shared';
import { intl } from '../Internationalization';
import { ScheduleEndCriteria } from '../types';

export const SCHEDULE_SETTINGS_VALIDATOR = new Schema({
  startDateTime: {
    required: true,
    type: 'date',
    validator: (_rule: InternalRuleItem, value: any, callback: (error?: string) => void) => {
      if (!value) {
        callback(
          intl.formatMessage({
            id: 'schedules.validator.startDateTime.missing',
            defaultMessage: 'Please provide a start date',
          })
        );
      } else if (!isValid(value)) {
        callback(
          intl.formatMessage({
            id: 'schedules.validator.startDateTime.invalid',
            defaultMessage: 'Please provide a valid date',
          })
        );
      }
      callback();
    },
  },
  endDateTime: {
    type: 'date',
    validator: validateWhen(
      (settings: Partial<ScheduleSettings>) => {
        return settings.scheduleEndCriteria === ScheduleEndCriteria.END_DATE_TIME;
      },
      (
        _rule: InternalRuleItem,
        value: any,
        callback: (error?: string) => void,
        source: Partial<ScheduleSettings>
      ) => {
        if (!value) {
          callback(
            intl.formatMessage({
              id: 'schedules.validator.endDateTime.missing',
              defaultMessage: 'Please provide an end date',
            })
          );
        } else if (!isValid(value)) {
          callback(
            intl.formatMessage({
              id: 'schedules.validator.endDateTime.invalid',
              defaultMessage: 'Please provide a valid date',
            })
          );
        } else if (isPast(value)) {
          callback(
            intl.formatMessage({
              id: 'schedules.validator.endDateTime.isInPast',
              defaultMessage: 'End date must be in the future',
            })
          );
        } else if (source.startDateTime && isBefore(value, source.startDateTime)) {
          callback(
            intl.formatMessage({
              id: 'schedules.validator.endDateTime.isBeforeStart',
              defaultMessage: 'End date must be after the start date',
            })
          );
        }
        callback();
      }
    ),
  },
  numberOfOccurrences: {
    validator: validateWhen(
      (settings: Partial<ScheduleSettings>) => {
        return settings.scheduleEndCriteria === ScheduleEndCriteria.NUMBER_OF_OCCURRENCES;
      },
      (_rule: InternalRuleItem, value: any, callback: (error?: string) => void) => {
        if (!value || value < 1) {
          callback(
            intl.formatMessage({
              id: 'schedules.validator.occurrenceRequired',
              defaultMessage: 'Please provide an occurrences value greater than zero',
            })
          );
        }
        callback();
      }
    ),
  },
  note: notesLengthValidator,
});
