import { AxiosPromise } from 'axios';

import {
  CreateProjectRequest,
  ProjectDetail,
  ProjectsRequest,
  ProjectsResponse,
} from '../../types';

import { AXIOS, RequestConfig } from '../endpoints';

export function createProject(request: CreateProjectRequest): AxiosPromise<ProjectDetail> {
  return AXIOS.post('/projects', request);
}

export function getProjects(
  request: ProjectsRequest,
  config?: RequestConfig
): AxiosPromise<ProjectsResponse> {
  return AXIOS.get('/projects', {
    ...config,
    params: request,
  });
}
