import { AxiosPromise } from 'axios';

import {
  UsersResponse,
  AssignmentDetail,
  AssignmentPermissionsRequest,
  AssignmentSettings,
} from '../../types';
import { AXIOS, RequestConfig } from '../endpoints';

export function getAssignment(assignmentKey: string): AxiosPromise<AssignmentDetail> {
  return AXIOS.get(`/assignments/${assignmentKey}`);
}

export function updateAssignment(
  assignmentKey: string,
  request: AssignmentSettings
): AxiosPromise<AssignmentDetail> {
  return AXIOS.post(`/assignments/${assignmentKey}`, request);
}

export function getPermissions(
  assignmentKey: string,
  request: AssignmentPermissionsRequest,
  config?: RequestConfig
): AxiosPromise<UsersResponse> {
  return AXIOS.get(`/assignments/${assignmentKey}/permissions`, {
    ...config,
    params: request,
  });
}

export function addPermissions(
  assignmentKey: string,
  userKey: string
): AxiosPromise<UsersResponse> {
  return AXIOS.put(`/assignments/${assignmentKey}/permissions/${userKey}`);
}

export function removePermissions(
  assignmentKey: string,
  userKey: string
): AxiosPromise<UsersResponse> {
  return AXIOS.delete(`/assignments/${assignmentKey}/permissions/${userKey}`);
}
