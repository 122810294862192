import { useState, useEffect, FC } from 'react';

import { Line } from 'react-chartjs-2';

import AssessmentIcon from '@mui/icons-material/Assessment';

import { ChartPaper } from '../../../components';
import { GroupedDataSet, OffsetDateTimePercentage } from '../../../types';
import {
  extractSortedLabels,
  chartColors,
  limitLabelValueToTwoDecimals,
  ViewMode,
} from '../../../util';
import { intl } from '../../../Internationalization';

export const populateSubmissionQualityData = (
  submissionQuality: GroupedDataSet<string, OffsetDateTimePercentage>[]
) => {
  const colorScheme = chartColors(submissionQuality.length);
  const datasets = submissionQuality.map((assignment, index) => ({
    backgroundColor: colorScheme[index],
    borderColor: colorScheme[index],
    label: assignment.group,
    fill: false,
    lineTension: 0,
    borderWidth: 1,
    data: assignment.data.map(({ x, y }) => ({ x, y: y * 100 })),
  }));

  return {
    labels: extractSortedLabels(datasets),
    datasets,
  };
};

interface SubmissionQualityChartProps {
  submissionQuality: GroupedDataSet<string, OffsetDateTimePercentage>[];
  viewMode: ViewMode;
}

const SubmissionQualityChart: FC<SubmissionQualityChartProps> = ({
  submissionQuality,
  viewMode,
}) => {
  const [submissionQualityData, setSubmissionQualityData] = useState(
    populateSubmissionQualityData(submissionQuality)
  );

  useEffect(() => {
    setSubmissionQualityData(populateSubmissionQualityData(submissionQuality));
  }, [submissionQuality]);

  return (
    <ChartPaper
      icon={AssessmentIcon}
      iconColor="#66bb6a"
      title={intl.formatMessage({
        id: 'dashboard.specification.submissionQualityChart.title',
        defaultMessage: 'Conformance over time',
      })}
      columnMode={viewMode === 'COLUMN'}
      id="submission-quality-chart"
    >
      <Line
        data={submissionQualityData}
        options={{
          maintainAspectRatio: viewMode === 'COLUMN' ? false : true,
          plugins: {
            tooltip: {
              callbacks: {
                label: limitLabelValueToTwoDecimals,
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: intl.formatMessage({
                  id: 'dashboard.specification.submissionQualityChart.xAxis.label',
                  defaultMessage: 'Time',
                }),
              },
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'yyyy-MM-dd HH:mm',
                },
                tooltipFormat: 'yyyy-MM-dd HH:mm',
              },
            },
            y: {
              title: {
                display: true,
                text: intl.formatMessage({
                  id: 'dashboard.specification.submissionQualityChart.yAxis.label',
                  defaultMessage: 'Quality',
                }),
              },
              suggestedMin: 0,
              suggestedMax: 100,
              ticks: {
                autoSkip: false,
                stepSize: 10,
              },
            },
          },
        }}
      />
    </ChartPaper>
  );
};

export default SubmissionQualityChart;
