import { SvgIconProps } from '@mui/material';
import { ActionKind } from './specification';
import { intl } from '../Internationalization';
import {
  ErrorIcon,
  HourglassInfoIcon,
  SkippedInfoIcon,
  SubmissionRunningIcon,
  SuccessIcon,
} from '../components/icons';
import {
  AutorenewInfoSvg,
  CheckCircleSuccessSvg,
  ErrorSvg,
  HourglassEmptyInfoSvg,
  PdfIcon,
  ReplyInfoSvg,
} from '../components/pdf/PdfIcons';

export enum SubmissionActionOutcome {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface ActionStepResult {
  state: SubmissionActionState;
  inputKeys: string[];
}

export enum SubmissionActionState {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  SKIPPED = 'SKIPPED',
  SUCCEEDED = 'SUCCEEDED',
  FINISHED_WITH_ERROR = 'FINISHED_WITH_ERROR',
}

export interface SubmissionActionResult {
  outcome: SubmissionActionOutcome;

  kind: ActionKind;
  key: string;
  name: string;
  steps: ActionStepResult[];
}

export interface CopyInputsSubmissionActionResult extends SubmissionActionResult {
  kind: ActionKind.COPY_INPUTS;
  steps: CopyInputsActionStepResult[];
}

export interface CopyInputsActionStepResult extends ActionStepResult {
  inputDestination?: string;
}

export interface DebugSubmissionActionResult extends SubmissionActionResult {
  kind: ActionKind.DEBUG;
}

export interface SubmissionActionOutcomeMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
  pdfIcon: PdfIcon;
}
export const SUBMISSION_ACTION_OUTCOME_METADATA: {
  [type in SubmissionActionOutcome]: SubmissionActionOutcomeMetadata;
} = {
  SUCCESS: {
    label: intl.formatMessage({
      id: 'submission.action.outcomeMetadata.success.label',
      defaultMessage: 'Success',
    }),
    icon: SuccessIcon,
    pdfIcon: CheckCircleSuccessSvg,
  },
  FAILURE: {
    icon: ErrorIcon,
    label: intl.formatMessage({
      id: 'submission.action.outcomeMetadata.failure.label',
      defaultMessage: 'Failure',
    }),
    pdfIcon: ErrorSvg,
  },
};

export type SubmissionActionResults =
  | CopyInputsSubmissionActionResult
  | DebugSubmissionActionResult;

export interface SubmissionActionStateMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
  pdfIcon: PdfIcon;
}
export const SUBMISSION_ACTION_STATE_METADATA: {
  [type in SubmissionActionState]: SubmissionActionStateMetadata;
} = {
  NOT_STARTED: {
    label: intl.formatMessage({
      id: 'submission.action.stateMetadata.notStarted.label',
      defaultMessage: 'Not started',
    }),
    icon: HourglassInfoIcon,
    pdfIcon: HourglassEmptyInfoSvg,
  },
  RUNNING: {
    label: intl.formatMessage({
      id: 'submission.action.stateMetadata.running.label',
      defaultMessage: 'Running',
    }),
    icon: SubmissionRunningIcon,
    pdfIcon: AutorenewInfoSvg,
  },
  SKIPPED: {
    label: intl.formatMessage({
      id: 'submission.action.stateMetadata.skipped.label',
      defaultMessage: 'Skipped',
    }),
    icon: SkippedInfoIcon,
    pdfIcon: ReplyInfoSvg,
  },
  SUCCEEDED: {
    label: intl.formatMessage({
      id: 'submission.action.stateMetadata.succeeded.label',
      defaultMessage: 'Successful',
    }),
    icon: SuccessIcon,
    pdfIcon: CheckCircleSuccessSvg,
  },

  FINISHED_WITH_ERROR: {
    icon: ErrorIcon,
    label: intl.formatMessage({
      id: 'submission.action.stateMetadata.errored.label',
      defaultMessage: 'Finished with errors',
    }),
    pdfIcon: ErrorSvg,
  },
};
