import { FC, useContext, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { ValidateFieldsError } from 'async-validator';
import { FormattedMessage } from 'react-intl';

import * as SpecificationFieldsApi from '../../../../../../api/specification/specificationFields';
import { intl } from '../../../../../../Internationalization';
import {
  DefaultButton,
  PaddedDialogActions,
  ValidatedTextField,
} from '../../../../../../components';
import {
  CreateSpecificationFieldRequest,
  FIELD_TYPES,
  FIELD_TYPES_METADATA,
  FieldType,
  SpecificationFieldDetail,
} from '../../../../../../types';
import { newSpecificationFieldConfigValidator, validate } from '../../../../../../validation';
import { SpecificationContext } from '../../SpecificationContext';

const INITIAL_FIELD: CreateSpecificationFieldRequest = {
  description: '',
  type: FieldType.STRING,
  name: '',
};

interface NewSpecificationFieldConfig extends CreateSpecificationFieldRequest {
  name: string;
}

interface AddFieldDialogProps {
  dialogOpen: boolean;
  disabled: boolean;
  onConfirm: (fieldConfig: SpecificationFieldDetail) => void;
  onCloseDialog: () => void;
}

const AddFieldDialog: FC<AddFieldDialogProps> = ({
  dialogOpen,
  disabled,
  onConfirm,
  onCloseDialog,
}) => {
  const { specificationKey } = useContext(SpecificationContext);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [field, setField] = useState<Partial<NewSpecificationFieldConfig>>(INITIAL_FIELD);

  const handleClose = () => {
    onCloseDialog();
    setField(INITIAL_FIELD);
    setFieldErrors(undefined);
  };

  const validateAndAddField = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      createFieldConfig(
        await validate(newSpecificationFieldConfigValidator(specificationKey), field, {
          firstFields: true,
        })
      );
      handleClose();
    } catch (errors: any) {
      setFieldErrors(errors);
    } finally {
      setProcessing(false);
    }
  };

  const createFieldConfig = async (validatedConfig: NewSpecificationFieldConfig) => {
    try {
      const response = await SpecificationFieldsApi.createSpecificationField(
        specificationKey,
        validatedConfig.name,
        validatedConfig
      );
      onConfirm(response.data);
      handleClose();
    } catch (errors: any) {
      setFieldErrors(errors);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Dialog id="add-field-dialog" open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="specification.configuration.fieldConfig.fieldDialog.title"
          defaultMessage="Add Field"
        />
      </DialogTitle>
      <DialogContent>
        <ValidatedTextField
          name="name"
          label={intl.formatMessage({
            id: 'specification.configuration.fieldConfig.fieldDialog.name.label',
            defaultMessage: 'Name',
          })}
          fullWidth
          value={field.name}
          onChange={(event) => {
            setField((prev) => ({ ...prev, name: event.target.value }));
          }}
          variant="outlined"
          fieldErrors={fieldErrors}
          disabled={processing || disabled}
          margin="normal"
        />
        <ValidatedTextField
          name="description"
          label={intl.formatMessage({
            id: 'specification.configuration.fieldConfig.fieldDialog.description.label',
            defaultMessage: 'Description',
          })}
          fullWidth
          value={field.description}
          onChange={(event) => {
            setField((prev) => ({ ...prev, description: event.target.value }));
          }}
          variant="outlined"
          fieldErrors={fieldErrors}
          disabled={processing || disabled}
          margin="normal"
        />
        <ValidatedTextField
          name="type"
          label={intl.formatMessage({
            id: 'specification.configuration.fieldConfig.fieldDialog.type.label',
            defaultMessage: 'Type',
          })}
          fullWidth
          value={field.type}
          select
          onChange={(event) => {
            setField((prev) => ({ ...prev, type: event.target.value as FieldType }));
          }}
          variant="outlined"
          fieldErrors={fieldErrors}
          disabled={processing || disabled}
          margin="normal"
        >
          {FIELD_TYPES.map((fieldType) => (
            <MenuItem key={fieldType} value={fieldType}>
              {FIELD_TYPES_METADATA[fieldType].label}
            </MenuItem>
          ))}
        </ValidatedTextField>
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancel"
          disabled={processing || disabled}
          onClick={handleClose}
          color="secondary"
        >
          <FormattedMessage
            id="specification.configuration.fieldConfig.fieldDialog.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton
          name="confirm"
          disabled={processing || disabled}
          onClick={validateAndAddField}
        >
          <FormattedMessage
            id="specification.configuration.fieldConfig.fieldDialog.createButton"
            defaultMessage="Add Field"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default AddFieldDialog;
