import Schema from 'async-validator';

import { absoluteHttpUriValidator } from './shared';
import { intl } from '../Internationalization';

export const SAML2_IDENTITY_PROVIDER_SERVICE_SETTINGS_VALIDATOR = new Schema({
  entityId: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'saml2.validator.entityId.required',
        defaultMessage: 'Please provide an entity ID',
      }),
    },
  ],
  ssoServiceLocation: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'saml2.validator.ssoServiceLocation.required',
        defaultMessage: 'Please provide an SSO service location',
      }),
    },
    absoluteHttpUriValidator,
  ],
});
