import { FC } from 'react';
import { WarningIcon } from '../../../components/icons';
import { intl } from '../../../Internationalization';

interface ConfigurationInvalidProps {
  invalid?: boolean;
}

export const ConfigurationInvalid: FC<ConfigurationInvalidProps> = ({ invalid }) => {
  if (invalid) {
    return (
      <WarningIcon
        titleAccess={intl.formatMessage({
          id: 'components.configurationInvalid.titleAccess',
          defaultMessage: 'Configuration invalid',
        })}
      />
    );
  }

  return null;
};

export default ConfigurationInvalid;
