import Schema from 'async-validator';
import { absoluteHttpUriValidator } from './shared';
import { intl } from '../Internationalization';

export const OAUTH_IDENTITY_PROVIDER_SERVICE_SETTINGS_VALIDATOR = new Schema({
  authenticationMethod: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.authenticationMethod.required',
      defaultMessage: 'Please select an authentication method',
    }),
  },
  clientId: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.clientId.required',
      defaultMessage: 'Please provide a client ID',
    }),
  },
  clientSecret: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.clientSecret.required',
      defaultMessage: 'Please provide a client secret',
    }),
  },
  authorizationUri: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'oAuth2.validator.authorizationUri.required',
        defaultMessage: 'Please provide an authorization URI',
      }),
    },
    absoluteHttpUriValidator,
  ],
  tokenUri: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'oAuth2.validator.tokenUri.required',
        defaultMessage: 'Please provide a token URI',
      }),
    },
    absoluteHttpUriValidator,
  ],
  jwkSetUri: absoluteHttpUriValidator,
  userInfoUri: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'oAuth2.validator.userInfoUri.required',
        defaultMessage: 'Please provide a user info URI',
      }),
    },
    absoluteHttpUriValidator,
  ],
  userNameAttributeName: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.userNameAttributeName.required',
      defaultMessage: 'Please provide a user name attribute name',
    }),
  },
  userInfoAuthenticationMethod: {
    required: true,
    message: intl.formatMessage({
      id: 'oAuth2.validator.userInfoAuthenticationMethod.required',
      defaultMessage: 'Please select a user info authentication method',
    }),
  },
});
