import { useState, useContext, useEffect, FC, useCallback } from 'react';
import { startOfDay, sub } from 'date-fns';

import { FilterBar, FilterContainer, FilterToggle, MessageBox } from '../../../../components';
import * as AssignmentStatsApi from '../../../../api/assignment/assignmentStats';
import { extractErrorMessage } from '../../../../api/endpoints';
import {
  ValuePercentage,
  GroupedDataSet,
  DateCount,
  OffsetDateTimePercentage,
  PeriodStatsRequest,
  CompleteDateRange,
} from '../../../../types';
import { toCompleteOffsetDateTimeRange, getCurrentTimeZone, ViewMode } from '../../../../util';

import { useErrorBlock } from '../../../../contexts/error-block';
import { intl } from '../../../../Internationalization';
import FilterCompleteDateRange from '../../../../components/browse-table/FilterCompleteDateRange';

import SpecificationCharts from '../../../dashboard/specification/SpecificationCharts';
import ChartsSkeleton from '../../../dashboard/ChartsSkeleton';

import { MyAssignmentContext } from '../MyAssignmentContext';
import DashboardViewMode from '../../../dashboard/DashboardViewMode';
import { useTitle } from '../../../../hooks';

const createRequest = (
  dateRange: CompleteDateRange,
  includeRejected: boolean
): PeriodStatsRequest => ({
  period: toCompleteOffsetDateTimeRange(dateRange),
  timeZone: getCurrentTimeZone(),
  includeRejected,
});

const endDate = startOfDay(new Date());
const MyDashboard: FC = () => {
  const { assignmentKey } = useContext(MyAssignmentContext);
  useTitle(
    intl.formatMessage({ id: 'title.assignmentDashboard', defaultMessage: 'Assignment Dashboard' })
  );
  const { raiseError } = useErrorBlock();

  const [viewMode, setViewMode] = useState<ViewMode>('GRID');
  const [dateRange, setDateRange] = useState<CompleteDateRange>({
    start: sub(endDate, { months: 1 }),
    end: endDate,
  });
  const [ruleConformance, setRuleConformance] = useState<ValuePercentage[]>();
  const [submissionCounts, setSubmissionCounts] = useState<GroupedDataSet<string, DateCount>[]>();
  const [submissionQuality, setSubmissionQuality] =
    useState<GroupedDataSet<string, OffsetDateTimePercentage>[]>();

  const [includeRejected, setIncludeRejected] = useState<boolean>(false);

  const fetchTopRulesStats = useCallback(() => {
    AssignmentStatsApi.ruleConformance(assignmentKey, createRequest(dateRange, includeRejected))
      .then((response) => {
        setRuleConformance(response.data);
      })
      .catch((error) => {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'myAssignment.dashboard.fetchTopRulesStatsError',
              defaultMessage: 'Failed to fetch top rules stats',
            })
          )
        );
      });
  }, [assignmentKey, dateRange, includeRejected, raiseError]);

  const fetchSubmissionCountStats = useCallback(() => {
    AssignmentStatsApi.submissionCounts(assignmentKey, createRequest(dateRange, includeRejected))
      .then((response) => {
        setSubmissionCounts(response.data);
      })
      .catch((error) => {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'myAssignment.dashboard.fetchSubmissionCountStatsError',
              defaultMessage: 'Failed to fetch submission count stats',
            })
          )
        );
      });
  }, [assignmentKey, dateRange, includeRejected, raiseError]);

  const fetchSubmissionQualityStats = useCallback(() => {
    AssignmentStatsApi.submissionQuality(assignmentKey, createRequest(dateRange, includeRejected))
      .then((response) => {
        setSubmissionQuality(response.data);
      })
      .catch((error) => {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'myAssignment.dashboard.fetchSubmissionQualityStatsError',
              defaultMessage: 'Failed to fetch submission quality stats',
            })
          )
        );
      });
  }, [assignmentKey, dateRange, includeRejected, raiseError]);

  useEffect(() => {
    const { start, end } = dateRange;

    if (start && end) {
      fetchTopRulesStats();
      fetchSubmissionCountStats();
      fetchSubmissionQualityStats();
    }
  }, [dateRange, fetchTopRulesStats, fetchSubmissionCountStats, fetchSubmissionQualityStats]);

  const onIncludeRejectedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeRejected(event.target.checked);
  };

  const handleViewModeChange = (_: React.MouseEvent<HTMLElement>, newViewMode: ViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const renderContent = () => {
    if (!ruleConformance || !submissionCounts || !submissionQuality) {
      return <ChartsSkeleton />;
    }

    if (!ruleConformance.length && !submissionCounts.length && !submissionQuality.length) {
      return (
        <MessageBox
          message={intl.formatMessage({
            id: 'myAssignment.dashboard.noData',
            defaultMessage:
              'There is no data for your selected date period. Please try to refine your search to try again.',
          })}
          level="info"
        />
      );
    }

    return (
      <SpecificationCharts
        viewMode={viewMode}
        ruleConformance={ruleConformance}
        submissionCounts={submissionCounts}
        submissionQuality={submissionQuality}
      />
    );
  };

  return (
    <div id="my-assignment-dashboard">
      <FilterBar
        actions={
          <>
            <FilterToggle
              label={intl.formatMessage({
                id: 'myAssignment.dashboard.filterRejectedToggle.label',
                defaultMessage: 'Include Rejected',
              })}
              name="includeRejected"
              checked={includeRejected}
              onChange={onIncludeRejectedChange}
            />
            <DashboardViewMode viewMode={viewMode} onChange={handleViewModeChange} sx={{ mr: 1 }} />
          </>
        }
        barFilters={
          <FilterContainer>
            <FilterCompleteDateRange
              range={dateRange}
              onRangeUpdated={setDateRange}
              rangeLimit={{ years: 1 }}
            />
          </FilterContainer>
        }
      />
      {renderContent()}
    </div>
  );
};

export default MyDashboard;
