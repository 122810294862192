import { FC, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { ValidateFieldsError } from 'async-validator';
import { FormattedMessage } from 'react-intl';

import { intl } from '../../../../../../Internationalization';
import {
  DefaultButton,
  PaddedDialogActions,
  ValidatedTextField,
} from '../../../../../../components';
import { SpecificationInputDetail } from '../../../../../../types';
import { newSpecificationInputConfigValidator, validate } from '../../../../../../validation';

const INITIAL_INPUT = {
  name: '',
  required: false,
  linkedDataStores: [],
};

interface AddInputDialogProps {
  dialogOpen: boolean;
  disabled: boolean;
  onConfirm: (input: SpecificationInputDetail) => void;
  onCloseDialog: () => void;
}

const AddInputDialog: FC<AddInputDialogProps> = ({
  dialogOpen,
  disabled,
  onConfirm,
  onCloseDialog,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [input, setInput] = useState<Partial<SpecificationInputDetail>>(INITIAL_INPUT);

  const handleClose = () => {
    onCloseDialog();
    setInput(INITIAL_INPUT);
    setFieldErrors(undefined);
  };

  const validateAndAddInput = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      onConfirm(await validate(newSpecificationInputConfigValidator(), input));
      handleClose();
    } catch (errors: any) {
      setFieldErrors(errors);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Dialog id="add-input-dialog" open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="specification.configuration.inputConfig.inputDialog.title"
          defaultMessage="Add Input"
        />
      </DialogTitle>
      <DialogContent>
        <ValidatedTextField
          name="name"
          label={intl.formatMessage({
            id: 'specification.configuration.inputConfig.inputDialog.name.label',
            defaultMessage: 'Name',
          })}
          fullWidth
          value={input.name}
          onChange={(event) => {
            setInput((prev) => ({ ...prev, name: event.target.value }));
          }}
          variant="standard"
          fieldErrors={fieldErrors}
          disabled={processing || disabled}
          margin="normal"
        />
        <FormControlLabel
          sx={{ mt: 1 }}
          control={
            <Checkbox
              checked={input.required}
              onChange={(event) => {
                setInput((prev) => ({ ...prev, required: event.target.checked }));
              }}
            />
          }
          label={
            <Typography>
              <FormattedMessage
                id="specification.configuration.inputConfig.inputDialog.required.label"
                defaultMessage="Required"
              />
            </Typography>
          }
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancel"
          disabled={processing || disabled}
          onClick={handleClose}
          color="secondary"
        >
          <FormattedMessage
            id="specification.configuration.inputConfig.inputDialog.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton
          name="confirm"
          disabled={processing || disabled}
          onClick={validateAndAddInput}
        >
          <FormattedMessage
            id="specification.configuration.inputConfig.inputDialog.createButton"
            defaultMessage="Create Input"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default AddInputDialog;
