import Schema, { RuleItem } from 'async-validator';
import {
  NOT_BLANK_REGEX,
  duplicateValidator,
  slugKeyValidator,
  notTrimmableValidator,
  nameLengthValidator,
} from './shared';
import { intl } from '../Internationalization';
import { supplierByName } from '../api/supplier/supplierLookup';
import { supplierUniqueByKey } from '../api/supplier/supplier';
import * as SupplierMappingLookupApi from '../api/supplier/supplierMappingLookup';

function supplierNameValidator(getExistingName?: () => string): RuleItem[] {
  return [
    {
      required: true,
      pattern: NOT_BLANK_REGEX,
      message: intl.formatMessage({
        id: 'supplier.validator.name.required',
        defaultMessage: 'Please provide a name',
      }),
    },
    nameLengthValidator,
    notTrimmableValidator,
    duplicateValidator({
      regex: NOT_BLANK_REGEX,
      existingValue: getExistingName,
      checkUnique: supplierByName,
      alreadyExistsMessage: intl.formatMessage({
        id: 'supplier.validator.name.unique',
        defaultMessage: 'A supplier with this name already exists',
      }),
      errorMessage: intl.formatMessage({
        id: 'supplier.validator.name.checkUniqueError',
        defaultMessage: 'There was a problem verifying the supplier name',
      }),
    }),
  ];
}

export function supplierSettingsValidator(getExistingName?: () => string): Schema {
  return new Schema({
    name: supplierNameValidator(getExistingName),
  });
}

export function createSupplierRequestValidator(): Schema {
  return new Schema({
    name: supplierNameValidator(),
    key: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'supplier.validator.key.required',
          defaultMessage: 'Please provide a key',
        }),
      },
      slugKeyValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: supplierUniqueByKey,
        alreadyExistsMessage: intl.formatMessage({
          id: 'supplier.validator.key.unique',
          defaultMessage: 'A supplier with this key already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'supplier.validator.key.checkUniqueError',
          defaultMessage: 'There was a problem verifying the supplier key',
        }),
      }),
    ],
  });
}

export const supplierMappingValidator = (
  getIdentityProviderKey: () => string,
  getSupplierKey: () => string
): Schema => {
  return new Schema({
    group: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'supplierMapping.validator.group.required',
          defaultMessage: 'Please provide a group identifier for this mapping',
        }),
      },
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: (group) =>
          SupplierMappingLookupApi.supplierMappingBySupplierKeyAndGroup(
            getIdentityProviderKey(),
            getSupplierKey(),
            group
          ),
        alreadyExistsMessage: intl.formatMessage({
          id: 'supplierMapping.validator.group.unique',
          defaultMessage: 'A mapping with this group identifier already exists for this supplier',
        }),
        errorMessage: intl.formatMessage({
          id: 'supplierMapping.validator.group.checkUniqueError',
          defaultMessage: 'There was a problem verifying the supplier mapping',
        }),
      }),
    ],
    supplierKey: {
      required: true,
      pattern: NOT_BLANK_REGEX,
      message: intl.formatMessage({
        id: 'supplierMapping.validator.supplierKey.required',
        defaultMessage: 'Please select a supplier',
      }),
    },
  });
};
