import { FC } from 'react';

import { ExpressionTemplateDialog } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';
import { UxDefaultDataStoreIcon } from '../../../../../../components/icons';
import { SPECIFICATION_TEXT_FIELD_GROUPS } from '../../../../../../util';
import { ValidateFieldsError } from 'async-validator';

const targetPathFieldGroup = {
  icon: UxDefaultDataStoreIcon,
  label: intl.formatMessage({
    id: 'specification.configuration.actionConfig.targetPath.fieldGroup.dataStore',
    defaultMessage: 'Input',
  }),
  textFields: [
    {
      expression: 'inputName',
      label: intl.formatMessage({
        id: 'specification.configuration.actionConfig.targetPath.field.inputName',
        defaultMessage: 'Input Name',
      }),
      icon: UxDefaultDataStoreIcon,
    },
    {
      expression: 'inputFilename',
      label: intl.formatMessage({
        id: 'specification.configuration.actionConfig.targetPath.field.inputFilename',
        defaultMessage: 'Input Filename',
      }),
      icon: UxDefaultDataStoreIcon,
    },
    {
      expression: 'inputFileExtension',
      label: intl.formatMessage({
        id: 'specification.configuration.actionConfig.targetPath.field.fileExtension',
        defaultMessage: 'File Extension',
      }),
      icon: UxDefaultDataStoreIcon,
    },
  ],
};

const dataStoreConfigFieldGroups = [...SPECIFICATION_TEXT_FIELD_GROUPS, targetPathFieldGroup];

interface TargetPathProps {
  targetPath: string;
  handleTargetPathUpdated: (targetPath: string) => void;
  disabled: boolean;
  fieldErrors?: ValidateFieldsError;
  fieldName: string;
}

const TargetPath: FC<TargetPathProps> = ({
  targetPath,
  handleTargetPathUpdated,
  disabled,
  fieldErrors,
  fieldName,
}) => {
  return (
    <ExpressionTemplateDialog
      id="edit-target-path-dialog"
      fieldName={fieldName}
      fieldErrors={fieldErrors}
      label={intl.formatMessage({
        id: 'specification.configuration.actionConfig.targetPath.label',
        defaultMessage: 'Target Path',
      })}
      onConfirm={handleTargetPathUpdated}
      textFieldGroups={dataStoreConfigFieldGroups}
      expressionTemplate={targetPath}
      disabled={disabled}
    />
  );
};

export default TargetPath;
