import { FC } from 'react';

import * as IdentityProvidersApi from '../../api/identity-provider/identityProviders';
import { IdentityProviderMetadata } from '../../types';
import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from './FilteredAutocomplete';

type IdentityProvidersAutocompleteProps = BasicFilteredAutocompleteProps<
  IdentityProviderMetadata,
  false
>;

const fetchOptions = async (filter: string) => {
  const response = await IdentityProvidersApi.getIdentityProviders({ page: 0, size: 100, filter });
  return response.data.results;
};

const renderOption = (identityProvider: IdentityProviderMetadata) => identityProvider.name;
const getOptionSelected = (option: IdentityProviderMetadata, value: IdentityProviderMetadata) =>
  option.key === value.key;
const getOptionLabel = (identityProvider: IdentityProviderMetadata) => identityProvider.name;

const IdentityProvidersAutocomplete: FC<IdentityProvidersAutocompleteProps> = (props) => (
  <FilteredAutocomplete
    {...props}
    fetchOptions={fetchOptions}
    renderOption={renderOption}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getOptionLabel}
  />
);

export default IdentityProvidersAutocomplete;
