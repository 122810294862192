import { AxiosPromise } from 'axios';
import { AXIOS, RequestConfig, entityDoesNotExist } from '../endpoints';
import {
  SpecificationFieldDetail,
  CreateSpecificationFieldRequest,
  SpecificationFieldConfig,
} from '../../types';

export function fetchSpecificationFields(
  specificationKey: string,
  config?: RequestConfig
): AxiosPromise<SpecificationFieldDetail[]> {
  return AXIOS.get(`/specifications/${specificationKey}/fields`, {
    ...config,
  });
}

export function createSpecificationField(
  specificationKey: string,
  fieldName: string,
  request: CreateSpecificationFieldRequest
): AxiosPromise<SpecificationFieldDetail> {
  return AXIOS.post(`/specifications/${specificationKey}/fields/${fieldName}`, request);
}

export function fetchSpecificationField(
  specificationKey: string,
  fieldName: string,
  config?: RequestConfig
): AxiosPromise<SpecificationFieldDetail> {
  return AXIOS.get(`/specifications/${specificationKey}/fields/${fieldName}`, {
    ...config,
  });
}

export function lookupSpecificationFieldByName(
  specificationKey: string,
  fieldName: string
): Promise<boolean> {
  return entityDoesNotExist(AXIOS.head(`/specifications/${specificationKey}/fields/${fieldName}`));
}

export function updateSpecificationFields(
  specificationKey: string,
  request: SpecificationFieldConfig[]
): AxiosPromise<SpecificationFieldDetail[]> {
  return AXIOS.post(`/specifications/${specificationKey}/fields`, request);
}

export function deleteSpecificationField(
  specificationKey: string,
  fieldName: string
): AxiosPromise<void> {
  return AXIOS.delete(`/specifications/${specificationKey}/fields/${fieldName}`);
}
