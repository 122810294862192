import { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';

import * as UserMediaApi from '../../../../../api/user/userMedia';
import { UserProfile } from '../../../../../types';
import { extractInitials } from '../../../../../util';
import { resultsPdfStyles } from '../../../../../components';

import { SubmissionAvatar } from './SubmissionAvatar';

interface SubmissionAuthorAvatarProps {
  user: UserProfile;
  gutterBottom?: boolean;
}

export const SubmissionAuthorAvatar: FC<SubmissionAuthorAvatarProps> = ({ user, gutterBottom }) => {
  const imageUri = user.avatar.image && UserMediaApi.downloadImageUri(user.key, user.avatar.image);
  return (
    <View
      style={[resultsPdfStyles.rowContainer, gutterBottom ? resultsPdfStyles.gutterBottom : {}]}
    >
      <SubmissionAvatar
        initials={extractInitials(user.name)}
        backgroundColor={user.avatar.color}
        imageSrc={imageUri}
        objectFit="cover"
      />
      <View>
        <Text style={[resultsPdfStyles.submissionSummaryInfo]}>{user.name}</Text>
        <Text style={[resultsPdfStyles.submissionSummaryInfo, { fontSize: 10 }]}>{user.email}</Text>
      </View>
    </View>
  );
};
