import { FC, Fragment } from 'react';
import { TableCell, TableCellProps, Tooltip, useTheme, Box } from '@mui/material';
import ErrorIcon from '../../../components/icons/ErrorIcon';

interface ValidatedCellProps extends TableCellProps {
  fieldErrors?: string[];
}

const ValidatedCell: FC<ValidatedCellProps> = ({ fieldErrors, children, ...rest }) => {
  const { palette } = useTheme();
  const hasErrors = fieldErrors && fieldErrors.length;

  const renderTooltip = () => {
    if (hasErrors) {
      return (
        <Tooltip
          title={
            <Fragment>
              {fieldErrors?.map((error) => (
                <div key={error}>{error}</div>
              ))}
            </Fragment>
          }
        >
          <Box color={palette.error.main} display="flex" alignItems="center">
            <ErrorIcon fontSize="small" titleAccess={fieldErrors.join(', ')} />
            <Box ml={0.5}>{children}</Box>
          </Box>
        </Tooltip>
      );
    }
    return children;
  };

  return <TableCell {...rest}>{renderTooltip()}</TableCell>;
};

export default ValidatedCell;
