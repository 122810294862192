import React, { FC, useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Container, TableCell, TableRow } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import * as SchedulesApi from '../../api/schedule/schedules';
import {
  FilterPagination,
  MinWidthTableCell,
  ButtonRow,
  DefaultButton,
  FilterToggle,
  StyledTableHead,
  BrowseTable,
  browseTableBody,
  FilterBar,
  FilterContainer,
  ProjectAutocomplete,
  SpecificationAutocomplete,
  AssignmentAutocomplete,
  NamedAccountAvatar,
} from '../../components';
import { CrossIcon, TickIcon } from '../../components/icons';
import { useBrowseRequest } from '../../hooks';

import { intl } from '../../Internationalization';
import {
  REPEAT_METADATA,
  dateTimeFormat,
  durationFormat,
  parseCronToRepeatValue,
} from '../../util';
import {
  AssignmentDetail,
  ProjectDetail,
  ScheduleDetail,
  SchedulesRequest,
  SpecificationDetail,
} from '../../types';
import { RequestConfig } from '../../api/endpoints';
import { toScheduleUrl } from '../my-assignments/my-assignment/schedules/Schedules';
import { AuthenticatedContext } from '../../contexts/authentication';

const PAGE_SIZE = 10;
const SchedulesTableBody = browseTableBody<ScheduleDetail>();

interface ScheduledSubmissionsProps {
  userKey?: string;
}

const ScheduledSubmissions: FC<ScheduledSubmissionsProps> = ({ userKey }) => {
  const { me } = useContext(AuthenticatedContext);

  const [showDuration, setShowDuration] = useState<boolean>(false);
  const [project, setProject] = useState<ProjectDetail | null>(null);
  const [specification, setSpecification] = useState<SpecificationDetail | null>(null);
  const [assignment, setAssignment] = useState<AssignmentDetail | null>(null);

  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, ownerKey: userKey },
    onRequest: useCallback(
      (req: SchedulesRequest, config?: RequestConfig) => SchedulesApi.getSchedules(req, config),
      []
    ),
  });

  const updateShowDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDuration(event.target.checked);
  };

  const handleProjectChange = (selectedProject: ProjectDetail | null) => {
    setProject(selectedProject);
    setSpecification(null);
    setAssignment(null);
    updateRequest({
      projectKey: selectedProject?.key,
      specificationKey: undefined,
      assignmentKey: undefined,
    });
  };

  const handleSpecificationChange = (selectedSpecification: SpecificationDetail | null) => {
    setSpecification(selectedSpecification);
    setAssignment(null);
    updateRequest({ specificationKey: selectedSpecification?.key, assignmentKey: undefined });
  };

  const handleAssignmentChange = (selectedAssignment: AssignmentDetail | null) => {
    setAssignment(selectedAssignment);
    updateRequest({ assignmentKey: selectedAssignment?.key });
  };

  const renderScheduleRow = (schedule: ScheduleDetail) => (
    <TableRow key={schedule.key}>
      <TableCell>{schedule.assignment.specification.project.name}</TableCell>
      <TableCell>{schedule.assignment.specification.name}</TableCell>
      <TableCell>{schedule.assignment.reference}</TableCell>
      {!userKey && (
        <TableCell>
          <NamedAccountAvatar user={schedule.owner} />
        </TableCell>
      )}
      <TableCell>{dateTimeFormat(schedule.startDateTime)}</TableCell>
      <TableCell>{REPEAT_METADATA[parseCronToRepeatValue(schedule)].label}</TableCell>
      <TableCell>
        {schedule.active ? (
          <TickIcon
            titleAccess={intl.formatMessage({
              id: 'scheduledSubmissions.active',
              defaultMessage: 'Active',
            })}
          />
        ) : (
          <CrossIcon
            titleAccess={intl.formatMessage({
              id: 'scheduledSubmissions.inactive',
              defaultMessage: 'Inactive',
            })}
          />
        )}
      </TableCell>
      <TableCell>
        {schedule.nextOccurrences.at(0)
          ? showDuration
            ? durationFormat(schedule.nextOccurrences.at(0)!)
            : dateTimeFormat(schedule.nextOccurrences.at(0)!)
          : intl.formatMessage({
              id: 'scheduledSubmissions.noUpcomingSubmission',
              defaultMessage: 'No upcoming submission',
            })}
      </TableCell>
      <MinWidthTableCell>
        <ButtonRow whiteSpace="nowrap">
          <DefaultButton
            name="navigateToSchedule"
            color="grey"
            component={Link}
            to={toScheduleUrl(schedule)}
            disabled={!schedule.isEditable}
          >
            <EditIcon
              titleAccess={intl.formatMessage({
                id: 'scheduledSubmissions.navigateToSchedule.titleAccess',
                defaultMessage: 'Navigate to schedule',
              })}
            />
          </DefaultButton>
        </ButtonRow>
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <Container maxWidth="xl" id="system-activity-scheduled-submissions" disableGutters>
      <FilterBar
        barFilters={
          !!me.receiverPermissions && (
            <FilterContainer>
              <ProjectAutocomplete
                id="project-select"
                name="project"
                label={intl.formatMessage({
                  id: 'scheduledSubmissions.projectFilter.label',
                  defaultMessage: 'Project',
                })}
                multiple={false}
                value={project}
                onChange={handleProjectChange}
                variant="standard"
              />
              <SpecificationAutocomplete
                id="specification-select"
                name="specification"
                label={intl.formatMessage({
                  id: 'scheduledSubmissions.specificationFilter.label',
                  defaultMessage: 'Specification',
                })}
                value={specification}
                onChange={handleSpecificationChange}
                variant="standard"
                projectKey={project?.key}
              />
              <AssignmentAutocomplete
                id="assignment-select"
                name="assignment"
                label={intl.formatMessage({
                  id: 'scheduledSubmissions.assignmentFilter.label',
                  defaultMessage: 'Assignment',
                })}
                value={assignment}
                onChange={handleAssignmentChange}
                variant="standard"
                projectKey={project?.key}
                specificationKey={specification?.key}
              />
            </FilterContainer>
          )
        }
        actions={
          <>
            <FilterToggle
              label={intl.formatMessage({
                id: 'scheduledSubmissions.showTimeUntilNextSubmission',
                defaultMessage: 'Show time until next submission',
              })}
              name="showDuration"
              checked={showDuration}
              onChange={updateShowDuration}
              disabled={processing}
            />
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
          </>
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="scheduledSubmissions.table.projectColumn"
                defaultMessage="Project"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="scheduledSubmissions.table.specificationColumn"
                defaultMessage="Specification"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="scheduledSubmissions.table.assignmentColumn"
                defaultMessage="Assignment"
              />
            </TableCell>
            {!userKey && (
              <TableCell>
                <FormattedMessage
                  id="scheduledSubmissions.table.ownerColumn"
                  defaultMessage="Owner"
                />
              </TableCell>
            )}
            <TableCell>
              <FormattedMessage
                id="scheduledSubmissions.table.startColumn"
                defaultMessage="Start"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="scheduledSubmissions.table.repeatsColumn"
                defaultMessage="Repeats"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="scheduledSubmissions.table.activeColumn"
                defaultMessage="Active"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="scheduledSubmissions.table.nextSubmissionColumn"
                defaultMessage="Next Submission"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="scheduledSubmissions.table.actionsColumn"
                defaultMessage="Actions"
              />
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <SchedulesTableBody
          data={response?.results}
          mapToRow={renderScheduleRow}
          noDataMessage={intl.formatMessage({
            id: 'scheduledSubmissions.table.noSchedules',
            defaultMessage: 'No matching schedules.',
          })}
          numCols={8}
        />
      </BrowseTable>
    </Container>
  );
};

export default ScheduledSubmissions;
