import { FC, useCallback } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
  LineElement,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import {
  ProjectDetail,
  SpecificationDetail,
  AssignmentDetail,
  CompleteDateRange,
} from '../../types';
import { useDashboardState } from '../../contexts/global-state/Global';

import { DashboardContext } from './DashboardContext';
import DashboardRouting from './DashboardRouting';
import { intl } from '../../Internationalization';
import { useTitle } from '../../hooks';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

export interface DashboardState {
  globalFilters: GlobalFilters;
  specificationFilters: SpecificationFilters;
}

export interface GlobalFilters {
  dateRange: CompleteDateRange;
  includeRejected: boolean;
}

export interface SpecificationFilters {
  dateRange: CompleteDateRange;
  project: ProjectDetail | null;
  specification: SpecificationDetail | null;
  assignment: AssignmentDetail | null;
  includeRejected: boolean;
}

const Dashboard: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.dashboard',
      defaultMessage: 'Dashboard',
    })
  );
  const [dashboardState, setDashboardState] = useDashboardState();

  const updateSpecificationFilters = useCallback(
    (specificationFilters: SpecificationFilters) => {
      setDashboardState((prev) => ({ ...prev, specificationFilters }));
    },
    [setDashboardState]
  );

  const updateGlobalFilters = useCallback(
    (globalFilters: GlobalFilters) => {
      setDashboardState((prev) => ({ ...prev, globalFilters }));
    },
    [setDashboardState]
  );

  return (
    <DashboardContext.Provider
      value={{
        updateSpecificationFilters: updateSpecificationFilters,
        updateGlobalFilters: updateGlobalFilters,
        ...dashboardState,
      }}
    >
      <DashboardRouting />
    </DashboardContext.Provider>
  );
};

export default Dashboard;
