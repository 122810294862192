import { FC, useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';

import {
  AppDecorator,
  AppTabs,
  HeaderBreadcrumb,
  RouterTab,
  RouterTabs,
} from '../../../components';
import { intl } from '../../../Internationalization';
import { useRequiredParams, useRouterTab } from '../../../hooks';

import MyAssignmentHeader from './MyAssignmentHeader';
import { MyAssignmentContext } from './MyAssignmentContext';
import MyDashboard from './dashboard/MyDashboard';
import SpecificationInformation from './information/SpecificationInformation';
import MySubmissions from './submissions/MySubmissions';
import MySubmission from './submissions/submission/MySubmission';
import { SavedMappingsRouting } from './saved-mappings';
import { AssignmentDetail } from '../../../types';
import SchedulesRouting from './schedules/SchedulesRouting';

// Key is required on MyAssignment component to remount it when the assignment key changes
const CurrentMySubmission: FC = () => {
  const { submissionReference } = useRequiredParams<{ submissionReference: string }>();
  return <MySubmission key={submissionReference} submissionReference={submissionReference} />;
};

export const toMySubmissionsLink = (assignmentKey: string) => {
  return `/my_assignments/${assignmentKey}/submissions`;
};

export const renderAssignmentBreadcrumb = (assignment: AssignmentDetail) => (
  <HeaderBreadcrumb>
    <Typography>
      <AssignmentIcon
        titleAccess={intl.formatMessage({
          id: 'myAssignment.breadcrumb.assignment.titleAccess',
          defaultMessage: 'Assignment',
        })}
      />
      {assignment.reference}
    </Typography>
  </HeaderBreadcrumb>
);

const MyAssignmentRouting: FC = () => {
  const { routerTab, resolveTabRoute } = useRouterTab();
  const { assignment } = useContext(MyAssignmentContext);

  const fullView = !assignment.viewOnly;

  const renderTabs = () => (
    <RouterTabs id="my-assignments-tabs" value={routerTab}>
      <RouterTab
        id="submissions-tab"
        value="submissions"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.submissions',
          defaultMessage: 'Submissions',
        })}
        resolveTabRoute={resolveTabRoute}
        visible={fullView}
      />
      <RouterTab
        id="information-tab"
        value="information"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.information',
          defaultMessage: 'Information',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="dashboard-tab"
        value="dashboard"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.dashboard',
          defaultMessage: 'Dashboard',
        })}
        resolveTabRoute={resolveTabRoute}
        visible={fullView}
      />
      <RouterTab
        id="saved-mappings-tab"
        value="saved_mappings"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.savedMappings',
          defaultMessage: 'Saved Mappings',
        })}
        resolveTabRoute={resolveTabRoute}
        visible={fullView}
      />
      <RouterTab
        id="schedules-tab"
        value="schedules"
        label={intl.formatMessage({
          id: 'myAssignment.routing.tabs.schedules',
          defaultMessage: 'Schedules',
        })}
        resolveTabRoute={resolveTabRoute}
        visible={fullView}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator
      renderBreadcrumb={() => renderAssignmentBreadcrumb(assignment)}
      renderTabs={renderTabs}
    >
      <MyAssignmentHeader />
      <AppTabs />
      <Box p={3}>
        <Routes>
          {fullView && (
            <>
              <Route path="submissions" element={<MySubmissions />} />
              <Route path="submissions/:submissionReference/*" element={<CurrentMySubmission />} />
              <Route path="dashboard" element={<MyDashboard />} />
              <Route path="saved_mappings/*" element={<SavedMappingsRouting />} />
              <Route path="schedules/*" element={<SchedulesRouting />} />
            </>
          )}
          <Route path="information" element={<SpecificationInformation />} />
          <Route
            path="*"
            element={<Navigate to={fullView ? 'submissions' : 'information'} replace />}
          />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default MyAssignmentRouting;
