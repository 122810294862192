import { FC, Fragment, useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { TableRow, TableCell, Box, IconButton, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import * as SupplierApi from '../../../api/supplier/supplier';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  SupplierMemberAutocomplete,
  BrowseTable,
  browseTableBody,
  DefaultButton,
  FilterPagination,
  ConfirmDialog,
  FilterBar,
  NamedAccountAvatar,
  FilterSearch,
  StyledTableHead,
} from '../../../components';
import { useBrowseRequest, useTitle } from '../../../hooks';
import { intl } from '../../../Internationalization';
import { MembersRequest, UserSummary } from '../../../types';

import { SupplierContext } from './SupplierContext';
import MemberPermissions from './MemberPermissions';

const MembersTableBody = browseTableBody<UserSummary>();

const PAGE_SIZE = 10;

const Members: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { supplierKey, supplier } = useContext(SupplierContext);
  useTitle(
    intl.formatMessage(
      { id: 'title.supplier', defaultMessage: 'Supplier ({supplierName})' },
      { supplierName: supplier.name }
    )
  );
  const { request, response, processing, updateRequest, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, membership: true },
    onRequest: useCallback(
      (req: MembersRequest, config) => SupplierApi.getMembers(supplierKey, req, config),
      [supplierKey]
    ),
  });

  const [executing, setExecuting] = useState<boolean>(false);
  const [member, setMember] = useState<UserSummary | null>(null);
  const [removeMember, setRemoveMember] = useState<UserSummary>();
  const [activeMember, setActiveMember] = useState<UserSummary>();

  const handleFilterUpdate = useCallback(
    (filter: string) => updateRequest({ filter }),
    [updateRequest]
  );

  const disableInputs = executing || processing;

  const addMember = async () => {
    if (!member) {
      return;
    }
    setExecuting(true);
    try {
      await SupplierApi.grantSupplierMembership(supplierKey, member.key, { supervisor: false });
      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'supplier.members.addSuccess',
            defaultMessage: "Supplier membership for ''{name}'' successful",
          },
          { name: member.name }
        ),
        { variant: 'success' }
      );
      refresh();
      setMember(null);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'supplier.members.addError',
            defaultMessage: 'Failed to add member',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setExecuting(false);
    }
  };

  const handleRemoveMember = async () => {
    if (!removeMember) {
      return;
    }
    if (activeMember && removeMember.key === activeMember.key) {
      setActiveMember(undefined);
    }
    setExecuting(true);
    try {
      SupplierApi.revokeSupplierMembership(supplierKey, removeMember.key);
      enqueueSnackbar(
        intl.formatMessage(
          {
            id: 'supplier.members.removeSuccess',
            defaultMessage: "Supplier membership for ''{name}'' has been removed",
          },
          { name: removeMember.name }
        ),
        { variant: 'success' }
      );
      updateRequest();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'supplier.members.removeError',
            defaultMessage: 'Failed to remove member',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setExecuting(false);
      setRemoveMember(undefined);
    }
  };

  const onExpandClick = (user: UserSummary) => {
    setActiveMember((prevMember) => (prevMember && prevMember.key === user.key ? undefined : user));
  };

  const userRow = (user: UserSummary) => {
    const currentUserIsActiveMember = activeMember && activeMember.key === user.key;

    return (
      <Fragment key={user.key}>
        <TableRow>
          <TableCell align="left">
            <Box display="flex" alignItems="center">
              <NamedAccountAvatar user={user} showEmail />
            </Box>
          </TableCell>
          <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
            <IconButton
              name="removeMember"
              onClick={() => setRemoveMember(user)}
              disabled={disableInputs}
              size="large"
              aria-label={intl.formatMessage({
                id: 'supplier.members.removeMember.ariaLabel',
                defaultMessage: 'Remove member',
              })}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              name="expandMemberOptions"
              onClick={() => onExpandClick(user)}
              size="large"
              aria-label={
                currentUserIsActiveMember
                  ? intl.formatMessage({
                      id: 'supplier.members.collapseMemberOptions.ariaLabel',
                      defaultMessage: 'Collapse member options',
                    })
                  : intl.formatMessage({
                      id: 'supplier.members.expandMemberOptions.ariaLabel',
                      defaultMessage: 'Expand member options',
                    })
              }
            >
              <ExpandMoreIcon
                sx={(theme) => ({
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
                  transform: currentUserIsActiveMember ? 'rotate(180deg)' : 'rotate(0deg)',
                })}
              />
            </IconButton>
          </TableCell>
        </TableRow>
        {currentUserIsActiveMember && (
          <TableRow>
            <TableCell colSpan={2}>
              <MemberPermissions user={activeMember!} />
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    );
  };

  return (
    <Container maxWidth="md" id="supplier-members" disableGutters>
      <FilterBar
        startInput={
          <FilterSearch
            label={intl.formatMessage({
              id: 'supplier.members.filterSearch.placeholder',
              defaultMessage: 'Filter members…',
            })}
            onSearch={handleFilterUpdate}
          />
        }
        actions={
          <FilterPagination
            page={request.page}
            size={request.size}
            total={response?.total}
            disabled={disableInputs}
            setPage={setPage}
          />
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell align="left" sx={{ width: '100%' }}>
              <SupplierMemberAutocomplete
                supplierKey={supplierKey}
                id="member-select"
                name="member"
                label={intl.formatMessage({
                  id: 'supplier.members.member.label',
                  defaultMessage: 'Member',
                })}
                disabled={disableInputs}
                value={member}
                onChange={setMember}
                variant="standard"
              />
            </TableCell>
            <TableCell align="right">
              <DefaultButton
                name="addMemberButton"
                startIcon={<AddIcon />}
                color="grey"
                onClick={addMember}
                disabled={member === null || disableInputs}
              >
                <FormattedMessage id="supplier.members.addButton" defaultMessage="Add" />
              </DefaultButton>
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <MembersTableBody
          data={response && response.results}
          mapToRow={userRow}
          noDataMessage={intl.formatMessage({
            id: 'supplier.members.noMembers',
            defaultMessage: 'No matching members.',
          })}
          numCols={3}
        />
      </BrowseTable>
      <ConfirmDialog
        id="confirm-remove-member"
        title={intl.formatMessage({
          id: 'supplier.members.confirmRemoveMember.title',
          defaultMessage: 'Remove member',
        })}
        text={intl.formatMessage({
          id: 'supplier.members.confirmRemoveMember.text',
          defaultMessage:
            'Are you sure you wish to remove this member? They will be unassigned from all related assignments.',
        })}
        isOpen={!!removeMember}
        confirmBtnText={intl.formatMessage({
          id: 'supplier.members.confirmRemoveMember.confirmButton',
          defaultMessage: 'Remove Member',
        })}
        confirmAction={handleRemoveMember}
        closeAction={() => setRemoveMember(undefined)}
      />
    </Container>
  );
};

export default Members;
