import Schema from 'async-validator';
import {
  NOT_BLANK_REGEX,
  duplicateValidator,
  notTrimmableValidator,
  nameLengthValidator,
  descriptionLengthValidator,
  slugKeyValidator,
} from './shared';
import { intl } from '../Internationalization';
import { projectByName } from '../api/project/projectLookup';
import { AXIOS, entityDoesNotExist } from '../api/endpoints';

export function projectUniqueByKey(projectKey: string): Promise<boolean> {
  return entityDoesNotExist(AXIOS.head(`/projects/${projectKey}`));
}

export const projectNameValidator = (getExistingName?: () => string) => [
  {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'project.validator.name.required',
      defaultMessage: 'Please provide a name',
    }),
  },
  nameLengthValidator,
  notTrimmableValidator,
  duplicateValidator({
    regex: NOT_BLANK_REGEX,
    existingValue: getExistingName,
    checkUnique: projectByName,
    alreadyExistsMessage: intl.formatMessage({
      id: 'project.validator.name.unique',
      defaultMessage: 'A project with this name already exists',
    }),
    errorMessage: intl.formatMessage({
      id: 'project.validator.name.checkUniqueError',
      defaultMessage: 'There was a problem verifying the project name',
    }),
  }),
];

export const projectDescriptionValidator = [
  {
    required: true,
    message: intl.formatMessage({
      id: 'project.validator.description.required',
      defaultMessage: 'Please provide a description',
    }),
  },
  descriptionLengthValidator,
];

export function projectSettingsValidator(getExistingName?: () => string): Schema {
  return new Schema({
    name: projectNameValidator(getExistingName),
    description: projectDescriptionValidator,
  });
}

export function createProjectSettingsValidator(): Schema {
  return new Schema({
    name: projectNameValidator(),
    description: projectDescriptionValidator,
    key: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'project.validator.key.required',
          defaultMessage: 'Please provide a key',
        }),
      },
      slugKeyValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: projectUniqueByKey,
        alreadyExistsMessage: intl.formatMessage({
          id: 'project.validator.key.unique',
          defaultMessage: 'A project with this key already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'project.validator.key.checkUniqueError',
          defaultMessage: 'There was a problem verifying the project key',
        }),
      }),
    ],
  });
}
