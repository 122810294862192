import { AxiosPromise } from 'axios';

import { UserImportJobsResponse, UserImportJobDetail, UserImportJobsRequest } from '../../types';
import { AXIOS, FileUploadConfig } from '../endpoints';
import { createFormDataFromFile } from '../media';

export function fetchJobs(request: UserImportJobsRequest): AxiosPromise<UserImportJobsResponse> {
  return AXIOS.get('/user_import/', { params: request });
}

export function createJob(file: File, config: FileUploadConfig): AxiosPromise<UserImportJobDetail> {
  return AXIOS.post(`/user_import/`, createFormDataFromFile(file), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...(config || {}),
  });
}
