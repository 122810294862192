import { FC } from 'react';
import { SessionSchema, Task, TaskConfig } from '../../../../../../types';
import { TableRow, TableCell } from '@mui/material';
import TaskLabelCell from './TaskLabelCell';
import IncludeInResults from './IncludeInResults';

interface BaseTaskRowProps {
  task: Task;
  taskConfig: TaskConfig;
  sessionSchema: SessionSchema;
  updateTaskConfig: (taskConfig: TaskConfig) => void;
}

const BaseTaskRow: FC<BaseTaskRowProps> = ({
  task,
  taskConfig,
  sessionSchema,
  updateTaskConfig,
}) => {
  return (
    <TableRow key={taskConfig.taskIdentifier}>
      <TaskLabelCell task={task} sessionSchema={sessionSchema} />
      <TableCell colSpan={3}>
        <IncludeInResults taskConfig={taskConfig} updateTaskConfig={updateTaskConfig} />
      </TableCell>
    </TableRow>
  );
};

export default BaseTaskRow;
