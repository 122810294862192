import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Typography, TextField, Box } from '@mui/material';

import * as Saml2IdentityProviderApi from '../../../../api/identity-provider/saml2IdentityProvider';
import { PaddedPaper, Upload, MessageBox } from '../../../../components';
import { FileUploadConfig } from '../../../../api/endpoints';
import { intl } from '../../../../Internationalization';
import { useMediaUpload } from '../../../../hooks';
import { Saml2IdentityProviderDetail } from '../../../../types';

import { Saml2IdentityProviderContext } from './Saml2IdentityProviderContext';

const Saml2IdentityProviderSigning: FC = () => {
  const { identityProvider, identityProviderUpdated } = useContext(Saml2IdentityProviderContext);

  const [upload, uploading, uploadProgress] = useMediaUpload<Saml2IdentityProviderDetail>({
    onUpload: (files: File[], config: FileUploadConfig) =>
      Saml2IdentityProviderApi.updateIdpCertificate(identityProvider.key, files[0], config),
    onUploadComplete: (settings: any) => identityProviderUpdated(settings),
  });

  return (
    <Container maxWidth="md" id="system-identity-provider-saml2-identity-signing" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" component="h3" gutterBottom>
          <FormattedMessage
            id="saml2.identityProviderSigning.title"
            defaultMessage="Identity Provider Signing"
          />
        </Typography>
        <Box my={2}>
          {identityProvider.idpCertificate ? (
            <TextField
              rows={10}
              variant="outlined"
              fullWidth
              value={identityProvider.idpCertificate}
              multiline
              disabled
              label={intl.formatMessage({
                id: 'saml2.identityProviderSigning.identityProviderCertificate.label',
                defaultMessage: 'Identity Provider Certificate',
              })}
            />
          ) : (
            <MessageBox
              level="info"
              message={intl.formatMessage({
                id: 'saml2.identityProviderSigning.noCertificate',
                defaultMessage: 'No identity provider certificate, please upload one below',
              })}
            />
          )}
        </Box>
        <Upload
          accept=".pem, .txt"
          onDrop={upload}
          uploading={uploading}
          progress={uploadProgress}
        />
      </PaddedPaper>
    </Container>
  );
};

export default Saml2IdentityProviderSigning;
