import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Container, Grid, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import StopIcon from '@mui/icons-material/Stop';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import { extractErrorMessage } from '../../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../../api/specification/specification';
import * as SubmissionApi from '../../../../../../api/submission/submission';
import * as SubmissionMappingsApi from '../../../../../../api/submission/submissionMappings';

import {
  ConfirmDialog,
  FullWidthButton,
  Loading,
  MessageBox,
  PaddedPaper,
  MoreButton,
} from '../../../../../../components';
import { SubmissionErrorMessage } from '../../../../../components/submission/SubmissionErrorMessage';
import { AppBarStatsContext } from '../../../../../../contexts/app-bar-stats';
import { intl } from '../../../../../../Internationalization';
import {
  DataStoreClassMapping,
  DataStoreConfigDetail,
  DataStoreMapping,
  DataStoreSchema,
  SchemaMappingMode,
} from '../../../../../../types';

import {
  SchemaMappingContext,
  SchemaMapper,
  SchemaMappingValidationResults,
  MappingStatus,
  validateSchemaMappings,
  SchemaMappingDirection,
  isManualSchemaMappingSupported,
  mapDataStoreConfigs,
  areSchemaMappingInputsViewable,
} from '../../../../../components/schema-mapper';
import { useNavigationPrompt } from '../../../../../../contexts/navigation-prompt';
import { useTitle } from '../../../../../../hooks';

import { OpenSubmissionContext } from '../OpenSubmissionContext';
import ConfigureAndContinue from '../ConfigureAndContinue';

import DataStoreValidationMessages from './DataStoreValidationMessages';
import ExportSchema from './ExportSchema';
import ManageSchemaMapping from './ManageSchemaMapping';

const SchemaMapping: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.submissionSchemaMapping',
      defaultMessage: 'Submission Schema Mapping',
    })
  );
  const { enqueueSnackbar } = useSnackbar();

  const appBarStatsContext = useContext(AppBarStatsContext);
  const { submission, specification, submissionUpdated } = useContext(OpenSubmissionContext);
  const { blocking, raiseNavigationBlock, clearNavigationBlock } = useNavigationPrompt();

  const [reviewMapping, setReviewMapping] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataStoreConfigs, setDataStoreConfigs] = useState<Record<string, DataStoreConfigDetail>>();
  const [selectedDataStoreName, setSelectedDataStoreName] = useState<string>('');
  const [direction, setDirection] = useState<SchemaMappingDirection>(
    SchemaMappingDirection.TARGET_TO_SOURCE
  );
  const [sourceDataStores, setSourceDataStores] = useState<DataStoreSchema[]>();
  const [targetDataStores, setTargetDataStores] = useState<DataStoreSchema[]>();
  const [schemaMappings, setSchemaMappings] = useState<DataStoreMapping[]>();
  const [validationResults, setValidationResults] = useState<SchemaMappingValidationResults>();

  const [runningSubmission, setRunningSubmission] = useState<boolean>(false);
  const [applyingMapping, setApplyingMapping] = useState<boolean>(false);
  const [confirmContinue, setConfirmContinue] = useState<boolean>(false);
  const [confirmUnsavedChanges, setConfirmUnsavedChanges] = useState<boolean>(false);
  const [cancellingSubmission, setCancellingSubmission] = useState<boolean>(false);
  const [initialised, setInitialised] = useState<boolean>(false);
  const [openAutomationDialog, setOpenAutomationDialog] = useState<boolean>(false);

  const manualSchemaMappingSupported =
    dataStoreConfigs && isManualSchemaMappingSupported(dataStoreConfigs);
  const schemaMappingInputsViewable =
    dataStoreConfigs && areSchemaMappingInputsViewable(dataStoreConfigs);
  const displayReviewMappingButton =
    manualSchemaMappingSupported || (schemaMappingInputsViewable && !reviewMapping);

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const { data: submissionSchema } = await SubmissionApi.getSchema(submission.reference);
        const dataStoreConfigsData = (await SpecificationApi.getDataStoreConfigs(specification.key))
          .data;
        const dataStoreConfigsMapped = mapDataStoreConfigs(dataStoreConfigsData);
        const source = submissionSchema.source.filter((d) => d.importSchema);
        const target = submissionSchema.target.filter((d) => d.importSchema);
        setDataStoreConfigs(dataStoreConfigsMapped);
        setSelectedDataStoreName(source[0] && source[0].path);
        setSourceDataStores(source);
        setTargetDataStores(target);
        setSchemaMappings(submissionSchema.mappings);
        setReviewMapping(!source[0]);
      } catch (error: any) {
        setErrorMessage(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.schemaMapping.schemaMapper.loadError',
              defaultMessage: 'Failed to fetch submission schema',
            })
          )
        );
      } finally {
        setInitialised(true);
      }
    };
    fetchSchema();
  }, [specification.key, submission.reference]);

  const validateSchemaMapping = useCallback(() => {
    if (dataStoreConfigs && sourceDataStores && targetDataStores && schemaMappings) {
      const results = validateSchemaMappings(
        dataStoreConfigs,
        sourceDataStores,
        targetDataStores,
        schemaMappings,
        specification
      );
      setReviewMapping(
        (prev) =>
          prev ||
          results.source.status !== MappingStatus.OK ||
          results.target.status !== MappingStatus.OK
      );
      return results;
    }
  }, [dataStoreConfigs, sourceDataStores, targetDataStores, schemaMappings, specification]);

  useEffect(() => {
    setValidationResults(validateSchemaMapping());
  }, [validateSchemaMapping]);

  const updateClassMappings = (dataStoreClasses: DataStoreClassMapping[]) => {
    const newSchemaMappings = schemaMappings ? [...schemaMappings] : [];
    const index = newSchemaMappings.findIndex(({ path }) => path === selectedDataStoreName);
    newSchemaMappings[index].importMappings = dataStoreClasses;
    setSchemaMappings(newSchemaMappings);
    raiseNavigationBlock();
  };

  const runSubmission = async () => {
    setRunningSubmission(true);
    try {
      const { data: submissionData } = await SubmissionApi.run(submission.reference);
      submissionUpdated(submissionData);
    } catch (error: any) {
      setRunningSubmission(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.schemaMapping.schemaMapper.runError',
            defaultMessage: 'Failed to run submission',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const applyMapping = async (runAfterApply: boolean) => {
    if (!schemaMappings) {
      return;
    }
    setApplyingMapping(true);
    try {
      const editableSchemaMappings = schemaMappings.filter(
        (dataStoreMapping, index) =>
          dataStoreConfigs![dataStoreMapping.path].schemaMappingMode === SchemaMappingMode.MANUAL
      );
      const { data: dataStoreMapping } = await SubmissionMappingsApi.updateSchemaMappings(
        submission.reference,
        editableSchemaMappings
      );
      setSchemaMappings(dataStoreMapping);
      if (runAfterApply) {
        runSubmission();
      } else {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'openSubmission.schemaMapping.schemaMapper.applyMappingSuccess',
            defaultMessage: 'Schema mapping successfully applied',
          }),
          { variant: 'success' }
        );
      }
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.schemaMapping.schemaMapper.applyMappingError',
            defaultMessage: 'Failed to apply schema mapping',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setApplyingMapping(false);
    }
  };

  const onContinue = (targetMappingIncomplete?: boolean) => {
    if (
      specification.allowTargetUnmapped &&
      targetMappingIncomplete &&
      specification.showTargetUnmappedWarning
    ) {
      setConfirmContinue(true);
    } else {
      applyAndContinue();
    }
  };

  const apply = () => applyMapping(false);
  const applyAndContinue = () => applyMapping(true);

  const cancelCurrentSubmission = async () => {
    clearNavigationBlock();
    setCancellingSubmission(true);
    try {
      const { data: submissionData } = await SubmissionApi.cancel(submission.reference);
      submissionUpdated(submissionData);
      appBarStatsContext.refresh();
    } catch (error: any) {
      setCancellingSubmission(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.schemaMapping.schemaMapper.cancelError',
            defaultMessage: 'Failed to cancel submission',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const renderContent = () => {
    if (
      !dataStoreConfigs ||
      !sourceDataStores ||
      !targetDataStores ||
      !schemaMappings ||
      !validationResults
    ) {
      return {
        className: 'SchemaMapping-loading',
        content: (
          <Loading
            loadingText={intl.formatMessage({
              id: 'openSubmission.schemaMapping.schemaMapper.loading',
              defaultMessage: 'Fetching schema…',
            })}
          />
        ),
      };
    }

    if (!selectedDataStoreName) {
      return {
        className: 'SchemaMapping-noDataSets',
        content: (
          <Grid item xs={12}>
            <MessageBox
              level="info"
              message={intl.formatMessage({
                id: 'openSubmission.schemaMapping.schemaMapper.noDataSets',
                defaultMessage: 'There are no data sets to map',
              })}
            />
          </Grid>
        ),
      };
    }

    return {
      className: 'SchemaMapping-loaded',
      content: (
        <SchemaMappingContext.Provider
          value={{
            specification,
            dataStoreConfigs,
            selectedDataStoreName,
            handleSelectDataStoreName: setSelectedDataStoreName,
            direction,
            setDirection,
            sourceDataStores,
            targetDataStores,
            schemaMappings,
            updateSchemaMappings: setSchemaMappings,
            updateClassMappings,
            validationResults,
          }}
        >
          <SchemaMapper
            renderSecondaryAction={
              <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                  <ManageSchemaMapping
                    disabled={
                      !Object.values(dataStoreConfigs).find(
                        (config) => config.schemaMappingMode === SchemaMappingMode.MANUAL
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <ExportSchema
                    disabled={
                      !Object.values(dataStoreConfigs).find(
                        (config) =>
                          config.schemaMappingMode === SchemaMappingMode.MANUAL ||
                          config.schemaMappingMode === SchemaMappingMode.AUTOMATIC
                      )
                    }
                  />
                </Grid>
              </Grid>
            }
          />
        </SchemaMappingContext.Provider>
      ),
    };
  };

  const renderMapper = () => {
    if (errorMessage) {
      return {
        className: 'SchemaMapping-error',
        content: (
          <Container maxWidth="md">
            <SubmissionErrorMessage submission={submission} errorMessage={errorMessage} />
          </Container>
        ),
      };
    }

    if (reviewMapping || (!manualSchemaMappingSupported && !schemaMappingInputsViewable)) {
      const content = renderContent();
      return {
        className: content.className,
        content: (
          <PaddedPaper>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h3">
                  <FormattedMessage
                    id="openSubmission.schemaMapping.schemaMapper.schemaMapping"
                    defaultMessage="Schema Mapping"
                  />
                </Typography>
              </Grid>
              {content.content}
            </Grid>
          </PaddedPaper>
        ),
      };
    }
    return { className: 'SchemaMapping-loadedNoMapper', content: null };
  };

  const handleContinueAction = () => {
    if (blocking) {
      setOpenAutomationDialog(false);
      setConfirmUnsavedChanges(true);
    } else {
      onContinue(validationResults?.target.status !== MappingStatus.OK);
    }
  };

  const renderActions = () => {
    const processingAction = runningSubmission || applyingMapping || cancellingSubmission;
    const disableActions = !initialised || !!errorMessage || processingAction;
    const disableContinue =
      disableActions ||
      !validationResults ||
      validationResults.source.status === MappingStatus.ERROR ||
      validationResults.target.status === MappingStatus.ERROR;

    return (
      <>
        {selectedDataStoreName && !errorMessage && validationResults && (
          <DataStoreValidationMessages validationResults={validationResults} />
        )}
        <Grid item container spacing={3}>
          <Grid item xs={displayReviewMappingButton ? 4 : 6}>
            <FullWidthButton
              name="cancelSubmission"
              label={intl.formatMessage({
                id: 'openSubmission.schemaMapping.schemaMapper.cancelButton',
                defaultMessage: 'Cancel',
              })}
              disabled={processingAction || !submission.canUpdateSubmission}
              processing={cancellingSubmission}
              color="secondary"
              startIcon={<StopIcon />}
              onClick={cancelCurrentSubmission}
            />
          </Grid>
          {displayReviewMappingButton && (
            <Grid item xs={4}>
              {reviewMapping && manualSchemaMappingSupported ? (
                <FullWidthButton
                  id="apply-schema-mapping-submit"
                  label={intl.formatMessage({
                    id: 'openSubmission.schemaMapping.schemaMapper.applyButton',
                    defaultMessage: 'Apply',
                  })}
                  disabled={
                    disableActions || !selectedDataStoreName || !submission.canUpdateSubmission
                  }
                  processing={applyingMapping}
                  color="secondary"
                  startIcon={<CheckIcon />}
                  onClick={apply}
                />
              ) : (
                <FullWidthButton
                  name="reviewSchemaMapping"
                  label={intl.formatMessage({
                    id: 'openSubmission.schemaMapping.schemaMapper.reviewMappingButton',
                    defaultMessage: 'Review mapping',
                  })}
                  color="primary"
                  disabled={disableActions}
                  onClick={() => setReviewMapping(true)}
                  startIcon={<SyncAltIcon />}
                />
              )}
            </Grid>
          )}
          <Grid item xs={displayReviewMappingButton ? 4 : 6}>
            <MoreButton
              name="continueSubmission"
              moreName="automateSubmission"
              label={intl.formatMessage({
                id: 'openSubmission.schemaMapping.schemaMapper.continueButton',
                defaultMessage: 'Continue',
              })}
              disabled={disableContinue || !submission.canUpdateSubmission}
              processing={runningSubmission}
              color="primary"
              endIcon={<PlayArrowIcon />}
              onClick={handleContinueAction}
              onMore={() => setOpenAutomationDialog(true)}
            />
            <ConfigureAndContinue
              open={openAutomationDialog}
              onSubmit={handleContinueAction}
              onClose={() => setOpenAutomationDialog(false)}
            />
          </Grid>
        </Grid>
        {validationResults && (
          <ConfirmDialog
            id="confirm-continue-schema-mapping"
            isOpen={confirmContinue}
            title={intl.formatMessage({
              id: 'openSubmission.schemaMapping.schemaMapper.confirmContinue.title',
              defaultMessage: 'Are you sure you wish to continue?',
            })}
            text={
              validationResults.target.unmappedClasses
                ? intl.formatMessage(
                    {
                      id: 'openSubmission.schemaMapping.schemaMapper.confirmContinue.text.unmappedClasses',
                      defaultMessage:
                        'You have {unmappedClasses} unmapped target {unmappedClasses,plural,one{class}other{classes}}. Unmapped data can lead to submission failures during data processing. Are you sure you wish to continue?',
                    },
                    {
                      unmappedClasses: validationResults.target.unmappedClasses,
                    }
                  )
                : intl.formatMessage(
                    {
                      id: 'openSubmission.schemaMapping.schemaMapper.confirmContinue.text.unmappedAttributes',
                      defaultMessage:
                        'You have {unmappedAttributes} unmapped target {unmappedAttributes,plural,one{attribute}other{attributes}}. Unmapped data can lead to submission failures during data processing. Are you sure you wish to continue?',
                    },
                    {
                      unmappedAttributes: validationResults.target.unmappedAttributes,
                    }
                  )
            }
            confirmBtnText={intl.formatMessage({
              id: 'openSubmission.schemaMapping.schemaMapper.confirmContinue.confirmButton',
              defaultMessage: 'Continue',
            })}
            confirmAction={applyAndContinue}
            closeAction={() => setConfirmContinue(false)}
          />
        )}
        {blocking && (
          <ConfirmDialog
            id="confirm-unsaved-changes-continue-dialog"
            isOpen={confirmUnsavedChanges}
            title={intl.formatMessage({
              id: 'openSubmission.schemaMapping.schemaMapper.unsavedChangesContinue.title',
              defaultMessage: 'The mapping changes have not been saved.',
            })}
            text={intl.formatMessage({
              id: 'openSubmission.schemaMapping.schemaMapper.unsavedChangesContinue.text',
              defaultMessage:
                'Are you sure you want to continue without saving your mapping changes?',
            })}
            confirmBtnText={intl.formatMessage({
              id: 'openSubmission.schemaMapping.schemaMapper.unsavedChangesContinue.confirmButton',
              defaultMessage: 'Continue',
            })}
            confirmAction={() => {
              clearNavigationBlock();
              setConfirmUnsavedChanges(false);
              onContinue(validationResults?.target.status !== MappingStatus.OK);
            }}
            closeAction={() => {
              setOpenAutomationDialog(false);
              setConfirmUnsavedChanges(false);
            }}
          />
        )}
      </>
    );
  };

  const mapper = renderMapper();
  return (
    <Container
      maxWidth="xl"
      id="my-assignment-current-submission-schema"
      className={mapper.className}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {mapper.content}
        </Grid>
        <Grid item xs={12}>
          {renderActions()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SchemaMapping;
