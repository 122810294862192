import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BallotIcon from '@mui/icons-material/Ballot';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { TextFieldGroup } from '../components/editor/TemplatedInputEditor';
import { intl } from '../Internationalization';

export const SPECIFICATION_TEXT_FIELD_GROUPS: TextFieldGroup[] = [
  {
    icon: WorkIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.sharedExpressionTemplate.field.project',
      defaultMessage: 'Project',
    }),
    textFields: [
      {
        expression: 'projectName',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.projectName',
          defaultMessage: 'Project Name',
        }),
        icon: WorkIcon,
      },
      {
        expression: 'projectKey',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.projectKey',
          defaultMessage: 'Project Key',
        }),
        icon: WorkIcon,
      },
    ],
  },
  {
    icon: BallotIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.sharedExpressionTemplate.field.specification',
      defaultMessage: 'Specification',
    }),
    textFields: [
      {
        expression: 'specificationName',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.specificationName',
          defaultMessage: 'Specification Name',
        }),
        icon: BallotIcon,
      },
      {
        expression: 'specificationKey',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.specificationKey',
          defaultMessage: 'Specification Key',
        }),
        icon: BallotIcon,
      },
    ],
  },
  {
    icon: AssignmentIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.sharedExpressionTemplate.field.assignment',
      defaultMessage: 'Assignment',
    }),
    textFields: [
      {
        expression: 'assignmentReference',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.assignmentReference',
          defaultMessage: 'Assignment Reference',
        }),
        icon: AssignmentIcon,
      },
      {
        expression: 'assignmentKey',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.assignmentKey',
          defaultMessage: 'Assignment Key',
        }),
        icon: AssignmentIcon,
      },
    ],
  },
  {
    icon: LocalShippingIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.sharedExpressionTemplate.fieldGroup.supplier',
      defaultMessage: 'Supplier',
    }),
    textFields: [
      {
        expression: 'supplierName',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.supplierName',
          defaultMessage: 'Supplier Name',
        }),
        icon: LocalShippingIcon,
      },
      {
        expression: 'supplierKey',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.supplierKey',
          defaultMessage: 'Supplier Key',
        }),
        icon: LocalShippingIcon,
      },
    ],
  },
  {
    icon: AssessmentIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.sharedExpressionTemplate.fieldGroup.submission',
      defaultMessage: 'Submission',
    }),
    textFields: [
      {
        expression: 'submissionReference',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.reference',
          defaultMessage: 'Reference',
        }),
        icon: AssessmentIcon,
      },
      {
        expression: 'submissionStartDate',
        label: intl.formatMessage({
          id: 'specification.configuration.sharedExpressionTemplate.field.submissionStartDate',
          defaultMessage: 'Start Date',
        }),
        icon: CalendarMonthIcon,
      },
    ],
  },
];
