import { AxiosPromise } from 'axios';

import {
  ApiKeyDetail,
  ApiKeyFullDetail,
  ApiKeysResponse,
  ApiKeysRequest,
  CreateApiKeyRequest,
  ApiKeySettings,
} from '../types';

import { AXIOS, RequestConfig } from './endpoints';

export function getApiKeys(
  request: ApiKeysRequest,
  config?: RequestConfig
): AxiosPromise<ApiKeysResponse> {
  return AXIOS.get(`/api_keys`, {
    ...config,
    params: request,
  });
}

export function createApiKey(request: CreateApiKeyRequest): AxiosPromise<ApiKeyFullDetail> {
  return AXIOS.post(`/api_keys`, request);
}

export function deleteApiKey(userKey: string, name: string): AxiosPromise<void> {
  return AXIOS.delete(`/api_keys/${userKey}/${name}`);
}

export function getApiKey(userKey: string, name: string): AxiosPromise<ApiKeyDetail> {
  return AXIOS.get(`/api_keys/${userKey}/${name}`);
}

export function updateApiKey(
  request: ApiKeySettings,
  userKey: string,
  name: string
): AxiosPromise<ApiKeyDetail> {
  return AXIOS.put(`/api_keys/${userKey}/${name}`, request);
}
