import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  BackdropProps,
  DialogProps,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { intl } from '../Internationalization';
import { PaddedDialogActions, DefaultButton } from './';

interface ConfirmDialogProps extends React.PropsWithChildren {
  id: string;
  confirmAction: () => void;
  closeAction: () => void;
  isOpen: boolean;
  text?: string;
  title?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  BackdropProps?: Partial<BackdropProps>;
  disabled?: boolean;
  disableAction?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: DialogProps['fullWidth'];
}

const TransitionComponent = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const ConfirmDialog = ({
  id,
  confirmAction,
  closeAction,
  isOpen,
  text,
  title,
  confirmBtnText = intl.formatMessage({
    id: 'components.confirmDialog.confirmButton',
    defaultMessage: 'Confirm',
  }),
  cancelBtnText = intl.formatMessage({
    id: 'components.confirmDialog.cancelButton',
    defaultMessage: 'Cancel',
  }),
  BackdropProps: backdropProps,
  disabled = false,
  children,
  disableAction = false,
  maxWidth,
  fullWidth,
}: ConfirmDialogProps) => (
  <Dialog
    id={id}
    open={isOpen}
    onClose={disabled ? undefined : closeAction}
    TransitionComponent={TransitionComponent}
    BackdropProps={backdropProps}
    maxWidth={maxWidth}
    fullWidth={fullWidth}
  >
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>
      {text && <DialogContentText>{text}</DialogContentText>}
      {children}
    </DialogContent>
    <PaddedDialogActions>
      <DefaultButton name="cancel" onClick={closeAction} color="secondary" disabled={disabled}>
        {cancelBtnText}
      </DefaultButton>
      <DefaultButton name="confirm" onClick={confirmAction} disabled={disabled || disableAction}>
        {confirmBtnText}
      </DefaultButton>
    </PaddedDialogActions>
  </Dialog>
);

export default ConfirmDialog;
