import { FC, ReactNode } from 'react';
import { isArray } from 'lodash';
import { Svg as _Svg, Path } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

export const GREY = '#9E9E9E';
export const WHITE = '#FFFFFF';
export const SUCCESS_DARK = '#388E3C';
export const ERROR_DARK = '#D32F2F';
export const INFO_DARK = '#1976d2';
export const WARNING_DARK = '#f57c00';
export const WARNING_MAIN = '#ff9800';

// TODO:: Temp fix while waiting for react18 support: https://github.com/diegomura/react-pdf/issues/1779

function componentWithChildren<Props>(Component: React.ComponentType<Props>) {
  return Component as React.ComponentType<Props & { children: ReactNode }>;
}

const Svg = componentWithChildren(_Svg);

export type PdfIcon = FC<{ style?: Style | Style[] }>;

const combineStyles = (style?: Style | Style[], defaultStyle?: Style) => {
  if (isArray(style)) {
    return style.reduce((combined, next) => ({ ...combined, ...next }), defaultStyle || {});
  }

  return { ...defaultStyle, ...style };
};

const styledSvgIcon =
  (Icon: PdfIcon, defaultStyle: Style): PdfIcon =>
  ({ style }) =>
    <Icon style={combineStyles(style, defaultStyle)} />;

function createSvgIcon(path: string, defaultColor: string = GREY) {
  const Component: PdfIcon = ({ style }) => {
    const { color, ...rest } = combineStyles(style) || {};

    return (
      <Svg viewBox="0 0 24 24" style={{ height: 24, width: 24, ...rest }}>
        <Path d={path} fill={color || defaultColor} />
      </Svg>
    );
  };

  return Component;
}

export const InsertDriveFileSvg = createSvgIcon(
  'M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z'
);
export const WorkSvg = createSvgIcon(
  'M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z'
);
export const BallotSvg = createSvgIcon(
  'M13 9.5h5v-2h-5v2zm0 7h5v-2h-5v2zm6 4.5H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zM6 11h5V6H6v5zm1-4h3v3H7V7zM6 18h5v-5H6v5zm1-4h3v3H7v-3z'
);
export const AssignmentSvg = createSvgIcon(
  'M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z'
);
export const EventSvg = createSvgIcon(
  'M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z'
);
export const AssignmentReturnedSvg = createSvgIcon(
  'M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 15-5-5h3V9h4v4h3l-5 5z'
);
export const PlaylistAddCheckSvg = createSvgIcon(
  'M3 10h11v2H3zm0-4h11v2H3zm0 8h7v2H3zm17.59-2.07-4.25 4.24-2.12-2.12-1.41 1.41L16.34 19 22 13.34z'
);
export const FactCheckSvg = createSvgIcon(
  'M20,3H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M10,17H5v-2h5V17z M10,13H5v-2h5V13z M10,9H5V7h5V9z M14.82,15L12,12.16l1.41-1.41l1.41,1.42L17.99,9 l1.42,1.42L14.82,15z'
);
export const FileCopySvg = createSvgIcon(
  'M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4 6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z'
);
export const AssignmentTurnedInSvg = createSvgIcon(
  'M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z'
);
export const StorageSvg = createSvgIcon(
  'M2 20h20v-4H2v4zm2-3h2v2H4v-2zM2 4v4h20V4H2zm4 3H4V5h2v2zm-4 7h20v-4H2v4zm2-3h2v2H4v-2z'
);
export const TimerSvg = createSvgIcon(
  'M9 1h6v2H9zm10.03 6.39 1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM13 14h-2V8h2v6z'
);
export const EditLocationSvg = createSvgIcon(
  'M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm-1.56 10H9v-1.44l3.35-3.34 1.43 1.43L10.44 12zm4.45-4.45-.7.7-1.44-1.44.7-.7c.15-.15.39-.15.54 0l.9.9c.15.15.15.39 0 .54z'
);
export const ExploreSvg = createSvgIcon(
  'M12 10.9c-.61 0-1.1.49-1.1 1.1s.49 1.1 1.1 1.1c.61 0 1.1-.49 1.1-1.1s-.49-1.1-1.1-1.1zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm2.19 12.19L6 18l3.81-8.19L18 6l-3.81 8.19z'
);
export const HourglassEmptySvg = createSvgIcon(
  'M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5-4-4V4h8v3.5l-4 4z'
);
export const AutorenewSvg = createSvgIcon(
  'M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z'
);
export const ReplySvg = createSvgIcon(
  'M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z'
);
export const CheckCircleSvg = createSvgIcon(
  'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'
);
export const ErrorOutlineOutlinedSvg = createSvgIcon(
  'M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'
);
export const ReportProblemOutlinedSvg = createSvgIcon(
  'M12 5.99 19.53 19H4.47L12 5.99M12 2 1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z'
);
export const SyncAltSvg = createSvgIcon('m18 12 4-4-4-4v3H3v2h15zM6 12l-4 4 4 4v-3h15v-2H6z');
export const HelpSvg = createSvgIcon(
  'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z'
);
export const ArrowRightAltSvg = createSvgIcon('M16.01 11H4v2h12.01v3L20 12l-3.99-4z');
export const TocSvg = createSvgIcon(
  'M3 9h14V7H3v2zm0 4h14v-2H3v2zm0 4h14v-2H3v2zm16 0h2v-2h-2v2zm0-10v2h2V7h-2zm0 6h2v-2h-2v2z'
);
export const TransformSvg = createSvgIcon(
  'M22 18v-2H8V4h2L7 1 4 4h2v2H2v2h4v8c0 1.1.9 2 2 2h8v2h-2l3 3 3-3h-2v-2h4zM10 8h6v6h2V8c0-1.1-.9-2-2-2h-6v2z'
);

export const WarningSvg = createSvgIcon(
  'M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z',
  WARNING_MAIN
);
export const ErrorSvg = createSvgIcon(
  'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z',
  ERROR_DARK
);
export const ThumbDownSvg = createSvgIcon(
  'M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z',
  ERROR_DARK
);
export const ThumbUpSvg = createSvgIcon(
  'M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z',
  SUCCESS_DARK
);
export const PlayLessonOutlinedSvg = createSvgIcon(
  'M5 20V4h2v7l2.5-1.5L12 11V4h5v7.08c.33-.05.66-.08 1-.08s.67.03 1 .08V4c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h7.26c-.42-.6-.75-1.28-.97-2H5zm13-7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm-1.25 7.5v-5l4 2.5-4 2.5z',
  '#588CF0'
);

export const HourglassEmptyInfoSvg = styledSvgIcon(HourglassEmptySvg, { color: INFO_DARK });
export const AutorenewInfoSvg = styledSvgIcon(AutorenewSvg, { color: INFO_DARK });
export const TimerWarningSvg = styledSvgIcon(TimerSvg, { color: WARNING_MAIN });
export const ReplyInfoSvg = styledSvgIcon(ReplySvg, { color: INFO_DARK });
export const CheckCircleSuccessSvg = styledSvgIcon(CheckCircleSvg, { color: SUCCESS_DARK });
export const ErrorOutlineOutlinedErrorSvg = styledSvgIcon(ErrorOutlineOutlinedSvg, {
  color: ERROR_DARK,
});
export const ReportProblemOutlinedWarningSvg = styledSvgIcon(ReportProblemOutlinedSvg, {
  color: WARNING_DARK,
});
export const SyncAltInfoSvg = styledSvgIcon(SyncAltSvg, { color: INFO_DARK });
