import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Box } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';

import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../../components';
import { ErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';
import { useRouterTab, useTitle } from '../../../hooks';

import SystemHeader from '../SystemHeader';

import Import from './Import';
import ImportHistory from './ImportHistory';
import ProjectExport from './ProjectExport';

const ProjectImportRouting: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.system.projectImport',
      defaultMessage: 'Project Export/Import',
    })
  );
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="project-import-tabs" value={routerTab}>
      <RouterTab
        id="export-tab"
        value="export"
        label={intl.formatMessage({
          id: 'projectImport.routing.tabs.export',
          defaultMessage: 'Export',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="import-tab"
        value="import"
        label={intl.formatMessage({
          id: 'projectImport.routing.tabs.import',
          defaultMessage: 'Import',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="import-history-tab"
        value="import_history"
        label={intl.formatMessage({
          id: 'projectImport.routing.tabs.history',
          defaultMessage: 'Import History',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'projectImport.routing.title',
          defaultMessage: 'Project Export/Import',
        })}
        icon={WorkIcon}
      />
      <AppTabs />
      <Box p={3}>
        <ErrorBlock>
          <Routes>
            <Route path="export" element={<ProjectExport />} />
            <Route path="import" element={<Import />} />
            <Route path="import_history" element={<ImportHistory />} />
            <Route path="*" element={<Navigate to="export" replace />} />
          </Routes>
        </ErrorBlock>
      </Box>
    </AppDecorator>
  );
};

export default ProjectImportRouting;
