import { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as ValidationJobApi from '../../api/validationJob';

import { DefaultButton } from '../../components';
import { ValidationJobDetail, ValidationJobState } from '../../types';
import { useInterval } from '../../hooks';
import { enqueueSnackbar } from 'notistack';
import { extractErrorMessage } from '../../api/endpoints';
import { intl } from '../../Internationalization';

const POLL_INTERVAL = 5000;

interface RevalidateSpecificationsProps {
  projectKey?: string;
  onRevalidationComplete: () => void;
}

const RevalidateSpecifications: FC<RevalidateSpecificationsProps> = ({
  projectKey,
  onRevalidationComplete,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [validationJob, setValidationJob] = useState<ValidationJobDetail>();

  const shouldPoll = useCallback(
    () =>
      validationJob?.state === ValidationJobState.RUNNING ||
      validationJob?.state === ValidationJobState.NOT_STARTED,
    [validationJob?.state]
  );

  const pollIfRequired = () => {
    if (validationJob && shouldPoll()) {
      handlePoll(validationJob.key);
    }
  };

  useEffect(() => {
    if (validationJob && !shouldPoll()) {
      setValidationJob(undefined);
      if (validationJob.state === ValidationJobState.COMPLETED) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'projects.revalidate.poll.finishedSuccessfully',
            defaultMessage: 'Revalidation successfully complete',
          }),
          { variant: 'success' }
        );
      } else if (validationJob.state === ValidationJobState.FAILED) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'projects.revalidate.poll.finishedWithErrors',
            defaultMessage: 'Revalidation failed to complete',
          }),
          { variant: 'warning' }
        );
      }
      onRevalidationComplete();
    }
  }, [onRevalidationComplete, shouldPoll, validationJob]);

  useInterval(pollIfRequired, POLL_INTERVAL);

  const handlePoll = async (validationKey: string) => {
    setProcessing(true);
    try {
      const { data } = await ValidationJobApi.fetchValidationJob(validationKey);
      setValidationJob(data);
    } catch (error) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'projects.revalidate.poll.error',
            defaultMessage: 'Failed to fetch validation job details',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleRevalidate = async () => {
    if (validationJob) {
      return;
    }
    try {
      const { data } = await ValidationJobApi.createValidationJob({
        projectKey,
      });
      setValidationJob(data);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'projects.revalidate.createValidationJob.error',
            defaultMessage: 'Failed to start validation',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  return (
    <Box alignItems="center" display="flex">
      <DefaultButton
        name="revalidate"
        onClick={handleRevalidate}
        disabled={processing}
        endIcon={shouldPoll() ? <CircularProgress size={20} color="secondary" /> : <RefreshIcon />}
      >
        <FormattedMessage id="projects.revalidate.button" defaultMessage="Revalidate" />
      </DefaultButton>
    </Box>
  );
};

export default RevalidateSpecifications;
