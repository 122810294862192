import { FC } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Typography,
  Box,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Tooltip,
} from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

import { extractErrorMessage } from '../../../api/endpoints';
import * as UserImportApi from '../../../api/user/userImport';
import * as UserImportsApi from '../../../api/user/userImports';
import * as UserImportMediaApi from '../../../api/user/userImportMedia';
import {
  PaddedPaper,
  TableLoadingRow,
  TableInfoRow,
  FilterPagination,
  DefaultButton,
  MinWidthTableCell,
  ButtonRow,
} from '../../../components';
import { USER_IMPORT_JOB_STATE_METADATA, UserImportJobState } from '../../../types';
import { useBrowseRequest, useInterval } from '../../../hooks';
import { intl } from '../../../Internationalization';
import { dateTimeFormat } from '../../../util';

const PAGE_SIZE = 10;
const POLL_INTERVAL = 5000;

const UserImportHistory: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { request, response, processing, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE },
    onRequest: UserImportsApi.fetchJobs,
  });

  const shouldRefresh = () =>
    response && response.results.find((job) => job.state === UserImportJobState.RUNNING);

  const refreshIfRequired = () => {
    if (shouldRefresh()) {
      refresh();
    }
  };

  useInterval(refreshIfRequired, POLL_INTERVAL);

  const cancelImport = (jobId: number) => {
    UserImportApi.cancelJob(jobId)
      .then(() => {
        refresh();
        enqueueSnackbar(
          intl.formatMessage({
            id: 'userImport.importHistory.cancelSuccess',
            defaultMessage: 'Upload cancelled',
          }),
          { variant: 'success' }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'userImport.importHistory.cancelError',
              defaultMessage: 'Failed to cancel upload',
            })
          ),
          { variant: 'error' }
        );
      });
  };

  const renderTableRows = () => {
    if (!response) {
      return <TableLoadingRow colSpan={5} />;
    }

    if (!response.results.length) {
      return (
        <TableInfoRow
          colSpan={5}
          size="medium"
          message={intl.formatMessage({
            id: 'userImport.importHistory.noJobs',
            defaultMessage: 'No user import jobs to display',
          })}
        />
      );
    }

    return response.results.map(({ id, state, createdAt, added, skipped, log, data }) => {
      const { icon: Icon, label } = USER_IMPORT_JOB_STATE_METADATA[state];

      return (
        <TableRow key={id}>
          <TableCell>
            <Box display="flex" alignItems="center">
              <Tooltip title={label}>
                <Icon />
              </Tooltip>
            </Box>
          </TableCell>
          <TableCell>{dateTimeFormat(createdAt)}</TableCell>
          <TableCell align="right">{added}</TableCell>
          <TableCell align="right">{skipped}</TableCell>
          <MinWidthTableCell align="right">
            <ButtonRow whiteSpace="nowrap">
              {(state === UserImportJobState.RUNNING || state === UserImportJobState.PENDING) && (
                <DefaultButton
                  startIcon={<CancelIcon />}
                  size="small"
                  color="secondary"
                  onClick={() => cancelImport(id)}
                >
                  <FormattedMessage
                    id="userImport.importHistory.cancelButton"
                    defaultMessage="Cancel"
                  />
                </DefaultButton>
              )}
              <DefaultButton
                download
                color="secondary"
                size="small"
                disabled={!data}
                startIcon={<DescriptionOutlinedIcon />}
                href={data && UserImportMediaApi.downloadUserImportDataFileUri(id, data)}
              >
                <FormattedMessage
                  id="userImport.importHistory.downloadDataButton"
                  defaultMessage="data"
                />
              </DefaultButton>
              <DefaultButton
                download
                color="secondary"
                size="small"
                disabled={!log}
                startIcon={<DescriptionOutlinedIcon />}
                href={log && UserImportMediaApi.downloadUserImportLogFileUri(id, log)}
              >
                <FormattedMessage
                  id="userImport.importHistory.downloadLogButton"
                  defaultMessage="log"
                />
              </DefaultButton>
            </ButtonRow>
          </MinWidthTableCell>
        </TableRow>
      );
    });
  };

  return (
    <Container maxWidth="lg" id="system-user-import-history" disableGutters>
      <PaddedPaper>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5" component="h3" gutterBottom>
            <FormattedMessage id="userImport.importHistory.title" defaultMessage="Import History" />
          </Typography>
          <Box>
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
          </Box>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="userImport.importHistory.stateColumn"
                  defaultMessage="State"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="userImport.importHistory.createdColumn"
                  defaultMessage="Created"
                />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage
                  id="userImport.importHistory.addedColumn"
                  defaultMessage="Added"
                />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage
                  id="userImport.importHistory.skippedColumn"
                  defaultMessage="Skipped"
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </PaddedPaper>
    </Container>
  );
};

export default UserImportHistory;
