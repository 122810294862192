import { AxiosPromise } from 'axios';

import { NamedLocationDetail, NamedLocationRequest } from '../../types';
import { AXIOS } from '../endpoints';

export function getNamedLocation(namedLocationKey: string): AxiosPromise<NamedLocationDetail> {
  return AXIOS.get(`/named_location/${namedLocationKey}`);
}

export function updateNamedLocation(
  request: NamedLocationRequest,
  namedLocationKey: string
): AxiosPromise<NamedLocationDetail> {
  return AXIOS.post(`/named_location/${namedLocationKey}`, request);
}

export function deleteNamedLocation(namedLocationKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/named_location/${namedLocationKey}`);
}
