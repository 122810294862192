import { AxiosPromise } from 'axios';

import { CopyAssignmentsRequest } from '../../types';
import { AXIOS } from '../endpoints';

export function copyAssignments(
  specificationKey: string,
  request: CopyAssignmentsRequest
): AxiosPromise<void> {
  return AXIOS.post(`/specification/${specificationKey}/copyAssignments`, request);
}
