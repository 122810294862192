import { AxiosPromise } from 'axios';

import { AXIOS } from '../endpoints';
import { LdapUserSettings, LdapUser } from '../../types';

export function getUserSettings(): AxiosPromise<LdapUserSettings> {
  return AXIOS.get('/ldap/user');
}

export function updateUserSettings(
  ldapUserSettings: LdapUserSettings
): AxiosPromise<LdapUserSettings> {
  return AXIOS.post('/ldap/user', ldapUserSettings);
}

export function previewLdapUsers(): AxiosPromise<LdapUser[]> {
  return AXIOS.get('/ldap/user/preview');
}
