import { FC, useState } from 'react';
import { ValidateFieldsError } from 'async-validator';

import { Box, Card, Checkbox, FormControlLabel, IconButton, MenuItem } from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {
  FIELD_TYPES,
  FIELD_TYPES_METADATA,
  FieldType,
  ReorderDirection,
  SpecificationFieldDetail,
} from '../../../../../../types';

import { ConfirmDialog, ValidatedTextField } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';

interface FieldConfigCardProps {
  specificationField: SpecificationFieldDetail;
  handleUpdateSpecificationField: (updatedField: SpecificationFieldDetail) => void;
  processing: boolean;
  fieldErrors?: ValidateFieldsError;
  index: number;
  handleReorderField: (fieldName: string, direction: ReorderDirection) => void;
  handleRemoveField: (fieldName: string) => void;
  disableNext: boolean;
  disablePrev: boolean;
}

const FieldConfigCard: FC<FieldConfigCardProps> = ({
  specificationField,
  handleUpdateSpecificationField,
  processing,
  fieldErrors,
  index,
  handleReorderField,
  handleRemoveField,
  disableNext,
  disablePrev,
}) => {
  const [fieldToRemove, setFieldToRemove] = useState<string>();
  const onConfirmRemoveField = (fieldName: string) => {
    handleRemoveField(fieldName);
    setFieldToRemove(undefined);
  };
  return (
    <Card
      key={specificationField.name}
      sx={{ p: 2, display: 'flex', flexDirection: 'row' }}
      elevation={3}
    >
      <Box display="flex" flexDirection="column" mr={1.5}>
        <IconButton
          size="small"
          disabled={processing || disablePrev}
          onClick={() => handleReorderField(specificationField.name, 'UP')}
        >
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton
          size="small"
          disabled={processing || disableNext}
          onClick={() => handleReorderField(specificationField.name, 'DOWN')}
        >
          <ArrowDownwardIcon />
        </IconButton>
      </Box>
      <Box display="flex" width="100%" flexDirection="column" flexGrow={1}>
        <Box display="flex" flexDirection="row" columnGap={2}>
          <ValidatedTextField
            name={`fields.${index}.name`}
            label={intl.formatMessage({
              id: 'specification.configuration.fieldConfig.fieldCard.name.label',
              defaultMessage: 'Name',
            })}
            sx={{ width: 'calc(50% - 8px)' }}
            value={specificationField.name}
            disabled
            variant="outlined"
            margin="normal"
            size="small"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={specificationField.required}
                onChange={(event) =>
                  handleUpdateSpecificationField({
                    ...specificationField,
                    required: event.target.checked,
                  })
                }
                name={`fields.${index}.required`}
                color="primary"
                disabled={processing}
              />
            }
            label={intl.formatMessage({
              id: 'specification.configuration.fieldConfig.fieldCard.label',
              defaultMessage: 'Required',
            })}
          />
        </Box>
        <Box display="flex" flexDirection="row" columnGap={2}>
          <Box width="50%">
            <ValidatedTextField
              name={`fields.${index}.description`}
              label={intl.formatMessage({
                id: 'specification.configuration.fieldConfig.fieldCard.description.label',
                defaultMessage: 'Description',
              })}
              fullWidth
              value={specificationField.description}
              onChange={(event) => {
                handleUpdateSpecificationField({
                  ...specificationField,
                  description: event.target.value,
                });
              }}
              variant="outlined"
              fieldErrors={fieldErrors}
              disabled={processing}
              size="small"
              margin="normal"
            />
          </Box>
          <Box width="50%">
            <ValidatedTextField
              name={`fields.${index}.type`}
              label={intl.formatMessage({
                id: 'specification.configuration.fieldConfig.fieldCard.type.label',
                defaultMessage: 'Type',
              })}
              fullWidth
              value={specificationField.type}
              select
              onChange={(event) => {
                handleUpdateSpecificationField({
                  ...specificationField,
                  type: event.target.value as FieldType,
                });
              }}
              variant="outlined"
              fieldErrors={fieldErrors}
              disabled={processing}
              margin="normal"
              size="small"
            >
              {FIELD_TYPES.map((fieldType) => (
                <MenuItem key={fieldType} value={fieldType}>
                  {FIELD_TYPES_METADATA[fieldType].label}
                </MenuItem>
              ))}
            </ValidatedTextField>
          </Box>
        </Box>
      </Box>
      <Box ml={1.5}>
        <IconButton size="small" onClick={() => setFieldToRemove(specificationField.name)}>
          <DeleteForeverIcon />
        </IconButton>
      </Box>
      <ConfirmDialog
        id="confirm-remove-field"
        isOpen={!!fieldToRemove}
        title={intl.formatMessage({
          id: 'specification.configuration.fieldConfig.fieldCard.confirmRemove.title',
          defaultMessage: 'Remove field?',
        })}
        text={intl.formatMessage({
          id: 'specification.configuration.fieldConfig.fieldCard.confirmRemove.text',
          defaultMessage: 'Are you sure you wish to remove this field?',
        })}
        confirmBtnText={intl.formatMessage({
          id: 'specification.configuration.fieldConfig.fieldCard.confirmRemove.confirmButton',
          defaultMessage: 'Remove Field',
        })}
        confirmAction={() => fieldToRemove && onConfirmRemoveField(fieldToRemove)}
        closeAction={() => setFieldToRemove(undefined)}
        disabled={processing || !fieldToRemove}
      />
    </Card>
  );
};

export default FieldConfigCard;
