import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { intl } from '../../../../../../Internationalization';
import {
  DefaultButton,
  NoContentPlaceholder,
  PaddedDialogActions,
  ValidatedTextField,
} from '../../../../../../components';
import { DataStoreSchema } from '../../../../../../types';

interface DataStoreSelectDialogProps {
  linkedDataStores: string[];
  dataStoreSchemas: DataStoreSchema[];
  dataStoreDialogOpen: boolean;
  onCloseDataStoreDialog: () => void;
  onConfirm: (linkedDataStores: string[]) => void;
}

const DataStoreSelectDialog: FC<DataStoreSelectDialogProps> = ({
  linkedDataStores,
  dataStoreSchemas,
  dataStoreDialogOpen,
  onCloseDataStoreDialog,
  onConfirm,
}) => {
  const [dataStores, setDataStores] = useState<string[]>(linkedDataStores);
  const dataStoresToAdd = '';

  const handleRemoveLinkedDataStore = (index: number) => {
    setDataStores((prevDataStores) => {
      const updatedDataStores = [...prevDataStores];
      updatedDataStores.splice(index, 1);
      return updatedDataStores;
    });
  };

  const handleLinkDataStore = (dataStore: string) => {
    setDataStores((prev) => [...prev, dataStore]);
  };

  const handleCloseDataStoreDialog = () => {
    onCloseDataStoreDialog();
    setDataStores(linkedDataStores);
  };

  const handleUpdateLinkedDataStores = () => {
    onCloseDataStoreDialog();
    onConfirm(dataStores);
  };

  const availableDataStores = [
    ...dataStoreSchemas.filter((dataStore) => !!dataStore.importSchema).map(({ path }) => path),
    ...linkedDataStores,
  ].filter((dataStore) => !dataStores.includes(dataStore));

  return (
    <Dialog
      open={dataStoreDialogOpen}
      onClose={onCloseDataStoreDialog}
      maxWidth="sm"
      fullWidth
      id="input-data-store-dialog"
    >
      <DialogTitle>
        {intl.formatMessage({
          id: 'specification.configuration.inputConfig.dataStoreDialog.title',
          defaultMessage: 'Input Data Stores',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" gutterBottom>
          <FormattedMessage
            id="specification.configuration.inputConfig.dataStoreDialog.selectedDataStores"
            defaultMessage="Selected Data Stores"
          />
        </Typography>
        {dataStores.length ? (
          dataStores.map((dataStore, index) => (
            <Box display="flex" justifyContent="space-between" alignItems="center" key={dataStore}>
              <Typography>{dataStore}</Typography>
              <IconButton onClick={() => handleRemoveLinkedDataStore(index)}>
                <ClearIcon />
              </IconButton>
            </Box>
          ))
        ) : (
          <NoContentPlaceholder
            size="small"
            message={intl.formatMessage({
              id: 'specification.configuration.inputConfig.dataStoreDialog.noLinkedDataStore',
              defaultMessage: 'No linked data stores',
            })}
          />
        )}
        <ValidatedTextField
          name="linkDataStores"
          label={intl.formatMessage({
            id: 'specification.configuration.inputConfig.dataStoreDialog.selectDataStores.label',
            defaultMessage: 'Select Data Stores',
          })}
          select
          value={dataStoresToAdd}
          onChange={(event) => handleLinkDataStore(event.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        >
          {availableDataStores.length ? (
            availableDataStores.map((dataStore) => (
              <MenuItem key={dataStore} value={dataStore}>
                {dataStore}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled={true}>
              <FormattedMessage
                id="specification.configuration.inputConfig.dataStoreDialog.selectDataStores.none"
                defaultMessage="No data stores available"
              />
            </MenuItem>
          )}
        </ValidatedTextField>
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton name="cancel" onClick={handleCloseDataStoreDialog} color="secondary">
          <FormattedMessage
            id="specification.configuration.inputConfig.dataStoreDialog.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton name="confirm" onClick={handleUpdateLinkedDataStores}>
          <FormattedMessage
            id="specification.configuration.inputConfig.dataStoreDialog.confirmButton"
            defaultMessage="Confirm changes"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default DataStoreSelectDialog;
