import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { UserProfile } from '../../../types';
import ApiKeys from './ApiKeys';
import ApiKey from './ApiKey';
import { Container } from '@mui/material';

interface ApiKeyRoutingProps {
  user: UserProfile;
}

const ApiKeyRouting: FC<ApiKeyRoutingProps> = ({ user }) => (
  <Container maxWidth="md" disableGutters>
    <Routes>
      <Route index element={<ApiKeys user={user} />} />
      <Route path=":apiKeyName" element={<ApiKey user={user} />} />
      <Route path="*" element={<Navigate to="." replace />} />
    </Routes>
  </Container>
);

export default ApiKeyRouting;
