import { FC, useContext, useState } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Box, Checkbox, Container, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';

import * as VirusScannerApi from '../../../api/virusScanner';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  BlockFormControlLabel,
  DefaultButton,
  FormButtons,
  InputTooltip,
  MessageBox,
  PaddedPaper,
  ValidatedChipsArrayField,
  ValidatedTextField,
  MonospacedContainer,
} from '../../../components';
import { intl } from '../../../Internationalization';
import {
  ScannerSettings,
  VirusScannerSettings as VirusScannerSettingsRequest,
  VirusScannerTestResponse,
} from '../../../types';
import { editableDurationToIso, isoDurationToEditable } from '../../../util';
import { ApplicationContext } from '../../../contexts/application';
import { SETTINGS_VALIDATOR, validate } from '../../../validation';

import { VirusScannerContext } from './VirusScannerContext';

const VirusScannerSettings: FC = () => {
  const { virusScannerSettings, virusScannerSettingsUpdated } = useContext(VirusScannerContext);
  const { refresh } = useContext(ApplicationContext);
  const { enqueueSnackbar } = useSnackbar();

  const [scannerSettings, setScannerSettings] = useState<ScannerSettings>({
    ...virusScannerSettings.scannerSettings,
    scanTimeout: isoDurationToEditable(virusScannerSettings.scannerSettings.scanTimeout),
  });

  const [enabled, setEnabled] = useState<boolean>(virusScannerSettings.enabled);

  const [testResults, setTestResults] = useState<VirusScannerTestResponse>();

  const [processingSave, setProcessingSave] = useState<boolean>(false);
  const [processingTest, setProcessingTest] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validateAndSaveSettings = () => {
    setProcessingSave(true);
    validateAndPerform(handleSave);
  };

  const validateAndTestSettings = () => {
    setProcessingTest(true);
    validateAndPerform(handleTest);
  };

  const validateAndPerform = async (
    onValidated: (validatedSettings: VirusScannerSettingsRequest) => void
  ) => {
    setFieldErrors({});
    try {
      onValidated(
        await validate(SETTINGS_VALIDATOR, {
          enabled,
          scannerSettings: {
            ...scannerSettings,
            scanTimeout: scannerSettings.scanTimeout
              ? editableDurationToIso(scannerSettings.scanTimeout)
              : '',
          },
        })
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessingTest(false);
      setProcessingSave(false);
    }
  };

  const handleSave = async (validatedSettings: VirusScannerSettingsRequest) => {
    try {
      const repsonse = await VirusScannerApi.updateSettings(validatedSettings);
      virusScannerSettingsUpdated(repsonse.data);
      refresh();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'virusScanner.settings.saveSuccess',
          defaultMessage: 'Virus scanner settings saved successfully',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'virusScanner.settings.saveError',
            defaultMessage: 'Failed to save the virus scanner settings',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessingSave(false);
    }
  };

  const handleTest = async (validatedSettings: VirusScannerSettingsRequest) => {
    setTestResults(undefined);
    try {
      const response = await VirusScannerApi.testSettings(validatedSettings);
      setTestResults(response.data);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'virusScanner.settings.testError',
            defaultMessage: 'Failed to test virus scanner settings',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessingTest(false);
    }
  };

  const renderTestResults = () => {
    if (!testResults) {
      return null;
    }

    return (
      <>
        {testResults.success ? (
          <MessageBox
            level="success"
            message={intl.formatMessage({
              id: 'virusScanner.settings.testPassed.message',
              defaultMessage: 'Virus scanner settings test successful',
            })}
          />
        ) : (
          <MessageBox
            level="warning"
            message={intl.formatMessage({
              id: 'virusScanner.settings.testFailed.message',
              defaultMessage: 'Virus scanner settings test failed',
            })}
          />
        )}
        <MonospacedContainer className="VirusScannerSettings-scannerOutput">
          {testResults.scannerOutput}
        </MonospacedContainer>
      </>
    );
  };

  const disableInputs = processingSave || processingTest;

  return (
    <Container maxWidth="md" id="system-virus-scanner-settings" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" component="h3" gutterBottom>
          <FormattedMessage
            id="virusScanner.settings.title"
            defaultMessage="Virus Scanner Settings"
          />
        </Typography>
        <InputTooltip
          data-tooltip-for="enabled"
          title={intl.formatMessage({
            id: 'virusScanner.settings.enableVirusScanner.tooltip',
            defaultMessage: 'Enable virus scanning of uploaded files',
          })}
        >
          <BlockFormControlLabel
            control={
              <Checkbox
                color="primary"
                name="enabled"
                checked={enabled}
                onChange={(e) => setEnabled(e.target.checked)}
                disabled={disableInputs}
              />
            }
            label={intl.formatMessage({
              id: 'virusScanner.settings.enableVirusScanner.label',
              defaultMessage: 'Enable virus scanner?',
            })}
          />
        </InputTooltip>
        <ValidatedTextField
          required
          name="scannerSettings.scannerPath"
          label={intl.formatMessage({
            id: 'virusScanner.settings.scannerSettings.scannerPath.label',
            defaultMessage: 'Scanner Path',
          })}
          tooltip={intl.formatMessage({
            id: 'virusScanner.settings.scannerSettings.scannerPath.tooltip',
            defaultMessage: 'Full path to the clamdscan executable',
          })}
          value={scannerSettings.scannerPath || ''}
          onChange={(event) =>
            setScannerSettings((prev) => ({ ...prev, scannerPath: event.target.value }))
          }
          fieldErrors={fieldErrors}
          disabled={disableInputs}
          margin="normal"
          variant="outlined"
        />
        <ValidatedChipsArrayField
          fieldErrors={fieldErrors}
          disabled={disableInputs}
          name="scannerSettings.additionalArguments"
          label={intl.formatMessage({
            id: 'virusScanner.settings.scannerSettings.additionalArguments.label',
            defaultMessage: 'Additional Arguments',
          })}
          endAdornmentAriaLabel={intl.formatMessage({
            id: 'virusScanner.settings.scannerSettings.additionalArguments.endAdornmentAriaLabel',
            defaultMessage: 'Add Additional Argument',
          })}
          tooltip={intl.formatMessage({
            id: 'virusScanner.settings.scannerSettings.additionalArguments.tooltip',
            defaultMessage: 'Additional command-line arguments to pass to the scanner executable.',
          })}
          chips={
            scannerSettings?.additionalArguments?.map((argument) => ({
              key: argument,
              label: argument,
            })) || []
          }
          onChange={(additionalArguments) => {
            setScannerSettings((prev) => ({ ...prev, additionalArguments }));
          }}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={disableInputs}
          name="scannerSettings.scanTimeout"
          label={intl.formatMessage({
            id: 'virusScanner.settings.scannerSettings.label',
            defaultMessage: 'Scanner Timeout Duration (e.g. 1m 30s)',
          })}
          tooltip={intl.formatMessage({
            id: 'virusScanner.settings.scannerSettings.tooltip',
            defaultMessage: 'The amount of time before the scanning process is assumed to be stuck',
          })}
          value={scannerSettings.scanTimeout || ''}
          onChange={(event) =>
            setScannerSettings((prev) => ({ ...prev, scanTimeout: event.target.value }))
          }
          margin="normal"
          variant="outlined"
        />
        <Box mt={1}>{renderTestResults()}</Box>
        <FormButtons>
          <DefaultButton
            name="saveSettings"
            onClick={validateAndSaveSettings}
            disabled={processingSave}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage
              id="virusScanner.settings.saveButton"
              defaultMessage="Save Settings"
            />
          </DefaultButton>
          <DefaultButton
            name="testSettings"
            onClick={validateAndTestSettings}
            disabled={processingTest}
            startIcon={<SettingsInputAntennaIcon />}
          >
            <FormattedMessage
              id="virusScanner.settings.testButton"
              defaultMessage="Test Settings"
            />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default VirusScannerSettings;
