import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { TaskConfig } from '../../../../../../types';
interface IncludeInResultsProps {
  taskConfig: TaskConfig;
  updateTaskConfig: (taskConfig: TaskConfig) => void;
}
const IncludeInResults: FC<IncludeInResultsProps> = ({ taskConfig, updateTaskConfig }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={!taskConfig.hidden}
          onChange={(event) => updateTaskConfig({ ...taskConfig, hidden: !event.target.checked })}
        />
      }
      label={
        <Typography variant="body2">
          <FormattedMessage
            id="specification.configuration.taskConfig.includeInResults.label"
            defaultMessage="Include in results"
          />
        </Typography>
      }
    />
  );
};
export default IncludeInResults;
