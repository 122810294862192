import { AxiosPromise } from 'axios';

import { AXIOS } from '../endpoints';
import { SupplierCopyAssignmentsRequest } from '../../types';

export function copyAssignments(
  supplierKey: string,
  request: SupplierCopyAssignmentsRequest
): AxiosPromise<void> {
  return AXIOS.post(`/suppliers/${supplierKey}/copyAssignments`, request);
}
