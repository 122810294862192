import { AxiosPromise } from 'axios';

import {
  ProjectSettings,
  ProjectDetail,
  VirusCheckedMediaDetail,
  ProjectSpecificationDefaults,
} from '../../types';

import { AXIOS, FileUploadConfig } from '../endpoints';
import { uploadMediaAndVirusCheck } from '../media';

export function getProject(projectKey: string): AxiosPromise<ProjectDetail> {
  return AXIOS.get(`/projects/${projectKey}`);
}

export const uploadProjectImage = (
  projectKey: string,
  newImage: File,
  config?: FileUploadConfig
): AxiosPromise<VirusCheckedMediaDetail> =>
  uploadMediaAndVirusCheck(`/projects/${projectKey}/image`, newImage, config);

export function deleteProjectImage(projectKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/projects/${projectKey}/image`);
}

export function updateProjectSettings(
  projectKey: string,
  project: ProjectSettings
): AxiosPromise<ProjectDetail> {
  return AXIOS.post(`/projects/${projectKey}`, project);
}

export function updateProjectSpecificationDefaults(
  projectKey: string,
  project: ProjectSpecificationDefaults
): AxiosPromise<ProjectDetail> {
  return AXIOS.post(`/projects/${projectKey}/specification_defaults`, project);
}

export const extractProjectSettings = ({
  name,
  description,
  active,
  imageStyle,
}: ProjectDetail): ProjectSettings => ({
  name,
  description,
  active,
  imageStyle,
});

export const extractProjectSpecificationDefaults = ({
  defaultAutomationMode,
  defaultNotifications,
}: ProjectDetail): ProjectSpecificationDefaults => ({
  defaultAutomationMode,
  defaultNotifications,
});
