import { AxiosPromise } from 'axios';
import { AXIOS } from '../endpoints';

import {
  UserProfile,
  UserDetail,
  UserProfileRequest,
  ReceiverPermissionsRequest,
  CreateUserRequest,
  PasswordResetLink,
  ActivationLink,
} from '../../types';

export function getUserDetails(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.get(`/users/${userKey}`);
}

export function suspendUser(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/suspend`);
}

export function unsuspendUser(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/unsuspend`);
}

export function updateReceiverPermissions(
  userKey: string,
  receiverPermissions: ReceiverPermissionsRequest
): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/receiver_permissions`, receiverPermissions);
}

export function revokeReceiverPermissions(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.delete(`/users/${userKey}/receiver_permissions`);
}

export function resendActivationEmail(userKey: string): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/resend_activation_email`);
}

export function generatePasswordResetLink(userKey: string): AxiosPromise<PasswordResetLink> {
  return AXIOS.post(`/users/${userKey}/generate_password_reset_link`);
}

export function generateActivationLink(userKey: string): AxiosPromise<ActivationLink> {
  return AXIOS.post(`/users/${userKey}/generate_activation_link`);
}

export function createUser(request: CreateUserRequest): AxiosPromise<UserProfile> {
  return AXIOS.post('/users', request);
}

export function updateUserProfile(
  userKey: string,
  request: UserProfileRequest
): AxiosPromise<UserDetail> {
  return AXIOS.post(`/users/${userKey}/profile`, request);
}
