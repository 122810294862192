import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import SaveIcon from '@mui/icons-material/Save';
import { Typography, Grid, Container } from '@mui/material';

import * as SiteSettingsApi from '../../../api/site/siteSettings';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  compareDurations,
  editableDurationToIso,
  editablePeriodToIso,
  isoDurationToEditable,
  isoPeriodToEditable,
  nullableEditablePeriodToIso,
  onEnterCallback,
  parseDuration,
} from '../../../util';
import {
  PaddedPaper,
  ValidatedTextField,
  FormButtons,
  DefaultButton,
  MessageBox,
  ConfirmDialog,
} from '../../../components';

import { SiteContext } from './SiteContext';
import { SECURITY_SETTINGS_VALIDATOR, validate } from '../../../validation';

interface SecuritySettingFields {
  authenticationTokenExpiration: string;
  passwordResetTokenExpiration: string;
  maxApiKeyPeriod?: string;
}

const Security: FC = () => {
  const context = useContext(SiteContext);

  const [authenticationTokenExpiration, setAuthenticationTokenExpiration] = useState<string>(
    isoDurationToEditable(context.siteSettings.authenticationTokenExpiration)
  );
  const [passwordResetTokenExpiration, setPasswordResetTokenExpiration] = useState<string>(
    isoDurationToEditable(context.siteSettings.passwordResetTokenExpiration)
  );
  const [maxApiKeyPeriod, setMaxApiKeyPeriod] = useState<string | undefined>(
    context?.siteSettings?.maxApiKeyPeriod
      ? isoPeriodToEditable(context.siteSettings.maxApiKeyPeriod)
      : undefined
  );

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [confirmRegenerateSecret, setConfirmRegenerateSecret] = useState<boolean>(false);
  const [confirmUpdateMaxApiKeyPeriod, setConfirmUpdateMaxApiKeyPeriod] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const newMaxApiKeyPeriodIsShorter = () => {
    if (!maxApiKeyPeriod) {
      return false;
    }

    if (!context.siteSettings.maxApiKeyPeriod) {
      return true;
    }

    const previous = parseDuration(context.siteSettings.maxApiKeyPeriod);
    const newPeriod = parseDuration(editablePeriodToIso(maxApiKeyPeriod));
    return compareDurations(previous, newPeriod) === -1;
  };

  const validateAndSaveOrConfirm = async () => {
    setProcessing(true);
    setFieldErrors(undefined);
    try {
      const validatedSiteSettings = await validate(SECURITY_SETTINGS_VALIDATOR, {
        passwordResetTokenExpiration: editableDurationToIso(passwordResetTokenExpiration),
        authenticationTokenExpiration: editableDurationToIso(authenticationTokenExpiration),
        maxApiKeyPeriod: nullableEditablePeriodToIso(maxApiKeyPeriod),
      });
      if (newMaxApiKeyPeriodIsShorter()) {
        setConfirmUpdateMaxApiKeyPeriod(true);
        setProcessing(false);
      } else {
        updateSettings(validatedSiteSettings);
      }
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const saveValidatedSiteSettings = async () => {
    setProcessing(true);
    setFieldErrors(undefined);
    try {
      updateSettings({
        passwordResetTokenExpiration: editableDurationToIso(passwordResetTokenExpiration),
        authenticationTokenExpiration: editableDurationToIso(authenticationTokenExpiration),
        maxApiKeyPeriod: nullableEditablePeriodToIso(maxApiKeyPeriod),
      });
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
    setConfirmUpdateMaxApiKeyPeriod(false);
  };

  const submitOnEnter = onEnterCallback(validateAndSaveOrConfirm);

  const updateSettings = async (securitySettings: SecuritySettingFields) => {
    try {
      const { siteSettings, refreshApplicationContext, siteSettingsUpdated } = context;
      const response = await SiteSettingsApi.updateSiteSettings({
        ...siteSettings,
        ...securitySettings,
      });
      siteSettingsUpdated(response.data);
      refreshApplicationContext();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'site.security.updateSuccess',
          defaultMessage: 'Security settings have successfully been updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'site.security.updateError',
            defaultMessage: 'Failed to update security settings',
          })
        ),
        { variant: 'error' }
      );
    }
    setProcessing(false);
  };

  const confirmSecretRegeneration = async () => {
    setProcessing(true);
    try {
      await SiteSettingsApi.regenerateAuthenticationSecret();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'site.security.regenerateSuccess',
          defaultMessage: 'Access tokens have been successfully invalidated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'site.security.regenerateError',
            defaultMessage: 'Failed to invalidate access tokens',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
      setConfirmRegenerateSecret(false);
    }
  };

  return (
    <Container maxWidth="md" id="system-site-security" disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaddedPaper>
            <Typography variant="h5" component="h3" gutterBottom>
              <FormattedMessage id="site.security.title" defaultMessage="Security Settings" />
            </Typography>
            <ValidatedTextField
              disabled={processing}
              name="passwordResetTokenExpiration"
              label={intl.formatMessage({
                id: 'site.security.passwordResetTokenExpiration.label',
                defaultMessage: 'Password reset token expires after (e.g. 1h 30m)',
              })}
              value={passwordResetTokenExpiration}
              onChange={(e) => setPasswordResetTokenExpiration(e.target.value)}
              onKeyDown={submitOnEnter}
              margin="normal"
              variant="outlined"
              required
              fieldErrors={fieldErrors}
            />
            <ValidatedTextField
              disabled={processing}
              required
              name="authenticationTokenExpiration"
              label={intl.formatMessage({
                id: 'site.security.authenticationTokenExpiration.label',
                defaultMessage: 'Authentication token expires after (e.g. 36h)',
              })}
              value={authenticationTokenExpiration}
              onChange={(e) => setAuthenticationTokenExpiration(e.target.value)}
              onKeyDown={submitOnEnter}
              margin="normal"
              variant="outlined"
              fieldErrors={fieldErrors}
            />
            <ValidatedTextField
              disabled={processing}
              required
              name="maxApiKeyPeriod"
              label={intl.formatMessage({
                id: 'site.security.maxApiKeyPeriod.label',
                defaultMessage: 'Maximum time period until API key expiration (e.g. 7d)',
              })}
              value={maxApiKeyPeriod}
              onChange={(e) => setMaxApiKeyPeriod(e.target.value)}
              onKeyDown={submitOnEnter}
              margin="normal"
              variant="outlined"
              fieldErrors={fieldErrors}
            />
            <FormButtons>
              <DefaultButton
                name="updateSecuritySettings"
                onClick={validateAndSaveOrConfirm}
                disabled={processing}
                startIcon={<SaveIcon />}
              >
                <FormattedMessage
                  id="site.security.updateButton"
                  defaultMessage="Update Security Settings"
                />
              </DefaultButton>
            </FormButtons>
            <ConfirmDialog
              id="confirm-update-max-api-key-period"
              title={intl.formatMessage({
                id: 'site.security.confirmUpdatePeriod.title',
                defaultMessage: 'Update Maximum API Key Period',
              })}
              text={intl.formatMessage({
                id: 'site.security.confirmUpdatePeriod.text',
                defaultMessage:
                  'Are you sure you wish to update the maximum API Key period? If existing API Keys have a longer expiration date than the allowed period their expiration dates will be updated.',
              })}
              confirmBtnText={intl.formatMessage({
                id: 'site.security.confirmUpdatePeriod.confirmButton',
                defaultMessage: 'Continue',
              })}
              confirmAction={saveValidatedSiteSettings}
              closeAction={() => setConfirmUpdateMaxApiKeyPeriod(false)}
              isOpen={confirmUpdateMaxApiKeyPeriod}
              disabled={processing}
            />
          </PaddedPaper>
        </Grid>
        <Grid item xs={12}>
          <PaddedPaper>
            <Typography variant="h5" component="h3" gutterBottom>
              <FormattedMessage
                id="site.security.invalidate.title"
                defaultMessage="Invalidate Access Tokens"
              />
            </Typography>
            <MessageBox
              message={intl.formatMessage({
                id: 'site.security.invalidate',
                defaultMessage:
                  'This feature will invalidate all granted access tokens, forcing all users to re-authenticate',
              })}
              level="info"
            />
            <FormButtons>
              <DefaultButton
                name="regenerateAccessTokens"
                onClick={() => setConfirmRegenerateSecret(true)}
                disabled={processing}
              >
                <FormattedMessage
                  id="site.security.invalidateButton"
                  defaultMessage="Invalidate Access Tokens"
                />
              </DefaultButton>
            </FormButtons>
            <ConfirmDialog
              id="confirm-invalidate-tokens"
              title={intl.formatMessage({
                id: 'site.security.confirmInvalidate.title',
                defaultMessage: 'Invalidate Access Tokens',
              })}
              text={intl.formatMessage({
                id: 'site.security.confirmInvalidate.text',
                defaultMessage:
                  'Are you sure you wish to invalidate all granted access tokens and force all users to re-authenticate?',
              })}
              confirmBtnText={intl.formatMessage({
                id: 'site.security.confirmInvalidate.confirmButton',
                defaultMessage: 'Invalidate Tokens',
              })}
              confirmAction={confirmSecretRegeneration}
              closeAction={() => setConfirmRegenerateSecret(false)}
              isOpen={confirmRegenerateSecret}
              disabled={processing}
            />
          </PaddedPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Security;
