import React, { FC, useState } from 'react';

import { Chip, InputAdornment, IconButton, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import ValidatedTextField, { ValidatedTextFieldProps } from './ValidatedTextField';
import { onEnterCallback } from '../../util';

interface ChipData {
  key: string;
  label: string;
}

interface ValidatedChipsArrayFieldProps
  extends Omit<ValidatedTextFieldProps, 'onChange' | 'value'> {
  chips: ChipData[];
  onChange: (chipKeys: string[]) => void;
  endAdornmentAriaLabel: string;
}

const Chiplist = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  padding: 0.5,
  margin: 0,
});

const ValidatedChipsArrayField: FC<ValidatedChipsArrayFieldProps> = ({
  label,
  endAdornmentAriaLabel,
  chips,
  onChange,
  select,
  ...rest
}) => {
  const [newChip, setNewChip] = useState<string>('');

  const handleDelete = (chipToDeleteKey: string) => () => {
    onChange(chips.filter((chip) => chip.key !== chipToDeleteKey).map((chip) => chip.key));
  };

  const handleAddChip = () => {
    if (newChip && !chips.find((chip) => chip.key === newChip)) {
      const updatedChips = [...chips.map((chip) => chip.key), newChip];
      onChange(updatedChips);
    }
    setNewChip('');
  };

  const submitOnEnter = onEnterCallback(handleAddChip);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (select) {
      const updatedChips = [...chips.map((chip) => chip.key), event.target.value];
      onChange(updatedChips);
    } else {
      setNewChip(event.target.value);
    }
  };

  return (
    <div>
      <ValidatedTextField
        label={label}
        onKeyDown={submitOnEnter}
        InputProps={{
          endAdornment: !select && (
            <InputAdornment position="end">
              <IconButton aria-label={endAdornmentAriaLabel} onClick={handleAddChip} size="large">
                <AddIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleInputChange}
        value={newChip}
        select={select}
        {...rest}
      />
      <Chiplist>
        {chips.map((chip) => (
          <Chip
            component="li"
            key={chip.key}
            label={chip.label}
            onDelete={handleDelete(chip.key)}
            sx={{ mr: 0.5 }}
          />
        ))}
      </Chiplist>
    </div>
  );
};

export default ValidatedChipsArrayField;
