import { AxiosPromise } from 'axios';

import {
  ProjectImportJobsResponse,
  ProjectImportJobDetail,
  ProjectImportJobsRequest,
} from '../../types';
import { AXIOS, FileUploadConfig } from '../endpoints';
import { createFormDataFromFile } from '../media';

export function fetchJobs(
  request: ProjectImportJobsRequest
): AxiosPromise<ProjectImportJobsResponse> {
  return AXIOS.get('/project_import/', { params: request });
}

export function createJob(
  file: File,
  config: FileUploadConfig
): AxiosPromise<ProjectImportJobDetail> {
  return AXIOS.post(`/project_import/`, createFormDataFromFile(file), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...(config || {}),
  });
}
