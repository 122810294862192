import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Grid } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';

import * as SubmissionApi from '../../../../../../api/submission/submission';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import { FullWidthButton, FormButtons } from '../../../../../../components';
import {
  SubmissionErrorMessage,
  ConfigurationIssueMessage,
} from '../../../../../components/submission/SubmissionErrorMessage';
import { AppBarStatsContext } from '../../../../../../contexts/app-bar-stats';
import { SubmissionState } from '../../../../../../types';
import { intl } from '../../../../../../Internationalization';

import { OpenSubmissionContext } from '../OpenSubmissionContext';

const SubmissionError: FC = () => {
  const appBarStatsContext = useContext(AppBarStatsContext);
  const [processing, setProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { submissionUpdated, submission, specificationValid } = useContext(OpenSubmissionContext);

  const completeMySubmission = async () => {
    setProcessing(true);
    try {
      const updatedSubmission = (await SubmissionApi.close(submission.reference)).data;
      submissionUpdated(updatedSubmission);
      appBarStatsContext.refresh();
    } catch (error: any) {
      setProcessing(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.error.completeError',
            defaultMessage: 'Failed to complete submission',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const cancelMySubmission = async () => {
    setProcessing(true);
    try {
      const updatedSubmission = (await SubmissionApi.cancel(submission.reference)).data;
      submissionUpdated(updatedSubmission);
      appBarStatsContext.refresh();
    } catch (error: any) {
      setProcessing(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.error.cancelError',
            defaultMessage: 'Failed to cancel submission',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} lg={8} xl={6}>
        {specificationValid ? (
          <SubmissionErrorMessage submission={submission} />
        ) : (
          <ConfigurationIssueMessage submission={submission} />
        )}
        <FormButtons>
          <FullWidthButton
            name="cancelSubmission"
            label={intl.formatMessage({
              id: 'openSubmission.error.cancelButton',
              defaultMessage: 'Cancel Submission',
            })}
            processing={processing}
            color="secondary"
            disabled={
              (specificationValid && submission.state !== SubmissionState.FINISHED) ||
              !submission.canUpdateSubmission
            }
            startIcon={<StopIcon />}
            onClick={specificationValid ? completeMySubmission : cancelMySubmission}
          />
        </FormButtons>
      </Grid>
    </Grid>
  );
};

export default SubmissionError;
