import { useState, useEffect, useCallback, useContext } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { Typography, Link, Container, Grid, Box } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ScheduleIcon from '@mui/icons-material/Schedule';

import * as ScheduleApi from '../../../../api/schedule/schedule';
import * as PreviewScheduleApi from '../../../../api/schedule/cronSchedulePreview';
import { extractErrorMessage } from '../../../../api/endpoints';
import { useErrorBlock } from '../../../../contexts/error-block';
import { HeaderBreadcrumb, AppDecorator, PaperFormSkeleton } from '../../../../components';
import { intl } from '../../../../Internationalization';
import { ScheduleDetail, UpdateScheduleRequest } from '../../../../types';
import { useRequiredParams, useTitle } from '../../../../hooks';

import { MyAssignmentContext } from '../MyAssignmentContext';
import ScheduleSettingsForm from './ScheduleSettingsForm';
import { CronSchedulePreviewRequest } from '../../../../types/cronSchedulePreview';
import { useNavigationPrompt } from '../../../../contexts/navigation-prompt/context';
import ScheduleManagement from './ScheduleManagement';
import { ScheduleEvents } from './ScheduleEvents';

const Schedule = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.schedule',
      defaultMessage: 'Schedule',
    })
  );
  const { enqueueSnackbar } = useSnackbar();
  const { scheduleKey } = useRequiredParams<{ scheduleKey: string }>();
  const { assignment } = useContext(MyAssignmentContext);
  const { raiseError } = useErrorBlock();
  const { clearNavigationBlock } = useNavigationPrompt();

  const [schedule, setSchedule] = useState<ScheduleDetail>();
  const [processing, setProcessing] = useState<boolean>(false);

  const fetchSchedule = useCallback(async () => {
    try {
      const response = await ScheduleApi.getSchedule(scheduleKey);
      setSchedule(response.data);
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.schedules.loadScheduleError',
            defaultMessage: 'Failed to fetch schedule',
          })
        )
      );
    }
  }, [scheduleKey, raiseError]);

  const saveSchedule = async (updatedSchedule: UpdateScheduleRequest) => {
    try {
      const response = await ScheduleApi.updateSchedule(scheduleKey, updatedSchedule);
      setSchedule(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'myAssignment.schedules.settings.saveSuccess',
          defaultMessage: 'Schedule updated',
        }),
        { variant: 'success' }
      );
      clearNavigationBlock();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.schedules.settings.saveError',
            defaultMessage: 'Failed to update schedule',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const previewSchedule = async (updatedSchedule: CronSchedulePreviewRequest) => {
    try {
      const response = await PreviewScheduleApi.previewCronSchedule(updatedSchedule);
      setSchedule({ ...schedule!, nextOccurrences: response.data.nextOccurrences });
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.schedules.settings.previewError',
            defaultMessage: 'Failed to preview schedule',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    fetchSchedule();
  }, [fetchSchedule]);

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Link component={RouterLink} to={`/my_assignments/${assignment.key}/schedules`}>
        <AssignmentIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.schedules.breadcrumb.assignment.titleAccess',
            defaultMessage: 'Assignment',
          })}
        />
        {assignment.reference}
      </Link>
      <Typography>
        <ScheduleIcon
          titleAccess={intl.formatMessage({
            id: 'myAssignment.schedules.breadcrumb.schedule',
            defaultMessage: 'Schedule',
          })}
        />
        <FormattedMessage
          id="myAssignment.schedules.breadcrumb.schedule"
          defaultMessage="Schedule"
        />
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderTabs={undefined} renderBreadcrumb={renderBreadcrumb}>
      <Container maxWidth="lg" disableGutters>
        <Box display="flex" columnGap={2} id="schedule-settings">
          {schedule ? (
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <ScheduleSettingsForm
                schedule={schedule}
                handleSaveSchedule={saveSchedule}
                handlePreviewSchedule={previewSchedule}
                processing={processing}
                setProcessing={setProcessing}
              />
              <ScheduleEvents scheduleKey={scheduleKey} active={schedule.active} />
              <ScheduleManagement
                schedule={schedule}
                processing={processing}
                setProcessing={setProcessing}
              />
            </Grid>
          ) : (
            <PaperFormSkeleton contentRows={4} sx={{ width: '100%' }} />
          )}
        </Box>
      </Container>
    </AppDecorator>
  );
};

export default Schedule;
