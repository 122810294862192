import { FC, useContext, Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import * as SubmissionApi from '../../../api/submission/submission';
import { DefaultButton, ConfirmDialog } from '../../../components';
import { SubmissionContext } from './SubmissionContext';
import { extractErrorMessage } from '../../../api/endpoints';
import { intl } from '../../../Internationalization';
import { FormattedMessage } from 'react-intl';

const RemoveSubmissionMedia: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { submission, submissionUpdated } = useContext(SubmissionContext);
  const [removeMediaConfirm, setRemoveMediaConfirm] = useState(false);

  const remove = () => {
    setRemoveMediaConfirm(false);
    SubmissionApi.removeSubmissionMedia(submission.reference)
      .then((response) => {
        submissionUpdated(response.data);
        enqueueSnackbar(
          intl.formatMessage({
            id: 'submission.removeSubmissionMedia.removeSuccess',
            defaultMessage: 'Media has been removed',
          }),
          { variant: 'success' }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'submission.removeSubmissionMedia.removeError',
              defaultMessage: 'Media removal failed',
            })
          ),
          { variant: 'error' }
        );
      });
  };

  return (
    <>
      <ConfirmDialog
        id="confirm-remove-submissionn-media"
        isOpen={removeMediaConfirm}
        confirmAction={remove}
        closeAction={() => setRemoveMediaConfirm(false)}
        title={intl.formatMessage({
          id: 'submission.removeSubmissionMedia.confirmRemoveMedia.title',
          defaultMessage: 'Remove media?',
        })}
        text={intl.formatMessage({
          id: 'submission.removeSubmissionMedia.confirmRemoveMedia.text',
          defaultMessage:
            'Are you sure you wish to permanently delete the media from this submisision?',
        })}
      />
      <DefaultButton
        color="secondary"
        disabled={submission.mediaRemoved}
        onClick={() => setRemoveMediaConfirm(true)}
        startIcon={<DeleteForeverIcon />}
      >
        <FormattedMessage
          id="submission.removeSubmissionMedia.removeButton"
          defaultMessage="Remove Media"
        />
      </DefaultButton>
    </>
  );
};

export default RemoveSubmissionMedia;
