import { useInterval } from '.';
import { VirusCheckedMediaDetail, VirusScanState } from '../types';

interface useMediaVirusCheckingPollerProps {
  media?: VirusCheckedMediaDetail | VirusCheckedMediaDetail[];
  pollApi: () => Promise<void>;
}

const POLL_INTERVAL = 5000;

const useMediaVirusCheckingPoller = ({ media, pollApi }: useMediaVirusCheckingPollerProps) => {
  const pollIfRequired = async () => {
    if (shouldPoll() && pollApi) {
      try {
        await pollApi();
      } catch (error) {}
    }
  };

  useInterval(pollIfRequired, POLL_INTERVAL);

  const shouldPoll = () => {
    if (Array.isArray(media)) {
      for (var val of media!) {
        if (val.virusScanState === VirusScanState.PENDING) {
          return true;
        }
      }
      return false;
    } else {
      return media && media.virusScanState === VirusScanState.PENDING;
    }
  };
};

export default useMediaVirusCheckingPoller;
