import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { intl } from '../../../Internationalization';
import { IconBox } from '../../../components';
import { SuccessIcon } from '../../../components/icons';
import { AuthenticatedContext } from '../../../contexts/authentication';
import { submissionActionNotFinished } from '../../../util';
import { ACTION_KIND_METADATA, ActionKind, SubmissionDetail } from '../../../types';
import {
  CopyInputsSubmissionActionResult,
  DebugSubmissionActionResult,
  SUBMISSION_ACTION_OUTCOME_METADATA,
  SUBMISSION_ACTION_STATE_METADATA,
  SubmissionActionResult,
  SubmissionActionResults,
  SubmissionActionState,
} from '../../../types/submissionAction';

interface ActionResultsProps {
  submission: SubmissionDetail;
}

const ActionResults: React.FC<ActionResultsProps> = ({ submission }) => {
  const { me } = useContext(AuthenticatedContext);
  const { actionResults } = submission;

  if (!me.receiverPermissions || !actionResults || !actionResults.length) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            pr: 5,
            pl: 2,
            minHeight: 48,
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            borderRadius: 0,
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={3}>
              <IconBox>
                <FormattedMessage
                  id="submission.actionResults.title"
                  defaultMessage="Action Results"
                />
              </IconBox>
            </Grid>
            <Grid item xs={4}>
              <IconBox>
                {submission.actionResults.find((action) =>
                  action.steps.find((step) => submissionActionNotFinished(step.state))
                ) ? (
                  <CircularProgress
                    size={20}
                    color="secondary"
                    aria-label={intl.formatMessage({
                      id: 'submission.actionResults.actionSummary.inProgress.titleAccess',
                      defaultMessage: 'Action in progress',
                    })}
                  />
                ) : (
                  <SuccessIcon />
                )}
                <FormattedMessage
                  id="submission.actionResults.actionSummary.processedCount"
                  defaultMessage="Processed {count} of {total} actions"
                  values={{
                    count: submission.actionResults.filter((result) =>
                      result.steps.find((step) => !submissionActionNotFinished(step.state))
                    ).length,
                    total: submission.actionResults.length,
                  }}
                />
              </IconBox>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {actionResults.map((action) => (
          <Accordion key={action.key} className={`action-result action-type-${action.kind}`}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  titleAccess={intl.formatMessage({
                    id: 'submission.actionResults.expandResult.titleAccess',
                    defaultMessage: 'Expand action result',
                  })}
                />
              }
            >
              <ActionAccordionContent action={action} />
            </AccordionSummary>
            <AccordionDetails className="action-result-detail">
              {renderActionPanelDetails(action, submission)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default ActionResults;

interface ActionAccordionContentProps {
  action: SubmissionActionResult;
}
const ActionAccordionContent: FC<ActionAccordionContentProps> = ({ action }) => {
  const { icon: Icon, label } = SUBMISSION_ACTION_OUTCOME_METADATA[action.outcome];
  return (
    <Grid container spacing={1} className="action-result-header">
      <Grid item xs={3}>
        <IconBox>
          <Tooltip title={action.name}>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 'calc(100% - 28px)',
                display: 'inline-block',
              }}
            >
              {action.name}
            </Typography>
          </Tooltip>
        </IconBox>
      </Grid>
      <Grid item xs={4}>
        <IconBox>{ACTION_KIND_METADATA[action.kind].label}</IconBox>
      </Grid>
      <Grid item xs={4}>
        <IconBox>
          {action.steps.find((step) => step.state === SubmissionActionState.RUNNING) ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <Icon />
          )}
          &nbsp;{label}
        </IconBox>
      </Grid>
    </Grid>
  );
};

const renderActionPanelDetails = (
  action: SubmissionActionResults,
  submission: SubmissionDetail
) => {
  switch (action.kind) {
    case ActionKind.COPY_INPUTS:
      return <CopyInputActionResult action={action} submission={submission} />;
    case ActionKind.DEBUG:
      return <DebugActionResult action={action} submission={submission} />;
  }
};

interface CopyInputActionResultProps {
  action: CopyInputsSubmissionActionResult;
  submission: SubmissionDetail;
}
const CopyInputActionResult: FC<CopyInputActionResultProps> = ({ action, submission }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>
          <FormattedMessage
            id="submission.actionResults.copyInputActionResult.inputsHeader"
            defaultMessage="Inputs"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage
            id="submission.actionResults.copyInputActionResult.stateHeader"
            defaultMessage="State"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage
            id="submission.actionResults.copyInputActionResult.fileHeader"
            defaultMessage="File"
          />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {action.steps.map(({ inputKeys, inputDestination, state }, index) => {
        const { icon: Icon, label } = SUBMISSION_ACTION_STATE_METADATA[state];
        return (
          <TableRow key={index}>
            <TableCell>
              {inputKeys.map((inputKey) => submission.inputsByKey[inputKey].inputName).join(', ')}
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon sx={{ mr: 1 }} />
                {label}
              </Box>
            </TableCell>
            <TableCell>{inputDestination}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);

interface DebugActionResultProps {
  action: DebugSubmissionActionResult;
  submission: SubmissionDetail;
}
const DebugActionResult: FC<DebugActionResultProps> = ({ action, submission }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>
          <FormattedMessage
            id="submission.actionResults.debugActionResult.inputsHeader"
            defaultMessage="Inputs"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage
            id="submission.actionResults.debugActionResult.stateHeader"
            defaultMessage="State"
          />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {action.steps.map(({ inputKeys, state }, index) => {
        const { icon: Icon, label } = SUBMISSION_ACTION_STATE_METADATA[state];
        return (
          <TableRow key={index}>
            <TableCell>
              {inputKeys.map((inputKey) => submission.inputsByKey[inputKey].inputName).join(', ')}
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon sx={{ mr: 1 }} />
                {label}
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);
