import Schema, { Rule } from 'async-validator';
import {
  NOT_BLANK_REGEX,
  duplicateValidator,
  nameLengthValidator,
  notTrimmableValidator,
  referenceLengthValidator,
} from './shared';
import { intl } from '../Internationalization';
import { assignmentByReference } from '../api/assignment/assignmentLookup';
import { savedMappingByName } from '../api/saved-mapping/savedMappingLookup';

export function assignmentSettingsValidator(
  getSpecificationKey: () => string,
  getReference?: () => string
) {
  return new Schema({
    reference: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'assignment.validator.reference.required',
          defaultMessage: 'Please provide a reference',
        }),
      },
      notTrimmableValidator,
      referenceLengthValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        existingValue: getReference,
        checkUnique: (reference: string) => assignmentByReference(getSpecificationKey(), reference),
        alreadyExistsMessage: intl.formatMessage({
          id: 'assignment.validator.reference.unique',
          defaultMessage: 'An assignment with this reference already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'assignment.validator.reference.checkUniqueError',
          defaultMessage: 'There was a problem verifying the assignment reference',
        }),
      }),
    ],
    supplierKey: {
      required: true,
      message: intl.formatMessage({
        id: 'assignment.validator.supplierKey.required',
        defaultMessage: 'Please choose a supplier',
      }),
    },
    specificationKey: {
      required: true,
      message: intl.formatMessage({
        id: 'assignment.validator.specification.required',
        defaultMessage: 'Please choose a specification',
      }),
    },
  });
}

export const COPY_ASSIGNMENT_VALIDATOR = new Schema({
  sourceSpecificationKey: {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'assignment.copy.validator.sourceSpecificationKey',
      defaultMessage: 'Please choose a specification',
    }),
  },
});

export const COPY_SUPPLIERS_ASSIGNMENT_VALIDATOR = new Schema({
  sourceSupplierKey: {
    required: true,
    message: intl.formatMessage({
      id: 'suppliers.assignment.copy.validator.sourceSupplierKey',
      defaultMessage: 'Please choose a supplier',
    }),
  },
});

export const copySavedMappingValidator = (getAssignmentKey: () => string): Schema =>
  new Schema({
    name: savedMappingNameValidator(getAssignmentKey),
  });

export const savedMappingSettingsValidator = (
  getAssignmentKey: () => string,
  getExistingName: () => string
): Schema =>
  new Schema({
    name: savedMappingNameValidator(getAssignmentKey, getExistingName),
  });

export const createSavedMappingValidator = (getAssignmentKey: () => string): Schema =>
  new Schema({
    name: savedMappingNameValidator(getAssignmentKey),
  });

export const savedMappingNameValidator = (
  getAssignmentKey: () => string,
  getExistingName?: () => string
): Rule => [
  {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'assignmentSavedMapping.settings.validator.name.required',
      defaultMessage: 'Please provide a name',
    }),
  },
  nameLengthValidator,
  notTrimmableValidator,
  duplicateValidator({
    regex: NOT_BLANK_REGEX,
    existingValue: getExistingName,
    checkUnique: (name: string) => savedMappingByName(getAssignmentKey(), name),
    alreadyExistsMessage: intl.formatMessage({
      id: 'assignmentSavedMapping.settings.validator.name.unique',
      defaultMessage: 'A saved mapping with this name already exists',
    }),
    errorMessage: intl.formatMessage({
      id: 'assignmentSavedMapping.settings.validator.name.checkUniqueError',
      defaultMessage: 'There was a problem verifying the saved mapping name',
    }),
  }),
];
