import { intl } from '../Internationalization';

export interface SpecificationFieldDetail {
  name: string;
  description: string;
  required: boolean;
  type: FieldType;
}

export enum FieldType {
  STRING = 'STRING',
  DOUBLE = 'DOUBLE',
  LONG = 'LONG',
  INTEGER = 'INTEGER',
  BOOLEAN = 'BOOLEAN',
}

export const FIELD_TYPES = [
  FieldType.STRING,
  FieldType.DOUBLE,
  FieldType.LONG,
  FieldType.INTEGER,
  FieldType.BOOLEAN,
];

export interface FieldTypeMetadata {
  label: string;
}
export const FIELD_TYPES_METADATA: {
  [type in FieldType]: FieldTypeMetadata;
} = {
  STRING: {
    label: intl.formatMessage({
      id: 'specification.fieldType.metadata.string.label',
      defaultMessage: 'String',
    }),
  },
  DOUBLE: {
    label: intl.formatMessage({
      id: 'specification.fieldType.metadata.double.label',
      defaultMessage: 'Double',
    }),
  },
  LONG: {
    label: intl.formatMessage({
      id: 'specification.fieldType.metadata.long.label',
      defaultMessage: 'Long',
    }),
  },
  INTEGER: {
    label: intl.formatMessage({
      id: 'specification.fieldType.metadata.integer.label',
      defaultMessage: 'Integer',
    }),
  },
  BOOLEAN: {
    label: intl.formatMessage({
      id: 'specification.fieldType.metadata.boolean.label',
      defaultMessage: 'Boolean',
    }),
  },
};

export interface SpecificationFieldConfig {
  name: string;
  description: string;
  required: boolean;
  type: FieldType;
}

export interface CreateSpecificationFieldRequest {
  type: FieldType;
  description?: string;
  name?: string;
}
