import { FC } from 'react';

import * as SupplierMediaApi from '../../../../../api/supplier/supplierMedia';
import { SupplierMetadata } from '../../../../../types';
import { extractInitials } from '../../../../../util';

import { SubmissionAvatar } from './SubmissionAvatar';
import { SummaryInfo } from './SummaryInfo';

interface SubmissionSupplierAvatarProps {
  supplier: SupplierMetadata;
  gutterBottom?: boolean;
}

export const SubmissionSupplierAvatar: FC<SubmissionSupplierAvatarProps> = ({
  supplier,
  gutterBottom,
}) => {
  const imageUri =
    supplier.avatar.image && SupplierMediaApi.downloadImageUri(supplier.key, supplier.avatar.image);
  return (
    <SummaryInfo
      infoIcon={
        <SubmissionAvatar
          initials={extractInitials(supplier.name)}
          backgroundColor={supplier.avatar.color}
          borderRadius={5}
          imageSrc={imageUri}
          objectFit="contain"
        />
      }
      infoText={supplier.name}
      gutterBottom={gutterBottom}
    />
  );
};
