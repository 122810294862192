import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Box } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../../components';
import { ErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';
import { useRouterTab, useTitle } from '../../../hooks';

import SystemHeader from '../SystemHeader';

import Upload from './Upload';
import ImportHistory from './ImportHistory';

const UserImportRouting: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.system.userImport',
      defaultMessage: 'User Import',
    })
  );
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="user-import-tabs" value={routerTab}>
      <RouterTab
        id="upload-tab"
        value="upload"
        label={intl.formatMessage({
          id: 'userImport.routing.tabs.upload',
          defaultMessage: 'Upload',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="import-history-tab"
        value="import_history"
        label={intl.formatMessage({
          id: 'userImport.routing.tabs.history',
          defaultMessage: 'Import History',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'userImport.routing.title',
          defaultMessage: 'User Import',
        })}
        icon={GroupAddIcon}
      />
      <AppTabs />
      <Box p={3}>
        <ErrorBlock>
          <Routes>
            <Route path="upload" element={<Upload />} />
            <Route path="import_history" element={<ImportHistory />} />
            <Route path="*" element={<Navigate to="upload" replace />} />
          </Routes>
        </ErrorBlock>
      </Box>
    </AppDecorator>
  );
};

export default UserImportRouting;
