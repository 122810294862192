import { FC, useContext } from 'react';
import { Container, Grid } from '@mui/material';

import ReceiverAdminOnly from '../../../../../components/ReceiverAdminOnly';

import SpecificationSettingsForm from './SpecificationSettingsForm';
import SpecificationManagement from './SpecificationManagement';
import { useTitle } from '../../../../../hooks';
import { SpecificationContext } from '../SpecificationContext';
import { intl } from '../../../../../Internationalization';

const SpecificationSettings: FC = () => {
  const { specification } = useContext(SpecificationContext);
  useTitle(
    intl.formatMessage(
      { id: 'title.specification', defaultMessage: 'Specification ({specificationName})' },
      { specificationName: specification.name }
    )
  );
  return (
    <Container maxWidth="md" id="project-specification-settings" disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SpecificationSettingsForm />
        </Grid>
        <ReceiverAdminOnly>
          <Grid item xs={12}>
            <SpecificationManagement />
          </Grid>
        </ReceiverAdminOnly>
      </Grid>
    </Container>
  );
};

export default SpecificationSettings;
