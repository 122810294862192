import { FC, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Grid, Container } from '@mui/material';

import * as MailTemplateApi from '../../../../api/mail/mailTemplate';
import { extractErrorMessage } from '../../../../api/endpoints';
import {
  MailTemplateMetadata,
  MailTemplateDetail,
  MailTemplateSettings,
  ValidationErrors,
} from '../../../../types';

import { useErrorBlock } from '../../../../contexts/error-block';
import { intl } from '../../../../Internationalization';

import TemplateCard from './TemplateCard';
import TemplateEditor from './TemplateEditor';
import TemplateEditorSkeleton from './TemplateEditorSkeleton';
import { mailTemplates } from './templatesMetadata';

const MailTemplates: FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<MailTemplateMetadata>(mailTemplates[0]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>();
  const [mailTemplate, setMailTemplate] = useState<MailTemplateDetail>();
  const { enqueueSnackbar } = useSnackbar();
  const { raiseError } = useErrorBlock();

  useEffect(() => {
    setMailTemplate(undefined);
    setValidationErrors(undefined);
    MailTemplateApi.getGlobalMailTemplate(selectedTemplate.name)
      .then((result) => {
        setMailTemplate(result.data);
      })
      .catch((error) => {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'mail.templates.loadError',
              defaultMessage: 'Failed to load mail template',
            })
          )
        );
      });
  }, [selectedTemplate, raiseError]);

  const saveMailTemplate = (name: string, template: MailTemplateSettings) => {
    MailTemplateApi.updateGlobalMailTemplate(name, template)
      .then((result) => {
        setMailTemplate(result.data);
        enqueueSnackbar(
          intl.formatMessage({
            id: 'mail.templates.saveSuccess',
            defaultMessage: 'Mail template updated',
          }),
          { variant: 'success' }
        );
        setValidationErrors(undefined);
      })
      .catch((error: any) => {
        if (error?.response?.status === 400) {
          setValidationErrors(error.response.data);
        } else {
          setValidationErrors(undefined);
          enqueueSnackbar(
            extractErrorMessage(
              error,
              intl.formatMessage({
                id: 'mail.templates.saveError',
                defaultMessage: 'Failed to update mail template',
              })
            ),
            { variant: 'error' }
          );
        }
      });
  };

  return (
    <Grid id="system-mail-templates" container spacing={3} alignItems="stretch">
      {mailTemplates.map((template) => (
        <Grid item xs={6} md={4} xl={3} key={template.name}>
          <TemplateCard
            selected={selectedTemplate.name === template.name}
            onSelect={() => setSelectedTemplate(template)}
            template={template}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Container maxWidth="lg" disableGutters>
          {mailTemplate ? (
            <TemplateEditor
              templateMetadata={selectedTemplate}
              template={mailTemplate}
              onSave={saveMailTemplate}
              validationErrors={validationErrors}
            />
          ) : (
            <TemplateEditorSkeleton />
          )}
        </Container>
      </Grid>
    </Grid>
  );
};

export default MailTemplates;
