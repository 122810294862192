import { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { intl } from '../../../../Internationalization';
import { extractErrorMessage } from '../../../../api/endpoints';
import * as ScheduleApi from '../../../../api/schedule/schedule';
import {
  PaddedPaper,
  ActiveAvatar,
  ListItemActionArea,
  DefaultButton,
  ConfirmDialog,
} from '../../../../components';
import { ScheduleDetail } from '../../../../types';
import { useNavigationPrompt } from '../../../../contexts/navigation-prompt';

interface ScheduleManagementProps {
  schedule: ScheduleDetail;
  processing: boolean;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduleManagement: FC<ScheduleManagementProps> = ({
  schedule,
  processing,
  setProcessing,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { clearNavigationBlock } = useNavigationPrompt();
  const navigate = useNavigate();

  const [deleted, setDeleted] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (deleted) {
      navigate('..');
    }
  }, [deleted, navigate]);

  const handleDelete = async () => {
    setProcessing(true);
    try {
      await ScheduleApi.deleteSchedule(schedule.key);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'myAssignments.schedule.management.deleted',
          defaultMessage: 'Schedule deleted.',
        }),
        { variant: 'success' }
      );
      clearNavigationBlock();
      setDeleted(true);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignments.schedule.management.deleteError',
            defaultMessage: 'Failed to delete schedule.',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Grid item xs={12}>
      <PaddedPaper>
        <Typography variant="h5" component="h3" gutterBottom>
          <FormattedMessage
            id="myAssignments.schedule.management.title"
            defaultMessage="Manage Schedule"
          />
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <ActiveAvatar activated="inactive">
                <DeleteForeverIcon />
              </ActiveAvatar>
            </ListItemAvatar>
            <ListItemText
              primary={intl.formatMessage({
                id: 'myAssignments.schedule.management.deleteSchedule',
                defaultMessage: 'Delete Schedule',
              })}
              secondary={intl.formatMessage({
                id: 'myAssignments.schedule.management.description',
                defaultMessage:
                  'Permanently remove the schedule and all related submissions scheduled in the future.',
              })}
            />
            <ListItemActionArea>
              <DefaultButton
                name="deleteSchedule"
                color="grey"
                onClick={() => setDeleteDialogOpen(true)}
              >
                <FormattedMessage
                  id="myAssignments.schedule.management.deleteScheduleButton"
                  defaultMessage="Delete Schedule"
                />
              </DefaultButton>
            </ListItemActionArea>
          </ListItem>
        </List>
        <ConfirmDialog
          id="confirm-delete-schedule"
          isOpen={!!deleteDialogOpen}
          title={intl.formatMessage({
            id: 'myAssignments.schedule.management.confirmDelete.title',
            defaultMessage: 'Delete Schedule',
          })}
          text={intl.formatMessage({
            id: 'myAssignments.schedule.management.confirmDelete.text',
            defaultMessage: 'Are you sure you wish to delete this schedule?',
          })}
          confirmBtnText={intl.formatMessage({
            id: 'myAssignments.schedule.management.confirmDelete.button',
            defaultMessage: 'Delete Schedule',
          })}
          confirmAction={handleDelete}
          closeAction={() => setDeleteDialogOpen(false)}
          disabled={processing}
          maxWidth="sm"
          fullWidth
        />
      </PaddedPaper>
    </Grid>
  );
};

export default ScheduleManagement;
