import { FC, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { GeoJsonLayer } from '@deck.gl/layers';
import Layer from '@deck.gl/core/lib/layer';
import { RGBAColor } from '@deck.gl/core/utils/color';
import { PickInfo } from '@deck.gl/core/lib/deck';
import { Position } from '@deck.gl/core/utils/positions';

import { EditableGeoJsonLayer, GeoJsonEditMode } from 'nebula.gl';
import { Feature } from 'geojson';

import { extractErrorMessage } from '../../../../../../api/endpoints';
import { transformCRS } from '../../../../../../api/submission/submissionVisualiser';
import { SUBMISSION_STATE_METADATA, TaskResultKind } from '../../../../../../types';
import { REPORTS_LAYER_NAME, colShift, wgs84 } from '../../../../../../util';
import { intl } from '../../../../../../Internationalization';
import hotspot from '../../../../../../assets/images/hotspot.png';

import { toMySubmissionLink } from '../../MySubmissions';

import { OpenSubmissionContext } from '../OpenSubmissionContext';

import MapDeck from './MapDeck';
import { MapControls } from './map-controls';
import { MapViewerReportsContext } from './MapViewerReportsState';
import { MapViewerLayerStyleContext, MapViewerSessionContext } from './MapViewerSessionState';
import { MapViewerSelectionContext } from './MapViewerSelectionState';
import { SelectedObjectPanel } from './selected-object-panel';
import { SimpleResizableDrawer } from './SimpleResizableDrawer';
import TasksPanel from './TasksPanel';
import ReportsPanel from './ReportsPanel';
import { CenterBoth } from './CenterBoth';
import { VerticalCenter } from './VerticalCenter';

const StyledTabs = styled(Tabs)({
  p: 0,
  m: 0,
  minHeight: 0,
});

const StyledTab = styled(Tab)({
  fontSize: '80%',
  p: 0,
  m: 0,
  minHeight: 0,
});

const consumedHeight = () =>
  document.getElementById('assignments-header')!.getBoundingClientRect().height +
  document.getElementById('app-menu')!.getBoundingClientRect().height;

const MapViewerComponents: FC = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { submission } = useContext(OpenSubmissionContext);

  const { visualiserInfo, srid, loading: loadingInfo } = useContext(MapViewerSessionContext);
  const { layerStyles, hiddenLayers, wireframeLayers } = useContext(MapViewerLayerStyleContext);

  const { selectedObject, setSelectedObject } = useContext(MapViewerSelectionContext);
  const reportsContext = useContext(MapViewerReportsContext);

  const [toolMode, setToolMode] = useState<typeof GeoJsonEditMode | undefined>(undefined);
  const [pickPointMode, setPickPointMode] = useState<boolean>(false);

  const [tab, setTab] = useState<'TASKS' | 'REPORTS'>('TASKS');

  const copyPoint = useCallback(
    async (point: Position) => {
      const transformed = (
        await transformCRS(submission.reference, {
          fromSrid: wgs84,
          toSrid: srid,
          x: point[0],
          y: point[1],
        })
      ).data;

      const coords = '' + transformed.x + ', ' + transformed.y;
      navigator.clipboard
        .writeText(coords)
        .then(() => {
          enqueueSnackbar(
            intl.formatMessage(
              {
                id: 'openSubmission.map.mapViewerComponents.copy.success',
                defaultMessage: 'Copied: {coords}',
              },
              { coords: coords }
            ),
            {
              variant: 'success',
            }
          );
        })
        .catch((error) => {
          enqueueSnackbar(
            extractErrorMessage(
              error,
              intl.formatMessage(
                {
                  id: 'openSubmission.map.mapViewerComponents.copy.failed',
                  defaultMessage: 'Copy failed: {error}',
                },
                { error }
              )
            ),
            {
              variant: 'error',
            }
          );
        });
    },
    [enqueueSnackbar, srid, submission.reference]
  );

  const handleMapClick = useCallback(
    (info: PickInfo<any>, e: MouseEvent) => {
      const feature = info.object as Feature | undefined;
      if (!feature) {
        setSelectedObject(undefined, undefined);
        reportsContext?.setSelectedReportFeature(undefined);
      }

      if (pickPointMode && info.coordinate) {
        copyPoint(info.coordinate);
      }
    },
    [copyPoint, pickPointMode, reportsContext, setSelectedObject]
  );

  const handleFeatureClick = useCallback(
    (info: PickInfo<any>, e: HammerInput, layer: string) => {
      if (info.object?.properties?.id && !toolMode && !pickPointMode) {
        if (selectedObject?.id !== info.object?.properties?.id) {
          let coord = info.coordinate;
          if (info.object?.geometry.type === 'Point') {
            coord = info.object?.geometry.coordinates;
          }

          setSelectedObject(
            {
              id: info.object.properties.id,
              mbr: info.object.properties.mbr,
              layer: layer,
            },
            coord
          );
        }

        reportsContext?.setSelectedReportFeature(undefined);
      }
    },
    [toolMode, pickPointMode, selectedObject?.id, reportsContext, setSelectedObject]
  );

  const getAdditionalLayers = useCallback((): Layer<any>[] => {
    const output = [];

    if (toolMode) {
      output.push(
        new EditableGeoJsonLayer({
          id: 'tool',
          data: [],
          mode: toolMode,
          modeConfig: {
            turfOptions: {
              units: 'metres',
            },
          },
          _subLayerProps: {
            geojson: {
              pointRadiusMinPixels: 5,
              pointRadiusMaxPixels: 5,
              getFillColor: [255, 165, 0, 255],
              getLineColor: [255, 165, 0, 255],
            },
          },
        })
      );
    }

    if (
      reportsContext &&
      reportsContext.reportFeatures.length > 0 &&
      visualiserInfo &&
      !hiddenLayers.includes(REPORTS_LAYER_NAME)
    ) {
      const reportColour: RGBAColor = [
        layerStyles[0].colour.red,
        layerStyles[0].colour.green,
        layerStyles[0].colour.blue,
        255,
      ];

      output.push(
        new GeoJsonLayer({
          id: 'reports',
          data: reportsContext.reportFeatures,
          pointType: 'icon',
          iconAtlas: hotspot,
          getIcon: () => 'hotspot',
          iconMapping: {
            hotspot: {
              x: 0,
              y: 0,
              width: 128,
              height: 128,
              anchorY: 64,
              mask: false,
            },
          },
          pickable: true,
          iconSizeMinPixels: 75,
          iconSizeMaxPixels: 75,

          lineWidthMinPixels: 2,
          lineWidthMaxPixels: 15,

          parameters: {
            depthTest: false,
          },
          _full3d: Boolean(visualiserInfo.hasZ),
          getFillColor: reportColour,
          getLineColor: colShift(reportColour, -60),
          getIconColor: reportColour,
          highlightColor: [71, 245, 95, 185],
          highlightedObjectIndex: reportsContext.selectedReportFeature,
          filled: !wireframeLayers.includes(REPORTS_LAYER_NAME),
          onClick: (info: PickInfo<any>) => {
            if (info.object) {
              const mbr = info.object.properties.report.attributes.geometry;
              setSelectedObject(
                {
                  id: info.object.properties.report.gothicId,
                  mbr: '' + mbr.x0 + ',' + mbr.y0 + ',' + mbr.x1 + ',' + mbr.y1,
                  layer: info.object.properties.report.className,
                },
                undefined
              );
              reportsContext.setSelectedReportFeature(info.object.properties.featureIndex);
              reportsContext.setSelectedReport(info.object.properties.reportIndex);
            }
          },
        })
      );
    }

    return output;
  }, [
    toolMode,
    reportsContext,
    visualiserInfo,
    hiddenLayers,
    layerStyles,
    wireframeLayers,
    setSelectedObject,
  ]);

  return (
    <Box
      id="my-assignment-open-submission-map"
      sx={{
        display: 'flex',
        position: 'relative',
        margin: -3,
        height: `calc(100vh - ${consumedHeight()}px)`,
      }}
    >
      <Box flexGrow={1} display="flex" position="relative">
        {!loadingInfo ? (
          <MapDeck
            handleFeatureClick={handleFeatureClick}
            handleMapClick={handleMapClick}
            getAdditionalLayers={getAdditionalLayers}
            sessionNumObjects={
              submission.taskResults
                ?.filter((task) => task.kind === TaskResultKind.OpenDataTask)
                .reduce((sum, current) => sum + current.processed, 0) ?? 0
            }
          />
        ) : (
          <CenterBoth>
            <CircularProgress />
          </CenterBoth>
        )}
        <Box
          width="100%"
          height="100%"
          position="absolute"
          display="flex"
          sx={{ pointerEvents: 'none' }}
        >
          <Box
            sx={{
              minWidth: '25%',
              maxWidth: '75%',
              height: '40px',
              background: (theme) => theme.palette.background.paper,
              borderBottomRightRadius: '3px',
              borderTopRightRadius: '3px',
              pointerEvents: 'all',
            }}
          >
            <Box display="flex">
              <Tooltip
                title={intl.formatMessage({
                  id: 'openSubmission.map.mapViewerComponents.return',
                  defaultMessage: 'Return to submission',
                })}
              >
                <IconButton onClick={() => navigate(toMySubmissionLink(submission))}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
              <Box
                flexGrow={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                overflow="hidden"
              >
                <span style={{ fontSize: '120%', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <FormattedMessage
                    id="openSubmission.map.mapViewerComponents.return"
                    defaultMessage="Return to submission"
                  />
                </span>
              </Box>
              <VerticalCenter>
                <Typography
                  component="i"
                  sx={{
                    fontSize: '80%',
                    opacity: '0.8',
                    marginLeft: '10px',
                    marginRight: '10px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <FormattedMessage
                    id="openSubmission.map.mapViewerComponents.status"
                    defaultMessage="Status:"
                  />
                  <b> {SUBMISSION_STATE_METADATA[submission.state].label}</b>
                </Typography>
              </VerticalCenter>
            </Box>
          </Box>
          <Box flexGrow={1} />
          <MapControls
            toolMode={toolMode}
            setToolMode={setToolMode}
            pickPointMode={pickPointMode}
            setPickPointMode={setPickPointMode}
          />
          <SelectedObjectPanel />
        </Box>
      </Box>
      <SimpleResizableDrawer id="cacheViewer" minWidth={250}>
        <Paper sx={{ width: '100%', height: '100%' }}>
          <Box display="flex" flexDirection="column" height="100%">
            {reportsContext && reportsContext.objectReports.length > 0 ? (
              <StyledTabs value={tab} onChange={(e, v) => setTab(v)}>
                <StyledTab
                  label={intl.formatMessage({
                    id: 'openSubmission.map.mapViewerComponents.tasksLayers',
                    defaultMessage: 'tasks/layers',
                  })}
                  value="TASKS"
                />
                <StyledTab
                  label={intl.formatMessage({
                    id: 'openSubmission.map.mapViewerComponents.reports',
                    defaultMessage: 'Reports',
                  })}
                  value="REPORTS"
                />
              </StyledTabs>
            ) : null}
            <Box flexGrow={1} position="relative">
              <Box
                width="100%"
                height="100%"
                position="absolute"
                display="flex"
                flexDirection="column"
                style={{ overflowX: 'hidden', overflowY: 'auto' }}
              >
                {tab === 'TASKS' || !reportsContext?.objectReports.length ? (
                  <TasksPanel />
                ) : (
                  <ReportsPanel />
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </SimpleResizableDrawer>
    </Box>
  );
};

export default MapViewerComponents;
