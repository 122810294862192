import { FC } from 'react';
import { VirusScanState, VIRUS_SCANNER_STATE_METADATA } from '../../types';
import { Tooltip } from '@mui/material';

interface VirusScanStateIconProps {
  state: VirusScanState;
}

const VirusScanStateIcon: FC<VirusScanStateIconProps> = ({ state }) => {
  const { icon: Icon, color, label } = VIRUS_SCANNER_STATE_METADATA[state];
  return (
    <Tooltip title={label}>
      <Icon
        sx={{ color }}
        fontSize="large"
        className="VirusScanStateIcon"
        data-virusscanstate={state}
      />
    </Tooltip>
  );
};

export default VirusScanStateIcon;
