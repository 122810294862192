import { FC } from 'react';

import { intl } from '../../../../../Internationalization';
import NewAssignmentForm from './NewAssignmentForm';
import CopyAssignmentsForm from './CopyAssignmentsForm';
import { TabbedDialog } from '../../../../../components/dialog';

interface AddAssignmentDialogProps {
  onClose: () => void;
  onRefreshAssignments: () => void;
}

const AddAssignmentDialog: FC<AddAssignmentDialogProps> = ({ onClose, onRefreshAssignments }) => {
  const tabs = [
    {
      id: 'SINGLE',
      title: intl.formatMessage({
        id: 'assignment.add.tabs.singleAssignment',
        defaultMessage: 'Single Assignment',
      }),
      content: <NewAssignmentForm onClose={onClose} />,
    },
    {
      id: 'COPY',
      title: intl.formatMessage({
        id: 'assignment.add.tabs.copyAssignments',
        defaultMessage: 'Copy Assignments',
      }),
      content: (
        <CopyAssignmentsForm onClose={onClose} onRefreshAssignments={onRefreshAssignments} />
      ),
    },
  ];

  return (
    <TabbedDialog
      title={intl.formatMessage({
        id: 'assignment.create.title',
        defaultMessage: 'Add Assignment',
      })}
      tabs={tabs}
      id="add-assignment-dialog"
      onClose={onClose}
      open={true}
      fullWidth
    />
  );
};

export default AddAssignmentDialog;
