import axios, {
  AxiosError,
  AxiosProgressEvent,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  isAxiosError,
} from 'axios';
import Qs from 'qs';
import { PageResponse } from '../types';

export const API_BASE_URL = '/api/v1/';
export const ACCESS_TOKEN = 'accessToken';

export const generateApiUri = (url: string, config?: AxiosRequestConfig) =>
  generateUri(url, config);

export const generateUri = (url: string, config?: AxiosRequestConfig) =>
  AXIOS.getUri({
    ...config,
    params: {
      ...config?.params,
      token: localStorage.getItem(ACCESS_TOKEN),
    },
    url: constructUriPath(url),
  });

const constructUriPath = (url: string) => {
  if (url.startsWith('/')) {
    url = url.substr(1);
  }
  return url;
};

export const AXIOS = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data: any, headers?: AxiosRequestHeaders) => {
      if (headers && localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
      }

      if ((headers && headers['Content-Type'] !== 'application/json') || data instanceof Blob) {
        return data;
      }

      return JSON.stringify(data);
    },
  ],
  paramsSerializer: (params) => {
    return Qs.stringify(params, { allowDots: true });
  },
});

export interface FileUploadConfig extends RequestConfig {
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export interface RequestConfig {
  signal?: AbortSignal;
}

export type AxiosApiError = AxiosError<{ message: string }>;

/**
 * Extracts an error message from an axios error object if the object contains one.
 *
 * Failing that, the provided default error message is returned.
 */
export const extractErrorMessage = (error: any | unknown, defaultMessage: string) => {
  if (isAxiosError(error)) {
    return error.response?.data.message || error.message || defaultMessage;
  }

  return defaultMessage;
};

/**
 * Wraps a request which should return 404 if the input was "unique".
 *
 * Used to validate unique values, for form validation for example.
 */
export async function entityDoesNotExist(existsPromise: AxiosPromise): Promise<boolean> {
  try {
    await existsPromise;
    return false;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return true;
    }
    throw error;
  }
}

export async function searchReturnsNoResults(
  existsPromise: AxiosPromise<PageResponse<any>>
): Promise<boolean> {
  try {
    return !(await existsPromise).data.total;
  } catch (error: any) {
    throw error;
  }
}
