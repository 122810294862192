import React, { ReactNode, FC, CSSProperties } from 'react';
import { PaperProps } from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { AvatarPaper } from '../../components';

interface ChartPaperProps extends PaperProps {
  children: ReactNode;
  icon: React.ComponentType<SvgIconProps>;
  iconColor: CSSProperties['backgroundColor'];
  title?: string;
  columnMode?: boolean;
}

const ChartPaper: FC<ChartPaperProps> = ({
  sx = [],
  children,
  icon,
  iconColor,
  title,
  columnMode,
  id,
}) => (
  <AvatarPaper
    className="ChartPaper"
    sx={[
      {
        'textAlign': 'center',
        '& canvas': {
          width: '100% !important',
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    icon={icon}
    iconColor={iconColor}
    id={id}
  >
    {title && (
      <Typography
        sx={{ m: (theme) => theme.spacing(1, 9.5, 1.5, 0) }}
        variant="h6"
        color="textSecondary"
      >
        {title}
      </Typography>
    )}
    <Box sx={{ height: columnMode ? '80vh' : 'auto', position: 'relative' }}>{children}</Box>
  </AvatarPaper>
);

export default ChartPaper;
