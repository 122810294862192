export interface CronScheduleDetail {
  startDateTime: string;
  endDateTime: string;
  cronExpression: string;
  numberOfOccurrences: number;
  endCriteria: ScheduleEndCriteria;
  nextOccurrences: string[];
}

export enum ScheduleEndCriteria {
  NONE = 'NONE',
  END_DATE_TIME = 'END_DATE_TIME',
  NUMBER_OF_OCCURRENCES = 'NUMBER_OF_OCCURRENCES',
}

export interface CronSchedulePreviewRequest {
  startDateTime: string;
  endDateTime?: string | null;
  cronExpression: string;
  numberOfOccurrences?: number;
  endCriteria: ScheduleEndCriteria;
}
