import Schema from 'async-validator';
import {
  NOT_BLANK_REGEX,
  duplicateValidator,
  nameLengthValidator,
  notTrimmableValidator,
} from './shared';
import { intl } from '../Internationalization';
import { AXIOS, searchReturnsNoResults } from '../api/endpoints';

export function namedLocationByName(name: string): Promise<boolean> {
  return searchReturnsNoResults(
    AXIOS.get('/named_locations', {
      params: { filter: name, exactMatch: true },
    })
  );
}

export const namedLocationValidator = (getExistingName?: () => string) =>
  new Schema({
    name: namedLocationNameValidator(getExistingName),
    rootDirectory: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'namedLocation.validator.rootDirectory.required',
          defaultMessage: 'Please provide a root directory',
        }),
      },
      {
        max: 255,
        message: intl.formatMessage({
          id: 'namedLocation.validator.rootDirectory.length',
          defaultMessage: 'Must be 255 characters or fewer',
        }),
      },
    ],
  });

export const namedLocationNameValidator = (getExistingName?: () => string) => [
  {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'namedLocation.validator.name.required',
      defaultMessage: 'Please provide a name',
    }),
  },
  nameLengthValidator,
  notTrimmableValidator,
  duplicateValidator({
    regex: NOT_BLANK_REGEX,
    existingValue: getExistingName,
    checkUnique: namedLocationByName,
    alreadyExistsMessage: intl.formatMessage({
      id: 'namedLocation.validator.name.unique',
      defaultMessage: 'A named location with this name already exists',
    }),
    errorMessage: intl.formatMessage({
      id: 'namedLocation.validator.name.checkUniqueError',
      defaultMessage: "There was a problem verifying the named location's name",
    }),
  }),
];
