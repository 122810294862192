import { Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

function restPathToIntegratePath(restPath: string): string {
  if (!restPath.startsWith('/')) {
    restPath = '/' + restPath;
  }

  const type = restPath.split('/')[1];
  const path = restPath.substring(type.length + 2);

  let resourceType: string;
  switch (type) {
    case 'actionmaps':
      resourceType = 'ACTION_MAP';
      break;
    case 'sessiontests':
      resourceType = 'SESSION_TEST';
      break;
    default:
      resourceType = type.toUpperCase().substring(0, type.length - 1);
  }

  return resourceType + '://' + path;
}

interface ResourceLinkProps {
  path: string;
}

export const ResourceLink: FC<ResourceLinkProps> = ({ path }) => {
  const newPath = restPathToIntegratePath(path);
  const split = newPath.split('/');
  const name = split[split.length - 1];

  return (
    <Tooltip title={name}>
      <Typography
        component="span"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          marginLeft: '5px',
        }}
      >
        {name}
      </Typography>
    </Tooltip>
  );
};
