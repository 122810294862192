import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Typography, Box, Grid } from '@mui/material';

import { PaddedPaper, Upload, MessageBox } from '../../../components';
import * as ProjectImportsApi from '../../../api/project/projectImports';
import { ProjectImportJobDetail } from '../../../types';
import { useMediaUpload } from '../../../hooks';
import { intl } from '../../../Internationalization';
import { FileUploadConfig } from '../../../api/endpoints';

interface UploadZipProps {
  onUploaded: (jobDetail: ProjectImportJobDetail) => void;
}

const UploadZip: FC<UploadZipProps> = ({ onUploaded }) => {
  const [upload, uploading, uploadProgress] = useMediaUpload<ProjectImportJobDetail>({
    onUpload: (files: File[], config: FileUploadConfig) =>
      ProjectImportsApi.createJob(files[0], config),
    onUploadComplete: (jobDetail: ProjectImportJobDetail) => onUploaded(jobDetail),
  });

  return (
    <Container maxWidth="xl" id="system-project-import-upload" disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaddedPaper>
            <Typography variant="h5" component="h3" gutterBottom>
              <FormattedMessage
                id="projectImport.uploadZip.title"
                defaultMessage="Project Import"
              />
            </Typography>
            <MessageBox
              level="info"
              message={intl.formatMessage({
                id: 'projectImport.uploadZip.uploadInfo',
                defaultMessage: 'Upload a Zip file below containing the projects to import.',
              })}
            />
            <Box py={2}>
              <Upload
                accept=".zip"
                onDrop={upload}
                uploading={uploading}
                progress={uploadProgress}
              />
            </Box>
          </PaddedPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UploadZip;
