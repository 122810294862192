import { FC, PropsWithChildren } from 'react';

import { Box, BoxProps, TextFieldProps, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

export interface InputTooltipProps extends PropsWithChildren {
  title: string;
  iconVisible?: boolean;
  ml?: number;
  mt?: number;
  fullWidth?: boolean;
}

export const calculateTooltipVariantTopOffset = (
  margin?: BoxProps['margin'],
  variant?: TextFieldProps['variant']
) => {
  const variantOffset = variant === 'standard' ? 2 : 0;
  const calculateMarginOffset = () => {
    switch (margin) {
      case 'normal':
        return 1;
      case 'dense':
        return 0.5;
      case 'none':
      default:
        return 0;
    }
  };
  return variantOffset + calculateMarginOffset();
};

const InputTooltip: FC<InputTooltipProps> = ({
  ml,
  mt,
  title,
  children,
  iconVisible = true,
  fullWidth,
  ...rest
}) => (
  <Box display="flex" alignItems="center" sx={fullWidth ? { width: '100%' } : {}}>
    {children}
    {iconVisible && (
      <Box ml={ml} mt={mt}>
        <Tooltip title={title} placement="right">
          <HelpIcon sx={{ color: (theme) => theme.palette.grey[500] }} {...rest} />
        </Tooltip>
      </Box>
    )}
  </Box>
);

export default InputTooltip;
