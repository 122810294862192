import { useSnackbar } from 'notistack';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { extractErrorMessage } from '../api/endpoints';
import { intl } from '../Internationalization';

function useStickyState<T>(defaultValue: T, key: string): [T, Dispatch<SetStateAction<T>>] {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });

  useEffect(() => {
    const setStorageItem = async () => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error: any) {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'useStickyState.storageFull.error',
              defaultMessage: 'Local storage full, some features may not work as expected.',
            })
          )
        );
      }
    };

    setStorageItem();
  }, [enqueueSnackbar, key, value]);

  return [value, setValue];
}

export default useStickyState;
