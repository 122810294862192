import Schema from 'async-validator';
import { intl } from '../Internationalization';
import {
  NOT_BLANK_REGEX,
  absoluteHttpUriValidator,
  baseUriValidator,
  durationValidator,
  lowercaseCountRegex,
  notTrimmableValidator,
  numberCountRegex,
  periodValidator,
  specialCharacterCountRegex,
  uppercaseCountRegex,
} from './shared';
import { formatDuration } from 'date-fns';
import { parseDuration } from '../util';
import { PasswordRequirements } from '../types';

export const SITE_SETTINGS_VALIDATOR = new Schema({
  name: [
    {
      type: 'string',
      required: true,
      pattern: NOT_BLANK_REGEX,
      message: intl.formatMessage({
        id: 'site.validator.name.required',
        defaultMessage: 'Please provide the site name',
      }),
    },
  ],
  rootUrl: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'site.validator.rootUrl.required',
        defaultMessage: 'Please provide a valid URL, e.g. "https://www.example.com/"',
      }),
    },
    notTrimmableValidator,
    baseUriValidator,
  ],
  loginTitle: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'site.validator.loginTitle.required',
      defaultMessage: 'Please provide the login title',
    }),
  },
  helpUrl: [
    notTrimmableValidator,
    {
      type: 'string',
      pattern: NOT_BLANK_REGEX,
      message: intl.formatMessage({
        id: 'site.validator.helpUrl.required',
        defaultMessage: 'Please provide a valid URL',
      }),
    },
    absoluteHttpUriValidator,
  ],
});

export const SECURITY_SETTINGS_VALIDATOR = new Schema({
  authenticationTokenExpiration: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'site.validator.authenticationTokenExpiration.required',
        defaultMessage: 'Please provide an expiration duration for authentication tokens',
      }),
    },
    durationValidator({ minutes: 5 }),
  ],
  passwordResetTokenExpiration: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'site.validator.passwordResetTokenExpiration.required',
        defaultMessage: 'Please provide an expiration duration for password reset tokens',
      }),
    },
    durationValidator({ minutes: 5 }),
  ],
  maxApiKeyPeriod: [periodValidator({ days: 1 })],
});

export const PASSWORD_REQUIREMENT_VALIDATOR = new Schema({
  minLength: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.minLength.required',
      defaultMessage: 'Please provide the minimum password length',
    }),
  },
  uppercase: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.uppercase.required',
      defaultMessage: 'Please specify the upper case requirement',
    }),
  },
  lowercase: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.lowercase.required',
      defaultMessage: 'Please specify the lower case requirement',
    }),
  },
  numbers: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.numbers.required',
      defaultMessage: 'Please specify the number requirement',
    }),
  },
  specialCharacters: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.specialCharacters.required',
      defaultMessage: 'Please specify the special characters requirement',
    }),
  },
  reuseGenerations: {
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.reuseGenerations.required',
      defaultMessage: 'Reuse generations must be a number',
    }),
  },
  authFailureLockoutThreshold: {
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.authFailureLockoutThreshold.required',
      defaultMessage: 'Authentication failure lockout threshold must be a number',
    }),
  },
  changeInterval: periodValidator({ days: 1 }),
});

interface PasswordRequirementsMetadata {
  label: string;
  message: (count?: number | string) => string;
  validationType?: 'min' | 'max';
  validationRegex?: (value: number) => RegExp;
}

export const PASSWORD_REQUIREMENTS_METADATA: Record<
  keyof PasswordRequirements,
  PasswordRequirementsMetadata
> = {
  minLength: {
    label: intl.formatMessage({
      id: 'passwordRequirements.minLength.label',
      defaultMessage: 'Min length',
    }),
    message: (count) =>
      intl.formatMessage(
        {
          id: 'passwordRequirements.minLength.message',
          defaultMessage:
            'Must contain at least {count} {count, plural, one{character}other{characters}}',
        },
        {
          count,
        }
      ),
    validationType: 'min',
  },
  uppercase: {
    label: intl.formatMessage({
      id: 'passwordRequirements.uppercase.label',
      defaultMessage: 'Uppercase',
    }),
    message: (count) =>
      intl.formatMessage(
        {
          id: 'passwordRequirements.uppercase.message',
          defaultMessage:
            'Must contain at least {count} uppercase {count, plural, one{letter}other{letters}}',
        },
        {
          count,
        }
      ),
    validationRegex: (value) => uppercaseCountRegex(value),
  },
  lowercase: {
    label: intl.formatMessage({
      id: 'passwordRequirements.lowercase.label',
      defaultMessage: 'Lowercase',
    }),
    message: (count) =>
      intl.formatMessage(
        {
          id: 'passwordRequirements.lowercase.message',
          defaultMessage:
            'Must contain at least {count} lowercase {count, plural, one{letter}other{letters}}',
        },
        {
          count,
        }
      ),
    validationRegex: (value) => lowercaseCountRegex(value),
  },
  numbers: {
    label: intl.formatMessage({
      id: 'site.passwordRequirements.numbers.label',
      defaultMessage: 'Numbers',
    }),
    message: (count) =>
      intl.formatMessage(
        {
          id: 'passwordRequirements.numbers.message',
          defaultMessage:
            'Must contain at least {count} {count, plural, one{number}other{numbers}}',
        },
        {
          count,
        }
      ),
    validationRegex: (value) => numberCountRegex(value),
  },
  specialCharacters: {
    label: intl.formatMessage({
      id: 'passwordRequirements.specialCharacters.label',
      defaultMessage: 'Special Characters',
    }),
    message: (count) =>
      intl.formatMessage(
        {
          id: 'passwordRequirements.specialCharacters.message',
          defaultMessage:
            'Must contain at least {count} {count, plural, one{special character}other{special characters}}',
        },
        {
          count,
        }
      ),
    validationRegex: (value) => specialCharacterCountRegex(value),
  },
  reuseGenerations: {
    label: intl.formatMessage({
      id: 'passwordRequirements.reuseGenerations.label',
      defaultMessage: 'Reuse Generations',
    }),
    message: (count) =>
      intl.formatMessage(
        {
          id: 'passwordRequirements.reuseGenerations.message',
          defaultMessage:
            'Must not be the same as {count, plural, one{your most recent password used}other{any of the previous {count} passwords used}}',
        },
        {
          count,
        }
      ),
  },
  authFailureLockoutThreshold: {
    label: intl.formatMessage({
      id: 'passwordRequirements.authFailureLockoutThreshold.label',
      defaultMessage: 'Login attempts before automatic suspension',
    }),
    message: (count) =>
      intl.formatMessage(
        {
          id: 'passwordRequirements.authFailureLockoutThreshold.message',
          defaultMessage: '{count} login attempts allowed before automatic suspension',
        },
        {
          count,
        }
      ),
  },
  changeInterval: {
    label: intl.formatMessage({
      id: 'passwordRequirements.changeInterval.label',
      defaultMessage: 'Change Interval',
    }),
    message: (count) =>
      intl.formatMessage(
        {
          id: 'passwordRequirements.changeInterval.message',
          defaultMessage: 'Must be changed every {count}',
        },
        {
          count: count && formatDuration(parseDuration(count as string)),
        }
      ),
  },
};
