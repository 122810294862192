import Schema, { ValidateOption, InternalRuleItem, Rule, RuleItem, Values } from 'async-validator';
import { isUri } from 'valid-url';
import { parse, serialize, normalize } from 'uri-js';
import emailAddresses from 'email-addresses';
import { formatDuration } from 'date-fns';

import {
  compareDurations,
  durationContainsFractionalComponents,
  durationToString,
  parseDuration,
} from '../util/date';
import { intl } from '../Internationalization';

export const SLUG_KEY_VALIDATOR = /^$|^[A-Z0-9][A-Z0-9_]*$/;
export const ROUTER_SAFE_VALIDATOR = /^[^\\/;%.]*$/;
export const NOT_BLANK_REGEX = /.*\S.*/;

const minOccurencesRegex = (count: number, characterClass: string, regexFlags?: string) =>
  new RegExp(`(?:.*?${characterClass}){${count},}.*$`, regexFlags);

export const uppercaseCountRegex = (minUppercase: number) =>
  minOccurencesRegex(minUppercase, '[A-Z]');
export const lowercaseCountRegex = (minLowercase: number) =>
  minOccurencesRegex(minLowercase, '[a-z]');
export const numberCountRegex = (minNumbers: number) =>
  minOccurencesRegex(minNumbers, '[0-9]', 'i');
export const specialCharacterCountRegex = (minSpecialCharacter: number) =>
  minOccurencesRegex(minSpecialCharacter, '[^a-zA-Z0-9]');

export const nameLengthValidator = {
  max: 70,
  message: intl.formatMessage({
    id: 'validator.name.length',
    defaultMessage: 'Must be 70 characters or fewer',
  }),
};

export const referenceLengthValidator = {
  max: 128,
  message: intl.formatMessage({
    id: 'validator.reference.length',
    defaultMessage: 'Must be 128 characters or fewer',
  }),
};

export const descriptionLengthValidator: RuleItem = {
  max: 255,
  message: intl.formatMessage({
    id: 'validator.description.length',
    defaultMessage: 'Must be 255 characters or fewer',
  }),
};

export const nominalUriLengthValidator = {
  max: 512,
  message: intl.formatMessage({
    id: 'validator.nominalUriLength.length',
    defaultMessage: 'Must be 512 characters or fewer',
  }),
};
export const notesLengthValidator = {
  max: 1024,
  message: intl.formatMessage({
    id: 'validator.notesLengthValidator.length',
    defaultMessage: 'Must be 1024 characters or fewer',
  }),
};

interface DuplicateValidatorProps {
  regex: RegExp;
  existingValue?: () => string;
  checkUnique: (value: string) => Promise<boolean>;
  alreadyExistsMessage: string;
  errorMessage: string;
}

export const duplicateValidator = ({
  regex,
  existingValue,
  checkUnique,
  alreadyExistsMessage,
  errorMessage,
}: DuplicateValidatorProps) => ({
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value.match(regex) || (existingValue && existingValue() === value)) {
      callback();
      return;
    }

    checkUnique(value)
      .then((unique) => {
        if (unique) {
          callback();
          return;
        }
        callback(alreadyExistsMessage);
      })
      .catch(() => {
        callback(errorMessage);
      });
  },
});

/**
 * Validates returning a promise which is rejected on validation failure
 */
export const validate = <T extends object>(
  validator: Schema,
  source: Partial<T>,
  options?: ValidateOption
): Promise<T> => {
  return new Promise((resolve, reject) => {
    validator.validate(source, options ? options : {}, (errors, fieldErrors) => {
      if (errors) {
        reject(fieldErrors);
      } else {
        resolve(source as T);
      }
    });
  });
};

export const internetAddressValidator = {
  validator(rule: any, value: any, callback: (error?: string) => void) {
    if (value && emailAddresses({ input: value }) === null) {
      callback(
        intl.formatMessage({
          id: 'validator.internetAddress.invalidAddress',
          defaultMessage:
            "Value must be a valid email address which may include the sender name, e.g. 'Sender Name <no-reply@example.com>'",
        })
      );
    } else {
      callback();
    }
  },
};

export const slugKeyValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (value && !SLUG_KEY_VALIDATOR.test(value)) {
      callback(
        intl.formatMessage({
          id: 'validator.slugKey.invalidCharacter',
          defaultMessage:
            'Value must start with a letter or number and can only contain upper case letters (A-Z), numbers and underscores',
        })
      );
    } else {
      callback();
    }
  },
};

export const routerSafeValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (value && !ROUTER_SAFE_VALIDATOR.test(value)) {
      callback(
        intl.formatMessage({
          id: 'validator.routerSafe.invalidCharacter',
          defaultMessage: 'Value must not contain the following characters: / \\ ; % .',
        })
      );
    } else {
      callback();
    }
  },
};

export const portValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (value === undefined || value === null) {
      callback();
    } else if (isNaN(value)) {
      callback(
        intl.formatMessage({
          id: 'validator.port.isNan',
          defaultMessage: 'Port must be a number',
        })
      );
    } else if (value < 1 || value > 65535) {
      callback(
        intl.formatMessage({
          id: 'validator.port.invalidRange',
          defaultMessage: 'Port must range between 1 and 65535',
        })
      );
    } else if (!Number.isInteger(value)) {
      callback(
        intl.formatMessage({
          id: 'validator.port.notAnInteger',
          defaultMessage: 'Port must be an integer',
        })
      );
    } else {
      callback();
    }
  },
};

export const ldapUrlValidator = () => ({
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value) {
      return callback();
    }

    const isValidLdapUri = () => {
      const parsedUrl = parse(value);
      if (parsedUrl.query || parsedUrl.fragment || parsedUrl.userinfo) {
        return false;
      }
      if (parsedUrl.path && parsedUrl.path !== '/') {
        return false;
      }
      return parsedUrl.host && (parsedUrl.scheme === 'ldap' || parsedUrl.scheme === 'ldaps');
    };

    if (isUri(value) && isValidLdapUri()) {
      callback();
    } else {
      callback(
        intl.formatMessage({
          id: 'validator.ldapUrl.invalidUrl',
          defaultMessage:
            'Please provide a valid LDAP server address, e.g. "ldaps://ldap.example.com"',
        })
      );
    }
  },
});

export const baseUriValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value) {
      return callback();
    }

    const isValidBaseUri = () => {
      const parsedUrl = parse(value);
      if (parsedUrl.query || parsedUrl.fragment || parsedUrl.userinfo) {
        return false;
      }
      return parsedUrl.host && (parsedUrl.scheme === 'http' || parsedUrl.scheme === 'https');
    };

    if (isUri(value) && isValidBaseUri()) {
      callback();
    } else {
      callback(
        intl.formatMessage({
          id: 'validator.baseUri.invalidUrl',
          defaultMessage: 'Please provide a valid URL, e.g. "https://www.example.com/"',
        })
      );
    }
  },
};

export const absoluteHttpUriValidator = {
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value) {
      return callback();
    }

    const isValidWebUri = () => {
      const parsedUrl = parse(value);
      if (parsedUrl.reference !== 'absolute') {
        return false;
      }
      if (!parsedUrl.path) {
        return false;
      }
      return parsedUrl.scheme === 'http' || parsedUrl.scheme === 'https';
    };

    if (isUri(value) && isValidWebUri()) {
      callback();
    } else {
      callback(
        intl.formatMessage({
          id: 'validator.absoluteHttpUri.invalidUrl',
          defaultMessage: 'Please provide an absolute URL, e.g. "https://www.example.com/"',
        })
      );
    }
  },
};

export const notTrimmableValidator = {
  validator(rule: InternalRuleItem, value: string, callback: (error?: string) => void) {
    if (value && value.trim() !== value) {
      callback(
        intl.formatMessage({
          id: 'validator.notTrimmable.invalidCharacter',
          defaultMessage: 'Value must not have leading or trailing whitespace',
        })
      );
    } else {
      callback();
    }
    return;
  },
};

export const normalizeUri = (uri: string) => {
  return serialize(normalize(parse(uri)));
};

export const periodValidator = (minimum: Duration) =>
  periodDurationValidator(
    minimum,
    intl.formatMessage({
      id: 'validator.periodDuration.invalidPeriod',
      defaultMessage: 'Must be a valid period (e.g. "1y 2m 3d")',
    }),
    intl.formatMessage(
      {
        id: 'validator.periodDuration.minimum',
        defaultMessage: 'Must be greater than: {minimum}',
      },
      { minimum: formatDuration(minimum) }
    )
  );

export const durationValidator = (minimum: Duration) =>
  periodDurationValidator(
    minimum,
    intl.formatMessage({
      id: 'validator.periodDuration.invalidDuration',
      defaultMessage: 'Must be a valid duration (e.g. "1h 2m 30s")',
    }),
    intl.formatMessage(
      {
        id: 'validator.periodDuration.minimum',
        defaultMessage: 'Must be greater than: {minimum}',
      },
      { minimum: formatDuration(minimum) }
    )
  );

const periodDurationValidator = (
  minimumDuration: Duration,
  invalidFormatMessage: string,
  minimumLengthMessage: string
) => ({
  validator(rule: InternalRuleItem, value: any, callback: (error?: string) => void) {
    if (!value) {
      callback();
      return;
    }
    const parsedDuration = parseDuration(value);
    if (value !== durationToString(parsedDuration)) {
      // The parse method stops at the first unexpected component, so "P1D1M1Y" parses successfully as "P1D".
      // We round-trip the parsed duration to detect this.
      callback(invalidFormatMessage);
    }
    if (durationContainsFractionalComponents(parsedDuration)) {
      callback(
        intl.formatMessage({
          id: 'validator.periodDuration.fractionalComponent',
          defaultMessage: 'Fractional units of time are not supported. Please use integer values.',
        })
      );
    }
    if (compareDurations(minimumDuration, parsedDuration) < 0) {
      callback(minimumLengthMessage);
    }
    callback();
  },
});

export interface ConditionalRule<T> {
  getRulesIfApplicable(item: T): Record<string, Rule> | undefined;
}

export const validateArrayWithConditionalRules = <T>(
  items: T[],
  rules: ConditionalRule<T>[]
): Rule => {
  let fieldRecords: Record<string, Rule> = {};
  items.forEach((item, index) => {
    fieldRecords[index.toString()] = {
      type: 'object',
      required: true,
      fields: rules
        .map((rule) => rule.getRulesIfApplicable(item))
        .reduce((a, b) => {
          return { ...a, ...b };
        }),
    };
  });
  return {
    type: 'array',
    fields: fieldRecords,
  };
};

export const integerValidator = {
  validator: (
    rule: InternalRuleItem,
    value: any,
    callback: (error?: string) => void,
    source: Values
  ) => {
    if (value !== undefined && !Number.isInteger(value)) {
      callback(
        intl.formatMessage({
          id: 'validator.shared.notAnInteger',
          defaultMessage: 'Must be an integer',
        })
      );
    }
    callback();
  },
};

type ValidatorFunction = (
  rule: InternalRuleItem,
  value: any,
  callback: (error?: string) => void,
  source: Values,
  options: ValidateOption
) => void;

type ConditionFunction = (source: Values) => boolean;

export const validateWhen =
  (condition: ConditionFunction, validator: ValidatorFunction) =>
  (
    rule: InternalRuleItem,
    value: any,
    callback: (error?: string) => void,
    source: Values,
    options: ValidateOption
  ) => {
    if (condition(source)) {
      validator(rule, value, callback, source, options);
    } else {
      callback();
    }
  };
