import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import {
  DataStoreSchema,
  ReorderDirection,
  SpecificationInputDetail,
} from '../../../../../../types';
import { intl } from '../../../../../../Internationalization';
import {
  DefaultButton,
  MinWidthTableCell,
  PaddedDialogActions,
  ValidatedTextField,
} from '../../../../../../components';

import DataStoreSelectDialog from './DataStoreSelectDialog';

interface InputConfigTableRowProps {
  input: SpecificationInputDetail;
  expandedInput?: SpecificationInputDetail;
  dataStoreSchemas: DataStoreSchema[];
  disabled: boolean;
  disablePrev: boolean;
  disableNext: boolean;
  fieldError?: ValidateFieldsError;

  handleRemoveInput: (inputKey: string) => void;
  handleExpandInput: React.Dispatch<React.SetStateAction<SpecificationInputDetail | undefined>>;

  handleUpdateInput: (input: SpecificationInputDetail) => void;
  handleReorderAction: (inputKey: string, direction: ReorderDirection) => void;
}

const InputConfigTableRow: FC<InputConfigTableRowProps> = ({
  input,
  expandedInput,
  dataStoreSchemas,
  disabled,
  disablePrev,
  disableNext,
  fieldError,
  handleRemoveInput,
  handleExpandInput,
  handleUpdateInput,
  handleReorderAction,
}) => {
  const [removeInputDialogOpen, setRemoveInputDialogOpen] = useState<boolean>(false);
  const [dataStoreDialogOpen, setDataStoreDialogOpen] = useState<boolean>(false);
  const isExpanded = expandedInput?.key === input.key;
  const rowSpan = isExpanded && input.linkedDataStores.length ? input.linkedDataStores.length : 1;
  const moreCount = input.linkedDataStores.length - 1;

  const handleUpdateLinkedDataStores = (linkedDataStores: string[]) => {
    handleUpdateInput({ ...input, linkedDataStores });
  };

  return (
    <>
      <TableRow key={input.key} sx={{ height: 60 }}>
        <TableCell rowSpan={rowSpan} sx={{ verticalAlign: 'top' }}>
          <ValidatedTextField
            name="name"
            label={intl.formatMessage({
              id: 'specification.configuration.inputConfig.name.label',
              defaultMessage: 'Name',
            })}
            fullWidth
            value={input.name || ''}
            onChange={(event) => {
              handleUpdateInput({ ...input, name: event.target.value });
            }}
            variant="standard"
            disabled={disabled}
            fieldErrors={fieldError}
          />
        </TableCell>
        <TableCell rowSpan={rowSpan} sx={{ verticalAlign: 'top' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={input.required}
                onChange={(event) => {
                  handleUpdateInput({ ...input, required: event.target.checked });
                }}
                disabled={disabled}
              />
            }
            label={
              <Typography variant="body2">
                <FormattedMessage
                  id="specification.configuration.inputConfig.required.label"
                  defaultMessage="Required"
                />
              </Typography>
            }
          />
        </TableCell>
        <MinWidthTableCell rowSpan={rowSpan} sx={{ verticalAlign: 'top' }}>
          <Box display="flex" alignItems="top">
            <IconButton
              name="inputDataStores"
              size="large"
              onClick={() => setDataStoreDialogOpen(true)}
              sx={{ mr: 1 }}
              disabled={disabled}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </MinWidthTableCell>
        <TableCell>
          {input.linkedDataStores[0]}
          {!isExpanded && moreCount > 0 && ` + (${moreCount})`}
        </TableCell>
        <TableCell
          rowSpan={rowSpan}
          align="right"
          sx={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}
        >
          <IconButton
            name="removeInput"
            onClick={() => setRemoveInputDialogOpen(true)}
            size="large"
            aria-label={intl.formatMessage({
              id: 'specification.configuration.inputConfig.removeInput.ariaLabel',
              defaultMessage: 'Remove input',
            })}
            disabled={disabled}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            name="expandInputDetails"
            onClick={() =>
              handleExpandInput((prev: any) => (prev?.key === input.key ? undefined : input))
            }
            disabled={moreCount < 1 || disabled}
            size="large"
            aria-label={
              isExpanded
                ? intl.formatMessage({
                    id: 'specification.configuration.inputConfig.collapseDetails.ariaLabel',
                    defaultMessage: 'Collapse input details',
                  })
                : intl.formatMessage({
                    id: 'specification.configuration.inputConfig.expandDetails.ariaLabel',
                    defaultMessage: 'Expand input details',
                  })
            }
          >
            <ExpandMoreIcon
              sx={(theme) => ({
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              })}
            />
          </IconButton>
          <IconButton
            disableTouchRipple
            onClick={() => handleReorderAction(input.key, 'UP')}
            disabled={disabled || disablePrev}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            disableTouchRipple
            onClick={() => handleReorderAction(input.key, 'DOWN')}
            disabled={disabled || disableNext}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {isExpanded &&
        input.linkedDataStores.map((dataStore, index) => {
          if (!index) {
            return null;
          }
          return (
            <TableRow sx={{ height: 60 }}>
              <TableCell>{dataStore}</TableCell>
            </TableRow>
          );
        })}
      <DataStoreSelectDialog
        linkedDataStores={input.linkedDataStores}
        dataStoreDialogOpen={dataStoreDialogOpen}
        dataStoreSchemas={dataStoreSchemas}
        onCloseDataStoreDialog={() => setDataStoreDialogOpen(false)}
        onConfirm={handleUpdateLinkedDataStores}
      />
      <Dialog
        id="remove-input-dialog"
        open={removeInputDialogOpen}
        onClose={() => setRemoveInputDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <FormattedMessage
            id="specification.configuration.inputConfig.removeInput.title"
            defaultMessage="Remove input"
          />
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" gutterBottom>
            <FormattedMessage
              id="specification.configuration.inputConfig.removeInput.message"
              defaultMessage="Are you sure you want to remove {name}?"
              values={{ name: input.name }}
            />
          </Typography>
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton
            name="cancel"
            onClick={() => setRemoveInputDialogOpen(false)}
            color="secondary"
          >
            <FormattedMessage
              id="specification.configuration.inputConfig.removeInput.cancelButton"
              defaultMessage="Cancel"
            />
          </DefaultButton>
          <DefaultButton name="confirm" onClick={() => handleRemoveInput(input.key)}>
            <FormattedMessage
              id="specification.configuration.inputConfig.removeInput.removeButton"
              defaultMessage="Remove"
            />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default InputConfigTableRow;
