import Schema from 'async-validator';
import {
  NOT_BLANK_REGEX,
  internetAddressValidator,
  notTrimmableValidator,
  portValidator,
} from './shared';
import { intl } from '../Internationalization';

export const SENDER_SETTINGS_VALIDATOR = new Schema({
  from: [
    {
      type: 'string',
      required: true,
      message: intl.formatMessage({
        id: 'mail.validator.from.required',
        defaultMessage: 'Please provide an email address',
      }),
    },
    notTrimmableValidator,
    internetAddressValidator,
  ],
  protocol: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'mail.validator.protocol.required',
      defaultMessage: 'Please select a protocol',
    }),
  },
  host: {
    type: 'string',
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'mail.validator.host.required',
      defaultMessage: 'Please provide the host name or address',
    }),
  },
  port: portValidator,
  security: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'mail.validator.security.required',
      defaultMessage: 'Please select a security option',
    }),
  },
});
