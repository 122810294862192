import { FC, useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { Box, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import {
  AddFab,
  BrowseTable,
  ButtonRow,
  DefaultButton,
  FilterBar,
  FilterPagination,
  FilterToggle,
  MinWidthTableCell,
  NamedAccountAvatar,
  StyledTableHead,
  browseTableBody,
} from '../../../../components';
import { CrossIcon, TickIcon } from '../../../../components/icons';
import * as SchedulesApi from '../../../../api/schedule/schedules';
import { intl } from '../../../../Internationalization';
import { ScheduleDetail, SchedulesRequest } from '../../../../types';
import { RequestConfig, extractErrorMessage } from '../../../../api/endpoints';
import { useBrowseRequest, useTitle } from '../../../../hooks';
import * as CreateScheduleApi from '../../../../api/schedule/createSchedule';
import { MyAssignmentContext } from '../MyAssignmentContext';
import {
  REPEAT_METADATA,
  dateTimeFormat,
  durationFormat,
  parseCronToRepeatValue,
} from '../../../../util';

export const toScheduleUrl = (schedule: ScheduleDetail) =>
  `/my_assignments/${schedule.assignment.key}/schedules/${schedule.key}`;
const PAGE_SIZE = 10;

const SchedulesTableBody = browseTableBody<ScheduleDetail>();

const Schedules: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.schedules',
      defaultMessage: 'Schedules',
    })
  );
  const navigate = useNavigate();
  const { assignmentKey } = useContext(MyAssignmentContext);
  const [creating, setCreating] = useState<boolean>(false);
  const [showDuration, setShowDuration] = useState<boolean>(false);
  const { request, response, processing, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, assignmentKey },
    onRequest: useCallback(
      (req: SchedulesRequest, config?: RequestConfig) => SchedulesApi.getSchedules(req, config),
      []
    ),
  });

  const createNewSchedule = async () => {
    setCreating(true);
    try {
      const { data: newSchedule } = await CreateScheduleApi.createSchedule(assignmentKey);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'myAssignment.schedules.createScheduleSuccess',
          defaultMessage: 'Schedule created successfully',
        }),
        { variant: 'success' }
      );
      navigate(toScheduleUrl(newSchedule));
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.schedules.createScheduleError',
            defaultMessage: 'Failed to create new schedule',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setCreating(false);
    }
  };

  const updateShowDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDuration(event.target.checked);
  };

  const renderScheduleRow = (schedule: ScheduleDetail) => (
    <TableRow key={schedule.key}>
      <TableCell>
        <NamedAccountAvatar user={schedule.owner} />
      </TableCell>
      <TableCell>{dateTimeFormat(schedule.startDateTime)}</TableCell>
      <TableCell>{REPEAT_METADATA[parseCronToRepeatValue(schedule)].label}</TableCell>
      <TableCell>
        {schedule.active ? (
          <TickIcon
            titleAccess={intl.formatMessage({
              id: 'myAssignment.schedules.active',
              defaultMessage: 'Active',
            })}
          />
        ) : (
          <CrossIcon
            titleAccess={intl.formatMessage({
              id: 'myAssignment.schedules.inactive',
              defaultMessage: 'Inactive',
            })}
          />
        )}
      </TableCell>
      <TableCell>
        {schedule.nextOccurrences.at(0)
          ? showDuration
            ? durationFormat(schedule.nextOccurrences.at(0)!)
            : dateTimeFormat(schedule.nextOccurrences.at(0)!)
          : intl.formatMessage({
              id: 'myAssignment.schedules.noUpcomingSubmission',
              defaultMessage: 'No upcoming submission',
            })}
      </TableCell>
      <MinWidthTableCell>
        <ButtonRow whiteSpace="nowrap">
          <DefaultButton
            name="navigateToSchedule"
            color="grey"
            component={Link}
            to={toScheduleUrl(schedule)}
            disabled={!schedule.isEditable}
          >
            <EditIcon
              titleAccess={intl.formatMessage({
                id: 'myAssignment.schedules.navigateToSchedule.titleAccess',
                defaultMessage: 'Navigate to schedule',
              })}
            />
          </DefaultButton>
        </ButtonRow>
      </MinWidthTableCell>
    </TableRow>
  );

  return (
    <Box id="my-assignment-schedules">
      <FilterBar
        actions={
          <>
            <FilterToggle
              label={intl.formatMessage({
                id: 'myAssignment.schedules.showTimeUntilNextSubmission',
                defaultMessage: 'Show time until next submission',
              })}
              name="showDuration"
              checked={showDuration}
              onChange={updateShowDuration}
              disabled={processing}
            />
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
          </>
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="myAssignment.schedules.table.ownerColumn"
                defaultMessage="Owner"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="myAssignment.schedules.table.startColumn"
                defaultMessage="Start"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="myAssignment.schedules.table.repeatsColumn"
                defaultMessage="Repeats"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="myAssignment.schedules.table.activeColumn"
                defaultMessage="Active"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="myAssignment.schedules.table.nextSubmissionColumn"
                defaultMessage="Next Submission"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="myAssignment.schedules.table.actionsColumn"
                defaultMessage="Actions"
              />
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <SchedulesTableBody
          data={response?.results}
          mapToRow={renderScheduleRow}
          noDataMessage={intl.formatMessage({
            id: 'myAssignment.schedules.table.noSchedules',
            defaultMessage: 'No matching schedules.',
          })}
          numCols={6}
          processing={processing}
        />
      </BrowseTable>
      <AddFab
        disabled={creating}
        name="addSchedule"
        onClick={createNewSchedule}
        aria-label={intl.formatMessage({
          id: 'myAssignment.schedules.table.addFab.ariaLabel',
          defaultMessage: 'Add schedule',
        })}
      />
    </Box>
  );
};

export default Schedules;
