import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';

import AssignmentRow, { AssignmentRowDetailProps } from './AssignmentRow';

interface SpecificationRowProps {
  specification: { key: string; name: string };
  assignment?: AssignmentRowDetailProps;
  nestedRow?: boolean;
  actionColumn?: boolean;
  rowSpan: number;
}

const SpecificationRow: FC<SpecificationRowProps> = ({
  specification,
  assignment,
  nestedRow,
  actionColumn,
  rowSpan,
}) => {
  const details = (
    <>
      <TableCell rowSpan={rowSpan}>
        {specification.key} - {specification.name}
      </TableCell>
      {assignment ? (
        <AssignmentRow assignment={assignment} actionColumn nestedRow />
      ) : (
        <>
          <TableCell />
          <TableCell />
        </>
      )}
      {actionColumn && <TableCell />}
    </>
  );

  if (nestedRow) {
    return details;
  }

  return <TableRow>{details}</TableRow>;
};

export default SpecificationRow;
