import { AxiosPromise } from 'axios';

import * as Saml2IdentityProviderApi from '../api/identity-provider/saml2IdentityProvider';
import * as OAuth2IdentityProviderApi from '../api/identity-provider/oAuth2IdentityProvider';
import { SimplePageRequest, PageResponse } from './';
import { intl } from '../Internationalization';

export interface IdentityProvidersRequest extends SimplePageRequest {
  filter?: string;
}

export interface IdentityProviderMetadata {
  key: string;
  name: string;
  type: IdentityProviderType;
}

export interface IdentityProviderDetail extends IdentityProviderMetadata {
  ssoRequired: boolean;
  linkByEmail: boolean;
  createMissing: boolean;
  emailAttribute: string;
  nameAttribute: string;
  groupAttribute: string;
  mapSuppliersByGroup: boolean;
  mapReceiverByGroup: boolean;
  configuration: IdentityProviderConfigurationDetail;
}

export interface IdentityProviderSettings {
  name: string;
  ssoRequired: boolean;
  linkByEmail: boolean;
  createMissing: boolean;
  emailAttribute: string;
  nameAttribute: string;
  groupAttribute: string;
  mapSuppliersByGroup: boolean;
  mapReceiverByGroup: boolean;
}

export interface IdentityProviderConfigurationDetail {
  spEntityId?: string;
  ssoUrl?: string;
  loginUrl?: string;
}

export enum IdentityProviderType {
  SAML2 = 'SAML2',
  OAUTH2 = 'OAUTH2',
}

export const identityProviderTypes = [IdentityProviderType.SAML2, IdentityProviderType.OAUTH2];

export type IdentityProviderResponse = PageResponse<IdentityProviderMetadata>;

// OAuth types:

export interface CreateOAuth2IdentityProviderRequest extends OAuth2IdentityProviderSettings {
  key: string;
}

export interface OAuth2IdentityProviderDetail extends IdentityProviderDetail {
  authenticationMethod: OAuth2ClientAuthenticationMethod;
  clientId: string;
  clientSecret: string;
  scopes: string[];
  authorizationUri: string;
  tokenUri: string;
  jwkSetUri?: string;
  userInfoUri: string;
  userNameAttributeName: string;
  userInfoAuthenticationMethod: OAuth2AuthenticationMethod;
}

export enum OAuth2ClientAuthenticationMethod {
  BASIC = 'BASIC',
  POST = 'POST',
  NONE = 'NONE',
}

export const oAuth2ClientAuthenticationMethods = [
  OAuth2ClientAuthenticationMethod.BASIC,
  OAuth2ClientAuthenticationMethod.POST,
  OAuth2ClientAuthenticationMethod.NONE,
];

export const OAUTH2_CLIENT_AUTHENTICATION_METHOD_METADATA = {
  BASIC: intl.formatMessage({
    id: 'identityProvider.oAuth2ClientAuthenticationMethodMetadata.basic',
    defaultMessage: 'Basic',
  }),
  POST: intl.formatMessage({
    id: 'identityProvider.oAuth2ClientAuthenticationMethodMetadata.post',
    defaultMessage: 'Post',
  }),
  NONE: intl.formatMessage({
    id: 'identityProvider.oAuth2ClientAuthenticationMethodMetadata.none',
    defaultMessage: 'None',
  }),
};

export enum OAuth2AuthenticationMethod {
  HEADER = 'HEADER',
  FORM = 'FORM',
  QUERY = 'QUERY',
}

export const oAuth2AuthenticationMethod = [
  OAuth2AuthenticationMethod.HEADER,
  OAuth2AuthenticationMethod.FORM,
  OAuth2AuthenticationMethod.QUERY,
];

export const OAUTH2_AUTHENTICATION_METHOD_METADATA = {
  HEADER: intl.formatMessage({
    id: 'identityProvider.oAuth2AuthenticationMethodMetadata.header',
    defaultMessage: 'Header',
  }),
  FORM: intl.formatMessage({
    id: 'identityProvider.oAuth2AuthenticationMethodMetadata.form',
    defaultMessage: 'Form',
  }),
  QUERY: intl.formatMessage({
    id: 'identityProvider.oAuth2AuthenticationMethodMetadata.query',
    defaultMessage: 'Query',
  }),
};

export interface OAuth2IdentityProviderSettings extends IdentityProviderSettings {
  authenticationMethod: OAuth2ClientAuthenticationMethod;
  clientId: string;
  clientSecret: string;
  scopes: string[];
  authorizationUri: string;
  tokenUri: string;
  jwkSetUri?: string;
  userInfoUri: string;
  userNameAttributeName: string;
  userInfoAuthenticationMethod: OAuth2AuthenticationMethod;
}

// Saml types:

export interface CreateSaml2IdentityProviderRequest extends Saml2IdentityProviderSettings {
  key: string;
}

export interface Saml2IdentityProviderSettings extends IdentityProviderSettings {
  entityId: string;
  ssoServiceLocation: string;
  signRequests: boolean;
}

export interface Saml2IdentityProviderDetail extends IdentityProviderDetail {
  entityId: string;
  ssoServiceLocation: string;
  idpCertificate: string;
  signRequests: boolean;
  spCertificate: string;
  spPrivateKey: string;
}

// Metadata:

type DefaultIdentityProviderSettings<I extends IdentityProviderSettings> = Omit<I, 'name' | 'key'>;

export interface IdentityProviderTypeMetadata<
  I extends IdentityProviderSettings,
  D extends IdentityProviderDetail
> {
  type: string;
  label: string;
  authenticationPath: (identityProviderKey: string) => string;
  defaults: DefaultIdentityProviderSettings<I>;
  createApi: (request: I) => AxiosPromise<D>;
  deleteApi: (identityProviderKey: string) => AxiosPromise<void>;
}

const SAML2_METADATA: IdentityProviderTypeMetadata<
  CreateSaml2IdentityProviderRequest,
  Saml2IdentityProviderDetail
> = {
  type: 'saml2',
  label: 'SAML 2.0',
  authenticationPath: (identityProviderKey) => `/saml2/authenticate/${identityProviderKey}`,
  defaults: {
    ssoRequired: false,
    linkByEmail: true,
    createMissing: false,
    emailAttribute: 'email',
    nameAttribute: 'name',
    groupAttribute: 'group',
    mapSuppliersByGroup: false,
    mapReceiverByGroup: false,
    entityId: 'https://example.com/login/saml/authorize',
    ssoServiceLocation: 'https://example.com/login/saml/sso',
    signRequests: false,
  },
  createApi: Saml2IdentityProviderApi.createIdentityProvider,
  deleteApi: Saml2IdentityProviderApi.deleteIdentityProvider,
};

const OAUTH2_METADATA: IdentityProviderTypeMetadata<
  CreateOAuth2IdentityProviderRequest,
  OAuth2IdentityProviderDetail
> = {
  type: 'oauth2',
  label: 'OAuth 2.0',
  authenticationPath: (identityProviderKey) => `/oauth2/authorize/${identityProviderKey}`,
  defaults: {
    ssoRequired: false,
    linkByEmail: true,
    createMissing: false,
    emailAttribute: 'email',
    nameAttribute: 'name',
    groupAttribute: 'group',
    mapSuppliersByGroup: false,
    mapReceiverByGroup: false,
    authenticationMethod: OAuth2ClientAuthenticationMethod.POST,
    clientId: 'client_id',
    clientSecret: 'secret',
    scopes: ['email'],
    authorizationUri: 'https://example.com/login/oauth/authorize',
    tokenUri: 'https://example.com/login/oauth/access_token',
    jwkSetUri: undefined,
    userInfoUri: 'https://example.com/oauth/user',
    userNameAttributeName: 'id',
    userInfoAuthenticationMethod: OAuth2AuthenticationMethod.HEADER,
  },
  createApi: OAuth2IdentityProviderApi.createIdentityProvider,
  deleteApi: OAuth2IdentityProviderApi.deleteIdentityProvider,
};

export const IDENTITY_PROVIDER_TYPE_METADATA: {
  [type in IdentityProviderType]: IdentityProviderTypeMetadata<
    IdentityProviderSettings,
    IdentityProviderDetail
  >;
} = {
  SAML2: SAML2_METADATA as unknown as IdentityProviderTypeMetadata<
    IdentityProviderSettings,
    IdentityProviderDetail
  >,
  OAUTH2: OAUTH2_METADATA as unknown as IdentityProviderTypeMetadata<
    IdentityProviderSettings,
    IdentityProviderDetail
  >,
};
