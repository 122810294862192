import Schema from 'async-validator';

import { intl } from '../Internationalization';
import { nameLengthValidator } from './shared';
import { PasswordRequirements } from '../types';

import { PASSWORD_VALIDATOR, verifiedPasswordDescriptor } from './auth';

export const UPDATE_MY_PROFILE_VALIDATOR = new Schema({
  name: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'me.validator.name.required',
        defaultMessage: 'Please provide a name',
      }),
    },
    nameLengthValidator,
  ],
});

export const changePasswordValidator = (passwordRequirements: PasswordRequirements) =>
  new Schema({
    currentPassword: PASSWORD_VALIDATOR,
    ...verifiedPasswordDescriptor(passwordRequirements),
  });
