import { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../../components';
import { intl } from '../../../Internationalization';
import { useRouterTab, useTitle } from '../../../hooks';

import SystemHeader from '../SystemHeader';
import SenderSettings from './settings/SenderSettings';
import MailTemplates from './templates/MailTemplates';

const MailRouting: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.system.mail',
      defaultMessage: 'Mail',
    })
  );
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="mail-tabs" value={routerTab}>
      <RouterTab
        id="sender-settings-tab"
        value="sender_settings"
        label={intl.formatMessage({
          id: 'mail.routing.tabs.settings',
          defaultMessage: 'Sender Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="templates-tab"
        value="templates"
        label={intl.formatMessage({
          id: 'mail.routing.tabs.templates',
          defaultMessage: 'Templates',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'mail.routing.title',
          defaultMessage: 'Mail',
        })}
        icon={MailOutlineIcon}
      />
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route path="sender_settings" element={<SenderSettings />} />
          <Route path="templates" element={<MailTemplates />} />
          <Route path="*" element={<Navigate to="sender_settings" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default MailRouting;
