import { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AppDecorator } from '../../components';
import { ErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';

import System from './System';

import ServiceConfiguration from './service-configuration/ServiceConfiguration';
import MailRouting from './mail/MailRouting';
import Site from './site/Site';
import Ldap from './ldap/Ldap';
import UserImportRouting from './user-import/UserImportRouting';
import ProjectImportRouting from './project-import/ProjectImportRouting';
import IdentityProvidersRouting from './identity-providers/IdentityProvidersRouting';
import SystemActivityRouting from './activity/SystemActivityRouting';
import VirusScanner from './virus-scanner/VirusScanner';
import Help from './help/Help';

const SystemRouting: FC = () => (
  <AppDecorator
    title={intl.formatMessage({
      id: 'system.routing.title',
      defaultMessage: 'System',
    })}
  >
    <ErrorBlock>
      <Routes>
        <Route index element={<System />} />
        <Route path="service_configuration/*" element={<ServiceConfiguration />} />
        <Route path="mail/*" element={<MailRouting />} />
        <Route path="site/*" element={<Site />} />
        <Route path="ldap/*" element={<Ldap />} />
        <Route path="user_import/*" element={<UserImportRouting />} />
        <Route path="project_import/*" element={<ProjectImportRouting />} />
        <Route path="identity_providers/*" element={<IdentityProvidersRouting />} />
        <Route path="activity/*" element={<SystemActivityRouting />} />
        <Route path="virus_scanner/*" element={<VirusScanner />} />
        <Route path="help/*" element={<Help />} />
        <Route path="*" element={<Navigate to="." replace />} />
      </Routes>
    </ErrorBlock>
  </AppDecorator>
);

export default SystemRouting;
