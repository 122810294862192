import { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import { Typography, MenuItem, Checkbox } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import { editablePeriodToIso, isoPeriodToEditable } from '../../../../../util';
import { intl } from '../../../../../Internationalization';
import { AxiosApiError, extractErrorMessage } from '../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../api/specification/specification';
import { specificationSettingsValidator, validate } from '../../../../../validation';
import {
  InputTooltip,
  ValidatedTextField,
  FormButtons,
  PaddedPaper,
  DefaultButton,
  BlockFormControlLabel,
} from '../../../../../components';
import {
  MediaRetentionPolicy,
  mediaRetentionPolicies,
  MEDIA_RETENTION_POLICY_LABELS,
  SpecificationSettings,
} from '../../../../../types';

import { SpecificationContext } from '../SpecificationContext';

const SpecificationSettingsForm: FC = () => {
  const { specification, specificationKey, projectKey, specificationUpdated } =
    useContext(SpecificationContext);
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState<string>(specification.name);
  const [description, setDescription] = useState<string>(specification.description);
  const [active, setActive] = useState<boolean>(specification.active);
  const [timeoutPeriod, setTimeoutPeriod] = useState<string>(
    isoPeriodToEditable(specification.timeoutPeriod)
  );
  const [autoClosePeriod, setAutoCloseperiod] = useState<string>(
    isoPeriodToEditable(specification.autoClosePeriod)
  );
  const [mediaRetentionPolicy, setMediaRetentionPolicy] = useState<MediaRetentionPolicy>(
    specification.mediaRetentionPolicy
  );
  const [mediaRetentionPeriod, setMediaRetentionPeriod] = useState<string>(
    isoPeriodToEditable(specification.mediaRetentionPeriod)
  );
  const [allowSourceUnmapped, setAllowSourceUnmapped] = useState<boolean>(
    specification.allowSourceUnmapped
  );
  const [allowTargetUnmapped, setAllowTargetUnmapped] = useState<boolean>(
    specification.allowTargetUnmapped
  );
  const [showTargetUnmappedWarning, setShowTargetUnmappedWarning] = useState<boolean>(
    specification.showTargetUnmappedWarning
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validator = specificationSettingsValidator(
    () => projectKey,
    () => specification.name
  );

  const onSubmit = () => {
    setProcessing(true);
    setFieldErrors(undefined);
    validateAndSubmit();
  };

  const validateAndSubmit = () => {
    const updatedSpecification: SpecificationSettings = {
      ...SpecificationApi.extractSpecificationSettings(specification),
      name,
      description,
      active,
      timeoutPeriod: editablePeriodToIso(timeoutPeriod),
      autoClosePeriod: editablePeriodToIso(autoClosePeriod),
      mediaRetentionPolicy,
      mediaRetentionPeriod: editablePeriodToIso(mediaRetentionPeriod),
      allowSourceUnmapped,
      allowTargetUnmapped,
      showTargetUnmappedWarning,
    };
    validate(validator, updatedSpecification)
      .then(() => updateSpecification(updatedSpecification))
      .catch((error: ValidateFieldsError) => {
        setFieldErrors(error);
        setProcessing(false);
      });
  };

  const updateSpecification = (updatedSpecification: SpecificationSettings) => {
    SpecificationApi.updateSpecification(specificationKey, updatedSpecification)
      .then((response) => {
        specificationUpdated(response.data);
        enqueueSnackbar(
          intl.formatMessage(
            {
              id: 'specification.settings.saveSuccess',
              defaultMessage: 'Specification {name} has been updated',
            },
            { name: updatedSpecification.name }
          ),
          { variant: 'success' }
        );
      })
      .catch((error: AxiosApiError) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'specification.settings.saveError',
              defaultMessage: 'Failed to update specification',
            })
          ),
          { variant: 'error' }
        );
      })
      .finally(() => setProcessing(false));
  };

  return (
    <PaddedPaper>
      <Typography variant="h5" component="h3" gutterBottom>
        <FormattedMessage
          id="specification.settings.title"
          defaultMessage="Specification Settings"
        />
      </Typography>
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'specification.settings.key.tooltip',
          defaultMessage: 'Key is a user-friendly identifier, not editable.',
        })}
        disabled={true}
        name="key"
        label={intl.formatMessage({
          id: 'specification.settings.key.label',
          defaultMessage: 'Key',
        })}
        value={specification.key}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'specification.settings.name.tooltip',
          defaultMessage: 'A short name for the specification.',
        })}
        fieldErrors={fieldErrors}
        disabled={processing}
        name="name"
        label={intl.formatMessage({
          id: 'specification.settings.name.label',
          defaultMessage: 'Name',
        })}
        value={name}
        onChange={(e) => setName(e.target.value)}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'specification.settings.description.tooltip',
          defaultMessage:
            'Detailed free text description of the specification, shown to the suppliers on the "My Assignments" screen.',
        })}
        fieldErrors={fieldErrors}
        disabled={processing}
        name="description"
        label={intl.formatMessage({
          id: 'specification.settings.description.label',
          defaultMessage: 'Description',
        })}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'specification.settings.timeoutPeriod.tooltip',
          defaultMessage:
            'Control how long the system allows before automatically rejecting submissions which are in progress, but not yet finished. Period format must be in descending order, e.g. 1y 2m 10d or 1m 20d',
        })}
        label={intl.formatMessage({
          id: 'specification.settings.timeoutPeriod.label',
          defaultMessage: 'Timeout Period (e.g. 1d)',
        })}
        fieldErrors={fieldErrors}
        name="timeoutPeriod"
        value={timeoutPeriod}
        onChange={(e) => setTimeoutPeriod(e.target.value)}
        margin="normal"
        variant="outlined"
        disabled={processing}
      />
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'specification.settings.autoClosePeriod.tooltip',
          defaultMessage:
            'Controls how long the system allows before automatically closing finished submissions. Period format must be in descending order, e.g. 1y 2m 10d or 1m 20d',
        })}
        label={intl.formatMessage({
          id: 'specification.settings.autoClosePeriod.label',
          defaultMessage: 'Auto Close Period (e.g. 4d)',
        })}
        fieldErrors={fieldErrors}
        name="autoClosePeriod"
        value={autoClosePeriod}
        onChange={(e) => setAutoCloseperiod(e.target.value)}
        margin="normal"
        variant="outlined"
        disabled={processing}
      />
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'specification.settings.mediaRetentionPolicy.tooltip',
          defaultMessage:
            'The media retention policy controls how long uploaded data, output data and logs remain on the system.',
        })}
        label={intl.formatMessage({
          id: 'specification.settings.mediaRetentionPolicy.label',
          defaultMessage: 'Media Retention Policy',
        })}
        select
        fieldErrors={fieldErrors}
        name="mediaRetentionPolicy"
        value={mediaRetentionPolicy}
        fullWidth
        margin="normal"
        variant="outlined"
        disabled={processing}
      >
        {mediaRetentionPolicies.map((policy) => (
          <MenuItem key={policy} value={policy} onClick={() => setMediaRetentionPolicy(policy)}>
            {MEDIA_RETENTION_POLICY_LABELS[policy]}
          </MenuItem>
        ))}
      </ValidatedTextField>
      <ValidatedTextField
        tooltip={intl.formatMessage({
          id: 'specification.settings.mediaRetentionPeriod.tooltip',
          defaultMessage:
            'Only applies if "Retain for period" is selected. Defines the length of time before media is automatically removed. Period format must be in descending order, e.g. 1y 2m 10d or 1m 20d',
        })}
        label={intl.formatMessage({
          id: 'specification.settings.mediaRetentionPeriod.label',
          defaultMessage: 'Media Retention Period (e.g. 1y 2m)',
        })}
        fieldErrors={fieldErrors}
        name="mediaRetentionPeriod"
        value={mediaRetentionPeriod}
        onChange={(e) => setMediaRetentionPeriod(e.target.value)}
        margin="normal"
        variant="outlined"
        disabled={mediaRetentionPolicy !== MediaRetentionPolicy.PERIOD || processing}
      />
      <InputTooltip
        data-tooltip-for="active"
        title={intl.formatMessage({
          id: 'specification.settings.active.tooltip',
          defaultMessage:
            'If selected the specification will be visible and can be accessed by suppliers.',
        })}
      >
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="active"
              checked={active}
              onChange={() => setActive((isActive) => !isActive)}
              disabled={processing}
            />
          }
          label={intl.formatMessage({
            id: 'specification.settings.active.label',
            defaultMessage: 'Active?',
          })}
        />
      </InputTooltip>
      <InputTooltip
        data-tooltip-for="allowSourceUnmapped"
        title={intl.formatMessage({
          id: 'specification.settings.allowUnmappedSource.tooltip',
          defaultMessage:
            'Control the submission of unmapped data. If unchecked, users cannot submit classes or attributes outside of those in the target schema.',
        })}
      >
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="allowSourceUnmapped"
              checked={allowSourceUnmapped}
              onChange={() => setAllowSourceUnmapped((allow) => !allow)}
              disabled={processing}
            />
          }
          label={intl.formatMessage({
            id: 'specification.settings.allowUnmappedSource.label',
            defaultMessage: 'Allow unmapped source data?',
          })}
        />
      </InputTooltip>
      <InputTooltip
        data-tooltip-for="allowTargetUnmapped"
        title={intl.formatMessage({
          id: 'specification.settings.allowUnmappedTarget.tooltip',
          defaultMessage:
            'Control whether all target classes and attributes must be mapped to a value in the uploaded data. Allowing unmapped target data can lead to submission failures during data processing.',
        })}
      >
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="allowTargetUnmapped"
              checked={allowTargetUnmapped}
              onChange={() => setAllowTargetUnmapped((allow) => !allow)}
              disabled={processing}
            />
          }
          label={intl.formatMessage({
            id: 'specification.settings.allowUnmappedTarget.label',
            defaultMessage: 'Allow unmapped target data?',
          })}
        />
      </InputTooltip>
      <InputTooltip
        data-tooltip-for="showTargetUnmappedWarning"
        title={intl.formatMessage({
          id: 'specification.settings.showTargetUnmappedWarning.tooltip',
          defaultMessage:
            'If enabled and target data is unmapped, the user will be warned about the possibility of submission failure when they accept the schema mapping.',
        })}
      >
        <BlockFormControlLabel
          sx={{ ml: 2.5 }}
          control={
            <Checkbox
              color="primary"
              name="showTargetUnmappedWarning"
              checked={showTargetUnmappedWarning}
              onChange={() => setShowTargetUnmappedWarning((value) => !value)}
              disabled={processing || !allowTargetUnmapped}
            />
          }
          label={intl.formatMessage({
            id: 'specification.settings.showTargetUnmappedWarning.label',
            defaultMessage: 'Show unmapped target data warning?',
          })}
        />
      </InputTooltip>
      <FormButtons>
        <DefaultButton
          name="saveSpecification"
          onClick={onSubmit}
          disabled={processing}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage id="specification.settings.saveButton" defaultMessage="Save" />
        </DefaultButton>
      </FormButtons>
    </PaddedPaper>
  );
};

export default SpecificationSettingsForm;
