import { FC } from 'react';
import { Grid } from '@mui/material';
import { ValidateFieldsError } from 'async-validator';

import { ReorderDirection, SpecificationFieldDetail } from '../../../../../../types';
import { NoContentPlaceholder } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';

import FieldConfigCard from './FieldConfigCard';

interface FieldConfigCardsProps {
  specificationFields: SpecificationFieldDetail[];
  handleUpdateSpecificationField: (updatedParam: SpecificationFieldDetail) => void;
  processing: boolean;
  fieldErrors?: ValidateFieldsError;
  handleReorderField: (fieldName: string, direction: ReorderDirection) => void;
  handleRemoveField: (fieldName: string) => void;
}
const FieldConfigCards: FC<FieldConfigCardsProps> = ({
  specificationFields,
  handleUpdateSpecificationField,
  processing,
  fieldErrors,
  handleReorderField,
  handleRemoveField,
}) => {
  return (
    <>
      {specificationFields.length ? (
        specificationFields.map((specificationField, index) => {
          if (!specificationField) {
            return null;
          }

          return (
            <Grid item xs={12} key={specificationField.name}>
              <FieldConfigCard
                specificationField={specificationField}
                handleUpdateSpecificationField={handleUpdateSpecificationField}
                processing={processing}
                fieldErrors={fieldErrors}
                index={index}
                handleReorderField={handleReorderField}
                handleRemoveField={handleRemoveField}
                disablePrev={index === 0}
                disableNext={index === specificationFields.length - 1}
              />
            </Grid>
          );
        })
      ) : (
        <NoContentPlaceholder
          size="medium"
          message={intl.formatMessage({
            id: 'specification.configuration.fieldConfig.noFields',
            defaultMessage: 'No metadata configured in the specification.',
          })}
        />
      )}
    </>
  );
};

export default FieldConfigCards;
