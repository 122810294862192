import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, ReactNode } from 'react';

interface StyledRowProps {
  title: string;
  body: ReactNode;
}

const StyledRow: FC<StyledRowProps> = ({ title, body }) => {
  return (
    <Box
      width="100%"
      display="flex"
      mt="5px"
      px={1}
      py={0.75}
      sx={{ background: 'rgba(0,0,0,0.05)' }}
    >
      <Box width="50%">
        <Typography fontWeight={500} overflow="hidden" textOverflow="ellipsis" variant="body2">
          {title}
        </Typography>
      </Box>
      <Box width="50%">
        <Typography overflow="hidden" textOverflow="ellipsis" variant="body2">
          {body}
        </Typography>
      </Box>
    </Box>
  );
};
export default StyledRow;
