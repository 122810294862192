import { useContext } from 'react';
import { Grid, Container } from '@mui/material';

import { ReceiverAdminOnly } from '../../../components';

import { SupplierContext } from './SupplierContext';
import SupplierSettingsForm from './SupplierSettingsForm';
import SupplierManagement from './SupplierManagement';
import { useTitle } from '../../../hooks';
import { intl } from '../../../Internationalization';

function SupplierSettings() {
  const supplierContext = useContext(SupplierContext);
  useTitle(
    intl.formatMessage(
      { id: 'title.supplier', defaultMessage: 'Supplier ({supplierName})' },
      { supplierName: supplierContext.supplier.name }
    )
  );
  return (
    <Container maxWidth="lg" id="supplier-settings" disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SupplierSettingsForm
            supplierDetail={supplierContext.supplier}
            onSupplierUpdated={supplierContext.supplierUpdated}
          />
        </Grid>
        <ReceiverAdminOnly>
          <Grid item xs={12}>
            <SupplierManagement />
          </Grid>
        </ReceiverAdminOnly>
      </Grid>
    </Container>
  );
}

export default SupplierSettings;
