import { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import FileCopy from '@mui/icons-material/FileCopy';
import { Checkbox, DialogContent, Divider } from '@mui/material';

import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  Loading,
  PaddedDialogActions,
  DefaultButton,
  ActiveSupplierAutocomplete,
  BlockFormControlLabel,
  InputTooltip,
} from '../../../components';
import { SupplierDetail, SupplierCopyAssignmentsRequest } from '../../../types';
import { validate, COPY_SUPPLIERS_ASSIGNMENT_VALIDATOR } from '../../../validation';
import { SupplierContext } from './SupplierContext';
import * as SupplierCopyAssignmentsApi from '../../../api/supplier/copyAssignments';

interface NewAssignmentFormProps {
  onClose: () => void;
  onRefreshAssignments: () => void;
}

const CopySupplierAssignmentsForm: FC<NewAssignmentFormProps> = ({
  onClose,
  onRefreshAssignments,
}) => {
  const context = useContext(SupplierContext);
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [supplier, setSupplier] = useState<SupplierDetail | null>(null);
  const [useExistingReferences, setUseExistingReferences] = useState<boolean>(false);
  const [activeOnly, setActiveOnly] = useState<boolean>(true);

  const validateAndSubmitCopyAssignments = async () => {
    const settings: Partial<SupplierCopyAssignmentsRequest> = {
      sourceSupplierKey: supplier?.key,
      copyOnlyActiveAssignments: activeOnly,
      incrementExistingReferences: useExistingReferences,
    };

    setProcessing(true);
    setFieldErrors(undefined);
    try {
      copyAssignments(await validate(COPY_SUPPLIERS_ASSIGNMENT_VALIDATOR, settings));
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const copyAssignments = async (request: SupplierCopyAssignmentsRequest) => {
    try {
      await SupplierCopyAssignmentsApi.copyAssignments(context.supplierKey, request);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'suppliers.assignment.copy.copySuccess',
          defaultMessage: 'Assignments copied successfully.',
        }),
        { variant: 'success' }
      );
      onRefreshAssignments();
      onClose();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'suppliers.assignment.copy.copyError',
            defaultMessage: 'Failed to copy assignments',
          })
        ),
        { variant: 'error' }
      );
      setProcessing(false);
    }
  };

  const renderContent = () => {
    if (processing) {
      return (
        <Loading
          loadingText={intl.formatMessage({
            id: 'suppliers.assignment.copy.copying',
            defaultMessage: 'Copying assignments…',
          })}
        />
      );
    }
    return (
      <>
        <ActiveSupplierAutocomplete
          id="active-supplier-select"
          tooltip={intl.formatMessage({
            id: 'suppliers.assignment.copy.sourceSupplierKey.tooltip',
            defaultMessage: 'Select the supplier to copy assignments from.',
          })}
          name="sourceSupplierKey"
          label={intl.formatMessage({
            id: 'suppliers.assignment.copy.sourceSupplierKey.label',
            defaultMessage: 'Source Supplier',
          })}
          variant="outlined"
          margin="normal"
          fieldErrors={fieldErrors}
          disabled={processing}
          value={supplier}
          onChange={setSupplier}
          filterOptions={(options) => {
            return options.filter((option) => option.key !== context.supplierKey);
          }}
          fullWidth
        />
        <InputTooltip
          data-tooltip-for="useExistingReferences"
          title={intl.formatMessage({
            id: 'suppliers.assignment.copy.useExistingReferences.tooltip',
            defaultMessage:
              "By default the name of the target supplier will be used as the references for copied assignments since an assignment's reference must be unique within a specification. If selected the existing references will be used with an extra number added to the end so they are unique.",
          })}
        >
          <BlockFormControlLabel
            control={
              <Checkbox
                color="primary"
                name="useExistingReferences"
                checked={useExistingReferences}
                onChange={(e, checked) => setUseExistingReferences(checked)}
                disabled={processing}
              />
            }
            label={intl.formatMessage({
              id: 'suppliers.assignment.copy.useExistingReferences.label',
              defaultMessage: 'Use existing references?',
            })}
          />
        </InputTooltip>
        <InputTooltip
          data-tooltip-for="activeOnly"
          title={intl.formatMessage({
            id: 'suppliers.assignment.copy.activeOnly.tooltip',
            defaultMessage: 'If selected only active assignments will be copied.',
          })}
        >
          <BlockFormControlLabel
            control={
              <Checkbox
                color="primary"
                name="activeOnly"
                checked={activeOnly}
                onChange={(e, checked) => setActiveOnly(checked)}
                disabled={processing}
              />
            }
            label={intl.formatMessage({
              id: 'suppliers.assignment.copy.activeOnly.label',
              defaultMessage: 'Only copy active assignments?',
            })}
          />
        </InputTooltip>
      </>
    );
  };

  return (
    <>
      <DialogContent>{renderContent()}</DialogContent>
      <Divider />
      <PaddedDialogActions>
        <DefaultButton
          name="cancelCopyAssignments"
          color="secondary"
          onClick={onClose}
          disabled={processing}
        >
          <FormattedMessage id="suppliers.assignment.copy.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          name="copyAssignments"
          onClick={validateAndSubmitCopyAssignments}
          disabled={processing || !supplier}
          startIcon={<FileCopy />}
        >
          <FormattedMessage
            id="suppliers.assignment.copy.copyButton"
            defaultMessage="Copy Assignments"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </>
  );
};

export default CopySupplierAssignmentsForm;
