import React, { ComponentType } from 'react';
import { TableCell } from '@mui/material';
import { StringKey } from '../../util/types';
import { MinWidthTableCellProps } from './MinWidthTableCell';

export type ColumnContent = JSX.Element | string;

export interface ColumnStyle {
  component?: ComponentType<MinWidthTableCellProps>;
  componentProps?: MinWidthTableCellProps;
}

interface ConditionalTableCellsProps<T extends Record<string, ColumnContent>> {
  values: T;
  selections: StringKey<T>[];
  styles: Partial<Record<StringKey<T>, ColumnStyle>>;
}

function ConditionalTableCells<T extends { [key: string]: ColumnContent }>({
  values,
  selections,
  styles,
}: ConditionalTableCellsProps<T>) {
  return (
    <>
      {selections.map((cellKey) => {
        const { component: CellComponent = TableCell, componentProps = {} } = (styles[cellKey] ||
          {})!;
        return (
          <CellComponent key={cellKey} {...componentProps}>
            {values[cellKey]}
          </CellComponent>
        );
      })}
    </>
  );
}

export default ConditionalTableCells;
