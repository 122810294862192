import { AxiosPromise } from 'axios';

import {
  ConfigurableSiteSettings,
  PasswordRequirements,
  SiteSettingsDetail,
} from '../../types/site';
import { VirusCheckedMediaDetail } from '../../types';

import { AXIOS, FileUploadConfig } from '../endpoints';
import { uploadMediaAndVirusCheck } from '../media';

export function getSiteSettings(): AxiosPromise<SiteSettingsDetail> {
  return AXIOS.get(`/site`);
}

export function updateSiteSettings(
  request: ConfigurableSiteSettings
): AxiosPromise<SiteSettingsDetail> {
  return AXIOS.post(`/site`, request);
}

export function updatePasswordRequirements(
  request: PasswordRequirements
): AxiosPromise<SiteSettingsDetail> {
  return AXIOS.post(`/site/password_requirements`, request);
}

export const uploadLogoAndVirusCheck = (
  logo: File,
  config?: FileUploadConfig
): AxiosPromise<VirusCheckedMediaDetail> =>
  uploadMediaAndVirusCheck('/site/resources/logo', logo, config);

export function deleteLogo(): AxiosPromise<void> {
  return AXIOS.delete('/site/resources/logo');
}

export const uploadHeroImageAndVirusCheck = (
  heroImage: File,
  config?: FileUploadConfig
): AxiosPromise<VirusCheckedMediaDetail> =>
  uploadMediaAndVirusCheck('/site/resources/heroImage', heroImage, config);

export function deleteHeroImage(): AxiosPromise<void> {
  return AXIOS.delete('/site/resources/heroImage');
}

export function regenerateAuthenticationSecret(): AxiosPromise {
  return AXIOS.post('/site/regenerate_authentication_secret');
}

export const extractConfigurableSiteSettings = ({
  name,
  rootUrl,
  theme,
  loginTitle,
  authenticationTokenExpiration,
  passwordResetTokenExpiration,
  helpUrl,
  maxApiKeyPeriod,
}: SiteSettingsDetail): ConfigurableSiteSettings => ({
  name,
  rootUrl,
  theme,
  loginTitle,
  authenticationTokenExpiration,
  passwordResetTokenExpiration,
  helpUrl,
  maxApiKeyPeriod,
});
