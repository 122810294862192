import { useContext, FC } from 'react';
import { useSnackbar } from 'notistack';

import * as OAuth2IdentityProviderApi from '../../../../api/identity-provider/oAuth2IdentityProvider';
import { IdentityProviderSettings } from '../../../../types';
import { extractErrorMessage } from '../../../../api/endpoints';
import { intl } from '../../../../Internationalization';

import IdentityProviderSettingsForm from '../IdentityProviderSettingsForm';
import { OAuth2IdentityProviderContext } from './OAuth2IdentityProviderContext';

const OAuth2IdentityProviderSettings: FC = () => {
  const { identityProvider, identityProviderUpdated } = useContext(OAuth2IdentityProviderContext);
  const { enqueueSnackbar } = useSnackbar();

  const updateIdentityProvider = async (identityProviderSettings: IdentityProviderSettings) => {
    try {
      const { data: updatedIdentityProvider } =
        await OAuth2IdentityProviderApi.updateIdentityProvider(identityProvider.key, {
          ...identityProvider,
          ...identityProviderSettings,
        });

      identityProviderUpdated(updatedIdentityProvider);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'oauth2.saveSuccess',
          defaultMessage: 'Identity provider updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'oauth2.saveError',
            defaultMessage: 'Failed to update identity provider',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  return (
    <IdentityProviderSettingsForm
      onValidSubmit={updateIdentityProvider}
      identityProviderSettings={identityProvider}
    />
  );
};

export default OAuth2IdentityProviderSettings;
