import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { resultsPdfStyles } from '../../../../../components';
import {
  ACTION_KIND_METADATA,
  SUBMISSION_ACTION_STATE_METADATA,
  SubmissionActionResults,
  SubmissionDetail,
  DebugSubmissionActionResult,
} from '../../../../../types';
import {
  SUBMISSION_ACTION_OUTCOME_METADATA,
  CopyInputsSubmissionActionResult,
  ActionKind,
} from '../../../../../types';
import { ErrorOutlineOutlinedSvg } from '../../../../../components/pdf/PdfIcons';
import { PdfSection, PdfSectionHeader, PdfTableCell, PdfTableRow } from '../components';

interface ActionsTableProps {
  submission: SubmissionDetail;
}

const ActionsTable: FC<ActionsTableProps> = ({ submission }) => {
  const actions = submission.actionResults;
  const renderContent = () => {
    if (!actions.length) {
      return (
        <View style={[resultsPdfStyles.footerSummary, resultsPdfStyles.noResultIconContainer]}>
          <View style={[resultsPdfStyles.gutterBottom]}>
            <ErrorOutlineOutlinedSvg />
          </View>
          <Text>
            <FormattedMessage
              id="submission.resultsPdf.noActionsToDisplay"
              defaultMessage="There are no actions to display"
            />
          </Text>
        </View>
      );
    }

    return (
      <>
        {actions.map((action) => {
          const { label, pdfIcon: ActionOutcomeIcon } =
            SUBMISSION_ACTION_OUTCOME_METADATA[action.outcome];
          const { label: kindLabel } = ACTION_KIND_METADATA[action.kind];
          const { header, content } = renderActionTableContent(action, submission);
          return (
            <PdfSection gutterBottom key={action.key}>
              <View wrap={false}>
                <PdfSectionHeader>
                  <PdfTableCell colSpan="third">
                    <Text style={[resultsPdfStyles.sectionHeaderText]}>{action.name}</Text>
                  </PdfTableCell>
                  <PdfTableCell colSpan="third">
                    <Text style={[resultsPdfStyles.sectionHeaderText]}>{kindLabel}</Text>
                  </PdfTableCell>
                  <PdfTableCell icon colSpan="third">
                    <ActionOutcomeIcon
                      style={[resultsPdfStyles.mediumIcon, resultsPdfStyles.iconMarginRight]}
                    />
                    <Text style={[resultsPdfStyles.sectionHeaderText]}>{label}</Text>
                  </PdfTableCell>
                </PdfSectionHeader>
                {header}
              </View>
              {content}
            </PdfSection>
          );
        })}
      </>
    );
  };
  return (
    <View wrap={false}>
      <Text
        style={[
          resultsPdfStyles.subTitle,
          resultsPdfStyles.borderBottom,
          resultsPdfStyles.gutterBottom,
        ]}
      >
        <FormattedMessage
          id="submission.resultsPdf.actionResult.title"
          defaultMessage="Action Results"
        />
      </Text>
      {renderContent()}
    </View>
  );
};

export default ActionsTable;

const renderActionTableContent = (
  action: SubmissionActionResults,
  submission: SubmissionDetail
) => {
  switch (action.kind) {
    case ActionKind.COPY_INPUTS:
      return {
        header: <CopyInputActionTableHead />,
        content: <CopyInputActionTableContent action={action} submission={submission} />,
      };
    case ActionKind.DEBUG:
      return {
        header: <DebugActionTableHead />,
        content: <DebugActionTableContent action={action} submission={submission} />,
      };
  }
};

export const CopyInputActionTableHead: FC = () => (
  <PdfTableRow header>
    <PdfTableCell colSpan="quarter" header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.actions.inputsHeader" defaultMessage="Inputs" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="quarter" header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.actions.stateHeader" defaultMessage="State" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="half" header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.actions.fileHeader" defaultMessage="File" />
      </Text>
    </PdfTableCell>
  </PdfTableRow>
);

interface CopyInputActionTableContentProps {
  action: CopyInputsSubmissionActionResult;
  submission: SubmissionDetail;
}

export const CopyInputActionTableContent: FC<CopyInputActionTableContentProps> = ({
  action,
  submission,
}) => {
  return (
    <View>
      {action.steps.map(({ inputKeys, inputDestination, state }, index) => {
        const { pdfIcon: Icon, label } = SUBMISSION_ACTION_STATE_METADATA[state];
        return (
          <PdfTableRow key={index}>
            <PdfTableCell icon colSpan="quarter">
              <Text>
                {inputKeys.map((inputKey) => submission.inputsByKey[inputKey].inputName).join(', ')}
              </Text>
            </PdfTableCell>
            <PdfTableCell icon colSpan="quarter">
              <Icon style={[resultsPdfStyles.mediumIcon, resultsPdfStyles.iconMarginRight]} />
              <Text>{label}</Text>
            </PdfTableCell>
            <PdfTableCell colSpan="half">
              <Text>{inputDestination}</Text>
            </PdfTableCell>
          </PdfTableRow>
        );
      })}
    </View>
  );
};
export const DebugActionTableHead: FC = () => (
  <PdfTableRow header>
    <PdfTableCell colSpan="third" header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.actions.inputsHeader" defaultMessage="Inputs" />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" header>
      <Text>
        <FormattedMessage id="submission.resultsPdf.actions.stateHeader" defaultMessage="State" />
      </Text>
    </PdfTableCell>
  </PdfTableRow>
);

interface DebugActionTableContentProps {
  action: DebugSubmissionActionResult;
  submission: SubmissionDetail;
}

export const DebugActionTableContent: FC<DebugActionTableContentProps> = ({
  action,
  submission,
}) => {
  return (
    <View>
      {action.steps.map(({ inputKeys, state }, index) => {
        const { pdfIcon: Icon, label } = SUBMISSION_ACTION_STATE_METADATA[state];
        return (
          <PdfTableRow key={index}>
            <PdfTableCell colSpan="third">
              <Text>
                {inputKeys.map((inputKey) => submission.inputsByKey[inputKey].inputName).join(', ')}
              </Text>
            </PdfTableCell>
            <PdfTableCell icon colSpan="third">
              <Icon style={[resultsPdfStyles.mediumIcon, resultsPdfStyles.iconMarginRight]} />
              <Text>{label}</Text>
            </PdfTableCell>
          </PdfTableRow>
        );
      })}
    </View>
  );
};
