import { useContext, FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Link, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';

import { ApplicationContext } from '../../contexts/application';
import { useTitle } from '../../hooks';
import { intl } from '../../Internationalization';

import PreAuthPage from './PreAuthPage';
import SignInForm from './SignInForm';
import SignInSso from './SignInSso';

const SignIn: FC = () => {
  useTitle(
    intl.formatMessage({
      id: 'title.signIn',
      defaultMessage: 'Sign In',
    })
  );
  const {
    applicationDetails: {
      site: { loginTitle },
      identityProviders,
    },
  } = useContext(ApplicationContext);
  const supportsSso = identityProviders.length > 0;
  const [useSso, setUseSso] = useState<boolean>(supportsSso);
  const LoginComponent = useSso ? SignInSso : SignInForm;

  const renderModeSwitcher = () => {
    if (supportsSso) {
      return (
        <Box display="flex" flexDirection="column">
          <Divider />
          {useSso ? (
            <Link
              id="navigate-sign-in-form"
              sx={{ cursor: 'pointer', mt: 2 }}
              onClick={() => setUseSso(false)}
            >
              <FormattedMessage
                id="preauth.signIn.usernamePasswordLink"
                defaultMessage="Sign in with username/password"
              />
            </Link>
          ) : (
            <Link
              id="navigate-sign-in-sso"
              sx={{ cursor: 'pointer', mt: 2 }}
              onClick={() => setUseSso(true)}
            >
              <FormattedMessage id="preauth.signIn.ssoLink" defaultMessage="Sign in with SSO" />
            </Link>
          )}
        </Box>
      );
    }
  };

  return (
    <PreAuthPage>
      <Typography variant="h4" component="h1" gutterBottom>
        {loginTitle}
      </Typography>
      <LoginComponent />
      {renderModeSwitcher()}
    </PreAuthPage>
  );
};

export default SignIn;
