import { FC, useContext, useEffect, useState } from 'react';

import * as SpecificationApi from '../../../../../../api/specification/specification';
import { SpecificationActions, SpecificationInputDetail } from '../../../../../../types';
import { SpecificationContext } from '../../SpecificationContext';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import { intl } from '../../../../../../Internationalization';
import { Box, MenuItem, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ValidatedChipsArrayField } from '../../../../../../components';

interface LinkedInputsProps {
  action: SpecificationActions;
  handleUpdateInputs: (inputs: SpecificationInputDetail[]) => void;
  disabled: boolean;
}

const LinkedInputs: FC<LinkedInputsProps> = ({ action, handleUpdateInputs, disabled }) => {
  const { specificationKey } = useContext(SpecificationContext);
  const [fetching, setFetching] = useState<boolean>(false);

  const [specificationInputs, setSpecificationInputs] = useState<SpecificationInputDetail[]>();

  useEffect(() => {
    const fetchInputs = async () => {
      try {
        setFetching(true);
        const response = await SpecificationApi.getInputs(specificationKey);
        setSpecificationInputs(response.data);
      } catch (error: any) {
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.actionConfig.inputsDialog.loadError',
            defaultMessage: 'Failed to fetch inputs',
          })
        );
      } finally {
        setFetching(false);
      }
    };
    fetchInputs();
  }, [specificationKey]);

  const availableInputs = specificationInputs
    ? [...specificationInputs].filter(
        (input) => !action.inputs?.find(({ key }) => key === input.key)
      )
    : [];

  const handleUpdateLinkedInput = (updatedInputs: string[]) => {
    const mappedInputs = specificationInputs?.filter((input) => updatedInputs.includes(input.key));
    if (mappedInputs) {
      handleUpdateInputs(mappedInputs);
    }
  };
  return (
    <>
      <Box display="flex" width="100%" alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          <FormattedMessage
            id="specification.configuration.actionConfig.linkedInputs.title"
            defaultMessage="Linked inputs"
          />
        </Typography>
      </Box>
      <ValidatedChipsArrayField
        label={intl.formatMessage({
          id: 'specification.configuration.actionConfig.linkedInputs.label',
          defaultMessage: 'Linked inputs',
        })}
        chips={action?.inputs?.map((input) => input && { key: input.key, label: input.name }) || []}
        name="inputs"
        onChange={handleUpdateLinkedInput}
        endAdornmentAriaLabel={intl.formatMessage({
          id: 'specification.configuration.actionConfig.linkedInputs.endAdornmentAriaLabel',
          defaultMessage: 'Link Input',
        })}
        select
        disabled={fetching || disabled}
        margin="dense"
        size="small"
        variant="outlined"
        sx={{
          '.MuiInputAdornment-root': {
            mr: 1,
          },
        }}
      >
        {availableInputs.length ? (
          availableInputs.map((input) => (
            <MenuItem key={input.key} value={input.key}>
              {input.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>
            <FormattedMessage
              id="specification.configuration.actionConfig.linkedInputs.selectInput.none"
              defaultMessage="No inputs available"
            />
          </MenuItem>
        )}
      </ValidatedChipsArrayField>
    </>
  );
};

export default LinkedInputs;
