import { FC, Fragment, useCallback, useState } from 'react';
import { Box, Grid } from '@mui/material';

import * as SuppliersApi from '../../api/supplier/suppliers';
import * as SupplierMediaApi from '../../api/supplier/supplierMedia';
import {
  AppDecorator,
  AddFab,
  FilterPagination,
  FilterToggle,
  FilterBar,
  CardGrid,
  LogoCard,
  NoContentPlaceholder,
  StandardPagination,
  FilterSearch,
  BrowseImageCardsSkeleton,
} from '../../components';
import { intl } from '../../Internationalization';
import { updateActiveOnlyValue, checkVirusScanMediaDetail } from '../../util';
import { useBrowseRequest, useTitle } from '../../hooks';
import { SupplierDetail } from '../../types';

import NewSupplierForm from './supplier/NewSupplierForm';

export const toSupplierUrl = (supplier: SupplierDetail) => `/suppliers/${supplier.key}`;

const PAGE_SIZE = 12;

const Suppliers: FC = () => {
  useTitle(intl.formatMessage({ id: 'title.suppliers', defaultMessage: 'Suppliers' }));
  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, active: true, filter: '' },
    onRequest: SuppliersApi.getSuppliers,
  });

  const [addSupplier, setAddSupplier] = useState<boolean>(false);

  const handleFilterUpdate = useCallback(
    (filter: string) => updateRequest({ filter }),
    [updateRequest]
  );
  const handleActiveOnlyUpdate = updateActiveOnlyValue(updateRequest);

  const renderSupplierCards = () => {
    if (!response) {
      return <BrowseImageCardsSkeleton />;
    }

    if (!response.results.length) {
      return (
        <NoContentPlaceholder
          message={intl.formatMessage({
            id: 'suppliers.noSuppliers',
            defaultMessage: 'There are no suppliers matching the filter',
          })}
        />
      );
    }
    return (
      <>
        <CardGrid>
          {response.results.map((supplier) => {
            const avatarImage = checkVirusScanMediaDetail(supplier.avatar.image);
            return (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                xl={3}
                key={supplier.key}
                sx={!supplier.active ? { opacity: 0.7, filter: 'grayscale(50%)' } : {}}
              >
                <LogoCard
                  data-supplier-key={supplier.key}
                  mediaUrl={
                    avatarImage && SupplierMediaApi.downloadImageUri(supplier.key, avatarImage)
                  }
                  toUrl={toSupplierUrl(supplier)}
                  title={supplier.name}
                  color={avatarImage ? '#fff' : supplier.avatar.color}
                />
              </Grid>
            );
          })}
        </CardGrid>
        <StandardPagination
          size={request.size}
          page={request.page}
          total={response.total}
          onChange={setPage}
        />
      </>
    );
  };

  return (
    <AppDecorator
      title={intl.formatMessage({
        id: 'suppliers.title',
        defaultMessage: 'Suppliers',
      })}
    >
      <Box p={3} id="suppliers">
        <FilterBar
          startInput={
            <FilterSearch
              label={intl.formatMessage({
                id: 'suppliers.filterSearch.placeholder',
                defaultMessage: 'Filter suppliers…',
              })}
              onSearch={handleFilterUpdate}
            />
          }
          actions={
            <Fragment>
              <FilterPagination
                page={request.page}
                size={request.size}
                total={response?.total}
                disabled={processing}
                setPage={setPage}
              />
              <FilterToggle
                label={intl.formatMessage({
                  id: 'suppliers.filterActiveToggle.label',
                  defaultMessage: 'Active Only',
                })}
                name="active"
                checked={!!request.active}
                onChange={handleActiveOnlyUpdate}
                disabled={processing}
              />
            </Fragment>
          }
        />
        {renderSupplierCards()}
        {addSupplier && <NewSupplierForm onCancel={() => setAddSupplier(false)} />}
        <AddFab
          name="addSupplier"
          aria-label={intl.formatMessage({
            id: 'suppliers.addFab.addSupplier.ariaLabel',
            defaultMessage: 'Add supplier',
          })}
          onClick={() => setAddSupplier(true)}
        />
      </Box>
    </AppDecorator>
  );
};

export default Suppliers;
