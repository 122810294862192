import { MediaDetail } from '../../types';

import { generateApiUri } from '../endpoints';

export const downloadProjectImportLogFileUri = (jobId: number, log: MediaDetail): string => {
  return generateApiUri(`/project_import/${jobId}/log/${log.storedFilename}`, {
    params: { download: log.filename },
  });
};

export const downloadProjectImportDataFileUri = (jobId: number, file: MediaDetail): string => {
  return generateApiUri(`/project_import/${jobId}/data/${file.storedFilename}`, {
    params: { download: file.filename },
  });
};
