import { Box } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { enqueueSnackbar } from 'notistack';

import SaveIcon from '@mui/icons-material/Save';

import * as SpecificationFieldsApi from '../../../../../../api/specification/specificationFields';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import {
  CardGrid,
  DefaultButton,
  FormButtons,
  LabeledAddFab,
  Loading,
} from '../../../../../../components';
import {
  SpecificationFieldConfig,
  SpecificationFieldDetail,
} from '../../../../../../types/specificationFields';
import { ErrorBlockContext } from '../../../../../../contexts/error-block';
import { intl } from '../../../../../../Internationalization';
import { ReorderDirection } from '../../../../../../types';
import { useNavigationPrompt } from '../../../../../../contexts/navigation-prompt';
import { SPECIFICATION_FIELDS_CONFIG_VALIDATOR, validate } from '../../../../../../validation';

import { SpecificationContext } from '../../SpecificationContext';

import NoSessionMessage from '../NoSessionMessage';

import FieldConfigCards from './FieldConfigCards';
import AddFieldDialog from './AddFieldDialog';

const MetadataConfigEditor: FC = () => {
  const { raiseError } = useContext(ErrorBlockContext);
  const { projectKey, specificationKey, specification } = useContext(SpecificationContext);
  const { raiseNavigationBlock, clearNavigationBlock } = useNavigationPrompt();

  const [specificationFields, setSpecificationFields] = useState<SpecificationFieldDetail[]>();
  const [fetching, setFetching] = useState<boolean>(false);

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const fetchConfig = useCallback(async () => {
    setFetching(true);
    try {
      const response = await SpecificationFieldsApi.fetchSpecificationFields(specificationKey);
      setSpecificationFields(response.data);
      setFetching(false);
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.fieldConfig.loadError',
            defaultMessage: 'Failed to fetch metadata',
          })
        )
      );
    }
  }, [raiseError, specificationKey]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const handleFieldCreated = (field: SpecificationFieldDetail) => {
    setSpecificationFields((prev) => [...(prev ? prev : []), field]);
  };

  const handleUpdateSpecificationField = (updatedField: SpecificationFieldDetail) => {
    raiseNavigationBlock();
    setSpecificationFields((prevFields) => {
      const updatedFields = [...(prevFields || [])];

      const fieldIndex = updatedFields.findIndex((d) => d.name === updatedField.name);
      updatedFields[fieldIndex] = updatedField;

      return updatedFields;
    });
  };

  const validateAndSaveConfig = async () => {
    setProcessing(true);

    try {
      setFieldErrors({});
      saveConfig(
        (
          await validate(SPECIFICATION_FIELDS_CONFIG_VALIDATOR, {
            fields: specificationFields,
          })
        ).fields
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const saveConfig = async (validatedFields: SpecificationFieldConfig[]) => {
    setProcessing(true);

    try {
      const response = await SpecificationFieldsApi.updateSpecificationFields(
        specificationKey,
        validatedFields
      );
      setSpecificationFields(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'specification.configuration.fieldConfig.saveSuccessful',
          defaultMessage: 'Metadata updated successfully',
        }),
        { variant: 'success' }
      );
      clearNavigationBlock();
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.fieldConfig.saveError',
            defaultMessage: 'Failed to save metadata',
          })
        )
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleRemoveField = async (fieldName: string) => {
    setProcessing(true);
    try {
      await SpecificationFieldsApi.deleteSpecificationField(specificationKey, fieldName);
      setSpecificationFields((prev) => {
        const updatedFields = [...(prev || [])];
        const index = updatedFields.findIndex((field) => field.name === fieldName);

        updatedFields.splice(index, 1);

        return updatedFields;
      });
      enqueueSnackbar(
        intl.formatMessage({
          id: 'specification.configuration.fieldConfig.deleteSuccessful',
          defaultMessage: 'Field deleted successfully',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.fieldConfig.deleteError',
            defaultMessage: 'Failed to delete field',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const handleReorderField = (fieldName: string, direction: ReorderDirection) => {
    setFieldErrors(undefined);
    if (!specificationFields) {
      return;
    }
    const index = specificationFields.findIndex((field) => field.name === fieldName);
    if (
      (index === 0 && direction === 'UP') ||
      (index === specificationFields.length - 1 && direction === 'DOWN')
    ) {
      return;
    }
    setSpecificationFields((prevFields) => {
      const updatedArray = [...(prevFields || [])];
      const to = direction === 'UP' ? index - 1 : index + 1;
      [updatedArray[index], updatedArray[to]] = [updatedArray[to], updatedArray[index]];
      return updatedArray;
    });
    raiseNavigationBlock();
  };

  const renderCardGridContent = () => {
    if (specificationFields) {
      return {
        className: 'FieldConfigEditor-loaded',
        content: (
          <FieldConfigCards
            specificationFields={specificationFields}
            handleUpdateSpecificationField={handleUpdateSpecificationField}
            processing={processing}
            fieldErrors={fieldErrors}
            handleReorderField={handleReorderField}
            handleRemoveField={handleRemoveField}
          />
        ),
      };
    }

    if (!specification.sessionPath && !fetching) {
      return {
        className: 'FieldConfigEditor-noContent',
        content: <NoSessionMessage projectKey={projectKey} specificationKey={specificationKey} />,
      };
    }
    return {
      className: 'FieldConfigEditor-loading',
      content: <Loading />,
    };
  };

  const cards = renderCardGridContent();
  return (
    <Box id="specification-field-editor" className={cards.className}>
      <CardGrid>{cards.content}</CardGrid>
      <FormButtons>
        <DefaultButton
          name="saveFieldConfig"
          disabled={processing || fetching}
          onClick={validateAndSaveConfig}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage
            id="specification.configuration.fieldConfig.saveButton"
            defaultMessage="Save Metadata"
          />
        </DefaultButton>
      </FormButtons>
      <AddFieldDialog
        dialogOpen={dialogOpen}
        disabled={fetching}
        onConfirm={handleFieldCreated}
        onCloseDialog={() => setDialogOpen(false)}
      />
      <LabeledAddFab
        name="createField"
        label={intl.formatMessage({
          id: 'specification.configuration.fieldConfig.addFieldButton',
          defaultMessage: 'Add Field',
        })}
        onClick={() => setDialogOpen(true)}
        disabled={fetching}
      />
    </Box>
  );
};

export default MetadataConfigEditor;
