import { FC, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import {
  DefaultButton,
  PaddedDialogActions,
  ValidatedTextField,
} from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';
import {
  ACTION_KINDS,
  ACTION_KIND_METADATA,
  SpecificationActions,
  SpecificationActionDetail,
  ActionKind,
} from '../../../../../../types';
import {
  CREATE_SPECIFICATION_ACTION_CONFIG_VALIDATOR,
  validate,
} from '../../../../../../validation';

const INITIAL_ACTION = {
  name: '',
  kind: ActionKind.COPY_INPUTS,
};

interface AddActionDialogProps {
  dialogOpen: boolean;
  disabled: boolean;
  onConfirm: (action: SpecificationActionDetail) => void;
  onCloseDialog: () => void;
}

const AddActionDialog: FC<AddActionDialogProps> = ({
  dialogOpen,
  disabled,
  onConfirm,
  onCloseDialog,
}) => {
  const [action, setAction] = useState<Partial<SpecificationActionDetail>>(INITIAL_ACTION);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const handleClose = () => {
    setAction(INITIAL_ACTION);
  };

  const validateAndAddAction = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      onConfirm(await validate(CREATE_SPECIFICATION_ACTION_CONFIG_VALIDATOR, action));
      handleClose();
    } catch (errors: any) {
      setFieldErrors(errors);
    } finally {
      setProcessing(false);
    }
  };

  const handleUpdateAction = (updatedAction: Partial<SpecificationActions>) => {
    setAction((prev) => ({ ...prev, ...updatedAction }));
  };

  const disableInputs = processing || disabled;

  return (
    <Dialog id="add-action-dialog" open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="specification.configuration.actionConfig.actionDialog.title"
          defaultMessage="Add Action"
        />
      </DialogTitle>
      <DialogContent>
        <ValidatedTextField
          name="name"
          label={intl.formatMessage({
            id: 'specification.configuration.actionConfig.actionDialog.name.label',
            defaultMessage: 'Name',
          })}
          fullWidth
          value={action.name}
          onChange={(event) => {
            handleUpdateAction({ name: event.target.value });
          }}
          variant="outlined"
          fieldErrors={fieldErrors}
          disabled={disableInputs}
          margin="normal"
          size="small"
        />
        <ValidatedTextField
          name="kind"
          label={intl.formatMessage({
            id: 'specification.configuration.actionConfig.actionDialog.kind.label',
            defaultMessage: 'Kind',
          })}
          value={action.kind}
          onChange={(event) => handleUpdateAction({ kind: event.target.value as ActionKind })}
          margin="normal"
          size="small"
          variant="outlined"
          select
          disabled={disableInputs}
        >
          {ACTION_KINDS.map((kind) => (
            <MenuItem key={kind} value={kind}>
              {ACTION_KIND_METADATA[kind].label}
            </MenuItem>
          ))}
        </ValidatedTextField>
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancel"
          disabled={disableInputs}
          onClick={onCloseDialog}
          color="secondary"
        >
          <FormattedMessage
            id="specification.configuration.actionConfig.actionDialog.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton name="confirm" disabled={disableInputs} onClick={validateAndAddAction}>
          <FormattedMessage
            id="specification.configuration.actionConfig.actionDialog.createButton"
            defaultMessage="Create Action"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default AddActionDialog;
