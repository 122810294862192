import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

import { DefaultButton } from '../../../../../components';

interface NoSessionMessageProps {
  projectKey: string;
  specificationKey: string;
}

const NoSessionMessage: FC<NoSessionMessageProps> = ({ projectKey, specificationKey }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="space-around"
    height={150}
    my={3}
  >
    <BlockIcon fontSize="large" />
    <Typography variant="h5" component="p" color="textSecondary" gutterBottom>
      <FormattedMessage
        id="specification.configuration.noSession.title"
        defaultMessage="Session template missing or invalid"
      />
    </Typography>
    <DefaultButton
      component={Link}
      to={`/projects/${projectKey}/specifications/${specificationKey}/settings`}
    >
      <FormattedMessage
        id="specification.configuration.noSession.settingsButton"
        defaultMessage="Specification Settings"
      />
    </DefaultButton>
  </Box>
);

export default NoSessionMessage;
