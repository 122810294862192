import { AxiosPromise } from 'axios';

import {
  NamedLocationsRequest,
  NamedLocationsResponse,
  NamedLocationDetail,
  NamedLocationRequest,
} from '../../types';
import { AXIOS, RequestConfig } from '../endpoints';

export function getNamedLocations(
  request: NamedLocationsRequest,
  config?: RequestConfig
): AxiosPromise<NamedLocationsResponse> {
  return AXIOS.get('/named_locations', {
    ...config,
    params: request,
  });
}

export function createNamedLocation(
  request: NamedLocationRequest
): AxiosPromise<NamedLocationDetail> {
  return AXIOS.post('/named_locations', request);
}
