import { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DefaultButton, PaddedDialogActions } from '../../../../../../components';

interface DeleteActionDialogProps {
  dialogOpen: boolean;
  disabled: boolean;
  onConfirm: () => void;
  onCloseDialog: () => void;
}

const DeleteActionDialog: FC<DeleteActionDialogProps> = ({
  dialogOpen,
  disabled,
  onConfirm,
  onCloseDialog,
}) => {
  const handleConfirmDelete = () => {
    onConfirm();
    onCloseDialog();
  };
  return (
    <Dialog
      id="delete-action-dialog"
      open={dialogOpen}
      onClose={onCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <FormattedMessage
          id="specification.configuration.actionConfig.deleteActionDialog.title"
          defaultMessage="Delete Action"
        />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="specification.configuration.actionConfig.deleteActionDialog.text"
            defaultMessage="Are you sure you want to delete this action?"
          />
        </Typography>
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton name="cancel" disabled={disabled} onClick={onCloseDialog} color="secondary">
          <FormattedMessage
            id="specification.configuration.actionConfig.deleteActionDialog.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton name="confirm" disabled={disabled} onClick={handleConfirmDelete}>
          <FormattedMessage
            id="specification.configuration.actionConfig.deleteActionDialog.deleteButton"
            defaultMessage="Delete Action"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default DeleteActionDialog;
