import React from 'react';

import {
  AssignmentDetail,
  DataStoreConfigDetail,
  ProjectDetail,
  SessionSchema,
  SpecificationDetail,
  SubmissionDetail,
} from '../../../../../types';

interface OpenSubmissionContextValue {
  submissionUpdated: React.Dispatch<React.SetStateAction<SubmissionDetail | undefined>>;
  submission: SubmissionDetail;
  assignment: AssignmentDetail;
  project: ProjectDetail;
  specification: SpecificationDetail;
  dataStores: DataStoreConfigDetail[];
  specificationValid: boolean;
  sessionSchema: SessionSchema;
}

const OpenSubmissionContextDefaultValue = {} as OpenSubmissionContextValue;
export const OpenSubmissionContext = React.createContext(OpenSubmissionContextDefaultValue);
