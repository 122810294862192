import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import copy from 'clipboard-copy';
import { FormattedMessage } from 'react-intl';

import { Box, Card, IconButton, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import AbcIcon from '@mui/icons-material/Abc';
import KeyIcon from '@mui/icons-material/Key';

import { intl } from '../../../../../../Internationalization';
import {
  DataStoreConfigDetail,
  DataStoreParameter,
  DataStoreParameterType,
  DataStoreSchema,
  DataStoreTypeMetadata,
  Schema,
  SCHEMA_MAPPING_MODES,
  SCHEMA_MAPPING_MODE_LABELS,
} from '../../../../../../types';
import {
  dataStoreNameFromPath,
  metadataForSchema,
  updateOnChangeValue,
} from '../../../../../../util';
import { UxNotSupportedIcon } from '../../../../../../components/icons';
import { NoContentPlaceholder, ValidatedBooleanSelect } from '../../../../../../components';
import OutputFilenameTemplate from './OutputFilenameTemplate';

const calculateSchemaMetadata = (schema?: Schema): DataStoreTypeMetadata => {
  if (!schema) {
    return {
      label: intl.formatMessage({
        id: 'specification.configuration.dataStoreConfig.notSupported',
        defaultMessage: 'Not Supported',
      }),
      icon: UxNotSupportedIcon,
    };
  }

  return metadataForSchema(schema);
};

interface DataStoreFormatLabelsProps {
  importSchema?: Schema;
  exportSchema?: Schema;
}

const DataStoreFormatLabels: FC<DataStoreFormatLabelsProps> = ({ importSchema, exportSchema }) => {
  const { label: importLabel, icon: ImportIcon } = calculateSchemaMetadata(importSchema);
  const { label: exportLabel, icon: ExportIcon } = calculateSchemaMetadata(exportSchema);

  return (
    <Box display="flex" alignItems="center" className="DataStoreFormats-container">
      <Box display="flex" alignItems="center" my={1}>
        <ImportIcon fontSize="large" />
        <Box display="flex" flexDirection="column" alignItems="left" ml={1}>
          <Typography variant="caption">
            <FormattedMessage
              id="specification.configuration.dataStoreConfig.inputformat"
              defaultMessage="Input format"
            />
          </Typography>
          <Typography variant="body2">{importLabel}</Typography>
        </Box>
      </Box>
      <ArrowRightAltIcon sx={{ mx: 1 }} />
      <Box display="flex" alignItems="center" my={1}>
        <ExportIcon fontSize="large" />
        <Box display="flex" flexDirection="column" alignItems="left" ml={1}>
          <Typography variant="caption">
            <FormattedMessage
              id="specification.configuration.dataStoreConfig.outputFormat"
              defaultMessage="Output format"
            />
          </Typography>
          <Typography variant="body2">{exportLabel}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface ParameterIconProps {
  type: DataStoreParameterType;
}

const ParameterIcon: FC<ParameterIconProps> = ({ type }) => {
  if (type === 'PASSWORD') {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.inputParameters.password',
          defaultMessage: 'Password',
        })}
      >
        <KeyIcon />
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'specification.configuration.dataStoreConfig.inputParameters.text',
        defaultMessage: 'Text',
      })}
    >
      <AbcIcon />
    </Tooltip>
  );
};

interface OverrideParametersProps {
  overrideParameters: DataStoreParameter[];
  handleDelete: (parameter: string) => void;
}

const OverrideParameters: FC<OverrideParametersProps> = ({ overrideParameters, handleDelete }) => {
  if (overrideParameters.length) {
    return (
      <>
        {overrideParameters.map((parameter) => {
          return (
            <Box display="flex" key={parameter.name} alignItems="start">
              <ParameterIcon type={parameter.type} />
              <Typography sx={{ ml: 1, flexGrow: 1 }}>{parameter.name}</Typography>
              <IconButton
                name="overrideParameterDeleteButton"
                onClick={() => handleDelete(parameter.name)}
                aria-label={intl.formatMessage(
                  {
                    id: 'specification.configuration.dataStoreConfig.overrideParameters.deleteButton.ariaLabel',
                    defaultMessage: 'Delete {name}',
                  },
                  { name: parameter.name }
                )}
                size="small"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Box>
          );
        })}
      </>
    );
  }

  return (
    <>
      <NoContentPlaceholder
        size="small"
        message={intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.overrideParameters.noParameters',
          defaultMessage: 'No parameters specified',
        })}
      />
    </>
  );
};

interface AddInputParameterProps {
  parameters?: DataStoreParameter[];
  handleAddParameter: (parameter: DataStoreParameter) => void;

  dialogOpen: boolean;
  handleCloseDialog: () => void;
}

const AddInputParameter: FC<AddInputParameterProps> = ({ parameters, handleAddParameter }) => {
  const [selectedParameter, setSelectedParameter] = useState<DataStoreParameter | undefined>();

  const onAddParameter = () => {
    if (!selectedParameter) {
      return;
    }
    handleAddParameter(selectedParameter);
    setSelectedParameter(undefined);
  };

  return (
    <Box display="flex">
      <TextField
        name="inputParameters"
        fullWidth
        value={selectedParameter?.name || ''}
        onChange={(event) => {
          setSelectedParameter(parameters?.find(({ name }) => name === event.target.value));
        }}
        variant="standard"
        size="small"
        margin="dense"
        select
        sx={{ '& .MuiSelect-select>svg': { verticalAlign: 'middle', mr: 1 } }}
      >
        {parameters && parameters.length ? (
          parameters.map((parameter) => (
            <MenuItem key={parameter.name} value={parameter.name} sx={{ columnGap: 1 }}>
              <ParameterIcon type={parameter.type} />
              {parameter.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled={true}>
            <FormattedMessage
              id="specification.configuration.dataStoreConfig.inputParameters.none"
              defaultMessage="No available parameters"
            />
          </MenuItem>
        )}
      </TextField>
      <IconButton
        disabled={!selectedParameter}
        onClick={onAddParameter}
        sx={{ ml: 0.5, minWidth: 42 }}
        size="small"
        name="overrideParameterAddButton"
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

interface DataStoreConfigCardProps {
  dataStoreSchema: DataStoreSchema;
  dataStoreConfig: DataStoreConfigDetail;

  savingConfig: boolean;
  updateDataStoreConfig: (dataStoreConfig: DataStoreConfigDetail) => void;
}

const DataStoreConfigCard: FC<DataStoreConfigCardProps> = ({
  dataStoreSchema,
  dataStoreConfig,
  savingConfig,
  updateDataStoreConfig,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [addParameterDialogOpen, setAddParameterDialogOpen] = useState<boolean>(false);

  const handleAddOverrideParameter = (parameter: DataStoreParameter) => {
    const updatedConfig = { ...dataStoreConfig };
    updatedConfig.overrideParameters.push(parameter);
    updateDataStoreConfig(updatedConfig);
  };
  const handleRemoveOverrideParameter = (parameterToDelete: string) => {
    const updatedConfig = { ...dataStoreConfig };
    updatedConfig.overrideParameters = updatedConfig.overrideParameters.filter(
      ({ name }) => name !== parameterToDelete
    );
    updateDataStoreConfig(updatedConfig);
  };

  const { importSchema, exportSchema } = dataStoreSchema;
  const { path, overrideParameters } = dataStoreConfig;

  const inputParameters = Object.values(importSchema?.type.inputParameters || {}).filter(
    ({ name }) => !overrideParameters.map((providedParam) => providedParam.name).includes(name)
  );

  const { schemaMappingMode, downloadModifiedInput, downloadOutput } = dataStoreConfig;
  const modifiesImportFile = dataStoreSchema.modifiesImportFile;

  const updateDataStoreConfigValue = updateOnChangeValue(
    (callback: (dataStoreConfig: DataStoreConfigDetail) => DataStoreConfigDetail) => {
      updateDataStoreConfig(callback(dataStoreConfig));
    }
  );

  return (
    <Card key={path} sx={{ p: 2 }} elevation={3}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center">
          <Tooltip
            disableInteractive={true}
            title={intl.formatMessage({
              id: 'specification.configuration.dataStoreConfig.copyFullDataStorePath.tooltip',
              defaultMessage: 'Copy full data store path',
            })}
          >
            <Box mr={1}>
              <IconButton
                name="copyDataStorePath"
                onClick={() => {
                  copy(path);
                  enqueueSnackbar(
                    intl.formatMessage({
                      id: 'specification.configuration.dataStoreConfig.copyFullDataStorePath.copySuccess',
                      defaultMessage: 'Copied to clipboard',
                    }),
                    { variant: 'info' }
                  );
                }}
                size="small"
                aria-label={intl.formatMessage({
                  id: 'specification.configuration.dataStoreConfig.copyFullDataStorePath.ariaLabel',
                  defaultMessage: 'Copy data store path',
                })}
              >
                <FilterNoneIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title={path}>
            <Typography variant="body2" className="DataStoreConfigCard-dataStoreName">
              {dataStoreNameFromPath(path)}
            </Typography>
          </Tooltip>
        </Box>
        <DataStoreFormatLabels importSchema={importSchema} exportSchema={exportSchema} />
      </Box>
      <Box display="flex" columnGap={3}>
        <Box sx={{ width: { sm: '100%', md: '33%' } }}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            <FormattedMessage
              id="specification.configuration.dataStoreConfig.input"
              defaultMessage="Input"
            />
          </Typography>
          <TextField
            name="schemaMappingMode"
            disabled={savingConfig}
            label={intl.formatMessage({
              id: 'specification.configuration.dataStoreConfig.schemaMappingMode.label',
              defaultMessage: 'Schema Mapping',
            })}
            value={schemaMappingMode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateDataStoreConfigValue(event, event.target.value);
            }}
            margin="dense"
            size="small"
            variant="outlined"
            select
            fullWidth
          >
            {SCHEMA_MAPPING_MODES.map((mode) => (
              <MenuItem key={mode} value={mode}>
                {SCHEMA_MAPPING_MODE_LABELS[mode]}
              </MenuItem>
            ))}
          </TextField>
          <ValidatedBooleanSelect
            disabled={!modifiesImportFile || savingConfig}
            name="downloadModifiedInput"
            value={downloadModifiedInput}
            label={intl.formatMessage({
              id: 'specification.configuration.dataStoreConfig.downloadModifiedInput.label',
              defaultMessage: 'Download Modified Input Mode',
            })}
            trueLabel={intl.formatMessage({
              id: 'specification.configuration.dataStoreConfig.downloadModifiedInput.trueLabel',
              defaultMessage: 'Downloadable',
            })}
            falseLabel={intl.formatMessage({
              id: 'specification.configuration.dataStoreConfig.downloadModifiedInput.falseLabel',
              defaultMessage: 'Not Supported',
            })}
            margin="dense"
            variant="outlined"
            size="small"
            fullWidth
            onChange={updateDataStoreConfigValue}
          />
        </Box>
        <Box sx={{ width: { sm: '100%', md: '33%' } }}>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            <FormattedMessage
              id="specification.configuration.dataStoreConfig.output"
              defaultMessage="Output"
            />
          </Typography>
          <ValidatedBooleanSelect
            disabled={!exportSchema || !exportSchema.type.downloadable || savingConfig}
            name="downloadOutput"
            value={downloadOutput}
            label={intl.formatMessage({
              id: 'specification.configuration.dataStoreConfig.downloadOutput.label',
              defaultMessage: 'Download Mode',
            })}
            trueLabel={intl.formatMessage({
              id: 'specification.configuration.dataStoreConfig.downloadOutput.trueLabel',
              defaultMessage: 'Downloadable',
            })}
            falseLabel={intl.formatMessage({
              id: 'specification.configuration.dataStoreConfig.downloadOutput.falseLabel',
              defaultMessage: 'Not Supported',
            })}
            margin="dense"
            size="small"
            variant="outlined"
            fullWidth
            onChange={updateDataStoreConfigValue}
          />
          <OutputFilenameTemplate
            dataStoreConfig={dataStoreConfig}
            updateDataStoreConfig={updateDataStoreConfig}
            disabled={!downloadOutput || savingConfig}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: { sm: '100%', md: '33%' } }}
          className="Parameters-container"
        >
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            <FormattedMessage
              id="specification.configuration.dataStoreConfig.parameter"
              defaultMessage="User-Supplied Parameters"
            />
          </Typography>
          <Box height="100%">
            <OverrideParameters
              overrideParameters={overrideParameters}
              handleDelete={handleRemoveOverrideParameter}
            />
          </Box>
          <AddInputParameter
            dialogOpen={addParameterDialogOpen}
            parameters={inputParameters}
            handleCloseDialog={() => setAddParameterDialogOpen(false)}
            handleAddParameter={handleAddOverrideParameter}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default DataStoreConfigCard;
