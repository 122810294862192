import { formatISO } from 'date-fns';

import { ScheduleDetail, UpdateScheduleRequest } from '../types';
import { intl } from '../Internationalization';
import { ScheduleEndCriteria } from '../types/cronSchedulePreview';
import { ScheduleEventFailureReason } from '../types/scheduleEvent';

export enum Repeat {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const REPEAT = [Repeat.NONE, Repeat.DAILY, Repeat.WEEKLY, Repeat.MONTHLY];

export const REPEAT_METADATA: Record<Repeat, { label: string }> = {
  NONE: {
    label: intl.formatMessage({
      id: 'schedules.repeatMetadata.none.label',
      defaultMessage: 'None',
    }),
  },
  DAILY: {
    label: intl.formatMessage({
      id: 'schedules.repeatMetadata.daily.label',
      defaultMessage: 'Daily',
    }),
  },
  WEEKLY: {
    label: intl.formatMessage({
      id: 'schedules.repeatMetadata.weekly.label',
      defaultMessage: 'Weekly',
    }),
  },
  MONTHLY: {
    label: intl.formatMessage({
      id: 'schedules.repeatMetadata.monthly.label',
      defaultMessage: 'Monthly (on the same day of the month)',
    }),
  },
};

export const SCHEDULE_END_CRITERIA_METADATA: Record<ScheduleEndCriteria, { label: string }> = {
  NONE: {
    label: intl.formatMessage({
      id: 'schedules.continueUntilMetadata.NONE.label',
      defaultMessage: 'None',
    }),
  },
  END_DATE_TIME: {
    label: intl.formatMessage({
      id: 'schedules.continueUntilMetadata.endDateTime.label',
      defaultMessage: 'Specified End Date Time',
    }),
  },
  NUMBER_OF_OCCURRENCES: {
    label: intl.formatMessage({
      id: 'schedules.continueUntilMetadata.numberOfOccurrences.label',
      defaultMessage: 'Number of Occurrences',
    }),
  },
};

export const calculateCronExpression = (startDateTime: Date, repeat: Repeat) => {
  const seconds = 0;
  const minutes = startDateTime.getUTCMinutes();
  const hours = startDateTime.getUTCHours();

  const dayOfMonth =
    repeat === Repeat.MONTHLY || repeat === Repeat.NONE
      ? startDateTime.getUTCDate()
      : repeat === Repeat.DAILY
      ? '*'
      : '?';
  const months = '*';
  const daysOfWeek = repeat === Repeat.WEEKLY ? startDateTime.getUTCDay() + 1 : '?';

  return `${seconds} ${minutes} ${hours} ${dayOfMonth} ${months} ${daysOfWeek}`;
};

export const parseCronToRepeatValue = (schedule: ScheduleDetail) => {
  const values = schedule.cronExpression.split(' ');

  if (
    schedule.endCriteria === ScheduleEndCriteria.NUMBER_OF_OCCURRENCES &&
    schedule.numberOfOccurrences === 1
  ) {
    return Repeat.NONE;
  } else if (!isNaN(Number(values[3]))) {
    return Repeat.MONTHLY;
  } else if (!isNaN(Number(values[5]))) {
    return Repeat.WEEKLY;
  } else {
    return Repeat.DAILY;
  }
};

export interface ScheduleSettings {
  active: boolean;
  startDateTime: Date;
  endDateTime: Date | null;
  repeat: Repeat;
  scheduleEndCriteria: ScheduleEndCriteria;
  note: string;
  numberOfOccurrences?: number;
}

export const parseScheduleToUpdateRequest = ({
  startDateTime,
  endDateTime,
  repeat,
  scheduleEndCriteria,
  note,
  numberOfOccurrences,
  active,
}: ScheduleSettings): UpdateScheduleRequest => {
  const cron = calculateCronExpression(startDateTime, repeat);

  return {
    note,
    active,
    cronExpression: cron,
    startDateTime: formatISO(startDateTime),
    endDateTime:
      scheduleEndCriteria === ScheduleEndCriteria.END_DATE_TIME && endDateTime !== null
        ? formatISO(endDateTime)
        : undefined,
    numberOfOccurrences:
      scheduleEndCriteria === ScheduleEndCriteria.NUMBER_OF_OCCURRENCES
        ? numberOfOccurrences
        : undefined,
    endCriteria: scheduleEndCriteria,
  };
};

export const SCHEDULE_EVENT_FAILURE_REASON_METADATA: Record<
  ScheduleEventFailureReason,
  { label: string }
> = {
  FIRED_WHILE_INACTIVE: {
    label: intl.formatMessage({
      id: 'schedules.event.failureReasons.FIRED_WHILE_INACTIVE.label',
      defaultMessage: 'Schedule was inactive',
    }),
  },
  MISSING_REQUIRED_PERMISSION: {
    label: intl.formatMessage({
      id: 'schedules.event.failureReasons.MISSING_REQUIRED_PERMISSION.label',
      defaultMessage: 'Schedule owner did not have required permissions to run the submission.',
    }),
  },
  CREATE_FAILED: {
    label: intl.formatMessage({
      id: 'schedules.event.failureReasons.CREATE_FAILED.label',
      defaultMessage: 'Unable to create a new submission.',
    }),
  },
  START_FAILED: {
    label: intl.formatMessage({
      id: 'schedules.event.failureReasons.START_FAILED.label',
      defaultMessage:
        'Unable to start the submission. You might be able to open the submission and fix the issue which prevented the submission starting.',
    }),
  },
  UNKNOWN: {
    label: intl.formatMessage({
      id: 'schedules.event.failureReasons.UNKNOWN.label',
      defaultMessage: 'An unexpected error occurred.',
    }),
  },
};
