import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { omitBy } from 'lodash';

import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  DataStoreClassSchema,
  MappedAttributes,
  SchemaAttribute,
  SchemaMappingMode,
} from '../../../types';
import { StyledTableHead, TableInfoRow } from '../../../components';
import { intl } from '../../../Internationalization';

import { SchemaMappingContext } from './SchemaMappingContext';
import { SCHEMA_MAPPING_DIRECTION_METADATA } from './schemaMappingMetadata';

enum AttributeMappingStatus {
  MAPPED = 'MAPPED',
  UNMAPPED = 'UNMAPPED',
}

const attributeMappingStatuses = [AttributeMappingStatus.MAPPED, AttributeMappingStatus.UNMAPPED];

const ATTRIBUTE_MAPPING_STATUS_METADATA: { [type in AttributeMappingStatus]: { label: string } } = {
  MAPPED: {
    label: 'Mapped',
  },
  UNMAPPED: {
    label: 'Unmapped',
  },
};

interface DataStoreClassAttributesProps {
  schemaMappingMode: SchemaMappingMode;
  classAttributesOpen: boolean;
  targetClass: DataStoreClassSchema;
  sourceClass: DataStoreClassSchema;
  mappedAttributes: MappedAttributes;
  onAttributesUpdated: (attributes: MappedAttributes) => void;
  processing: boolean;
  classIndex: number;
}

const DataStoreClassAttributes: FC<DataStoreClassAttributesProps> = ({
  schemaMappingMode,
  classAttributesOpen,
  targetClass,
  sourceClass,
  mappedAttributes,
  onAttributesUpdated,
  processing,
  classIndex,
}) => {
  const { direction } = useContext(SchemaMappingContext);

  const {
    findLeftMappedAttributeName,
    findRightMappedAttributeName,
    extractMappedAttributes,
    selectLeftOrSource,
    selectRightOrTarget,
    leftAttributeHeaderText,
    rightAttributeHeaderText,
    leftClassValidationResults: LeftValidationResults,
    rightClassValidationResults: RightValidationResults,
    rightClassHeaderText,
  } = SCHEMA_MAPPING_DIRECTION_METADATA[direction];

  const [attributeFilter, setAttributeFilter] = useState<string>('');
  const [attributeStatusFilter, setAttributeStatusFilter] = useState<AttributeMappingStatus[]>([]);
  const [filteredAttributes, setFilteredAttributes] = useState<SchemaAttribute[]>(
    targetClass.attributes
  );

  useEffect(() => {
    const currentlyMapped = extractMappedAttributes(mappedAttributes);
    setFilteredAttributes(
      selectLeftOrSource(sourceClass.attributes, targetClass.attributes)
        .filter((attributeToFilter) =>
          attributeToFilter.name.toLocaleLowerCase().includes(attributeFilter.toLocaleLowerCase())
        )
        .filter((attributeToFilter) => {
          if (!attributeStatusFilter.length) {
            return true;
          }
          const mapped = currentlyMapped.includes(attributeToFilter.name);
          return (
            (attributeStatusFilter.includes(AttributeMappingStatus.MAPPED) && mapped) ||
            (attributeStatusFilter.includes(AttributeMappingStatus.UNMAPPED) && !mapped)
          );
        })
        .sort()
    );
  }, [
    attributeFilter,
    attributeStatusFilter,
    extractMappedAttributes,
    mappedAttributes,
    selectLeftOrSource,
    sourceClass,
    targetClass,
  ]);

  const handleChangeAttribute = (leftAttribute: string, rightAttribute: string) => {
    const targetAttribute = selectRightOrTarget(leftAttribute, rightAttribute);
    const sourceAttribute = selectLeftOrSource(leftAttribute, rightAttribute);

    onAttributesUpdated({
      ...omitBy(mappedAttributes, (value: string) => value === targetAttribute),
      [sourceAttribute]: targetAttribute,
    });
  };

  const handleClearAttribute = (leftAttribute: string, rightAttribute: string) => {
    onAttributesUpdated(
      omitBy(
        mappedAttributes,
        (value) => value === selectRightOrTarget(leftAttribute, rightAttribute)
      )
    );
  };

  const renderAttributeIcon = (sourceAttribute: string) => {
    if (findRightMappedAttributeName(sourceAttribute, mappedAttributes)) {
      return (
        <CheckCircleIcon
          sx={{ mr: 1, color: 'success.main' }}
          fontSize="small"
          titleAccess={intl.formatMessage({
            id: 'schemaMapper.attributes.mapped.titleAccess',
            defaultMessage: 'Attribute mapped',
          })}
        />
      );
    }
    return (
      <HelpIcon
        sx={{ mr: 1, color: 'default.main' }}
        fontSize="small"
        titleAccess={intl.formatMessage({
          id: 'schemaMapper.attributes.unmapped.titleAccess',
          defaultMessage: 'Attribute unmapped',
        })}
      />
    );
  };

  const renderAttributeRow = (leftAttribute: SchemaAttribute, index: number) => {
    const rightAttribute = findLeftMappedAttributeName(leftAttribute.name, mappedAttributes);
    const rightAttributes = selectRightOrTarget(sourceClass.attributes, targetClass.attributes);
    return (
      <TableRow key={leftAttribute.name}>
        <TableCell>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px dotted',
              minHeight: '32px',
            }}
          >
            {leftAttribute.name}
            <ArrowRightAltIcon sx={{ marginRight: 1.25 }} />
          </Typography>
        </TableCell>
        <TableCell>
          {schemaMappingMode === SchemaMappingMode.MANUAL ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <TextField
                fullWidth
                sx={{
                  '& .MuiInput-input svg': {
                    display: 'none',
                  },
                }}
                select
                value={rightAttribute || ''}
                onChange={(e) => handleChangeAttribute(leftAttribute.name, e.target.value)}
                variant="standard"
                disabled={processing}
                name={`class.${classIndex}.${rightClassHeaderText}.attribute.${index}`}
              >
                {rightAttributes.length ? (
                  rightAttributes.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {renderAttributeIcon(name)} {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <FormattedMessage
                      id="schemaMapper.attributes.attributeSelect.noneFound"
                      defaultMessage="No attributes found"
                    />
                  </MenuItem>
                )}
              </TextField>
              <IconButton
                size="small"
                disabled={!rightAttribute || processing}
                aria-label={intl.formatMessage({
                  id: 'schemaMapper.attributes.clearAttribute.ariaLabel',
                  defaultMessage: 'Clear attribute',
                })}
                sx={{ ml: 1 }}
                onClick={() => handleClearAttribute(leftAttribute.name, rightAttribute!)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <Typography
              sx={{
                alignItems: 'center',
                borderBottom: '1px dotted',
                minHeight: '32px',
              }}
            >
              {rightAttribute}
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableRow sx={{ borderTop: 0 }}>
      <TableCell
        size="medium"
        sx={[{ p: 0 }, !classAttributesOpen ? { borderBottom: 0 } : {}]}
        colSpan={4}
      >
        <Collapse in={classAttributesOpen} timeout="auto" unmountOnExit>
          <Box
            padding={2}
            sx={(theme) => ({
              borderWidth: theme.spacing(2),
              borderColor: theme.palette.grey[50],
              borderStyle: 'solid',
            })}
          >
            <Box mb={2}>
              <Grid container spacing={3}>
                <Grid item xs={6} xl={3}>
                  <TextField
                    label={intl.formatMessage({
                      id: 'schemaMapper.attributes.attributeNameFilter.label',
                      defaultMessage: 'Attribute Name',
                    })}
                    value={attributeFilter}
                    onChange={(event) => setAttributeFilter(event.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} xl={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>
                      <FormattedMessage
                        id="schemaMapper.attributes.attributeStatusFilter.label"
                        defaultMessage="Mapping Status"
                      />
                    </InputLabel>
                    <Select
                      name="attributeStatusFilter"
                      id="attributes-mapping-status-filter"
                      label={intl.formatMessage({
                        id: 'schemaMapper.attributes.attributeStatusFilter.label',
                        defaultMessage: 'Mapping Status',
                      })}
                      multiple
                      value={attributeStatusFilter}
                      onChange={(event) =>
                        setAttributeStatusFilter(event.target.value as AttributeMappingStatus[])
                      }
                      renderValue={(selected) =>
                        (selected as AttributeMappingStatus[])
                          .map((status) => ATTRIBUTE_MAPPING_STATUS_METADATA[status].label)
                          .join(', ')
                      }
                      fullWidth
                    >
                      {attributeMappingStatuses.map((status) => (
                        <MenuItem
                          key={status}
                          value={status}
                          sx={{
                            '& .MuiListItem-root, &.Mui-selected': {
                              backgroundColor: 'rgba(0, 0, 0, 0)',
                            },
                          }}
                        >
                          <Checkbox checked={attributeStatusFilter.indexOf(status) > -1} />
                          <ListItemText primary={ATTRIBUTE_MAPPING_STATUS_METADATA[status].label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    height="100%"
                  >
                    <LeftValidationResults sourceClass={sourceClass} targetClass={targetClass} />
                    <RightValidationResults sourceClass={sourceClass} targetClass={targetClass} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Table
              size="small"
              sx={{
                '& .MuiTableCell-root': {
                  border: 'none',
                  p: 1,
                },
              }}
            >
              <StyledTableHead>
                <TableRow>
                  <TableCell>
                    <Typography>{leftAttributeHeaderText}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{rightAttributeHeaderText}</Typography>
                  </TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {filteredAttributes.length ? (
                  filteredAttributes.map((targetAttribute, index) =>
                    renderAttributeRow(targetAttribute, index)
                  )
                ) : (
                  <TableInfoRow
                    colSpan={2}
                    size="medium"
                    message={intl.formatMessage({
                      id: 'schemaMapper.attributes.noAttributes',
                      defaultMessage: 'No attributes match the filters provided',
                    })}
                  />
                )}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default DataStoreClassAttributes;
