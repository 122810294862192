import { FC } from 'react';
import { CardContent, Typography, Grid, Box } from '@mui/material';

import ConfigurationInvalid from '../../screens/projects/project/ConfigurationInvalid';
import { ProjectDetail } from '../../types';

import ProjectCardImage from './ProjectCardImage';
import { NavigationCard } from '.';

interface ProjectCardProps {
  project: ProjectDetail;
  projectUrl: string;
}

const ProjectCard: FC<ProjectCardProps> = ({ project, projectUrl, ...rest }) => (
  <Grid item xs={12} sm={6} lg={4} xl={3}>
    <NavigationCard
      to={projectUrl}
      key={project.key}
      sx={!project.active ? { opacity: 0.7, filter: 'grayscale(50%)' } : {}}
      {...rest}
    >
      <ProjectCardImage project={project} sx={{ height: 200 }} />

      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography gutterBottom variant="h5" component="h2">
            {project.name}
          </Typography>
          <ConfigurationInvalid invalid={!project.allActiveSpecificationsValid} />
        </Box>
        <Typography variant="body2" color="textSecondary" component="p">
          {project.description}
        </Typography>
      </CardContent>
    </NavigationCard>
  </Grid>
);

export default ProjectCard;
