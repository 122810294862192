import React, { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ValidateFieldsError } from 'async-validator';

import { Dialog, DialogTitle, DialogContent, MenuItem } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import * as SpecificationsApi from '../../../../api/specification/specifications';
import { AxiosApiError, extractErrorMessage } from '../../../../api/endpoints';
import { ValidatedTextField, PaddedDialogActions, DefaultButton } from '../../../../components';
import {
  SpecificationSettings,
  SpecificationDetail,
  MediaRetentionPolicy,
  ConformanceMode,
  mediaRetentionPolicies,
  MEDIA_RETENTION_POLICY_LABELS,
} from '../../../../types';
import { editablePeriodToIso, onEnterCallback } from '../../../../util';
import { specificationSettingsValidator, validate } from '../../../../validation';
import { intl } from '../../../../Internationalization';

import { ProjectContext } from '../ProjectContext';

interface NewSpecificationFormProps {
  toSpecificationUrl: (specification: SpecificationDetail) => string;
  onCancel: () => void;
}

const NewSpecificationForm: FC<NewSpecificationFormProps> = ({ toSpecificationUrl, onCancel }) => {
  const { projectKey } = useContext(ProjectContext);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [timeoutPeriod] = useState<string>('P1D');
  const [autoClosePeriod] = useState<string>('P1D');
  const [mediaRetentionPolicy, setMediaRetentionPolicy] = useState<MediaRetentionPolicy>();
  const [mediaRetentionPeriod, setMediaRetentionPeriod] = useState<string>('7d');
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validator = specificationSettingsValidator(() => projectKey);

  const onSubmit = () => {
    setProcessing(true);
    setFieldErrors(undefined);
    validateAndSubmit();
  };

  const submitOnEnter = onEnterCallback(onSubmit);

  const validateAndSubmit = async () => {
    const specification: Partial<SpecificationSettings> = {
      name,
      description,
      active: true,
      allowSourceUnmapped: true,
      allowTargetUnmapped: false,
      showTargetUnmappedWarning: true,
      showInformation: false,
      enableMap: true,
      documentation: '<p></p>',
      timeoutPeriod,
      autoClosePeriod,
      mediaRetentionPolicy,
      mediaRetentionPeriod: editablePeriodToIso(mediaRetentionPeriod),
      conformanceMode: ConformanceMode.CHECK_RULES_TASKS,
    };

    try {
      createSpecification(await validate(validator, specification));
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const createSpecification = (specification: SpecificationSettings) => {
    SpecificationsApi.createSpecification(projectKey, specification)
      .then((response) => {
        navigate(toSpecificationUrl(response.data));
        enqueueSnackbar(
          intl.formatMessage(
            {
              id: 'specification.create.createSuccess',
              defaultMessage: 'Specification {name} has been created',
            },
            { name: specification.name }
          ),
          { variant: 'success' }
        );
      })
      .catch((error: AxiosApiError) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'specification.create.createError',
              defaultMessage: 'Failed to create specification',
            })
          ),
          { variant: 'error' }
        );
        setProcessing(false);
      });
  };

  return (
    <Dialog
      id="add-specification-dialog"
      onClose={onCancel}
      aria-labelledby="add-specification-dialog-title"
      open={true}
      fullWidth
    >
      <DialogTitle id="add-specification-dialog-title">
        <FormattedMessage id="specification.create.title" defaultMessage="Add Specification" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidatedTextField
          tooltip={intl.formatMessage({
            id: 'specification.create.name.tooltip',
            defaultMessage: 'A short name for the specification.',
          })}
          fieldErrors={fieldErrors}
          disabled={processing}
          name="name"
          label={intl.formatMessage({
            id: 'specification.create.name.label',
            defaultMessage: 'Name',
          })}
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          tooltip={intl.formatMessage({
            id: 'specification.create.description.tooltip',
            defaultMessage:
              'Detailed free text description of the specification, shown to the suppliers on the "My Assignments" screen.',
          })}
          fieldErrors={fieldErrors}
          disabled={processing}
          name="description"
          label={intl.formatMessage({
            id: 'specification.create.description.label',
            defaultMessage: 'Description',
          })}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          tooltip={intl.formatMessage({
            id: 'specification.create.mediaRetentionPolicy.tooltip',
            defaultMessage:
              'The media retention policy controls how long uploaded data, output data and logs remain on the system.',
          })}
          label={intl.formatMessage({
            id: 'specification.create.mediaRetentionPolicy.label',
            defaultMessage: 'Media Retention Policy',
          })}
          select
          fieldErrors={fieldErrors}
          name="mediaRetentionPolicy"
          value={mediaRetentionPolicy || ''}
          fullWidth
          margin="normal"
          variant="outlined"
        >
          {mediaRetentionPolicies.map((policy) => (
            <MenuItem key={policy} value={policy} onClick={() => setMediaRetentionPolicy(policy)}>
              {MEDIA_RETENTION_POLICY_LABELS[policy]}
            </MenuItem>
          ))}
        </ValidatedTextField>
        <ValidatedTextField
          tooltip={intl.formatMessage({
            id: 'specification.create.mediaRetentionPeriod.tooltip',
            defaultMessage:
              'Only applies if "Retain for period" is selected. Defines the length of time before media is automatically removed. Period format must be in descending order, e.g. 1y 2m 10d or 1m 20d',
          })}
          label={intl.formatMessage({
            id: 'specification.create.mediaRetentionPeriod.label',
            defaultMessage: 'Media Retention Period',
          })}
          fieldErrors={fieldErrors}
          name="mediaRetentionPeriod"
          value={mediaRetentionPeriod}
          onChange={(e) => setMediaRetentionPeriod(e.target.value)}
          margin="normal"
          variant="outlined"
          disabled={mediaRetentionPolicy !== MediaRetentionPolicy.PERIOD || processing}
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancelAddNewSpecification"
          color="secondary"
          onClick={onCancel}
          disabled={processing}
        >
          <FormattedMessage id="specification.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          name="addNewSpecification"
          onClick={onSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage
            id="specification.create.createButton"
            defaultMessage="Add Specification"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NewSpecificationForm;
